@use "sass:math";
/* @import url('https://fonts.cdnfonts.com/css/ananda-black-personal-use');
@import url('https://fonts.cdnfonts.com/css/poppins'); */
/* src css file */
/* @import url("../fonts/fontawesome/Ananda BlackRegular-rg9Rx.ttf"); */
:root {
    --theme-color: #0060FF;
    --title-color: #1c1c1c;
    --body-color: #737887;
    --smoke-color: #F5F5F5;
    --smoke-color2: #E2E8FA;
    --black-color: #000000;
    --black-color2: #080E1C;
    --gray-color: #bdbdbd;
    --white-color: #ffffff;
    --light-color: #72849B;
    --yellow-color: #FFB539;
    --success-color: #28a745;
    --error-color: #dc3545;
    --border-color: #E0E0E0;
    --title-font: 'Barlow', sans-serif;
    --icon-font: "Font Awesome 6 Pro";
    --main-container: 1220px;
    --container-gutters: 24px;
    --section-space: 120px;
    --section-space-mobile: 80px;
    --section-title-space: 60px;
    --ripple-ani-duration: 5s;
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;
}

.process-card_icon img {
    max-width: 20% !important;
}

html,
body {
    scroll-behavior: auto !important;
}
body::-webkit-scrollbar {
    width: 11px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
body {
    font-family: var(--title-font) !important;
    font-size: 16px;
    font-weight: 400;
    color: var(--body-color);
    line-height: 26px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    height: inherit;
}
.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

iframe {
    border: none;
    width: 100%
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
    outline: none
}

input:focus {
    outline: none;
    box-shadow: none
}

.service-card .shape-icon img {
    margin-top: 23px;
}

.servicepage.show {
    color: #0060FF !important;

}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1320px !important;
}

img:not([draggable]),
embed,
object,
video {
    max-width: 100%;
    height: auto;

}

ul {
    list-style-type: disc
}

ol {
    list-style-type: decimal
}

table {
    margin: 0 0 1.5em;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid var(--border-color)
}

th {
    font-weight: 700;
    color: var(--title-color)
}

td,
th {
    border: 1px solid var(--border-color);
    padding: 9px 12px;
}

a {
    color: #122139;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    text-decoration: none !important;
}

a:active,
a:focus,
a:hover,
a:visited {
    text-decoration: none;
    outline: 0
}

button {
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

img {
    border: none;
    max-width: 100%
}

ins {
    text-decoration: none
}
pre {
    font-family: var(--body-font);
    background: #f5f5f5;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre-wrap;
    word-wrap: break-word
}
span.ajax-loader:empty,
p:empty {
    display: none
}

p {
    font-family: var(--body-font);
    margin: 0 0 18px 0;
    color: #2f3033;
    line-height: 1.75;
    font-size: 1.4rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: 0.5;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    font-family: var(--title-font);
    color: var(--title-color);
    text-transform: none;
    font-weight: 700;
    line-height: 1.4;
    margin: 0 0 15px 0
}

.h1,
h1 {
    font-size: 48px;
    line-height: 1.208
}

.h2,
h2 {
    font-size: 40px;
    line-height: 1.25;
    font-weight: 700 !important;
}

.h3,
h3 {
    font-size: 36px;
    line-height: 1.278
}

.h4,
h4 {
    font-size: 30px;
    line-height: 1.333
}

.h5,
h5 {
    font-size: 24px;
    line-height: 1.417
}

.h6,
h6 {
    font-size: 20px;
    line-height: 1.5
}

@media (max-width: 1199px) {

    .h1,
    h1 {
        font-size: 44px;
        line-height: 1.3
    }

    .h2,
    h2 {
        font-size: 36px;
        line-height: 1.3
    }

    .h3,
    h3 {
        font-size: 30px
    }

    .h4,
    h4 {
        font-size: 24px
    }

    .h5,
    h5 {
        font-size: 20px
    }

    .h6,
    h6 {
        font-size: 16px
    }
}

@media (max-width: 767px) {

    .h1,
    h1 {
        font-size: 40px
    }

    .h2,
    h2 {
        font-size: 34px;
        line-height: 1.3
    }

    .h3,
    h3 {
        font-size: 26px
    }

    .h4,
    h4 {
        font-size: 22px
    }

    .h5,
    h5 {
        font-size: 18px
    }

    .h6,
    h6 {
        font-size: 16px
    }

    .feature-main .features-list li {
        margin: 0 !important;
    }
}

@media (max-width: 575px) {

    .h1,
    h1 {
        font-size: 34px;
        line-height: 1.35
    }

    .h2,
    h2 {
        font-size: 28px
    }
}

@media (max-width: 375px) {

    .h1,
    h1 {
        font-size: 32px
    }
}

@media (max-width: 1399px) {
    :root {
        --main-container: 1250px
    }
}

@media (min-width: 1370px) {
    .as-container2 {
        --main-container: 1470px
    }

    .as-container2 .container {
        --main-container: 1220px
    }
}

@media (min-width: 1922px) {
    .as-container2 {
        margin-left: auto;
        margin-right: auto
    }
}

@media only screen and (min-width: 1300px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: calc(var(--main-container) + var(--container-gutters));
        padding-left: calc(var(--container-gutters) / 2);
        padding-right: calc(var(--container-gutters) / 2)
    }

    .container-xxl.px-0,
    .container-xl.px-0,
    .container-lg.px-0,
    .container-md.px-0,
    .container-sm.px-0,
    .container.px-0 {
        max-width: var(--main-container)
    }
}

@media only screen and (min-width: 1300px) {
    .as-container {
        --main-container: 1720px
    }
}

@media only screen and (max-width: 1600px) {
    .container-fluid.px-0 {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .container-fluid.px-0 .row {
        margin-left: 0 !important;
        margin-right: 0 !important
    }
}

.as-container3 {
    max-width: 1520px
}

.round-container {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    position: relative;
    z-index: 2;
    overflow: hidden
}

@media (max-width: 1199px) {
    .round-container {
        border-radius: 0
    }

}

.slick-track>[class*=col] {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;    
    margin-top: var(--bs-gutter-y)
}

.gy-30 {
    --bs-gutter-y: 30px
}

.gy-40 {
    --bs-gutter-y: 40px
}

.gy-50 {
    --bs-gutter-y: 50px
}

.gx-10 {
    --bs-gutter-x: 10px
}

@media (min-width: 1299px) {
    .gx-60 {
        --bs-gutter-x: 60px
    }

}

@media (min-width: 1399px) {
    .gx-30 {
        --bs-gutter-x: 30px
    }

    .gx-25 {
        --bs-gutter-x: 25px
    }

    .gx-40 {
        --bs-gutter-x: 40px
    }
}

@media (max-width: 991px) {
    .gy-50 {
        --bs-gutter-y: 40px
    }
}

select,
.form-control,
.form-select,
textarea,
input {
    height: 56px;
    padding: 0 25px 0 25px;
    padding-right: 45px;
    border: 1px solid transparent;
    color: var(--body-color);
    background-color: var(--smoke-color);
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    font-family: var(--body-font);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

select:focus,
.form-select:focus,
textarea:focus,
input:focus {
    outline: 0;
    box-shadow: none;
    border-color: #122139;
    background-color: var(--smoke-color)
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
    color: var(--body-color)
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: var(--body-color)
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
    color: var(--body-color)
}

select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: var(--body-color)
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
    color: var(--body-color)
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
    color: var(--body-color)
}

select::-ms-input-placeholder,
.form-control::-ms-input-placeholder,
.form-select::-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::-ms-input-placeholder {
    color: var(--body-color)
}

select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
    color: var(--body-color)
}

.space-top .slick-arrow {
    display: none !important;
}

.slick-track {
    height: 392px !important;
}

.service-featured .icon-btn i {
    line-height: 3.5;
}

.form-select,
select {
    display: block;
    width: 100%;
    line-height: 1.5;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 26px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer
}

.form-select.style2,
select.style2 {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87109 1.71094L5.71484 5.62109C5.56901 5.7487 5.41406 5.8125 5.25 5.8125C5.08594 5.8125 4.9401 5.7487 4.8125 5.62109L0.65625 1.71094C0.382812 1.40104 0.373698 1.09115 0.628906 0.78125C0.920573 0.507812 1.23047 0.498698 1.55859 0.753906L5.25 4.25391L8.96875 0.753906C9.27865 0.498698 9.57943 0.498698 9.87109 0.753906C10.1263 1.08203 10.1263 1.40104 9.87109 1.71094Z' fill='%238B929C'/%3E%3C/svg%3E")
}

textarea.form-control,
textarea {
    min-height: 154px;
    padding-top: 16px;
    padding-bottom: 17px
}

textarea.form-control.style2,
textarea.style2 {
    min-height: 100px
}

.form-group {
    margin-bottom: 10px;
    position: relative
}

.form-group>i {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 19px;
    font-size: 16px;
    color: var(--body-color)
}

.form-group>i.fa-envelope {
    padding-top: 1px
}

.form-group>i.fa-comment {
    margin-top: -2px
}

.form-group>i.fa-chevron-down {
    width: 17px;
    background-color: var(--smoke-color)
}

.form-group.has-label>i {
    top: 50px
}

option:checked,
option:focus,
option:hover {
    background-color: #122139;
    color: var(--white-color)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none
}

input[type="checkbox"]:checked~label:before {
    content: "\f00c";
    color: var(--white-color);
    background-color: #122139;
    border-color: #122139;
}

input[type="checkbox"]~label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: block
}

input[type="checkbox"]~label:before {
    content: "";
    font-family: var(--icon-font);
    font-weight: 700;
    position: absolute;
    left: 0px;
    top: 3.5px;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    height: 18px;
    width: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 12px
}

input[type="checkbox"].style2~label {
    color: #8B929C;
    padding-left: 23px;
    margin-bottom: -0.5em
}

input[type="checkbox"].style2~label:before {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #8B929C;
    height: 14px;
    width: 14px;
    line-height: 14px;
    border-radius: 3px;
    top: 6px
}

input[type="checkbox"].style2:checked~label:before {
    color: #122139;
}

input[type="radio"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none
}

input[type="radio"]~label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0
}

input[type="radio"]~label::before {
    content: "\f111";
    position: absolute;
    font-family: var(--icon-font);
    left: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    padding-left: 0;
    font-size: 0.6em;
    line-height: 19px;
    text-align: center;
    border: 1px solid #122139;
    border-radius: 100%;
    font-weight: 700;
    background: var(--white-color);
    color: transparent;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease
}

input[type="radio"]:checked~label::before {
    border-color: #122139;
    background-color: #122139;
    color: var(--white-color)
}

label {
    margin-bottom: 0.5em;
    margin-top: -0.3em;
    display: block;
    color: var(--title-color);
    font-family: var(--body-font);
    font-size: 16px
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
    border: 1px solid var(--error-color) !important;
    background-position: right calc(0.375em + 0.8875rem) center;
    background-image: none
}

textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
    outline: 0;
    box-shadow: none
}

textarea.is-invalid {
    background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem)
}

.row.no-gutters>.form-group {
    margin-bottom: 0
}

.form-messages {
    display: none
}

.form-messages.mb-0 * {
    margin-bottom: 0
}

.form-messages.success {
    color: var(--success-color);
    display: block
}

.form-messages.error {
    color: var(--error-color);
    display: block
}

.form-messages pre {
    padding: 0;
    background-color: transparent;
    color: inherit
}

.slick-track>[class*=col] {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--bs-gutter-y)
}

.slick-track {
    min-width: 100%
}

.slick-list {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden
}

.slick-slide img {
    display: inline-block;
}

.space-org .space-bottom .slider-shadow .slick-initialized .slick-list {
    height: 560px !important;
}

.container .slider-five .slick-slider .slick-list,
.bg-top-right .container .slider-five .slick-slider .slick-list {
    height: 105px !important;
}

.set-tech .as-carousel .slick-slider .slick-arrow {
    display: none !important;
}

.slick-dots {
    list-style-type: none;
    padding: 0;
    margin: 40px 0 0px 0;
    line-height: 0;
    text-align: center;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content
}

.counter-card-wrap .counter-card .counter-card_icon img {
    margin-top: 28px;
}

.slick-dots ul {
    position: relative;
}

.slick-dots li {
    display: inline-block;
    margin-right: 15px;
    position: absolute;
    right: -132.6px;
    z-index: -999;
}

.slick-dots li:nth-child(1) {
    top: -71px;
}

.slick-dots li:nth-child(2) {
    top: 24px;
}

.slick-dots li:nth-child(3) {
    top: 119px;
}

.slick-dots li:last-child {
    bottom: -57px;
}

.slick-dots button {
    font-size: 0;
    padding: 0;
    z-index: 99999;
    width: 56px;
    height: 56px;
    left: 23px;
    bottom: -27px;
    line-height: 0;
    border-radius: 9999px;
    border: none;
    background-color: var(--border-color);
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    position: relative
}

.slick-dots button:hover {
    border-color: #122139;
}

.slick-dots button:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    margin: -10px 0 0 -10px;
    background-color: #122139;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    opacity: 0;
    visibility: hidden
}

.slick-dots .slick-active button {
    background-color: #122139;
}

.slick-dots .slick-active button::before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.slick-arrow {
    display: inline-block;
    padding: 0;
    background-color: #122139;
    color: var(--white-color);
    position: absolute;
    top: 50%;
    border: none;
    font-size: 14px !important;
    left: var(--pos-x, -100px);
    width: var(--icon-size, 56px);
    height: var(--icon-size, 56px);
    line-height: var(--icon-size, 56px);
    font-size: var(--icon-font-size, 16px);
    z-index: 2;
    border-radius: 99px
}

.slick-arrow.default {
    position: relative;
    --pos-x: 0;
    margin-top: 0
}

.slick-arrow.slick-next {
    right: var(--pos-x, -100px);
    left: auto
}

.slick-arrow:hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

.arrow-margin .slick-arrow {
    top: calc(50% - 30px)
}

.arrow-wrap .slick-arrow {
    opacity: 0;
    visibility: hidden
}

.arrow-wrap:hover .slick-arrow {
    opacity: 1;
    visibility: visible
}

@media (max-width: 1500px) {
    .slick-arrow {
        --arrow-horizontal: -20px;
        --pos-x: -70px
    }
}

@media (max-width: 1399px) {
    .slick-arrow {
        --arrow-horizontal: 40px;
    }
}

@media (max-width: 991px) {
    .slick-arrow {
        --icon-size: 40px;
        line-height: 38px;
        margin-right: 40px;
        font-size: 14px;
        margin-top: -82px;
        display: none !;
    }

    .slick-arrow.slick-next {
        margin-right: 0;
        margin-left: 40px
    }

    .slick-dots {
        margin: 40px 0 0 0
    }

    .icon-box .slick-arrow {
        margin-right: 0
    }
}

.slick-3d-active {
    margin-left: -12%;
    margin-right: -12%
}

.slick-3d-active .slick-list {
    padding-left: 30% !important;
    padding-right: 30% !important
}

.slick-3d-active .slick-track {
    max-width: 100% !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    -webkit-perspective: 100px;
    perspective: 100px
}

.slick-3d-active .slick-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    width: 100% !important;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 1s, -webkit-transform 1s;
    transition: opacity 1s, -webkit-transform 1s;
    transition: transform 1s, opacity 1s;
    transition: transform 1s, opacity 1s, -webkit-transform 1s
}

.slick-3d-active .slick-3d-next,
.slick-3d-active .slick-3d-prev,
.slick-3d-active .slick-3d-next2,
.slick-3d-active .slick-3d-prev2 {
    display: block
}

.slick-3d-active .slick-current {
    opacity: 1;
    position: relative;
    display: block;
    z-index: 2
}

.slick-3d-active .slick-3d-next {
    opacity: 1;
    -webkit-transform: translate3d(50%, 0, -21px);
    transform: translate3d(50%, 0, -21px);
    z-index: 1;
    -webkit-perspective: 1000px;
    perspective: 1000px
}

.slick-3d-active .slick-3d-next2 {
    opacity: 1;
    -webkit-transform: translate3d(40%, 0, -23px);
    transform: translate3d(40%, 0, -23px);
    z-index: 0;
    -webkit-perspective: 1000px;
    perspective: 1000px
}

.slick-3d-active .slick-3d-prev {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, -21px);
    transform: translate3d(-50%, 0, -21px)
}

.slick-3d-active .slick-3d-prev .testi-card {
    box-shadow: none
}

.slick-3d-active .slick-3d-prev2 {
    opacity: 1;
    -webkit-transform: translate3d(-40%, 0, -23px);
    transform: translate3d(-40%, 0, -23px)
}

.as-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999999;
    width: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    opacity: 0;
    visibility: hidden
}

.as-menu-wrapper .mobile-logo {
    padding-bottom: 30px;
    padding-top: 40px;
    display: block;
    text-align: center;
    background-color: var(--smoke-color2)
}

.as-menu-wrapper .mobile-logo img {
    max-width: 185px
}

.as-menu-wrapper .as-menu-toggle {
    border: none;
    font-size: 22px;
    position: absolute;
    right: -21.5px;
    top: 25px;
    padding: 0;
    line-height: 1;
    width: 40px;
    height: 40px;
    line-height: 35px;
    font-size: 18px;
    z-index: 1;
    color: var(--white-color);
    background-color: #1c1c1c;
    border-radius: 50%
}

.as-menu-wrapper .as-menu-toggle:hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

.as-menu-wrapper .as-menu-area {
    width: 100%;
    max-width: 310px;
    background-color: #fff;
    border-right: 3px solid #122139;
    height: 100%;
    position: relative;
    left: -110%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    z-index: 1
}

.as-menu-wrapper.as-body-visible {
    opacity: 1;
    visibility: visible
}

.as-menu-wrapper.as-body-visible .as-menu-area {
    left: 0;
    opacity: 1;
    visibility: visible
}

.as-mobile-menu {
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
    padding-bottom: 40px;
    margin-top: 33px;
    text-align: left
}

.as-mobile-menu ul {
    margin: 0;
    padding: 0 0;
}

.as-mobile-menu ul li {
    border-bottom: 1px solid #fdedf1;
    list-style-type: none;
    position: relative;
}

.as-mobile-menu .sub-menu-one,
.as-mobile-menu .sub-menu-two,
.as-mobile-menu .sub-menu-three,
.as-mobile-menu .sub-menu-four,
.as-mobile-menu .sub-menu-five {
    display: none;
}

.menu-item-has-children .show {
    color: #00a3ff;
    font-weight: 400;
}
.menu-item-has-children a.active{
    color: #00a3ff;
}
.widget_shopping_cart .woocommerce-mini-cart li.set-box a {
    font-style: normal;
    font-size: 20px;
}

.widget_shopping_cart .woocommerce-mini-cart li.active.sub-item a,
.widget_shopping_cart .woocommerce-mini-cart li a:focus,
.widget_shopping_cart .woocommerce-mini-cart li.active.sub-item a,
.widget_shopping_cart .woocommerce-mini-cart li a:hover {
    width: 412px;
    transition: all 0.5s;
}

.as-mobile-menu ul li li:first-child {
    border-top: 1px solid #fdedf1;
}

.widget_shopping_cart .woocommerce-mini-cart li a {
    transition: all 0.5s;
}

.widget_shopping_cart .woocommerce-mini-cart li a.active {
    border-bottom: 1px solid #FFFFFFFF !important;
    width: 250px !important;
}

.widget_shopping_cart .woocommerce-mini-cart li a.game-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.web-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.mobile-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.node,
.widget_shopping_cart .woocommerce-mini-cart li a.react,
.widget_shopping_cart .woocommerce-mini-cart li a.unity,
.widget_shopping_cart .woocommerce-mini-cart li a.uiux {
    font-family: 'Ananda Black Personal Use';
}
.react-tabs__tab:focus:after{
    display: none !important;
}
.widget_shopping_cart .widget_shopping_cart_content .cart_list a.ios-dev:focus>a.mobile-dev {
    border: none !important;
}

.widget_shopping_cart .cart_list a.active {
    display: block !important;
}

.as-mobile-menu ul li a {
    display: block;
    position: relative;
    padding: 12px 0;
    line-height: 1.4;
    font-size: 16px;
    text-transform: capitalize;
    color: #000;
    padding-left: 18px
}

.as-mobile-menu ul li.services.show a.servicepage:before {
    content: '\f105';
    font-family: var(--icon-font);
    position: absolute;
    left: 0;
    top: 12px;
    margin-right: 10px;
    display: inline-block;
    transform: rotate(90deg) !important;
}

.as-mobile-menu ul li.services.show a.servicepage {
    color: #0060f8;
}

.as-mobile-menu ul li.services.show>a.home {
    color: #000 !important;
}

.as-mobile-menu ul li a.show::before,
.as-mobile-menu ul li a.show.home,
.as-mobile-menu ul li a.show.services,
.as-mobile-menu ul li a.show.blog,
.as-mobile-menu ul li a.show.pages {
    color: #0060f8;
    font-weight: 600;
}

.as-mobile-menu ul li a.servicepage:before {
    content: '\f105';
    font-family: var(--icon-font);
    position: absolute;
    left: 0;
    top: 12px;
    margin-right: 10px;
    display: inline-block;
}

.as-mobile-menu ul li.as-active>a {
    color: #122139;
}

.as-mobile-menu ul li.as-active>a:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.as-mobile-menu ul li ul li {
    padding-left: 20px
}

.as-mobile-menu ul li ul li:last-child {
    border-bottom: none
}

.as-mobile-menu ul .as-item-has-children>a .as-mean-expand {
    position: absolute;
    right: 0;
    top: 50%;
    font-weight: 400;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-top: -12.5px;
    display: inline-block;
    text-align: center;
    background-color: var(--smoke-color);
    color: var(--title-color);
    box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
    border-radius: 50%
}

.as-mobile-menu ul .as-item-has-children>a .as-mean-expand:before {
    content: '\f067';
    font-family: var(--icon-font)
}

.as-mobile-menu ul .as-item-has-children>a:after {
    content: "\f067";
    font-family: var(--icon-font);
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    border-radius: 50px;
    background-color: var(--smoke-color);
    float: right;
    margin-top: 1px
}

.as-mobile-menu ul .as-item-has-children.as-active>a .as-mean-expand:before {
    content: '\f068'
}

.as-mobile-menu ul .as-item-has-children.as-active>a:after {
    content: "\f068"
}

.as-mobile-menu>ul {
    padding: 0 40px
}

.as-mobile-menu>ul>li:last-child {
    border-bottom: none
}

.as-menu-toggle {
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 20px;
    border: none;
    background-color: #8f8f8f;
    color: var(--white-color);
    display: inline-block;
    border-radius: 4px
}

.as-menu-toggle:hover {
    background-color: var(--title-color)
}

.as-menu-toggle.style-text,
.as-menu-toggle.style-text-white {
    width: auto;
    height: auto;
    background-color: transparent;
    color: var(--title-color);
    font-size: 20px
}

.as-menu-toggle.style-text i,
.as-menu-toggle.style-text-white i {
    margin-right: 10px
}

.as-menu-toggle.style-text-white {
    color: var(--white-color)
}

@media (max-width: 400px) {
    .as-menu-wrapper .as-menu-area {
        width: 100%;
        max-width: 270px
    }

    .as-mobile-menu>ul {
        padding: 0 20px
    }
}

.preloader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: #040524
}

.preloader .as-btn {
    padding: 15px 20px;
    border-radius: 0;
    font-size: 14px
}

.preloader-inner {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 1
}

.preloader-inner img {
    display: block;
    margin: 0 auto 0 auto
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}


.as-btn {
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;    
    text-transform: uppercase;
    text-align: center;
    background-color: #1255dc;
    color: var(--white-color);
    font-family: var(--body-font);
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 20.5px 20px;
    min-width: 144px;
    border-radius: 4px;
    display: inline-block;
    font-weight: 700;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
}
.as-btn::before{
    background-color: #FFFFFF;
    border-radius: 50px;
    bottom: -100%;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: -100%;
    width: 100%;
    transform: scaleY(0);
    transform-origin: bottom;
    transition-duration: 1s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.14, 0, 0, 1.01);
    will-change: transform;
    z-index: -1;
}
.as-btn:hover{
    color: #1255dc;    
    transition: all 0.5s;
    background-color: transparent;
}
.as-btn:hover:before{
    transform: scaleY(1);
    transform-origin: top;   
}
.as-btn:hover{
    color: #1255dc;    
    transition: all 0.5s;
}

.as-btn:hover::before{
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.as-btn.style3:hover{
    color: #FFFFFF;
}
.as-btn.style2 {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
    padding: 20px 40px;
    box-shadow: none
}

.as-btn.style2:hover {
    color: var(--title-color);
    border-color: var(--white-color)
}

.as-btn.style2:hover:before,
.as-btn.style2:hover:after {
    background-color: #FFFFFF !important;
}

.as-btn.style3:hover:before,
.as-btn.style3:hover:after {
    background-color: #1c1c1c;
    color: #FFFFFFFF;
}

.as-btn.style4 {
    background-color: var(--title-color);
    color: var(--white-color)
}

.as-btn.style4:hover {
    color: var(--title-color)
}

.as-btn.style4:hover:before,
.as-btn.style4:hover:after {
    background-color: var(--white-color)
}

.as-btn.style5 {
    background-color: var(--white-color);
    color: var(--title-color);
    box-shadow: none
}

.as-btn.style5:hover {
    color: var(--white-color)
}

.as-btn.style5:hover:before,
.as-btn.style5:hover:after {
    background-color: var(--title-color)
}

.as-btn.btn-fw {
    width: 100%
}

.as-btn.btn-fw:before,
.as-btn.btn-fw:after {
    display: none
}

.as-btn.btn-fw:hover {
    background-color: var(--title-color)
}

.contact-feature .icon-btn i {
    line-height: 2.4;
}

.icon-btn {
    display: inline-block;
    width: var(--btn-size, 56px);
    height: var(--btn-size, 56px);
    line-height: var(--btn-size, 56px);
    font-size: var(--btn-font-size, 16px);
    background-color: var(--icon-bg, transparent);
    color: var(--title-color);
    text-align: center;
    border-radius: 4px;
    border: 1px solid #b9c1db;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.icon-btn:hover {
    background-color: var(--white-color);
    color: #122139;
    border-color: var(--white-color)
}

.play-btn {
    display: inline-block;
    position: relative;
    z-index: 1
}

.play-btn>i {
    display: inline-block;
    width: var(--icon-size, 60px);
    height: var(--icon-size, 60px);
    line-height: var(--icon-size, 60px);
    text-align: center;
    background-color: var(--white-color);
    color: #122139;
    font-size: var(--icon-font-size, 1.1em);
    border-radius: 50%;
    z-index: 1;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.play-btn:after,
.play-btn:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white-color);
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.play-btn:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.play-btn:hover:after,
.play-btn:hover::before{
    background-color: #8f8f8f;
    color: var(--white-color)
}

.play-btn.style2:before,
.play-btn.style2:after {
    background-color: transparent;
    border: 1px solid var(--white-color)
}

.play-btn.style3>i {
    background-color: #122139;
    color: var(--white-color);
    font-size: 20px
}

.play-btn.style3:before,
.play-btn.style3:after {
    background-color: #122139;
}

.play-btn.style3:hover>i {
    background-color: var(--white-color);
    color: #122139;
}

.play-btn.style3:hover:before,
.play-btn.style3:hover:after {
    background-color: var(--white-color)
}

.link-btn {
    right: 90px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    line-height: 0.8;
    bottom: 21px;
    position: absolute;
    padding-bottom: 2px;
    margin-bottom: -2px;
    text-transform: uppercase;
    color: #122139;
    margin-top: 10px;
}

.link-btn i {
    margin-left: 5px;
    font-size: 0.9rem
}

.link-btn:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #122139;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.link-btn:hover {
    color: #122139;
}

.link-btn:hover::before {
    width: 100%
}

.line-btn {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    line-height: 0.8;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: -1px;
    text-transform: uppercase;
    color: #122139;
}

.line-btn i {
    margin-left: 5px;
    font-size: 0.9rem
}

.line-btn:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #122139;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.line-btn:hover {
    color: var(--title-color)
}

.line-btn:hover::before {
    background-color: var(--title-color);
    width: 45px
}

.scroll-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    z-index: 10000;
    opacity: 1;
    visibility: hidden;
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear
}

.scroll-top:after {
    content: "\f062";
    font-family: var(--icon-font);
    position: absolute;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #122139;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    border: 2px solid #122139;
    box-shadow: none;
    border-radius: 50%
}

.scroll-top svg {
    color: #122139;
    border-radius: 50%;
    background: var(--white-color)
}

.scroll-top svg path {
    fill: none
}

.scroll-top .progress-circle path {
    stroke: #122139;
    stroke-width: 20px;
    box-sizing: border-box;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear
}

.scroll-top.show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.sec-title {
    margin-bottom: calc(var(--section-title-space) - 10px);
    margin-top: -0.32em;
    text-transform: capitalize
}

.sub-title {
    display: block;
    font-size: 16px;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: #1255dc;
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: -0.34em
}

.sub-title:has(img) {
    margin-top: 0
}

.sub-title img {
    margin: 0 15px 0 0
}

.box-title {
    font-size: 27px !important;
    line-height: 1.417 !important;
    font-weight: 700 !important;
    margin-top: -0.32em !important;
}

.box-title a {
    color: inherit
}

.box-title a:hover {
    color: #122139;
}

.sec-text {
    max-width: 720px
}

.title-area {
    margin-bottom: calc(var(--section-title-space) - 10px);
    position: relative;
    z-index: 2
}

#service-secs .box-title {
    font-size: 19px !important;
    line-height: 1.417 !important;
    font-weight: 700 !important;
    margin-top: 1.4em !important;
}

.project-grid_text {
    font-size: 1rem !important;
}

.sales-box p {
    font-size: 1rem !important;
}

.section-title p {
    font-size: 1.3rem !important;
}

.title-area .sec-title {
    margin-bottom: 0;
}
.team-sec .title-area h2{
    font-size: 3rem !important;
}

.our-full-services {
    max-width: 508x;
    margin: 0 auto;
}
.text-size{
    font-size: 40px !important;
}

#service-secs .title-area .sec-title {
    color: #000000 !important;
}

.title-area.mb-0 .sec-title {
    margin-bottom: -0.41em
}

.title-area .as-btn {
    margin-top: 20px;
    margin-bottom: 10px
}

hr.title-line {
    margin-top: 0;
    background-color: var(--border-color);
    opacity: 1
}

.our-title {
    color: #ffff;
}

.sec-btn,
.title-line {
    margin-bottom: var(--section-title-space)
}

.shadow-title {
    font-family: var(--title-font);
    font-size: 74px;
    font-weight: 900;
    line-height: 1;
    background-image: -webkit-linear-gradient(top, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%);
    background-image: linear-gradient(180deg, rgba(226, 232, 250, 0.7) 0%, rgba(226, 232, 250, 0) 88.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: -0.55em 0 -0.45em -0.25em
}

.shadow-title.color2 {
    background-image: -webkit-linear-gradient(top, #232C47 0%, rgba(20, 29, 56, 0) 91.15%);
    background-image: linear-gradient(180deg, #232C47 0%, rgba(20, 29, 56, 0) 91.15%)
}

.shadow-title.color3 {
    background-image: -webkit-linear-gradient(top, #E0E0E0 0%, rgba(220, 214, 214, 0) 93.75%);
    background-image: linear-gradient(180deg, #E0E0E0 0%, rgba(220, 214, 214, 0) 93.75%)
}

@media (max-width: 1199px) {
    .sub-title {
        margin-bottom: 20px
    }

    .slick-arrow {
        --icon-size: 40px;
        line-height: 38px;
        margin-right: 40px;
        font-size: 14px;
        margin-top: -82px;
        display: none !important;
    }

    .shadow-title {
        font-size: 64px
    }

    .title-area,
    .sec-title {
        --section-title-space: 60px
    }

    .title-area.mb-45,
    .sec-title.mb-45 {
        margin-bottom: 36px
    }

    .title-area.mb-50,
    .sec-title.mb-50 {
        margin-bottom: 40px
    }

    .sec-btn,
    .title-line {
        --section-title-space: 55px
    }
}

@media (max-width: 991px) {
    .shadow-title {
        font-size: 60px
    }

    .title-area,
    .sec-title {
        --section-title-space: 50px
    }

    .title-area.mb-45,
    .sec-title.mb-45 {
        margin-bottom: 35px
    }

    .sec-btn,
    .title-line {
        --section-title-space: 50px
    }
}

@media (max-width: 575px) {
    .shadow-title {
        font-size: 52px
    }
}

@media (max-width: 375px) {
    .shadow-title {
        font-size: 40px
    }
}

.shape-mockup-wrap {
    z-index: 2;
    position: relative
}

.shape-mockup {
    position: absolute;
    z-index: -1;
    right: 0%;
    top: 0%;
}

.shape-mockup-one {
    position: absolute;
    z-index: -1;
    right: 0%;
    top: 0%;
}

.shape-mockup-two {
    position: absolute;
    z-index: -1;
    left: 0%;
    top: 44.5%;
}

.shape-mockup.z-index-3 {
    z-index: 3
}

.z-index-step1 {
    position: relative;
    z-index: 4 !important
}

.z-index-common {
    position: relative;
}

.z-index-3 {
    z-index: 3
}

.z-index-n1 {
    z-index: -1
}

.media-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.media-body .counter-card_text {
    font-size: 1.2rem !important;
}

.badge {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    text-align: center;
    background-color: #122139;
    color: var(--white-color);
    padding: 0.25em 0.45em;
    font-size: 0.7em;
    border-radius: 50%;
    top: 8px;
    right: 8px;
    font-weight: 400;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.as-social a {
    display: inline-block;
    width: var(--icon-size, 46px);
    height: var(--icon-size, 46px);
    line-height: var(--icon-size, 46px);
    background-color: var(--white-color);
    color: var(--body-color);
    font-size: 16px;
    text-align: center;
    margin-right: 5px;
    border-radius: 50%
}

.as-social a:last-child {
    margin-right: 0
}

.as-social a:hover {
    background-color: #122139;
    color: var(--white-color)
}

.as-social.style2 a {
    --icon-size: 40px;
    font-size: 14px;
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid
}

.as-social.style2 a:hover {
    border-color: #122139;
    color: var(--white-color);
    background-color: #122139;
}

.as-social.color-theme a {
    color: var(--body-color);
    border-color: #122139;
}

.slider-shadow .slick-list {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
    margin-top: -30px
}

.btn-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}

.btn-group.style2 {
    gap: 20px 70px
}

/* Header  */
/*------------------------
    Header Sticky 
--------------------------*/
.logo-custom-css .logo-dark {
    display: none;
}

.rn-icon-list .icon-box>a,
.rn-icon-list .icon-box>button {
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff14;
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #acacac;
    position: relative;
    z-index: 2;
}

.rn-icon-list .icon-box>a::after,
.rn-icon-list .icon-box>button::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #24243557;
    top: 0;
    left: 0;
    transform: scale(0.5);
    opacity: 0;
    border-radius: 100%;
    transition: 0.3s;
    z-index: -1;
}

.rn-icon-list .icon-box>a i,
.rn-icon-list .icon-box>button i {
    font-size: 16px;
}

.rn-icon-list .icon-box>a img,
.rn-icon-list .icon-box>button img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.rn-icon-list .icon-box:hover a {
    border-color: transparent;
}

.rn-icon-list .icon-box:hover a::after {
    transform: scale(1);
    opacity: 1;
}

.rn-icon-list.notification-badge {
    position: relative;
    z-index: 2;
}

.rn-icon-list.notification-badge span.badge {
    position: absolute;
    top: -6px;
    right: -2px;
    padding: 0 5px;
    background: #00a3ff;
    display: inline-block;
    border-radius: 500px;
    line-height: 20px;
    min-width: 20px;
    height: 20px;
}

.rn-icon-list.user-account {
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 479px) {
    .rn-icon-list.user-account {
        height: 70px;
    }
}

.rn-icon-list.user-account .rn-dropdown {
    min-width: 320px;
    height: auto;
    position: absolute;
    top: 90%;
    right: 0;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    padding: 30px 30px;
    transition: 0.3s;
    border-radius: 0 0 10px 10px;
    background-color: #13131d;
    border-left: 1px solid #ffffff14;
    border-bottom: 1px solid #ffffff14;
    border-right: 1px solid #ffffff14;
}

@media only screen and (max-width: 767px) {
    .rn-icon-list.user-account .rn-dropdown {
        min-width: 300px;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 575px) {
    .rn-icon-list.user-account .rn-dropdown {
        right: -142px;
    }
}

.rn-icon-list.user-account .rn-dropdown .rn-inner-top {
    display: block;
    margin-bottom: 26px;
}

.rn-icon-list.user-account .rn-dropdown .rn-inner-top .title {
    font-size: 16px;
    margin-bottom: 7px;
}

.rn-icon-list.user-account .rn-dropdown .rn-inner-top span a {
    display: block;
    color: #00a3ff;
    font-weight: 500;
    line-height: initial;
    font-size: 14px;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list {
    margin: 0;
    padding: 0;
    margin-bottom: 21px;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list .thumbnail {
    margin-right: 12px;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list .thumbnail a {
    display: block;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list .thumbnail a img {
    max-height: 50px;
    border-radius: 100%;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list .title {
    font-size: 14px;
    margin-bottom: 4px;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list span {
    color: #acacac;
    display: block;
    font-size: 14px;
}

.rn-icon-list.user-account .rn-dropdown .rn-product-inner ul.product-list .single-product-list+.single-product-list {
    margin-top: 15px;
}

.rn-icon-list.user-account .rn-dropdown .add-fund-button {
    border-bottom: 1px solid #ffffff14;
}

.rn-icon-list.user-account .rn-dropdown .list-inner {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 14px;
    margin: 0 -14px;
    position: relative;
}

.rn-icon-list.user-account .rn-dropdown .list-inner li {
    margin: 0;
    padding: 0;
}

.rn-icon-list.user-account .rn-dropdown .list-inner li a {
    font-weight: 500;
    padding: 4px 14px;
    font-size: 16px;
    display: block;
    color: #acacac;
    color: #acacac;
    margin: 0 10px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.rn-icon-list.user-account .rn-dropdown .list-inner li a:hover {
    color: #00a3ff !important;
    background: #060606b3;
}

.rn-icon-list.user-account:hover .rn-dropdown {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.header_admin {
    display: none;
}
.padding-contorler-am-slide-right {
    padding-right: 200px !important;
    z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px !important;
        padding-top: 50px !important;
    }
}

@media only screen and (max-width: 767px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px !important;
        padding-top: 50px !important;
    }
}

.padding-contorler-am-slide {
    padding-left: 246px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .padding-contorler-am-slide {
        padding-left: 100px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .padding-contorler-am-slide {
        padding-left: 80px !important;
    }
    .banner-top-rating .banner-badge-top .subtitle{
        letter-spacing: 0px !important;
    }
    .title.large-height{
        line-height: 65px !important;
        font-weight: 800 !important;
        letter-spacing: 5px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding-contorler-am-slide {
        padding-left: 50px !important;
    }
}

@media only screen and (max-width: 767px) {
    .padding-contorler-am-slide {
        padding-left: 15px !important;
    }
}

.padding-contorler-am-slide-11 {
    padding-left: 246px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .padding-contorler-am-slide-11 {
        padding-left: 100px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .padding-contorler-am-slide-11 {
        padding-left: 0 !important;
    }
}

.padding-contorler-am-slide-right {
    padding-right: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px !important;
        padding-top: 50px !important;
    }
}

@media only screen and (max-width: 767px) {
    .padding-contorler-am-slide-right {
        padding-right: 30px;
        padding-top: 50px;
    }
}
.unity-service .title-area h2{
    font-size: 40px;
}
.unity-service .title-area .subtitle{
    max-width: 830px;
    margin: 25px auto;
}
.unity-service .srv-main .unty-srv-img img{
    border-radius: 20px;
}
.unity-service .srv-main .srv-text{
    padding: 10px 20px;
}
.unity-service .srv-main .srv-text h3{
    font-weight: 600;
}
.unity-service .our-lang .our-lan-img{
    margin-top: 20px;
    margin-bottom: 20px;
}
.uni-bg{
    background-image: url(../img/bg/Price_Change_Callout.avif);
}
.has-overlay::before{
        content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: .3;
}
.has-overlay__inner {
    position: relative;
    z-index: 1;
}
@media only screen and (min-width: 48em){
    .col-sm-offset-1 {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -moz-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding-right: 15px;
        padding-left: 15px;
        margin-left: 8.3333333333%;
    }
}
.component-callout__description p, .component-callout__title {
    color: #fff;
}
.md-m-top {
    margin-top: 32px!important;
}
@media only screen and (min-width: 48em){
    .component-callout__inner {
        padding: 120px 0 !important;
    }
    
}
.component-callout__inner {
    position: relative;
    padding: 60px 40px;
    border-radius: 6px;
    overflow: hidden;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.has-overlay__inner h2{
    font-size: 2.5rem;
    line-height: 3rem;
}
.has-overlay__inner  p{
    font-size: 1.3rem;
    line-height: 2.2rem;
    margin-top: 20px;
}
.component-cards__space-top {
    margin-top: 32px;
}
.component-card{
    background: #fff !important;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1)  !important; 
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1) !important;
}
.component-cards .component-card {
    margin-bottom: 30px!important;
}
.component-card {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    min-width: 150px;
    max-width: 640px;
    background: 0 0;
    margin-bottom: 50px;
}
.component-cards--has-shadow .component-card__content {
    padding: 12px 32px 40px !important;
}
.component-card__content {
    position: relative;
    padding: 24px 32px 40px;
}
.component-card:hover .component-card__content::before{
    top: 0;
}
.component-card__content::before{
    transition: all 0.5s ease-in-out;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 100%;
    left: 0;
    background: #078586;
    z-index: -1;
}
.component-card__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 800;
}
.component-card__description {
    margin: 0 0 30px;
}
.component-card__description p:last-child {
    margin: 0;
    font-size: 16px !important;
} 
.component-card__link {
    position: absolute;
    bottom: 23px;
    left: 32px;
}
.component-card__link a{
    color: #2196f3 !important;
}
.component-card__description p:last-child a{
    color: #2196f3;
}
.padding-contorler-am-slide-right p{
    color: #acacac;
}
.cv-gaming-services {
    position: relative;
}
.cv-gaming-services .cv-gaming-banner {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 100%;
}
.cv-gaming-services .cv-gaming-banner {
    justify-content: center;
}
.cv-gaming-services .cv-gaming-banner h4 {
    color: #fff;
    font-size: 30px;
    line-height: 38px;
    font-weight: 500;
}
.cv-gaming-services .cv-gaming-banner .cv-gaming-banner-inner {
    padding: 50px 50px;
    background-color: #2B284F;
    border-radius: 4px;
    width: 100%;
    margin-right: calc(50vw - 609px);
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
}
.cv-gaming-services .cv-gaming-banner p {
    max-width: 420px;
}
.cv-gaming-services .cv-gaming-banner p {
    margin-bottom: 32px;
    max-width: 555px;
    color: #fff;
    font-size: 16px;
    position: relative;
    font-weight: 400;
    line-height: 24px;
}
.cv-gaming-services .cv-gaming-banner .btn.cv-gaming-cta {
    padding: 8px 16px;
    background: #2196F3;
    border-radius: 4px;
    color: #fff;
}
.cv-gaming-services .cv-gaming-banner .cv-gaming-banner-inner .cv-gaming-img {
    position: absolute;
    top: 8%;
    right: 0;
    width: 450px;
    transform: translateY(0);
}
.cv-gaming-services .cv-gaming-banner .cv-gaming-banner-inner .cv-gaming-img.cv-img-ruler {
    top: 47%;
}
.cv-gaming-img img{
    width: 390px;
}
.slider-area {
    background-color: #13131d;
    color: #acacac;
}

.rn-slider-area.fullscreen-slide {
    top: -50px;
    position: relative;
}

@media only screen and (max-width: 575px) {
    .rn-slider-area.fullscreen-slide {
        top: -105px;
    }
}

.lg-product-share .profile-share .more-author-text {
    font-size: 18px;
}

.rn-slider-area.fullscreen-slide .rn-slick-dot-style .slick-dots {
    bottom: 50px;
}

.rn-slider-area .btn {
    border-radius: 5px;
}

.slide.slide-style-2 {
    padding-top: 250px;
    padding-bottom: 250px;
}

.slide.slide-style-2 h1 {
    font-size: 80px;
    letter-spacing: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slide.slide-style-2 h1 {
        font-size: 60px;
        letter-spacing: 1px;
    }
}

@media only screen and (max-width: 767px) {
    .slide.slide-style-2 h1 {
        font-size: 40px;
        letter-spacing: 1px;
    }
}

.haeder-default .header-inner {
    align-items: center;
    justify-content: space-between;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .haeder-default .header-inner {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .haeder-default .header-inner {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .haeder-default .header-inner {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 479px) {
    .haeder-default .header-inner {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.haeder-default .header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .haeder-default .header-left {
        flex-basis: 35%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .haeder-default .header-left {
        flex-basis: 35%;
    }
}

@media only screen and (max-width: 767px) {
    .haeder-default .header-left {
        flex-basis: 35%;
    }
}

@media only screen and (max-width: 479px) {
    .haeder-default .header-left {
        justify-content: center;
        flex-basis: 100%;
    }
}

.haeder-default .header-right {
    justify-content: flex-end;
    display: flex;
    flex-basis: 45%;
    align-items: center;
    margin: 0 -5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .haeder-default .header-right {
        flex-basis: 65%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .haeder-default .header-right {
        flex-basis: 65%;
    }
}

@media only screen and (max-width: 767px) {
    .haeder-default .header-right {
        flex-basis: 65%;
    }
}

@media only screen and (max-width: 479px) {
    .haeder-default .header-right {
        justify-content: center;
        flex-basis: 100%;
        margin-top: 10px;
    }
}

.haeder-default .header-right .setting-option {
    margin: 0 5px;
}

@media only screen and (max-width: 479px) {
    .haeder-default .header-right .setting-option {
        margin: 0 2px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .haeder-default .header-right .setting-option .icon-box a#connectbtn {
        padding: 10px 11px;
        font-size: 12px;
        border-radius: 6px;
    }
}

@media only screen and (max-width: 1199px) {
    .haeder-default .header-right .setting-option .icon-box a#connectbtn {
        padding: 10px 11px;
        font-size: 12px;
        border-radius: 6px;
    }
  
}

@media only screen and (max-width: 479px) {
    .haeder-default .header-right .setting-option .icon-box a#connectbtn {
        padding: 8px 11px;
        font-size: 12px;
        border-radius: 6px;
    }
}

.haeder-default .header-right ul {
    margin: 0;
}

.haeder-default .menu-list {
    display: flex;
    list-style: none;
    margin: 0;
    margin-top: 5px;
    margin-left: -10px;
}

.haeder-default .menu-list li {
    margin: 0;
}

.haeder-default .menu-list li a {
    transition: 0.3s;
    margin-left: 10px;
    color: #6d6d80;
}

.haeder-default .menu-list li a:hover {
    color: #00a3ff;
}

.haeder-default .header-right .mainmenu li a {
    color: #858596;
    transition: 0.3s;
}

.haeder-default .header-right .mainmenu li a:hover {
    color: #00a3ff;
}

.search-form-wrapper {
    position: relative;
}

.search-form-wrapper .form-control,
.search-form-wrapper input {
    background: transparent;
    border-color: #ffffff14;
    font-size: 15px;
    border-radius: 10px;
    padding: 0 15px;
    color: #acacac;
    outline: none;
    box-shadow: none;
    transition: 0.4s;
    padding-right: 35px;
}

.search-form-wrapper .form-control:focus,
.search-form-wrapper input:focus {
    border-color: #00a3ff;
}

.search-form-wrapper .search-icon {
    position: absolute;
    right: 15px;
    top: 54%;
    transform: translateY(-50%);
    font-size: 17px;
}

.search-form-wrapper .search-icon button {
    border: 0 none;
    padding: 0;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

.left-header-style .logo-area a img {
    max-width: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-search-mobile.form-group {
        position: absolute;
        top: 100%;
        background: #242435;
        width: 100%;
        right: 0;
        margin-bottom: 0;
        padding: 20px;
        z-index: 3;
    }

    .large-mobile-blog-search {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .large-mobile-blog-search.active {
        opacity: 1;
        visibility: visible;
    }

    .rn-search-mobile.form-group input {
        width: 100%;
        background: #212e48;
        padding-right: 52px;
        color: #acacac;
    }

    button.search-button {
        position: absolute;
    }

    .mobile-search-wrapper {
        position: static;
    }

    button.search-button {
        position: absolute;
        width: 52px;
        background: transparent;
        right: 19px;
        border: 0 none;
        top: 50%;
        transform: translateY(-50%);
        height: 47px;
    }

    .search-mobile-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        display: block;
        border-radius: 100%;
        display: inline-block;
        min-width: 40px;
    }

    .search-mobile-icon button {
        border: 0 none;
    }

    .search-mobile-icon button i {
        font-weight: 400;
        font-size: 16px;
    }

    .search-mobile-icon.open button i::before {
        content: "\ea02";
    }
}

@media only screen and (max-width: 767px) {
    .rn-search-mobile.form-group {
        position: absolute;
        top: 100%;
        background: #242435;
        width: 100%;
        right: 0;
        margin-bottom: 0;
        padding: 20px;
        z-index: 3;
    }

    .large-mobile-blog-search {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .large-mobile-blog-search.active {
        opacity: 1;
        visibility: visible;
    }

    .rn-search-mobile.form-group input {
        width: 100%;
        background: #212e48;
        padding-right: 52px;
        color: #acacac;
    }

    button.search-button {
        position: absolute;
    }

    .mobile-search-wrapper {
        position: static;
    }

    button.search-button {
        position: absolute;
        width: 52px;
        background: transparent;
        right: 19px;
        border: 0 none;
        top: 50%;
        transform: translateY(-50%);
        height: 47px;
    }

    .search-mobile-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        display: block;
        border-radius: 100%;
        display: inline-block;
        min-width: 40px;
    }

    .search-mobile-icon button {
        border: 0 none;
    }

    .search-mobile-icon button i {
        font-weight: 400;
        font-size: 16px;
    }

    .search-mobile-icon.open button i::before {
        content: "\ea02";
    }
}

.top-seller-slick-activation.slick-gutter-15 {
    margin: 0;
    margin-top: 15px;
    margin-right: -15px;
    margin-left: -15px;
}

.slider-activation-banner-3 button.slick-arrow {
    z-index: 100;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff14;
    transition: 0.3s;
}

.slider-activation-banner-3 button.slick-arrow:hover {
    background: #00a3ff;
    border: 2px solid transparent;
}

.slider-activation-banner-3 button.slick-arrow.slick-prev {
    left: 100px;
}

.slider-activation-banner-3 button.slick-arrow.slick-prev::before {
    content: "\e92f";
    font-family: "feather" !important;
}

.slider-activation-banner-3 button.slick-arrow.slick-next {
    right: 100px;
}

.slider-activation-banner-3 button.slick-arrow.slick-next::before {
    content: "\e930";
    font-family: "feather" !important;
}

.rn-section-gapTop {
    background-color: #13131d;
}

.rn-service-one {
    text-align: left;
    padding: 70px 30px;
    transition: 0.5s all ease-in-out;
    position: relative;
    border-radius: 10px;
    background: #24243557;
    position: relative;
    z-index: 1;
    height: 100%;
    padding-bottom: 40px;
}

.rn-service-one::before {
    content: "";
    position: absolute;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 0;
    left: 0;
    background: transparent;
    opacity: 0;
    z-index: -1;
}

.rn-service-one .inner .icon {
    position: absolute;
    z-index: 2;
    right: -110px;
    width: 100% !important;
    top: -49px;
}

@media only screen and (max-width: 767px) {
    .rn-service-one .inner .icon {
        right: 0;
        top: -29px;
    }
}

.rn-service-one .inner .icon svg {
    width: 60px;
    height: auto;
    color: #00a3ff;
    stroke-width: 1 !important;
    transition: 0.3s;
}

.rn-service-one .inner .icon img {
    max-height: 85px;
}

@media only screen and (max-width: 767px) {
    .rn-service-one .inner .icon img {
        max-height: 67px;
    }
}

.rn-service-one .inner .subtitle {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.rn-service-one .inner>* {
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(10px);
}

.rn-service-one .inner .content {
    padding-top: 20px;
}

.rn-service-one .inner .content .title {
    font-family: #00a3ff;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    color: var(--color-white);
}

.rn-service-one .inner .content p.description {
    font-size: 16px;
    line-height: 28px;
    color: var(--color-lightn);
    transition: 0.3s;
    transition: 0.3s;
    margin-bottom: 30px;
    margin-top: 20px;
}

.rn-service-one .inner .content .read-more-button {
    transition: 0.3s;
    font-size: 24px;
}

.rn-service-one .inner .content .read-more-button i {
    color: #acacac;
    transition: 0.3s;
}

.rn-service-one:hover .inner .content .read-more-button i {
    color: #00a3ff !important;
}

.rn-service-one:hover .inner .content h4.title {
    color: #00a3ff !important;
}

.rn-service-one:hover .inner>* {
    transform: translateY(0px);
}

.rn-service-one a.over-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

/*------------------------
      Mailmenu Nav  
  -------------------------*/
.mainmenu-nav .mainmenu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.mainmenu-nav .mainmenu li {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
}

.mainmenu-nav .mainmenu li a {
    font-weight: 500;
    padding: 28px 12px;
    font-size: 16px;
    display: block;
    color: #acacac;
    margin: 0 8px;
    border-radius: 3px;
    transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mainmenu-nav .mainmenu li a {
        padding: 28px 6px;
    }
}

.mainmenu-nav .mainmenu li .submenu {
    min-width: 230px;
    height: auto;
    position: absolute;
    top: 90%;
    left: 0;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    padding: 12px 0;
    transition: 0.3s;
    border-radius: 0 0 10px 10px;
    background-color: #13131d;
    border-left: 1px solid #ffffff14;
    border-bottom: 1px solid #ffffff14;
    border-right: 1px solid #ffffff14;
}

.mainmenu-nav .mainmenu li .submenu li a {
    font-weight: 500;
    padding: 4px 14px;
    font-size: 16px;
    display: block;
    color: #acacac;
    color: #acacac;
    margin: 0 10px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainmenu-nav .mainmenu li .submenu li a svg,
.mainmenu-nav .mainmenu li .submenu li a i {
    stroke-width: 2px;
    width: 22px;
    height: auto;
    background: transparent;
    padding: 2px;
    border-radius: 2px;
    transition: 0.3s;
    width: 22px;
    left: -10px;
    position: relative;
    color: #acacac;
    opacity: 0;
    margin: 0 -3px;
}

.mainmenu-nav .mainmenu li .submenu li a:hover {
    color: #00a3ff;
    background: #060606b3;
}

.mainmenu-nav .mainmenu li .submenu li a:hover svg,
.mainmenu-nav .mainmenu li .submenu li a:hover i {
    opacity: 1;
    left: 0;
}

.mainmenu-nav .mainmenu li:hover .submenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.mainmenu-nav .mainmenu li.with-megamenu {
    position: static;
    transition: 0.3s;
}

.mainmenu-nav .mainmenu li.with-megamenu:hover .rn-megamenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu {
    position: absolute;
    transition: 0.3s;
    top: 90%;
    width: 100%;
    left: 50%;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper {
    border-radius: 0 0 10px 10px;
    background-color: #13131d;
    border-bottom: 1px solid #ffffff14;
    border-right: 1px solid #ffffff14;
    border-left: 1px solid #ffffff14;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item {
    padding: 22px 22px;
    height: 100%;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a {
    font-weight: 500;
    padding: 4px 14px;
    font-size: 16px;
    display: block;
    color: #acacac;
    color: #acacac;
    margin: 0 10px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a svg,
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a i {
    stroke-width: 2px;
    width: 22px;
    height: auto;
    background: transparent;
    padding: 2px;
    border-radius: 2px;
    color: #acacac;
    transition: 0.3s;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover {
    color: #00a3ff;
    background: #060606b3;
}

.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover svg,
.mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a:hover i {
    color: #ffffff;
}

.mainmenu-nav .mainmenu li.has-droupdown>a,
.mainmenu-nav .mainmenu li.with-megamenu>a {
    position: relative;
}

.mainmenu-nav .mainmenu li.has-droupdown>a::after,
.mainmenu-nav .mainmenu li.with-megamenu>a::after {
    content: "\e92e";
    position: absolute;
    top: 50%;
    font-family: "feather" !important;
    right: -7px;
    transform: translateY(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .mainmenu-nav .mainmenu li.has-droupdown>a::after,
    .mainmenu-nav .mainmenu li.with-megamenu>a::after {
        right: -11px;
    }
}

.rn-header {
    position: relative;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid #ffffff14;
    transition: 0.5s;
    background-color: #13131d;
}

.rn-header .logo-thumbnail {
    padding-right: 32px;
    position: relative;
    margin-right: 17px;
}

@media only screen and (max-width: 479px) {
    .rn-header .logo-thumbnail {
        padding-right: 0;
        margin-right: 0;
    }
}

.rn-header .logo-thumbnail::after {
    background: #ffffff14;
    width: 1px;
    height: 36px;
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
    .rn-header .logo-thumbnail::after {
        display: none;
    }
}

.rn-header.header--sticky {
    position: sticky !important;
    top: 0;
    z-index: 999;
    width: 100%;
    backdrop-filter: blur(10px);
}

.rn-header.sticky {
    background-color: #1515218c;
    position: sticky !important;
    top: 0;
    display: block;
    box-shadow: var(--shadow-1);
}

.rn-header.rn-header-four.header--fixed.sticky {
    top: 0;
}

.rn-header.header--transparent {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 999;
    border-bottom: 1px solid #ffffff14;
}

.rn-header.header--fixed.position-absolute {
    background: #00000042;
    backdrop-filter: blur(1px);
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper {
    background: #00000042;
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a {
    color: #c7c7cf;
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a svg,
.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper .mega-menu-item li a i {
    color: #c7c7cf;
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.nav-item .submenu {
    background: #00000042 !important;
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.nav-item .submenu li a {
    color: #c7c7cf;
}

.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.nav-item .submenu li a svg,
.rn-header.header--fixed.position-absolute .mainmenu-nav .mainmenu li.nav-item .submenu li a i {
    color: #c7c7cf;
}

.rn-header.header--fixed.sticky {
    background: #00000070;
    backdrop-filter: blur(15px);
}

.rn-header.header--fixed.sticky .mainmenu-nav .mainmenu li.with-megamenu .rn-megamenu .wrapper {
    background: #13131d;
}

.rn-header.header--fixed.sticky .mainmenu-nav .mainmenu li.nav-item .submenu {
    background: #13131d !important;
}

span.rn-badge-card {
    margin-left: 7px;
    padding: 1px 7px;
    font-weight: 400;
    position: relative;
    z-index: 2;
    font-size: 14px;
    color: #ffffff;
}

span.rn-badge-card::before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(95deg, #000000 15%, #0074b9 45%, #0400ff 75%, #2a1b95) 95% 200% 100%;
    z-index: -1;
    border-radius: 100px;
    opacity: 0.5;
}

.live-expo {
    position: relative;
}

.live-expo::before {
    width: 16px;
    height: 16px;
    background: #00a3ff;
    position: absolute;
    right: 10px;
    top: 50%;
    content: "";
    transform: translateY(-50%);
    border-radius: 100%;
    opacity: 1;
    animation: customOne 1s infinite;
}

.live-expo::after {
    width: 16px;
    height: 16px;
    border: 4px solid #00a3ff;
    position: absolute;
    right: 10px;
    top: 50%;
    content: "";
    transform: translateY(-50%);
    border-radius: 100%;
    opacity: 1;
    animation: customOne 1.2s infinite;
}

/*------------------------------
      Popup Menu Styles  
  --------------------------------*/
.logo-thumbnail a {
    display: block;
}

.logo-thumbnail img {
    max-height: 35px;
    object-fit: cover;
}

.hamberger button.hamberger-button {
    padding: 5px;
    margin: 0;
    border: 0 none;
    font-size: 21px;
    display: flex;
    margin-top: 0px;
    color: #acacac;
    margin-right: 0px;
    border: 1px solid #ffffff14;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
}

.with-megamenu .nav-link.its_new,
.has-droupdown .nav-link.its_new {
    position: relative;
}

.with-megamenu .nav-link.its_new.open,
.has-droupdown .nav-link.its_new.open {
    position: relative;
}

.with-megamenu .nav-link.its_new.open::after,
.has-droupdown .nav-link.its_new.open::after {
    content: "\e931";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "feather" !important;
}

.with-megamenu .nav-link.its_new::after,
.has-droupdown .nav-link.its_new::after {
    content: "\e92e";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "feather" !important;
}

.popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.popup-mobile-menu .inner {
    width: 320px;
    z-index: 999;
    position: absolute;
    background-color: #151521;
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    left: -150px;
    transition: all 0.5s ease-out;
}

.popup-mobile-menu .inner .header-top {
    display: flex;
    border-bottom: 1px solid #ffffff14;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.popup-mobile-menu .inner .header-top .logo a img {
    max-height: 36px;
}

.popup-mobile-menu .inner .header-top .close-menu .close-button {
    background: rgb(29, 29, 29);
    border: 0 none;
    color: #ffffff;
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.popup-mobile-menu.active {
    visibility: visible;
    opacity: 1;
}

.popup-mobile-menu.active .inner {
    opacity: 1;
    left: 0;
    overflow-y: auto;
}

.popup-mobile-menu .mainmenu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 15px 20px;
}

.popup-mobile-menu .mainmenu li {
    margin: 0;
}

.popup-mobile-menu .mainmenu li a {
    padding: 8px 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #acacac;
    display: flex;
    justify-content: space-between;
}

.popup-mobile-menu .mainmenu li a svg {
    width: 19px;
    height: auto;
}

.popup-mobile-menu .mainmenu li a.active {
    color: #00a3ff !important;
}

.popup-mobile-menu .mainmenu li+li {
    border-top: 1px solid #ffffff14;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu {
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
    display: none;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu li a.active {
    color: #00a3ff !important;
}

.popup-mobile-menu .mainmenu .rn-megamenu {
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
    display: none;
}

.popup-mobile-menu .mainmenu .mega-menu-item {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0;
}

.popup-mobile-menu .mainmenu .mega-menu-item li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
    border-bottom: 1px solid #ffffff14;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .popup-mobile-menu .mainmenu .single-mega-item {
        width: 100%;
    }
}

.popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
    border-bottom-color: transparent;
}

@media (max-width: 767px) {
    .btn-group.style2 {
        gap: 20px 30px
    }

    .team-sec .z-index-common .slick-slider .slick-arrow {
        display: none;
    }

    .team-sec .z-index-common .slick-slider .slick-arrow {
        display: none;
    }

    .slick-arrow.slick-next,
    .team-sec .z-index-common .slick-slider .slick-arrow {
        display: none !important;
    }

    .year-counter {
        height: 162px !important;
        width: 162px !important;
    }

    .year-counter_text {
        font-size: 14px;
    }
}

.as-bg-img {
    position: absolute;
    inset: 0
}

.as-bg-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.mfp-zoom-in .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5)
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease
}

.mfp-zoom-in.mfp-ready .mfp-content {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.7
}

.mfp-zoom-in.mfp-removing .mfp-content {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.font-icon {
    font-family: var(--icon-font)
}

.font-title {
    font-family: var(--title-font)
}

.font-body {
    font-family: var(--body-font)
}

.fw-extralight {
    font-weight: 100
}

.fw-light {
    font-weight: 300
}

.fw-normal {
    font-weight: 400
}

.fw-medium {
    font-weight: 500
}

.fw-semibold {
    font-weight: 600
}

.fw-bold {
    font-weight: 700
}

.fw-extrabold {
    font-weight: 800
}

.fs-md {
    font-size: 18px
}

.fs-xs {
    font-size: 14px
}

.bg-theme {
    background-color: #1c1c1c !important
}

.bg-smoke {
    background-color: var(--smoke-color) !important
}

.bg-smoke2 {
    background-color: var(--smoke-color2) !important
}

.bg-white {
    background-color: var(--white-color) !important
}

.bg-black {
    background-color: var(--black-color) !important
}

.bg-black2 {
    background-color: var(--black-color2) !important
}

.bg-title {
    background-color: var(--title-color) !important
}

.gr-bg1 {
    background: linear-gradient(90deg, rgb(255 254 63 / 14%) 0%, rgb(146 240 89 / 14%) 25%, rgb(106 197 0 / 9%) 50%, rgb(140 210 146 / 11%) 75%, rgb(40 110 160 / 9%) 100%);
}

.gr-bg2 {
     background: linear-gradient(90deg, rgb(255 254 63 / 14%) 0%, rgb(146 240 89 / 14%) 25%, rgb(106 197 0 / 9%) 50%, rgb(140 210 146 / 11%) 75%, rgb(40 110 160 / 9%) 100%);
}

.gr-bg3 {
    background-image: -webkit-linear-gradient(339.41deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%);
    background-image: linear-gradient(110.59deg, rgba(236, 207, 254, 0.4) 0%, rgba(255, 221, 221, 0.5) 46.79%, rgba(247, 255, 229, 0.7) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px)
}

.gr-bg4 {
    background-image: -webkit-linear-gradient(344.56deg, rgba(255, 229, 133, 0.4) 0%, rgba(189, 255, 199, 0.4) 48.48%, rgba(223, 109, 223, 0.4) 100%);
    background-image: linear-gradient(105.44deg, rgba(255, 229, 133, 0.4) 0%, rgba(189, 255, 199, 0.4) 48.48%, rgba(223, 109, 223, 0.4) 100%);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px)
}

.background-image,
[data-bg-src] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.bg-fluid {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center
}

.bg-auto {
    background-size: auto auto
}

.bg-top-center {
    background-size: auto;
    background-position: top center
}

.bg-repeat {
    background-size: auto;
    background-repeat: repeat
}

.bg-bottom-right {
    background-size: auto;
    background-position: bottom right
}

.bg-top-right {
    background-size: auto;
    background-position: top right
}

.text-theme {
    color: #122139 !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, rgb(140, 25, 223) 0%, rgb(100, 51, 150) 30%, rgb(106 14 161) 30%, rgb(0, 127, 255) 73%, rgb(168, 62, 255) 100%);
}

.text-title {
    color: var(--title-color) !important
}

.text-body {
    color: var(--body-color) !important
}

.text-white {
    color: var(--white-color) !important
}

.text-light {
    color: var(--light-color) !important
}

.text-yellow {
    color: var(--yellow-color) !important
}

.text-success {
    color: var(--success-color) !important
}

.text-error {
    color: var(--error-color) !important
}

.text-inherit {
    color: inherit
}

.text-inherit:hover {
    color: #122139;
}

a.text-theme:hover,
.text-reset:hover {
    text-decoration: underline
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.position-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

[data-overlay] {
    position: relative;
    z-index: 2
}

[data-overlay] [class^="col-"],
[data-overlay] [class*="col-"] {
    z-index: 1
}

[data-overlay]:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}

[data-overlay="theme"]:before {
    background-color: #122139;
}

[data-overlay="title"]:before {
    background-color: var(--title-color)
}

[data-overlay="white"]:before {
    background-color: var(--white-color)
}

[data-overlay="black"]:before {
    background-color: var(--black-color)
}

[data-overlay="overlay1"]:before {
    background-color: #080E1C
}

[data-opacity="1"]:before {
    opacity: .1
}

[data-opacity="2"]:before {
    opacity: .2
}

[data-opacity="3"]:before {
    opacity: .3
}

[data-opacity="4"]:before {
    opacity: .4
}

[data-opacity="5"]:before {
    opacity: .5
}

[data-opacity="6"]:before {
    opacity: .6
}

[data-opacity="7"]:before {
    opacity: .7
}

[data-opacity="8"]:before {
    opacity: .8
}

[data-opacity="9"]:before {
    opacity: .9
}

[data-opacity="10"]:before {
    opacity: 1
}

.ripple-animation,
.play-btn:after,
.play-btn:before,
.testi-box_quote:after,
.testi-box_quote:before,
.testi-grid_quote:after,
.testi-grid_quote:before,
.service-grid_icon:after,
.service-grid_icon:before,
.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
    -webkit-animation-duration: var(--ripple-ani-duration);
    animation-duration: var(--ripple-ani-duration);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ripple;
    animation-name: ripple
}

@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}

@-webkit-keyframes ripple2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(2.8);
        transform: scale(2.8);
        opacity: 0
    }
}

@keyframes ripple2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(2.8);
        transform: scale(2.8);
        opacity: 0
    }
}

.fancy-animation {
    -webkit-animation: morph 8s ease-in-out infinite;
    animation: morph 8s ease-in-out infinite
}

@-webkit-keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }
}

.movingX {
    -webkit-animation: movingX 8s linear infinite;
    animation: movingX 8s linear infinite
}

@-webkit-keyframes movingX {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes movingX {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.moving {
    -webkit-animation: moving 8s linear infinite;
    animation: moving 8s linear infinite
}

@-webkit-keyframes moving {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes moving {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.jump {
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

@-webkit-keyframes jumpAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes jumpAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.jump-reverse {
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

@-webkit-keyframes jumpReverseAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes jumpReverseAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.spin {
    -webkit-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.bg-color-ani,
.color-animate {
    -webkit-animation: bgColor 6s linear infinite;
    animation: bgColor 6s linear infinite
}

@-webkit-keyframes bgColor {
    0% {
        background-color: #F2BA4C
    }

    25% {
        background-color: #81F24C
    }

    50% {
        background-color: #41F27D
    }

    75% {
        background-color: #0500FF
    }

    100% {
        background-color: #F2BA4C
    }
}

@keyframes bgColor {
    0% {
        background-color: #F2BA4C
    }

    25% {
        background-color: #81F24C
    }

    50% {
        background-color: #41F27D
    }

    75% {
        background-color: #0500FF
    }

    100% {
        background-color: #F2BA4C
    }
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0
    }
}

@keyframes animate-positive {
    0% {
        width: 0
    }
}

.scalein.as-animated {
    --animation-name: scalein
}

.slidetopleft.as-animated {
    --animation-name: slidetopleft
}

.slidebottomright.as-animated {
    --animation-name: slidebottomright
}

.slideinleft.as-animated {
    --animation-name: slideinleft
}

.slideinright.as-animated {
    --animation-name: slideinright
}

.slideinup.as-animated {
    --animation-name: slideinup
}

.slideindown.as-animated {
    --animation-name: slideindown
}

.rollinleft.as-animated {
    --animation-name: rollinleft
}

.rollinright.as-animated {
    --animation-name: rollinright
}

.scalein,
.slidetopleft,
.slidebottomright,
.slideinleft,
.slideinright,
.slideindown,
.slideinup,
.rollinleft,
.rollinright {
    opacity: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-name: var(--animation-name);
    animation-name: var(--animation-name)
}

.as-animated {
    opacity: 1
}

@-webkit-keyframes slideinup {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70px);
        transform: translateY(70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideinup {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70px);
        transform: translateY(70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes slideinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(70px);
        transform: translateX(70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(70px);
        transform: translateX(70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slideindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes slideinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-70px);
        transform: translateX(-70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-70px);
        transform: translateX(-70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slidebottomright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px) translateY(100px);
        transform: translateX(100px) translateY(100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@keyframes slidebottomright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px) translateY(100px);
        transform: translateX(100px) translateY(100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@-webkit-keyframes slidetopleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px) translateY(-100px);
        transform: translateX(-100px) translateY(-100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@keyframes slidetopleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px) translateY(-100px);
        transform: translateX(-100px) translateY(-100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0
}

.widget_nav_menu>ul,
.widget_nav_menu .menu,
.widget_meta>ul,
.widget_meta .menu,
.widget_pages>ul,
.widget_pages .menu,
.widget_archive>ul,
.widget_archive .menu,
.widget_categories>ul,
.widget_categories .menu {
    margin: 0 0 -10px 0
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
    display: block;
    background-color: var(--white-color);
    margin: 0 0 10px 0;
    padding: 16px 45px 19px 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.313;
    color: var(--body-color);
    border-radius: 4px
}

.widget_nav_menu a::before,
.widget_meta a::before,
.widget_pages a::before,
.widget_archive a::before,
.widget_categories a::before {
    content: "\f061";
    position: absolute;
    right: 25px;
    top: 17px;
    font-family: var(--icon-font);
    font-weight: 600;
    color: #737887;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}
.widget_categories a::before{
    top: 24px !important;
}

.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
    display: block;
    position: relative
}

.widget_nav_menu li a.active {
    background-color: #122139 !important;
}

.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
    border-bottom: none
}

.widget_nav_menu li>span,
.widget_meta li>span,
.widget_pages li>span,
.widget_archive li>span,
.widget_categories li>span {
    color: var(--white-color);
    background-color: #272A34;
    font-weight: 600;
    text-align: center;
    position: absolute;
    right: 24px;
    top: 15px;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    pointer-events: none;
    min-width: 20px;
    text-align: right
}

.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
    color: var(--white-color) !important;
    background-color: #1c1c1c;
}

.widget_categories a.active {
    background-color: #1c1c1c;
}

.widget_nav_menu a:hover:before,
.widget_meta a:hover:before,
.widget_pages a:hover:before,
.widget_archive a:hover:before,
.widget_categories a:hover:before {
    color: var(--white-color)
}

.widget_nav_menu a:hover~span,
.widget_meta a:hover~span,
.widget_pages a:hover~span,
.widget_archive a:hover~span,
.widget_categories a:hover~span {
    color: var(--white-color);
    background-color: #122139;
}

.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
    margin-left: 10px
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
    padding-right: 20px
}

.widget_nav_menu .sub-menu {
    margin-left: 10px
}

.wp-block-archives {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px
}

.wp-block-archives a:not(:hover) {
    color: inherit
}

.as-blog ul.wp-block-archives li {
    margin: 5px 0
}

.widget {
    padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
    background-color: var(--smoke-color);
    margin-bottom: 40px;
    position: relative;
    border-radius: 5px
}

.widget[data-overlay]:before {
    z-index: -1
}

.widget .as-social a {
    box-shadow: 0px 2px 14px rgba(4, 6, 66, 0.1)
}

.widget_title {
    position: relative;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--title-font);
    line-height: 1em;
    margin: -0.12em 0 28px 0
}

.widget .search-form {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.widget .search-form input {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 4px 0 0 4px;
    padding-right: 25px
}

.widget .search-form button {
    border: none;
    font-size: 16px;
    background-color: #122139;
    color: var(--white-color);
    display: inline-block;
    padding: 0;
    width: 56px;
    text-align: center;
    border-radius: 0 4px 4px 0
}

.widget .search-form button:hover {
    background-color: var(--title-color)
}

.wp-block-tag-cloud a,
.tagcloud a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--body-font);
    text-transform: capitalize;
    line-height: 1;
    padding: 13px 19px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: var(--body-color);
    background-color: var(--white-color);
    border-radius: 4px
}

.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
    background-color: rgb(28, 28, 28);
    color: var(--white-color) !important;
    border-color: rgb(28, 28, 28);
}

.wp-block-tag-cloud,
.tagcloud {
    margin-right: -10px;
    margin-bottom: -10px
}

.sidebar-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 320px
}

.sidebar-gallery .gallery-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--white-color);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
    -ms-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px)
}

.sidebar-gallery .gallery-btn:hover {
    color: #122139;
}

.sidebar-gallery .gallery-thumb {
    overflow: hidden;
    position: relative;
    border-radius: 5px
}

.sidebar-gallery .gallery-thumb:before {
    content: '';
    height: calc(100% - 14px);
    width: calc(100% - 14px);
    background-color: var(--title-color);
    opacity: 0.8;
    position: absolute;
    top: 7px;
    left: 7px;
    border: 1px solid var(--white-color);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    border-radius: inherit;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.sidebar-gallery .gallery-thumb img {
    width: 100%
}

.sidebar-gallery .gallery-thumb:hover:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.sidebar-gallery .gallery-thumb:hover .gallery-btn {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.recent-post {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px
}

.recent-post:last-child {
    margin-bottom: 0
}

.recent-post .media-img {
    margin-right: 15px;
    width: 80px;
    overflow: hidden;
    border-radius: 5px
}

.recent-post .media-img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.recent-post .post-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 8px 0;
    font-family: var(--title-font);
    text-transform: capitalize
}

.recent-post .recent-post-meta a {
    text-transform: capitalize;
    font-size: 14px;
    color: var(--body-color);
    font-family: var(--body-font)
}

.recent-post .recent-post-meta a i {
    margin-right: 6px;
    color: #122139;
}

.recent-post .recent-post-meta a:hover {
    color: #122139;
}

.recent-post:hover .media-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.sidebar-area {
    margin-bottom: -10px
}

.sidebar-area ul.wp-block-latest-posts {
    margin-bottom: 0
}

.sidebar-area ul.wp-block-latest-posts li:last-child {
    margin-bottom: 0
}

.sidebar-area select,
.sidebar-area input {
    background-color: var(--white-color)
}

.sidebar-area .widget .wp-block-search {
    margin-bottom: 0
}

.sidebar-area .wp-block-group__inner-container h2 {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 20px;
    margin-top: -0.07em
}

.sidebar-area ol.wp-block-latest-comments {
    padding: 0;
    margin: 0
}

.sidebar-area ol.wp-block-latest-comments li {
    line-height: 1.5;
    margin: 0 0 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px
}

.sidebar-area ol.wp-block-latest-comments li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.download-widget-wrap .as-btn {
    width: 100%
}

.download-widget-wrap .as-btn:first-child {
    margin-bottom: 20px
}

.download-widget-wrap .as-btn.style2 {
    color: var(--body-color)
}

.download-widget-wrap .as-btn.style2:before,
.download-widget-wrap .as-btn.style2:after {
    background-color: #122139;
}

.widget_banner {
    background-color: var(--title-color);
    position: relative
}

.widget_banner .shape1 {
    position: absolute;
    bottom: 0;
    right: 0
}

.widget_banner .widget_title {
    color: var(--white-color)
}

.widget_banner:has(.widget-map) {
    padding: 0;
    line-height: 0px
}

.widget-map iframe {
    min-height: 280px
}

.widget-banner {
    height: 360px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

@media (max-width: 360px) {
    .widget-banner {
        height: 280px
    }
}

.widget-banner .text {
    display: block;
    margin-top: -0.4em;
    margin-bottom: 15px;
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: 500
}

.widget-banner .title {
    color: var(--white-color);
    margin-bottom: 35px
}

.counter-card.mtstyle {
    margin-top: 12px;
}

@media (max-width: 1299px) {
    .widget-banner .title {
        font-size: 34px
    }

    .slick-arrow {
        --icon-size: 40px;
        line-height: 38px;
        margin-right: 40px;
        font-size: 14px;
        margin-top: -82px;
        display: none !important;
    }

    .conversational-content {
        margin-left: 20px;
    }

    .sales-content {
        margin-left: 20px;
    }

}

.project-info-list .contact-feature {
    padding: 17px
}

.project-info-list .contact-feature:not(:last-of-type) {
    margin-bottom: 10px
}

.price_slider_wrapper {
    margin-top: -0.5em
}

.price_slider_wrapper .price_label span {
    display: inline-block;
    color: var(--body-color)
}

.price_slider_wrapper .ui-slider {
    height: 6px;
    position: relative;
    width: 100%;
    background-color: #122139;
    border: none;
    margin-top: 15px;
    margin-bottom: 25px;
    cursor: pointer;
    border-radius: 0
}

.price_slider_wrapper .ui-slider-range {
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    display: block;
    background-color: var(--title-color)
}

.price_slider_wrapper .ui-slider-handle {
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    cursor: pointer;
    position: absolute;
    margin-top: -5px;
    z-index: 2;
    background-color: var(--white-color);
    border: 3px solid var(--title-color);
    -webkit-transform: translateX(-1px);
    -ms-transform: translateX(-1px);
    transform: translateX(-1px)
}

.price_slider_wrapper .ui-slider-handle:focus {
    outline: none;
    box-shadow: 1.5px 2.598px 10px 0px rgba(0, 0, 0, 0.15)
}

.price_slider_wrapper .ui-slider-handle:last-child {
    -webkit-transform: translateX(-9px);
    -ms-transform: translateX(-9px);
    transform: translateX(-9px)
}

.price_slider_wrapper button,
.price_slider_wrapper .button {
    background-color: #122139;
    color: var(--white-color);
    font-weight: 500;
    line-height: 1.6;
    text-transform: capitalize;
    text-align: center;
    border: none;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 7px 20px;
    min-width: 100px;
    font-size: 14px;
    border-radius: 5px;
    -webkit-transition: 0.4s ease-in;
    transition: 0.4s ease-in
}

.price_slider_wrapper button:hover,
.price_slider_wrapper .button:hover {
    background-color: var(--title-color)
}

.widget_shopping_cart .widget_title {
    margin-bottom: 30px;
    color: #FFFFFFFF;
    font-size: 39px;
    padding-bottom: 8px;
}

.careare ul {
    display: flex;
}

.widget_shopping_cart ul {
    margin: 0;
    padding: 0;
    width: 453px;
    font-family: 'Ananda Black Personal Use' !important;
}

.widget_shopping_cart .mini_cart_item {

    padding: 17px 30px 22px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    text-align: left
}

.widget_shopping_cart .cart_list a:not(.remove) {
    display: block;
    color: var(--body-color);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--title-font);
    font-weight: 600;
    color: var(--title-color);
    font-size: 29px;
    border: none;
}

.widget_shopping_cart .cart_list a:not(.remove):hover {
    color: #122139;

}

.widget_shopping_cart .cart_list a.remove {
    position: absolute;
    top: 50%;
    left: 95%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--body-color)
}

.widget_shopping_cart .cart_list a.remove:hover {
    color: #122139;
}

.widget_shopping_cart .cart_list img {
    width: 75px;
    height: 75px;
    position: absolute;
    left: 0;
    top: 18px
}

.widget_shopping_cart .quantity {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    white-space: nowrap;
    vertical-align: top;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500
}

.widget_shopping_cart .total {
    margin-top: 20px;
    font-size: 18px;
    color: var(--title-color);
    font-family: var(--body-font)
}

.widget_shopping_cart .total strong {
    font-family: var(--title-font)
}

.widget_shopping_cart .amount {
    padding-left: 5px
}

.widget_shopping_cart .as-btn {
    margin-right: 15px
}

.widget_shopping_cart .as-btn:last-child {
    margin-right: 0
}

.sidebar-area .widget_shopping_cart .as-btn {
    margin-right: 10px;
    padding: 8px 22px;
    font-size: 14px
}

@media (min-width: 1200px) {
    .sidebar-shop .widget {
        padding: 30px
    }
}

@media (min-width: 992px) {
    .sidebar-shop .widget {
        margin-bottom: 24px
    }
    .blg-details .row{
        justify-content: center;
        margin-top: 35px;
    }
}

@media (max-width: 1199px) {
    .widget {
        --widget-padding-y: 30px;
        --widget-padding-x: 30px
    }

    .widget_title {
        font-size: 22px;
        margin: -0.12em 0 24px 0
    }

    .author-widget-wrap .name {
        font-size: 22px
    }
}

@media (max-width: 991px) {
    .sidebar-area {
        padding-top: 30px
    }

    .widget {
        --widget-padding-y: 40px;
        --widget-padding-x: 40px
    }

    .wp-block-tag-cloud a,
    .tagcloud a {
        padding: 10.5px 18px
    }

    .year-counter_number {
        font-size: 2.5rem !important
    }
}

@media (max-width: 767px) {
    .widget {
        padding: 35px 20px
    }
}

@media (max-width: 330px) {
    .recent-post .post-title {
        font-size: 14px;
        line-height: 24px
    }

    .recent-post .recent-post-meta a {
        font-size: 12px
    }
}

.footer-widget {
    margin-bottom: 50px
}

.footer-widget,
.footer-widget .widget {
    padding: 0;
    border: none;
    padding-bottom: 0;
    background-color: transparent;
    box-shadow: none
}

.footer-widget .form-group>i {
    color: #122139;
    top: 18px
}

.footer-widget .sidebar-gallery {
    max-width: 287px
}

.footer-widget .widget_title {
    max-width: 270px;
    color: var(--white-color);
    font-weight: 600;
    text-transform: uppercase;
    margin: -0.12em 0 35px 0;
    padding: 0 0 17px 0
}

.footer-widget .widget_title p {
    color: #ffffff;
}

.footer-widget .widget_title:before {
    content: '';
    height: 2px;
    width: 100px;
    background-color: #00a3ff;
    position: absolute;
    left: 0;
    bottom: 0
}

.footer-widget .widget_title:after {
    content: '';
    height: 10px;
    width: 10px;
    background-color: var(--white-color);
    position: absolute;
    bottom: -4px;
    left: 96px;
    border-radius: 99px;
    border: 2px solid #122139;
}

.footer-widget.widget_meta ul,
.footer-widget.widget_pages ul,
.footer-widget.widget_archive ul,
.footer-widget.widget_categories ul,
.footer-widget.widget_nav_menu ul {
    margin-top: -5px
}

.footer-widget.widget_meta .menu,
.footer-widget.widget_meta>ul,
.footer-widget.widget_pages .menu,
.footer-widget.widget_pages>ul,
.footer-widget.widget_archive .menu,
.footer-widget.widget_archive>ul,
.footer-widget.widget_categories .menu,
.footer-widget.widget_categories>ul,
.footer-widget.widget_nav_menu .menu,
.footer-widget.widget_nav_menu>ul {
    margin-bottom: -5px
}

.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
    font-size: 20px;
    font-weight: 400;
    padding: 0 0 0 20px;
    margin-bottom: 21px;
    font-family: var(--body-font);
    display: block;
    max-width: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding-right: 0;
    color: #FFF !important;
    background-color: transparent;
    border-bottom: none;
    position: relative
}

.footer-widget.widget_meta a:before,
.footer-widget.widget_pages a:before,
.footer-widget.widget_archive a:before,
.footer-widget.widget_categories a:before,
.footer-widget.widget_nav_menu a:before {
    content: "\f054";
    font-weight: 600;
    left: 0;
    top: 2px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    font-size: 0.9em;
    background-color: transparent;
    border: none;
    color: inherit
}

.footer-widget.widget_meta a:hover,
.footer-widget.widget_pages a:hover,
.footer-widget.widget_archive a:hover,
.footer-widget.widget_categories a:hover,
.footer-widget.widget_nav_menu a:hover {
    background-color: transparent;
    color: #00a3ff;
    letter-spacing: 0.1;
}

.footer-widget.widget_meta a:hover:before,
.footer-widget.widget_pages a:hover:before,
.footer-widget.widget_archive a:hover:before,
.footer-widget.widget_categories a:hover:before,
.footer-widget.widget_nav_menu a:hover:before {
    color: #00a3ff;
}

.footer-widget.widget_meta li>span,
.footer-widget.widget_pages li>span,
.footer-widget.widget_archive li>span,
.footer-widget.widget_categories li>span,
.footer-widget.widget_nav_menu li>span {
    width: auto;
    height: auto;
    position: relative;
    background-color: transparent;
    color: var(--body-color);
    line-height: 1
}

.footer-widget.widget_meta li:last-child a,
.footer-widget.widget_pages li:last-child a,
.footer-widget.widget_archive li:last-child a,
.footer-widget.widget_categories li:last-child a,
.footer-widget.widget_nav_menu li:last-child a {
    margin-bottom: 0
}

.footer-widget .recent-post {
    max-width: 315px;
    margin-top: -0.3em;
    margin-bottom: 28px
}

.footer-widget .recent-post .post-title {
    color: var(--white-color);
    font-weight: 500
}

.footer-widget .recent-post:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0
}

.footer-widget .recent-post .recent-post-meta a {
    font-weight: 400;
    line-height: 1.2
}

.footer-widget .recent-post .recent-post-meta i {
    color: #122139;
}

.footer-widget .recent-post .recent-post-meta a:hover i {
    color: #122139;
}

.as-widget-about .about-logo {
    margin-bottom: 35px;
    margin-top: -8px
}

.as-widget-about .about-text {
    margin-bottom: 25px;
    margin-top: -0.5em;
    color: #FFFFFFFF;
    font-size: 1.2rem;
}

.footer-text {
    margin-top: -0.46em;
    margin-bottom: 25px
}

.social-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.social-box .social-title {
    font-size: 20px;
    color: var(--white-color);
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 0
}

.icon-group a {
    color: var(--white-color);
    font-size: 18px;
    margin-right: 17px
}

.icon-group a:last-child {
    margin-right: 0
}

.newsletter-widget {
    max-width: 290px
}

.newsletter-widget .form-control {
    background-color: var(--white-color) !important;
    border: 1px solid var(--border-color)
}

.newsletter-form {
    position: relative;
    max-width: 465px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.newsletter-form .as-btn {
    min-width: 145px;
    padding: 21px 20px
}

@media (max-width: 1199px) {

    .footer-widget.widget_meta a,
    .footer-widget.widget_pages a,
    .footer-widget.widget_archive a,
    .footer-widget.widget_categories a,
    .footer-widget.widget_nav_menu a {
        margin-bottom: 16px
    }
}

@media (max-width: 767px) {
    .footer-widget .widget_title {
        margin-bottom: 35px
    }

    .as-widget-about .about-text {
        margin-bottom: 20px
    }

    .social-box.mb-30 {
        margin-bottom: 25px
    }

}

.as-header {
    position: relative;
    z-index: 41
}

.sticky-wrapper {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.sticky-wrapper.sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: var(--white-color);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-animation: stickyAni 0.4s ease-in-out;
    animation: stickyAni 0.4s ease-in-out
}

@-webkit-keyframes stickyAni {
    0% {
        -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
        transform: translate3d(0, -40px, 0) scaleY(0.8);
        opacity: 0.7
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scaleY(1);
        transform: translate3d(0, 0, 0) scaleY(1);
        opacity: 1
    }
}

@keyframes stickyAni {
    0% {
        -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
        transform: translate3d(0, -40px, 0) scaleY(0.8);
        opacity: 0.7
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scaleY(1);
        transform: translate3d(0, 0, 0) scaleY(1);
        opacity: 1
    }
}

.main-menu a {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: var(--title-color);
    font-family: var(--title-font);
    text-transform: uppercase
}

.main-menu a:hover {
    color: #122139;
}

.main-menu>ul>li {
    margin: 0 10px;
    padding: 30px 0;
}

.main-menu>ul>li>a {
    font-weight: 700;
}

.main-menu>ul>li>a:hover {
    color: #122139;
}

.main-menu ul {
    margin: 0;
    padding: 0
}

.main-menu ul li {
    list-style-type: none;
    display: inline-block;
}

.main-menu ul li a {
    position: relative;
    font-size: 0.96rem !important;
    color: #FFFFFF;
    font-weight: 700;
}

.main-menu ul li a:hover ,.main-menu ul li a:hover .main-menu ul li.services::before {
    color: #00a3ff;
    z-index: 99;

}

.main-menu ul li a:hover:after {
    width: 100%;
    top: 32px;
    right: auto;
    left: 0;
}

.main-menu ul li a,
.main-menu ul li a:after,
.main-menu ul li a:before {
    transition: all .5s;
}

.main-menu ul .menu-item-has-children a::after {
    position: absolute;
    bottom: -8px;
    left: auto;
    right: 0;
    top: 32px;
    margin: auto;
    width: 0%;
    content: '';
    color: transparent;
    background: #aaa;
    height: 2px;
}

.main-menu ul li:last-child {
    margin-right: 0 !important
}

.main-menu ul li:first-child {
    margin-left: 0 !important
}

.main-menu ul li:hover>ul.sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    z-index: 9;
    border-top: 5px solid #1255dc
}
.main-menu ul li:hover>ul.sub-menu::after{
    content: '';
    background-image: url(../img/shape/tech_shape_2.png);
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -99;

}
.main-menu ul.sub-menu {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background-color: var(--white-color);
    visibility: hidden;
    width: -webkit-max-content;
    width: -moz-max-content;
    padding: 7px;
    left: -14px;
    opacity: 0;
    z-index: -1;
    border: 0;
    box-shadow: 10px 10px 30px #00000070;
    border-radius: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.main-menu ul.sub-menu a {
    font-size: 16px;
    line-height: 30px
}

.main-menu ul.sub-menu {
    padding: 18px 20px 18px 18px;
    left: -27px
}

.main-menu ul.sub-menu li {
    display: block;
    margin: 0 0;
    padding: 0px 9px
}

.main-menu ul.sub-menu li.menu-item-has-children>a:after {
    content: "\f105";
    float: right;
    top: 1px
}

.main-menu ul.sub-menu li a {
    position: relative;
    padding-left: 25px;
    text-transform: inherit;
    color: #404757;
    font-weight: 500 !important;
}
.main-menu ul.sub-menu li a.game-services{
    padding-left: 0;
    padding-top: 15px;
    font-weight: 400;
    padding-bottom: 5px !important;
    border-bottom: 1px solid;
}
.main-menu ul.sub-menu li a:hover,
.main-menu ul.sub-menu li a:hover::before{

    color: #1255dc;
}
.main-menu ul.sub-menu li a.game-services:before{
    display: none;
}

.main-menu ul.sub-menu li a:before {
    content: "\f649";
    position: absolute;
    top: 8px;
    left: 0;
    font-family: var(--icon-font);
    width: 11px;
    height: 11px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1;
    color: #122139;
    font-weight: 500
}

.main-menu ul.sub-menu li ul.sub-menu {
    left: 100%;
    right: auto;
    top: 0;
    margin: 0 0;
    margin-left: 20px
}

.main-menu ul.sub-menu li ul.sub-menu li ul {
    left: 100%;
    right: auto
}

.header-button {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.header-button .icon-btn {
    position: relative
}

.header-button .icon-btn:hover {
    color: var(--white-color);
    background-color: #122139;
    border-color: #122139;
}

.header-button .icon-btn:hover .badge {
    background-color: var(--white-color);
    color: #122139;
}

.header-links ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.header-links li {
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 400
}

.header-links li:not(:last-child) {
    margin: 0 26px 0 0
}

.header-links li>i {
    margin-right: 6px
}

.header-links li,
.header-links span,
.header-links p,
.header-links a {
    color: var(--body-color)
}

.header-links a:hover {
    color: #122139;
}

.header-links i {
    color: var(--body-color)
}

.header-links b,
.header-links strong {
    font-weight: 600
}

.header-social .social-title {
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    margin: 0 10px 0 0;
    color: var(--body-color)
}

.header-social a {
    font-size: 16px;
    display: inline-block;
    color: var(--body-color);
    margin: 0 10px 0 0
}

.header-social a:last-child {
    margin-right: 0
}

.header-social a:hover {
    color: #122139;
}

.header-logo {
    padding-top: 15px;
    padding-bottom: 15px
}

@media (max-width: 1199px) {
    .main-menu>ul>li>a {
        padding: 30px 0
    }

    .main-menu>ul>li {
        padding: 0;
        margin: 0 7px;
    }
}

.header-layout1 {
    position: relative
}

.header-layout1 .header-top {
    padding: 6px 0;
    background-color: #1f1f22;
    --body-color: #fff
}

.header-layout1 .header-top a:hover {
    color: #116dde;
}

.header-layout1 .menu-area {
    position: relative;
    z-index: 2;
    background-color: #1c1c1c;
}

.header-layout1 .header-logo {
    position: relative;
    z-index: 2
}
.header-layout1 .logo-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 29.5%;
    height: 100%
}

.header-layout1 .logo-bg:before,
.header-layout1 .logo-bg:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #8f8f8f;
    -webkit-clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, calc(100% - 80px) 0%, 100% 100%, 0% 100%);
    z-index: -2
}

.header-layout1 .logo-bg:after,
.header-layout1 .menu-area::after {
    /* background-image: url("../img/theme-img/logo_bg.svg"); */
    background-image: none;
    background-color: var(--white-color);
    background-repeat: repeat;
    width: calc(100% - 20px);
    -webkit-clip-path: polygon(0 0, calc(100% - 60px) 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, calc(100% - 60px) 0%, 100% 100%, 0% 100%);
    z-index: -1
}

@media (min-width: 1922px) {
    .header-layout1 .logo-bg {
        width: 37%
    }
}

@media (max-width: 1500px) {
    .header-layout1 .logo-bg {
        width: 24%
    }
}


@media (max-width: 1199px) {
    .header-layout1 .logo-bg:before {
        -webkit-clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%)
    }

    .header-layout1 .logo-bg:after {
        -webkit-clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 100%, 0% 100%)
    }

    .main-menu ul li a{
        font-size: 0.85rem !important;
    }

    .header-layout1 .header-top {
        padding: 12px 0
    }
}

@media (max-width: 991px) {
    .header-layout1 .logo-bg {
        width: 56%
    }

    .header-layout1 .header-logo img {
        max-height: 80px
    }
}

@media (max-width: 375px) {
    .header-layout1 .logo-bg {
        width: 70%
    }
}

.header-layout2 .icon-btn {
    background-color: var(--white-color);
    color: var(--title-color);
    border: none
}

.header-layout2 .icon-btn:hover {
    background-color: #122139;
    color: var(--white-color)
}

.header-layout2 .main-menu>ul>li>a {
    color: var(--title-color);
    font-weight: 600
}

.header-layout2 .main-menu>ul>li>a:hover {
    color: #122139;
}

.header-layout2 ul.sub-menu {
    background-color: #E1EAFF
}

.header-layout2 .header-top {
    padding: 17px 0;
    background-color: #122139;
    --body-color: #fff
}

.header-layout2 .header-top a:hover {
    color: var(--title-color)
}

.header-layout2 .menu-area {
    background-color: #E1EAFF
}

@media (max-width: 1199px) {
    .header-layout2 .header-top {
        padding: 12px 0
    }
}

.header-layout3 {

    top: 0;
    left: 0;
    width: 100%;
    --main-container: 1700px
}

.header-layout3 .header-button .icon-btn:not(:hover) {
    border-color: #bdbdbd
}

.header-layout3 .main-menu>ul>li>a {
    font-weight: 600
}

@media (max-width: 1399px) {
    .header-layout3 .header-button .as-btn {
        display: none
    }
}

.header-layout4 {
    --main-container: 1744px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.header-layout4 .header-top {
    padding: 10px 0
}

.header-layout4 .header-logo {
    position: absolute;
    top: 0;
    left: 50%;
    height: 150px;
    width: 280px;
    margin: 20px 0 0 -140px;
    text-align: center;
    background-size: 100% 100%;
    padding: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.header-layout4 .header-button .icon-btn {
    border-color: rgba(255, 255, 255, 0.2)
}

.header-layout4 .will-sticky .sticky-active {
    background-color: var(--title-color)
}

.header-layout4 .will-sticky .sticky-active.active .header-logo {
    margin-top: -5px !important
}

.as-header.header-layout4 .sticky-active.sticky {
    background: var(--title-color)
}

.as-header.header-layout4 .sticky-active.sticky .header-logo {
    margin-top: -5px !important
}

@media (max-width: 1500px) {
    .header-layout4 .main-menu>ul>li {
        margin: 0 8px
    }
}

@media (max-width: 1299px) {
    .header-layout4 .main-menu {
        display: none !important
    }

    .header-layout4 .as-menu-toggle {
        display: block !important
    }

    .header-layout4 .menu-area {
        padding: 20px 0
    }
}

@media (max-width: 1199px) {
    .header-layout4 .header-logo {
        height: 130px;
        width: 240px;
        margin: 44px 0 0 -120px
    }

    .header-layout4 .header-logo img {
        max-width: 120px
    }
}

@media (max-width: 991px) {
    .header-layout4 .header-button .icon-btn {
        display: none
    }
}

@media (max-width: 767px) {
    .header-layout4 .header-logo {
        height: 100px;
        width: 170px;
        margin: 42px 0 0 -85px;
        padding-top: 4px
    }

    .header-layout4 .header-logo img {
        max-width: 90px
    }

    .header-layout4 .will-sticky .sticky-active {
        background-color: var(--title-color)
    }

    .header-layout4 .will-sticky .sticky-active.active .header-logo {
        margin-top: -8px !important;
        padding-top: 6px
    }

    .header-social a {
        font-size: 14px
    }

    .as-header.header-layout4 .sticky-active.sticky {
        background: var(--title-color)
    }

    .as-header.header-layout4 .sticky-active.sticky .header-logo {
        margin-top: -8px !important;
        padding-top: 6px
    }
}

@media (max-width: 575px) {
    .header-layout4 .header-button .as-btn {
        padding: 21px 15px
    }

    .year-counter {
        height: 131px !important;
        width: 131px !important;
        padding: 16px 0 !important;
    }

    .year-counter_number {
        font-size: 45px !important;
        top: 60%!important;
    }
}

.footer-wrapper {
    --border-color: rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 2
}

.footer-wrapper .as-social a {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color)
}

.footer-wrapper .as-social a i {
    line-height: 2.8;
}

.footer-wrapper .as-social a:hover {
    background-color: #1255dc;
    border-color: #1255dc;
}

.widget-area {
    width: 1675px;
    margin: 0 auto;
    padding-top: var(--section-space);
    padding-bottom: 66px
}

.footer-links ul {
    padding: 0;
    margin: 0
}

.footer-links li {
    font-family: var(--body-font);
    display: inline-block;
    padding-right: 15px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 20px;
}

.footer-links li:after {
    content: "";
    height: 20px;
    width: 1px;
    background-color: var(--body-color);
    position: absolute;
    top: 50%;
    right: -12px;
    margin: -10px 0
}

.footer-links li:last-child {
    margin-right: 0;
    padding-right: 0
}

.footer-links li:last-child:after {
    display: none
}

.footer-links a {
    font-family: inherit;
    color: var(--body-color)
}

.footer-links a:hover {
    color: #116dde;
}

.main-menu ul li.home:focus a {
    color: #116dde;
}

.copyright-wrap {
    padding: 22px 0;
    background-size: 100% auto;
    background-color: #000 !important;
}


.copyright-text a {
    color: #116dde;
}

.copyright-text a:hover {
    color: var(--white-color)
}

.as-widget-contact {
    max-width: 265px
}

.footer-contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    padding-left: 50px;
}

.footer-contact-wrap {
    display: grid;
    grid-template-columns: auto auto auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.footer-contact_icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    min-width: 70px;
    font-size: 30px;
    background-color: var(--white-color);
    color: #122139;
    border-radius: 5px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.footer-contact_icon:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #8f8f8f;
    border-radius: inherit;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: -1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}   

.footer-contact_icon:hover:before {
    top: 0;
    left: 0
}

.footer-contact_text {
    display: block;
    color: var(--body-color);
    margin-bottom: 5px
}

.footer-contact_link {
    display: inline-block;
    color: var(--body-color);
    font-size: 17px;
    font-weight: 600
}

.footer-contact_link:hover {
    color: #00a3ff;
}

.info-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 21px
}

.info-box:last-child {
    margin-bottom: -5px
}

.info-box_icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 29px;
    font-size: 14px;
    background-color: transparent;
    color: #122139;
    border: 1px solid #122139;
    border-radius: 999px;
    text-align: center;
    margin-right: 10px
}

.info-box_text {
    display: block;
    color: var(--body-color);
    margin-top: -0.45em;
    margin-bottom: 0
}

.info-box_link {
    display: inline-block;
    color: var(--body-color)
}

.info-box_link:hover {
    color: #122139;
}

.footer-newsletter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 25px;
    padding-left: 60px
}

.footer-newsletter .newsletter-title {
    max-width: 425px;
    margin-bottom: 0
}

.footer-layout1 {
    background-color: #1c1c1c;
    --body-color: #fff;
    overflow: hidden
}

.footer-layout1 .footer-top{
    box-shadow: 0 0 10px 3px rgb(93 88 88 / 27%) !important;
}
.footer-layout1 .footer-tops {
    background: none !important;
}

.footer-layout1 .footer-top,
.footer-layout1 .footer-tops {
    background-color: #1c1c1c;
    position: relative;
    z-index: 2;

}

.footer-layout1 .footer-logo {
    padding: 45px 45px 45px 0
}

.footer-layout1 .shape-left,
.footer-layout1 .shape-right {
    position: absolute;
    z-index: -1
}

.footer-layout1 .shape-left {
    top: 0;
    left: 0
}

.footer-layout1 .shape-right {
    bottom: 65px;
    right: 0
}

.footer-layout1 .logo-bg {
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--white-color);
    height: 100%;
    width: 33%;
    z-index: -1;
    -webkit-clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0)
}

.footer-layout1 .logo-bg:before {
    content: "";
    height: 100%;
    width: calc(100% - 10px);
    background-color: var(--title-color);
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, calc(100% - 60px) 50%, 100% 100%, 0 100%, 0 0)
}

.btn-group {
    justify-content: space-around;
}

@media (min-width: 1922px) {
    .footer-layout1 .logo-bg {
        width: 37%;
    }
}

@media (max-width: 1500px) {
    .footer-layout1 .logo-bg {
        width: 31%;
    }

    .footer-layout1 .shape-right {
        bottom: -20px;
        right: -70px
    }
}

@media (max-width: 1299px) {
    .footer-layout1 .shape-left {
        max-width: 65%
    }
}

@media (max-width: 1199px) {
    .footer-layout1 .logo-bg {
        width: 100%;
        height: 170px;
        -webkit-clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0)
    }

    .footer-layout1 .logo-bg:before {
        width: 100%;
        height: calc(100% - 10px);
        -webkit-clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 40px), 0 100%, 0 0)
    }

    .footer-layout1 .footer-logo {
        padding: 40px 15px 60px 15px;
        text-align: center
    }

    .footer-contact-wrap {
        padding: 40px 0 30px 0
    }
}

@media (max-width: 991px) {
    .footer-wrapper .widget-area {
        padding-bottom: 30px
    }

    .footer-contact-wrap {
        grid-template-columns: auto auto;
        gap: 30px
    }

    .copyright-text {
        text-align: center
    }
}

@media (max-width: 575px) {
    .copyright-wrap {
        background-size: cover
    }

    .footer-contact {
        margin-left: 10px;
        padding-left: 0;
    }

    .footer-contact-wrap {
        grid-template-columns: auto
    }

    .footer-widget .widget_title {
        margin-bottom: 25px;
    }
}

.footer-layout2 {
    background-color: var(--smoke-color2)
}

.footer-layout2.gr-bg1,
.footer-layout2.gr-bg2 {
    background-color: transparent;
    border-top: 1px solid #d9e0f3
}

.footer-layout2 .footer-widget .widget_title,
.footer-layout2 .footer-widget .recent-post .post-title {
    color: var(--title-color);
    font-weight: 700
}

.footer-layout2 .copyright-wrap {
    --body-color: #fff;
    background-color: #122139;
}

.footer-layout2 .copyright-wrap a {
    color: var(--white-color)
}

.footer-layout2 .copyright-wrap a:hover {
    color: var(--title-color)
}

.footer-layout2 .as-social a {
    color: var(--body-color);
    box-shadow: none;
    border: 1px solid var(--body-color)
}

.footer-layout2 .as-social a:hover {
    color: var(--white-color)
}

.footer-widget .contact-feature {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    gap: 15px
}

.footer-widget .contact-feature:not(:last-child) {
    margin-bottom: 28px
}

.footer-widget .contact-feature .icon-btn {
    --btn-size: 44px;
    font-size: 16px
}

.footer-widget .contact-feature_label {
    margin-top: -0.4em;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.5
}

.footer-widget .contact-feature_link {
    font-size: 18px;
    margin-bottom: -0.4em;
    display: block
}

.newsletter-widget .newsletter-form {
    gap: 0
}

.newsletter-widget .form-group {
    margin-bottom: 0
}

.newsletter-widget input {
    border-radius: 5px 0 0 5px
}

.newsletter-widget .icon-btn {
    border: none;
    background-color: #122139;
    color: var(--white-color);
    border-radius: 0 5px 5px 0;
    min-width: 56px;
    padding: 0
}

.newsletter-widget .icon-btn:hover {
    background-color: var(--title-color)
}

.newsletter-widget .footer-text {
    margin-bottom: 30px
}

.footer-layout3 {
    background-color: var(--black-color2);
    background-size: cover;
    --body-color: #fff
}

.footer-layout3 .widget-area {
    padding-top: 100px;
    padding-bottom: 60px
}

.footer-layout3 .as-btn {
    box-shadow: none
}

.footer-layout3 .footer-top {
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background-color: transparent
}

.footer-layout3 .copyright-wrap {
    --body-color: #fff;
    background-color: #122139;
}

.footer-layout3 .copyright-wrap a {
    color: var(--white-color)
}

.footer-layout3 .copyright-wrap a:hover {
    color: var(--title-color)
}
/* Android Page css */
.hero-text{
    margin-top: 20px;
}
.hero-text h2{
    font-size: 64px;
    font-weight: 700;
}
.Mian-img{
    position: relative;
}
.Mian-img img{
    position: absolute;
    right: 0;
}
/* end  */

.newsletter-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-left: 2px solid #8f8f8f;
    padding-left: 25px;
    margin-top: 35px;
    margin-bottom: 15px;
}

.newsletter-wrap .newsletter-title {
    margin-top: -0.3em;
    margin-bottom: 5px;
    max-width: 400px;
    color: var(--white-color)
}

.newsletter-wrap .newsletter-text {
    margin-bottom: -0.5em;
    max-width: 400px;
    color: #fff;
    font-size: 1.2rem;
}

.newsletter-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 465px;
    gap: 15px;
    right: 0;
    position: absolute;
}

.newsletter-form .form-group {
    margin-bottom: 0;
    width: 100%
}

.newsletter-form .form-group>i {
    color: #FFFFFFFF;
}

.newsletter-form input {
    background-color: transparent;
    border: 1px solid #8f8f8f;
    width: 100%
}

.newsletter-form input:focus {
    background-color: transparent;
    color: var(--body-color)
}

.newsletter-form .as-btn {
    min-width: 150px
}

.newsletter-form .newsletter-btn {
    display: inline-block;
    border: none;
    background-color: #122139;
    color: var(--title-color);
    width: 55px;
    height: 55px;
    line-height: 55px;
    min-width: 55px
}




@media (max-width: 1199px) {
    .footer-top .footer-logo {
        text-align: center;
        margin: 0 auto 35px auto
    }
}

@media (max-width: 991px) {
    .footer-layout3 .widget-area {
        padding-top: 80px;
        padding-bottom: 40px
    }

    .footer-layout3 .footer-top {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .footer-wrapper .newsletter-form {
        max-width: 400px
    }

    .newsletter-wrap .newsletter-title {
        font-size: 24px
    }

    .newsletter-wrap .newsletter-text {
        font-size: 14px
    }
}

@media (max-width: 767px) {
    .newsletter-wrap {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        border-left: none;
        gap: 20px
    }

    .pt-40 {
        padding-top: 10px;
    }

    .newsletter-wrap .newsletter-title {
        text-align: center
    }

    .footer-wrapper .newsletter-form {
        max-width: 550px;
        gap: 15px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 200px !important;
    }

    .footer-contact {
        padding-left: 5px !important;
    }

    .newsletter-form input {
        width: 50%;
        position: relative;

    }

    .newsletter-form .form-group>i {
        position: absolute;
        left: 160px;
    }

    .newsletter-form .as-btn {
        min-width: 150px;
        position: absolute;
        left: 56%;
    }
}

@media (max-width: 575px) {
    .footer-wrapper .newsletter-form {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .newsletter-form input {
        width: 50%;
        position: relative;
        left: 5px;
    }

    .newsletter-form .as-btn {
        width: 100px;
    }
}

.breadcumb-menu {
    max-width: 100%;
    padding: 0;
    margin: 30px 0 -0.5em 0;
    list-style-type: none;
    position: relative
}

.breadcumb-menu li {
    display: inline-block;
    margin-right: 3px;
    padding-right: 5px;
    list-style: none;
    position: relative
}

.breadcumb-menu li:after {
    content: "\f324";
    position: relative;
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    color: var(--white-color);
    font-family: var(--icon-font)
}

.breadcumb-menu li:last-child {
    padding-right: 0;
    margin-right: 0
}

.breadcumb-menu li:last-child:after {
    display: none
}

.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
    white-space: normal;
    color: inherit;
    word-break: break-word;
    font-weight: 400;
    font-size: 18px;
    color: var(--white-color)
}

.breadcumb-title {
    color: var(--white-color);
    margin: -0.22em 0 -0.14em 0;
    line-height: 1.1
}

.breadcumb-wrapper {
    background-color: var(--title-color);
    padding: 160px 0;
    overflow: hidden;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


@media (max-width: 1199px) {
    .breadcumb-wrapper {
        padding: 140px 0
    }

    .breadcumb-menu {
        margin: 20px 0 -0.5em 0
    }

    .breadcumb-menu li,
    .breadcumb-menu a,
    .breadcumb-menu span {
        font-size: 16px
    }
}

@media (max-width: 991px) {
    .breadcumb-wrapper {
        padding: 120px 0
    }
}

@media (max-width: 767px) {
    .breadcumb-wrapper {
        padding: 100px 0
    }
}

@media (max-width: 575px) {
    .breadcumb-title {
        font-size: 34px
    }
}

.as-pagination {
    margin-bottom: 30px
}

.as-pagination ul {
    margin: 0;
    padding: 0
}

.as-pagination li {
    display: inline-block;
    margin: 0 3px;
    list-style-type: none
}

.as-pagination li:last-child {
    margin-right: 0
}

.as-pagination li:first-child {
    margin-left: 0
}

.as-pagination span,
.as-pagination a {
    display: inline-block;
    text-align: center;
    position: relative;
    border: none;
    color: #122139;
    background-color: var(--smoke-color2);
    width: 56px;
    height: 56px;
    line-height: 56px;
    z-index: 1;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px
}

.as-pagination span.active,
.as-pagination span:hover,
.as-pagination a.active,
.as-pagination a:hover {
    color: var(--white-color);
    background-color: #122139;
    box-shadow: 0px 6px 20px rgb(96 91 91 / 58%);
}

@media (max-width: 767px) {

    .as-pagination span,
    .as-pagination a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 14px
    }
}

blockquote,
.wp-block-quote {
    display: block;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    font-family: var(--body-font);
    color: var(--title-color);
    background-color: var(--quote-bg, #f5f5f5);
    padding: 50px 40px 38px 165px;
    margin: 35px 0;
    line-height: 1.667;
    border: none !important;
    border-radius: 5px
}

blockquote p,
blockquote pre,
.wp-block-quote p,
.wp-block-quote pre {
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    width: 100%;
    position: relative;
    z-index: 3;
    margin-top: -0.5em;
    margin-bottom: 0
}

blockquote p a,
blockquote pre a,
.wp-block-quote p a,
.wp-block-quote pre a {
    color: inherit
}

blockquote:before,
.wp-block-quote:before {
    content: "\f10e";
    font-family: var(--icon-font);
    position: absolute;
    left: 0;
    top: 0;
    font-size: 60px;
    font-weight: 900;
    line-height: 1;
    color: var(--white-color);
    background-color: #122139;
    width: 125px;
    height: 100%;
    text-align: center;
    display: grid;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

blockquote cite,
.wp-block-quote cite {
    display: inline-block;
    font-size: 18px !important;
    font-family: var(--title-font);
    position: relative;
    padding-left: 45px;
    line-height: 1;
    font-weight: 600;
    margin-top: 17px;
    font-style: normal;
    color: #122139;
    white-space: nowrap
}

blockquote cite:before,
.wp-block-quote cite:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 30px;
    height: 2px;
    border-top: 2px solid #122139;
}

blockquote.style-left-icon,
blockquote.is-large:not(.is-style-plain),
blockquote.is-style-large:not(.is-style-plain),
blockquote.has-text-align-right,
.wp-block-quote.style-left-icon,
.wp-block-quote.is-large:not(.is-style-plain),
.wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-quote.has-text-align-right {
    padding: 50px 40px 38px 165px
}

blockquote.style-left-icon,
.wp-block-quote.style-left-icon {
    font-size: 18px;
    color: var(--body-color);
    font-weight: 400;
    line-height: 1.556;
    background-color: var(--smoke-color);
    padding-left: 160px
}

blockquote.style-left-icon cite,
.wp-block-quote.style-left-icon cite {
    color: var(--title-color)
}

blockquote.style-left-icon cite:before,
.wp-block-quote.style-left-icon cite:before {
    background-color: var(--title-color);
    top: 8px
}

blockquote.is-large p,
blockquote.is-style-large p,
.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
    margin-bottom: -0.4em
}

blockquote.is-large cite:before,
blockquote.is-style-large cite:before,
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before {
    top: unset;
    bottom: 10px
}

blockquote.has-text-align-right:before,
.wp-block-quote.has-text-align-right:before {
    content: "\f10d";
    right: unset;
    left: 0
}

.wp-block-pullquote {
    color: var(--body-color);
    padding: 0
}

blockquote.has-very-dark-gray-color {
    color: var(--body-color) !important
}

.wp-block-column blockquote,
.wp-block-column .wp-block-quote {
    padding: 100px 15px 30px 15px
}

.wp-block-column blockquote:before,
.wp-block-column .wp-block-quote:before {
    width: 100%;
    height: 60px;
    font-size: 30px
}

.wp-block-column blockquote.style-left-icon,
.wp-block-column blockquote.is-large:not(.is-style-plain),
.wp-block-column blockquote.is-style-large:not(.is-style-plain),
.wp-block-column blockquote.has-text-align-right,
.wp-block-column .wp-block-quote.style-left-icon,
.wp-block-column .wp-block-quote.is-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.is-style-large:not(.is-style-plain),
.wp-block-column .wp-block-quote.has-text-align-right {
    padding: 100px 15px 30px 15px
}

@media (max-width: 1199px) {

    blockquote,
    .wp-block-quote {
        font-size: 16px;
        padding: 40px 20px 30px 120px
    }

    blockquote:before,
    .wp-block-quote:before {
        width: 100px;
        font-size: 52px
    }

    blockquote cite,
    .wp-block-quote cite {
        margin-top: 23px
    }

    blockquote.style-left-icon,
    blockquote.is-large:not(.is-style-plain),
    blockquote.is-style-large:not(.is-style-plain),
    blockquote.has-text-align-right,
    .wp-block-quote.style-left-icon,
    .wp-block-quote.is-large:not(.is-style-plain),
    .wp-block-quote.is-style-large:not(.is-style-plain),
    .wp-block-quote.has-text-align-right {
        padding: 40px 20px 30px 120px
    }
}

@media (max-width: 767px) {

    blockquote,
    .wp-block-quote {
        padding: 100px 20px 30px 20px
    }

    blockquote:before,
    .wp-block-quote:before {
        height: 60px;
        width: 100%;
        font-size: 2.5rem
    }

    blockquote.style-left-icon,
    blockquote.is-large:not(.is-style-plain),
    blockquote.is-style-large:not(.is-style-plain),
    blockquote.has-text-align-right,
    .wp-block-quote.style-left-icon,
    .wp-block-quote.is-large:not(.is-style-plain),
    .wp-block-quote.is-style-large:not(.is-style-plain),
    .wp-block-quote.has-text-align-right {
        padding: 100px 20px 30px 20px
    }

    .wp-block-pullquote.is-style-solid-color blockquote {
        max-width: 90%
    }
}

.blog-meta {
    display: block
}

.blog-meta span,
.blog-meta a {
    display: inline-block;
    font-size: 14px;
    color: var(--body-color);
    font-family: var(--body-font);
    position: relative;
    margin-right: 20px
}

.blog-meta span i,
.blog-meta a i {
    margin-right: 6px;
    color: #122139;
}

.blog-meta span:last-child,
.blog-meta a:last-child {
    margin-right: 0
}

.blog-meta .author img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 6px
}

.blog-meta a:hover {
    color: #122139;
}

@media (max-width: 1199px) {

    .blog-meta span,
    .blog-meta a {
        margin-right: 6px;
        padding-right: 15px
    }
}

.blog-audio img,
.blog-img img,
.blog-video img {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.blog-title a {
    color: inherit;
    font-size: 27px !important;
}

.blog-title a:hover {
    color: #122139;
}

.as-blog {
    margin-bottom: 30px
}

.blog-inner-title {
    margin-top: -0.25em;
    margin-bottom: 25px
}

.blog-inner-title i {
    color: #122139;
    margin-right: 4px
}

@media (min-width: 1300px) {

    .page-single,
    .blog-single,
    .as-comments-wrap,
    .as-comment-form {
        margin-right: 16px
    }
}

.blog-single {
    position: relative;
    margin-bottom: var(--blog-space-y, 40px);
    box-shadow: 0px 10px 30px rgb(8 14 28 / 17%);
    border-radius: 5px;
    overflow: hidden
}

.blog-single .blog-title {
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 1.4;
    font-weight: 600
}

.blog-single .blog-text {
    margin-bottom: 27px
}

.blog-single .social-links {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block
}

.blog-single .social-links li {
    display: inline-block;
    margin-right: 3px
}

.blog-single .social-links li:last-child {
    margin-right: 0
}

.blog-single .social-links a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--smoke-color);
    font-size: 14px;
    color: var(--title-color);
    text-align: center;
    border-radius: 5px
}

.blog-single .social-links a:hover {
    color: var(--white-color);
    background-color: #122139;
}

.blog-single .wp-block-tag-cloud a,
.blog-single .tagcloud a {
    background-color: var(--smoke-color);
    color: var(--title-color)
}

.blog-single .wp-block-tag-cloud a:hover,
.blog-single .tagcloud a:hover {
    background-color: #122139;
}

.blog-single .blog-meta {
    margin: -0.35em 0 10px 0
}

.blog-single .blog-content {
    margin: 0 0 0 0;
    padding: 40px 15px;
    position: relative
}
.blog-single .blog-content.blogdetsils-content {
    padding: 40px;
    font-family: 'Barlow', sans-serif !important;
    background-color: #F5F5F5;
}
.blog-single .blog-content.blogdetsils-content p,
.blog-single .blog-content.blogdetsils-content span,
.blog-single .blog-content.blogdetsils-content b,
.blog-single .blog-content.blogdetsils-content h1,
.blog-single .blog-content.blogdetsils-content h2,
.blog-single .blog-content.blogdetsils-content h3,
.blog-single .blog-content.blogdetsils-content h4,
.blog-single .blog-content.blogdetsils-content h5,
.blog-single .blog-content.blogdetsils-content h6,
.blog-single .blog-content.blogdetsils-content strong,
.blog-single .blog-content.blogdetsils-content a,
.blog-single .blog-content.blogdetsils-content ul,
.blog-single .blog-content.blogdetsils-content li,
.blog-single .blog-content.blogdetsils-content nav
.blog-single .blog-content.blogdetsils-content div{
    font-family: 'Barlow', sans-serif !important;
    background-color: #F5F5F5 !important;
}
.blog-single .blog-audio {
    line-height: 1
}

.blog-single .blog-audio,
.blog-single .blog-img,
.blog-single .blog-video {
    position: relative;
    overflow: hidden;
    background-color: var(--smoke-color);
}

.blog-single .blog-img .slick-arrow {
    --pos-x: 30px;
    --icon-size: 45px;
    border: none;
    background-color: var(--white-color);
    color: #122139;
    border-radius: 5px;
    box-shadow: none
}

.blog-single .blog-img .slick-arrow:hover {
    background-color: #122139;
    color: var(--white-color)
}

.blog-single .blog-img .play-btn {
    --icon-size: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2)
}

.blog-single .line-btn {
    display: block;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: -1px;
    margin-top: 25px;
}

.blog-single:hover .blog-img .slick-arrow {
    opacity: 1;
    visibility: visible
}

.share-links-title {
    font-size: 20px;
    color: var(--title-color);
    font-family: var(--title-font);
    font-weight: 700;
    margin: 0 15px 0 0;
    display: inline-block
}

.share-links {
    margin: 0 var(--blog-space-x, 40px) var(--blog-space-y, 40px) var(--blog-space-x, 40px);
    border-top: 1px solid var(--border-color);
    padding: 30px 0 0 0
}

.share-links>.row {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    --bs-gutter-y: 20px
}

.share-links .wp-block-tag-cloud,
.share-links .tagcloud {
    display: inline-block
}

.blog-details .blog-single {
    background-color: transparent
}

@media (max-width: 1399px) {
    .blog-single .blog-content {
        padding: 40px 20px
    }

    .blog-single .blog-title {
        font-size: 28px
    }

    .share-links {
        --blog-space-x: 20px
    }
}

@media (max-width: 991px) {
    .blog-single .blog-content {
        padding: 40px
    }

    .blog-details .blog-single {
        --blog-space-x: 20px;
        --blog-space-y: 40px
    }

    .share-links {
        --blog-space-x: 40px
    }
}

@media (max-width: 767px) {
    .share-links {
        --blog-space-x: 20px
    }

    .blog-details .blog-single {
        --blog-space-x: 20px;
        --blog-space-y: 20px
    }

    .blog-single .blog-content {
        padding: 30px 20px
    }

    .blog-single .blog-title {
        font-size: 24px;
        line-height: 1.3
    }

    .blog-single .blog-text {
        margin-bottom: 22px
    }

    .blog-single .blog-bottom {
        padding-top: 15px
    }

    .blog-single .share-links-title {
        font-size: 18px;
        display: block;
        margin: 0 0 10px 0
    }
}

.as-comment-form {
    margin-top: var(--blog-space-y, 40px);
    margin-bottom: 30px;
    padding: var(--blog-space-y, 40px) var(--blog-space-x, 40px);
    position: relative
}

.as-comment-form .row {
    --bs-gutter-x: 20px
}

.as-comment-form .blog-inner-title {
    margin-bottom: 0px
}

.as-comment-form .form-title {
    margin-top: -0.35em
}

.as-comment-form .form-title a#cancel-comment-reply-link {
    font-size: 0.7em;
    text-decoration: underline
}

.as-comment-form .form-text {
    margin-bottom: 25px
}

.as-comment-form .form-group>i {
    color: #122139;
}

.blog-comment-area {
    margin-bottom: 25px
}

.as-comment-form,
.as-comments-wrap {
    padding: 40px;
    box-shadow: 0px 6px 30px rgba(7, 36, 95, 0.07)
}

.as-comments-wrap {
    --border-color: #E2E8FA;
    margin-top: var(--blog-space-y, 40px);
    margin-bottom: 30px
}

.as-comments-wrap .description p:last-child {
    margin-bottom: -0.5em
}

.as-comments-wrap .comment-respond {
    margin: 30px 0
}

.as-comments-wrap pre {
    background: #ededed;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre-wrap;
    word-wrap: break-word
}

.as-comments-wrap li {
    margin: 0
}

.as-comments-wrap .as-post-comment {
    padding: 0;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    border-bottom: 1px solid var(--border-color)
}

.as-comments-wrap .as-post-comment ol,
.as-comments-wrap .as-post-comment ul,
.as-comments-wrap .as-post-comment dl {
    margin-bottom: 1rem
}

.as-comments-wrap .as-post-comment ol ol,
.as-comments-wrap .as-post-comment ol ul,
.as-comments-wrap .as-post-comment ul ol,
.as-comments-wrap .as-post-comment ul ul {
    margin-bottom: 0
}

.as-comments-wrap ul.comment-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -30px
}

.as-comments-wrap ul.comment-list ul ul,
.as-comments-wrap ul.comment-list ul ol,
.as-comments-wrap ul.comment-list ol ul,
.as-comments-wrap ul.comment-list ol ol {
    margin-bottom: 0
}

.as-comments-wrap .comment-avater {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 5px
}

.as-comments-wrap .comment-avater img {
    width: 100%
}

.as-comments-wrap .comment-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: -6px;
    position: relative
}

.as-comments-wrap .commented-on {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 2px;
    font-weight: 400;
    color: var(--body-color)
}

.as-comments-wrap .commented-on i {
    margin-right: 7px;
    font-size: 0.9rem
}

.as-comments-wrap .name {
    margin-bottom: 12px;
    font-size: 20px
}

.as-comments-wrap .comment-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.as-comments-wrap .text {
    margin-bottom: 10px
}

.as-comments-wrap .children {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-left: 80px
}

.as-comments-wrap .reply_and_edit {
    margin-top: 12px;
    margin-bottom: -0.46em
}

.as-comments-wrap .reply_and_edit a {
    margin-right: 10px
}

.as-comments-wrap .reply_and_edit a:last-child {
    margin-right: 0
}

.as-comments-wrap .reply-btn {
    font-weight: 600;
    font-size: 16px;
    color: #122139;
    display: inline-block
}

.as-comments-wrap .reply-btn i {
    margin-right: 7px
}

.as-comments-wrap .reply-btn:hover {
    color: var(--title-color)
}

.as-comments-wrap .star-rating {
    font-size: 12px;
    margin-bottom: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    width: 80px
}

ul.comment-list .as-comment-item:last-child>.as-post-comment {
    border-bottom: none;
    padding-bottom: 0
}

ul.comment-list .as-comment-item:first-child>.as-post-comment {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color)
}

.as-comments-wrap.as-comment-form {
    margin: 0
}

@media (max-width: 1399px) {

    .as-comment-form,
    .as-comments-wrap {
        padding: 40px 20px
    }
}

@media (max-width: 1199px) {
    .as-comments-wrap .children {
        margin-left: 40px
    }
}

@media (max-width: 991px) {

    .as-comment-form,
    .as-comments-wrap {
        padding: 40px
    }
}

@media (max-width: 767px) {

    .as-comment-form,
    .as-comments-wrap {
        padding: 40px 20px
    }

    .as-comments-wrap .as-post-comment {
        display: block
    }

    .as-comments-wrap .star-rating {
        position: relative;
        top: 0;
        right: 0
    }

    .as-comments-wrap .comment-top {
        display: block
    }

    .as-comments-wrap .comment-avater {
        margin-right: 0;
        margin-bottom: 25px
    }

    .as-comments-wrap .children {
        margin-left: 40px
    }

    .as-comments-wrap .children {
        margin-left: 30px
    }
}

@media (max-width: 767px) {
    .as-comment-form {
        --blog-space-x: 20px
    }
}

.as-hero-wrapper {
    position: relative;
    /* z-index: 9999; */
    overflow: hidden
}

.as-hero-wrapper .slick-arrow {
    --pos-x: 100px;
    background-color: transparent;
    box-shadow: none;
    color: var(--white-color);
    border: 1px solid var(--white-color)
}

.as-hero-wrapper .slick-arrow:hover {
    background-color: var(--white-color);
    color: #122139;
}

.as-hero-wrapper .slick-dots {
    position: absolute;
    top: 50%;
    left: 80px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media (max-width: 1500px) {
    .as-hero-wrapper .slick-arrow {
        --pos-x: 40px
    }
}

.as-hero-bg {
    position: absolute;
    inset: 0
}

.as-hero-bg img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.hero-subtitle {
    font-family: var(--title-font);
    color: #122139;
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 17px;
    text-transform: uppercase;
    margin-top: -0.24em
}

.hero-title {
    font-size: 74px;
    font-weight: bold;
    line-height: 1.135;
    margin-bottom: 0
}

.hero-title:last-of-type {
    margin-bottom: 21px
}

.hero-text {
    margin-bottom: 43px;
    font-size: 1.3rem !important;
}

.hero-1 {
    overflow: hidden;
    background: linear-gradient(90deg, rgb(255 254 63 / 14%) 0%, rgb(146 240 89 / 14%) 25%, rgb(106 197 0 / 9%) 50%, rgb(140 210 146 / 11%) 75%, rgb(40 110 160 / 9%) 100%);
    
}

.hero-1 .hero-shape1,
.hero-1 .hero-shape2,
.hero-1 .hero-shape3 {
    position: absolute;
    z-index: 2
}

.hero-1 .hero-shape1,
.hero-1 .hero-shape2 {
    top: 10%;
    right: 0;
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

.hero-1 .hero-shape2 {
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

.hero-1 .hero-shape3 {
    bottom: -80px;
    left: -60px;
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

.hero-1 .hero-img {
    position: absolute;
    top: 60px;
    right: 10%;
    z-index: 3
}

.hero-1 .hero-img:before {
    content: '';
    width: 500px;
    height: 500px;
    background-color: #F2BA4C;
    opacity: 0.3;
    -webkit-filter: blur(300px);
    filter: blur(300px);
    border-radius: 50%;
    position: absolute;
    right: -40px;
    top: -180px;
    z-index: -1;
    -webkit-animation: bgColor 8s ease-in-out infinite;
    animation: bgColor 8s ease-in-out infinite
}

.hero-style1 {
    position: relative;
    z-index: 6;
    padding: 219px 0 219px 0;
    max-width: 710px
}
.mobiledev-form .form-control{
    border: 2px solid #7378877a;
}
@media (min-width: 1922px) {
    .hero-1 .hero-img {
        right: 18%
    }
}

@media (max-width: 1700px) {
    .hero-1 .hero-img {
        right: 1%
    }
}

@media (max-width: 1400px) {
    .hero-1 .hero-img {
        top: 20px
    }

    .hero-style1 {
        padding: 180px 0
    }

    .hero-title {
        font-size: 68px
    }
}

@media (max-width: 1299px) {
    .hero-1 .hero-img {
        max-width: 485px
    }

    .hero-style1 {
        padding: 150px 0
    }
}

@media (max-width: 1199px) {
    .hero-title {
        font-size: 60px
    }

    .hero-1 .hero-img {
        right: -10%
    }

    .hero-1 .hero-shape1,
    .hero-1 .hero-shape2 {
        max-width: 600px
    }

    .hero-1 .hero-shape3 {
        max-width: 600px
    }
}

@media (max-width: 991px) {
    .hero-title {
        font-size: 54px;
        line-height: 1.2
    }

    .hero-style1 {
        padding: 110px 0;
        text-align: center
    }

    .hero-style1 .btn-group {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .hero-style1 .hero-text {
        margin-left: auto;
        margin-right: auto
    }

    .hero-1 .hero-img {
        position: relative;
        margin-top: 30px;
        margin-bottom: -60px;
        text-align: center;
        right: 0;
        top: 0;
        max-width: 100%;
        padding-left: 12px;
        padding-right: 12px
    }

}

@media (max-width: 767px) {
    .hero-subtitle {
        font-size: 18px
    }

    .hero-title {
        font-size: 48px
    }
}

@media (max-width: 575px) {
    .hero-subtitle {
        font-size: 16px;
        margin-bottom: -5px;
    }

    .header-layout1 .header-logo img {
        max-height: 59px;
    }

    .hero-title {
        font-size: 35px !important;
        line-height: 1.24
    }

    .hero-title:last-of-type {
        margin-bottom: 16px
    }

    .hero-text {
        margin-bottom: 28px;
        font-size: 1.2rem;
    }
}

@media (max-width: 390px) {
    .hero-title {
        font-size: 34px;
        line-height: 1.3
    }
}

@media (max-width: 330px) {
    .hero-title {
        font-size: 30px
    }
}

.hero-2 {
    overflow-x: hidden
}


.hero-2 .as-hero-bg-one:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    z-index: 2;
    opacity: 0.7
}
.hero-2 .as-hero-bg-two:before{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.7
}

.hero-2 .as-hero-bg-three:before{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #546b8900;   
    z-index: 2;
    opacity: 0.7
}

.hero-2 .hero-shape1,
.hero-2 .hero-shape2,
.hero-2 .hero-shape3 {
    position: absolute;
    right: 0
}

.hero-2 .hero-shape1 {
    bottom: 0px;
}

.hero-2 .hero-shape2,
.hero-2 .hero-shape3 {
    top: 0
}

.hero-2 .ripple-shape {
    position: absolute;
    top: 68px;
    left: 200px;
    z-index: 1;
    height: 100px;
    width: 100px
}

.ripple-1,
.ripple-2,
.ripple-3,
.ripple-4,
.ripple-5,
.ripple-6 {
    height: 100px;
    width: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid var(--white-color);
    border-radius: 50%;
    -webkit-animation: heroripple 8s linear infinite;
    animation: heroripple 8s linear infinite
}

.ripple-1 {
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.ripple-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.ripple-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.ripple-4 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.ripple-4 {
    -webkit-animation-delay: 4s;
    animation-delay: 4s
}

.ripple-5 {
    -webkit-animation-delay: 5s;
    animation-delay: 5s
}

.ripple-6 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

@-webkit-keyframes heroripple {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0.2
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(4);
        transform: scale(4)
    }
}

@keyframes heroripple {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0.2
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(4);
        transform: scale(4)
    }
}

.hero-style2 {
    position: relative;
    z-index: 6;
    padding-top: 110px !important;
    text-align: center;
    max-width: 791px;
    margin: 0 auto;
    height: 100%;
    animation: fadeInLeft 1.5s normal;
}

.hero-style2 .hero-text {
    color: var(--white-color);
    animation: fadeInLeft 1.5s normal;
}

.hero-style2 .hero-title {
    color: var(--white-color);
    animation: fadeInLeft 1.5s normal;

}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.hero-style2 .hero-subtitle {
    color: var(--white-color);
    padding-top: 25px;
}


@media (max-width: 991px) {
    .hero-2 .hero-shape1 {
        max-width: 180px
    }

    .hero-2 .hero-shape2 {
        max-width: 110px
    }

    .hero-2 .hero-shape3 {
        max-width: 150px
    }

}

@media (max-width: 767px) {
    .hero-style2 {
        margin: 100px 0;
        text-align: center
    }

    .hero-style2 .btn-group {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.hero-social {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr
}

.hero-social a {
    display: inline-block;
    color: var(--title-color);
    font-family: var(--title-font);
    text-transform: uppercase;
    font-weight: 600;
    position: relative
}

.hero-social a:not(:last-child):after {
    content: '';
    height: 6px;
    width: 6px;
    display: inline-block;
    background-color: #122139;
    position: relative;
    margin: 35px 0 30px 0;
    position: relative;
    top: -2px
}

.hero-social a:hover {
    color: #122139;
}

.hero-3 {
    background-color: #F7F8FD;
    overflow-x: hidden
}

.hero-3 .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2
}

.hero-3 .hero-img-phone {
    display: none
}

.hero-3 .shape-blur {
    position: absolute;
    bottom: 80px;
    left: 100px;
    width: 534px;
    height: 534px;
    background: #122139;
    opacity: 0.5;
    -webkit-filter: blur(250px);
    filter: blur(250px);
    z-index: -1
}

.hero-3 .hero-shape1 {
        position: absolute; 
        top: -30%; 
        left: 4%; 
        animation: jumpAni 7s linear infinite; 
}
.hero-3 .hero-social {
    position: absolute;
    bottom: 150px;
    left: 50px;
    z-index: 3;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.hero-style3 {
    padding: 150px 0 245px 0;
    max-width: 620px;    
    position: relative;    
    z-index: 5;
}

@media (max-width: 1700px) {
    .hero-3 .hero-img {
        right: -7%
    }
}

@media (max-width: 1500px) {
    .hero-3 .hero-img {
        right: -12%
    }

    .hero-3 .hero-social {
        left: 20px
    }
}

@media (max-width: 1400px) {
    .hero-3 .hero-img {
        right: -14%
    }

    .hero-3 .hero-social {
        display: none
    }
}

@media (max-width: 1299px) {
    .hero-3 .hero-img {
        right: -25%
    }

    .hero-style3 {
        padding: 205px 0 205px 0;
        max-width: 620px
    }

    .Mak-Appointment .header-button .Appointment {
        right: 0 !important;
        font-size: 12px !important;
    }
}

@media (max-width: 1199px) {
    .hero-3 .hero-img {
        right: -13%;
        max-width: 550px
    }

    .hero-3 .hero-shape1 {
        max-width: 500px;
        top: unset;
        left: unset;
        bottom: 0;
        right: 0
    }
}

@media (max-width: 991px) {
    .hero-3 .hero-img {
        display: none
    }

    .hero-3 .hero-img-phone {
        display: block;
        text-align: center;
        margin: 100px 15px 40px 15px;
        position: relative
    }

    .hero-style3 {
        padding: 0px 0 100px 0;
        margin: 0 auto;
        text-align: center
    }

    .hero-style3 .btn-group {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: left
    }
}

@media (max-width: 767px) {
    .hero-3 .hero-shape1 {
        display: none
    }
}

.hero-4 {
    background-color: #F0F4FF
}

.hero-4 .hero-img {
    position: absolute;
    top: 50px;
    right: 7%;
    z-index: 2
}

.hero-4 .body-particle {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1
}

.hero-4 .triangle-1,
.hero-4 .triangle-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 82%;
    background-color: #E1E7F9;
    opacity: 0.3;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    z-index: -1
}

.hero-4 .triangle-2 {
    width: calc(70% - 140px);
    height: calc(82% - 90px);
    background-color: #E0E6F8;
    opacity: 0.5
}

.hero-4 .hero-shape2,
.hero-4 .hero-shape3 {
    position: absolute;
    right: 0;
    top: 0
}

.hero-style4 {
    position: relative;
    z-index: 6;
    margin: 200px 0 219px 0;
    max-width: 620px
}

.hero-style4 .ripple-shape {
    position: absolute;
    top: -50px;
    left: -50px;
    z-index: 1;
    height: 100px;
    width: 100px
}

.hero-style4 .ripple-1,
.hero-style4 .ripple-2,
.hero-style4 .ripple-3,
.hero-style4 .ripple-4,
.hero-style4 .ripple-5,
.hero-style4 .ripple-6 {
    border-color: rgba(20, 29, 56, 0.3)
}

@media (min-width: 1922px) {
    .hero-4 .hero-img {
        right: 18%
    }
}

@media (max-width: 1700px) {
    .hero-4 .hero-img {
        right: 1%
    }
}

@media (max-width: 1400px) {
    .hero-4 .hero-img {
        top: 20px
    }

    .hero-style4 {
        margin: 180px 0
    }
}

@media (max-width: 1299px) {
    .hero-4 .hero-img {
        max-width: 520px;
        top: 80px
    }

    .hero-style4 {
        margin: 150px 35px;
    }
}

@media (max-width: 1199px) {
    .hero-4 .hero-img {
        right: -5%
    }

    .hero-style4 {
        max-width: 500px
    }
}

@media (max-width: 991px) {
    .hero-style4 {
        margin: 110px auto;
        text-align: center
    }

    .hero-style4 .btn-group {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: left
    }

    .hero-style4 .hero-text {
        margin-left: auto;
        margin-right: auto
    }

    .hero-4 .hero-img {
        position: relative;
        margin-top: 50px;
        margin-bottom: -70px;
        text-align: center;
        right: 0;
        top: 0;
        max-width: 100%;
        padding-left: 12px;
        padding-right: 12px
    }
}

@media (max-width: 767px) {
    .hero-subtitle {
        font-size: 18px
    }

    .hero-title {
        font-size: 48px
    }
}

@media (max-width: 575px) {
    .hero-subtitle {
        font-size: 16px;
        margin-bottom: 12px
    }

    .hero-title {
        font-size: 38px;
        line-height: 1.24
    }

    .hero-title:last-of-type {
        margin-bottom: 16px
    }

    .hero-text {
        margin-bottom: 28px
    }
}

.error-content {
    text-align: center
}

.error-img {
    text-align: center;
    margin-bottom: 45px
}

.error-title {
    margin-bottom: 20px
}

.error-text {
    margin-bottom: 35px;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto
}

@media (max-width: 991px) {
    .error-title {
        margin-bottom: 15px
    }

    .error-img {
        margin-bottom: 30px
    }

    .error-text {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .error-title {
        margin-bottom: 10px
    }
}

.popup-search-box {
    position: fixed;
    top: 0;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.95);
    height: 0;
    width: 0;
    overflow: hidden;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.popup-search-box button.searchClose {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 40px;
    right: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #122139;
    background-color: transparent;
    font-size: 22px;
    border-radius: 50%;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    color: #122139;
}

.popup-search-box button.searchClose:hover {
    color: var(--body-color);
    background-color: #fff;
    border-color: transparent;
    border-color: transparent;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.popup-search-box form {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    padding-bottom: 40px;
    cursor: auto;
    width: 100%;
    max-width: 700px;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    -webkit-transition: -webkit-transform ease 0.4s;
    transition: -webkit-transform ease 0.4s;
    transition: transform ease 0.4s;
    transition: transform ease 0.4s, -webkit-transform ease 0.4s
}

@media (max-width: 1199px) {
    .popup-search-box form {
        max-width: 600px
    }
}

.popup-search-box form input {
    font-size: 18px;
    height: 70px;
    width: 100%;
    border: 2px solid #122139;
    background-color: transparent;
    padding-left: 30px;
    color: #fff;
    border-radius: 50px
}

.popup-search-box form input::-moz-placeholder {
    color: #fff
}

.popup-search-box form input::-webkit-input-placeholder {
    color: #fff
}

.popup-search-box form input:-ms-input-placeholder {
    color: #fff
}

.popup-search-box form input::-ms-input-placeholder {
    color: #fff
}

.popup-search-box form input::placeholder {
    color: #fff
}

.popup-search-box form button {
    position: absolute;
    top: 0px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 24px;
    right: 12px;
    color: var(--white-color);
    cursor: pointer;
    width: 70px;
    height: 70px;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    -webkit-transform: scale(1.001);
    -ms-transform: scale(1.001);
    transform: scale(1.001)
}

.popup-search-box form button:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.popup-search-box.show {
    opacity: 1;
    visibility: visible;
    width: 100.1%;
    height: 100%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    border-radius: 0
}

.popup-search-box.show form {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.sidemenu-wrapper {
    position: fixed;
    z-index: 99999;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease 0.8s;
    transition: all ease 0.8s
}

.sidemenu-wrapper .as-social a {
    background-color: transparent;
    border: 1px solid
}

.sidemenu-wrapper .as-social a:hover {
    background-color: #122139;
    border-color: #122139;
}

.sidemenu-wrapper .closeButton {
    display: inline-block;
    border: 4px solid #FFFFFFFF;
    width: 60px;
    height: 60px;
    line-height: 48px;
    font-size: 24px;
    padding: 0;
    z-index: 999999;
    position: absolute;
    top: 60px;
    color: #FFFFFF;
    right: 30.6%;
    background-color: #00a3ff;
    border-radius: 50%;
    transform: rotate(0);
    transition: all ease 0.4s;
}

.sidemenu-wrapper .sidemenu-content {

    width: 1100px;
    margin-left: auto;
    height: 100%;
    overflow: scroll;
    position: relative;
    right: -500px;
    cursor: auto;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    -webkit-transition: right ease 1s;
    transition: right ease 1s
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5
}

.sidemenu-wrapper .widget {
    padding-top: 21%;
    padding-left: 15%;
    border: none;
    background-color: transparent;
}

.sidemenu-wrapper.show {
    opacity: 1;
    visibility: visible;
    width: 100%;
    -webkit-transition: all ease 0.8s;
    transition: all ease 0.8s
}

.sidemenu-wrapper.show .sidemenu-content {
    right: 0;
    opacity: 1;
    visibility: visible;
    padding-left: 90px;
}

.woocommerce-message,
.woocommerce-info {
    position: relative;
    padding: 11px 20px 11px 50px;
    background-color: #122139;
    color: var(--white-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    border-radius: 5px
}

.woocommerce-message a,
.woocommerce-info a {
    color: var(--white-color);
    text-decoration: underline
}

.woocommerce-message a:hover,
.woocommerce-info a:hover {
    color: var(--title-color)
}

.woocommerce-message:before,
.woocommerce-info:before {
    content: '\f06a';
    font-family: var(--icon-font);
    font-weight: 400;
    margin-right: 10px;
    font-size: 18px;
    position: absolute;
    left: 20px;
    top: 11px
}

.woocommerce-notices-wrapper .woocommerce-message {
    background-color: #122139;
    color: var(--white-color)
}

.woocommerce-notices-wrapper .woocommerce-message:before {
    content: '\f14a';
    font-weight: 300
}

.woocommerce-form-login-toggle .woocommerce-info {
    background-color: #122139;
    color: var(--white-color)
}

.woocommerce-form-login-toggle .woocommerce-info a {
    color: inherit
}

.woocommerce-form-login-toggle .woocommerce-info a:hover {
    color: var(--title-color)
}

.woocommerce-form-register,
.woocommerce-form-coupon,
.woocommerce-form-login {
    padding: 35px 40px 35px 40px;
    background-color: var(--white-color);
    box-shadow: 0px 6px 30px rgba(1, 15, 28, 0.1);
    margin-bottom: 0;
    border-radius: 10px
}

@media (max-width: 575px) {

    .woocommerce-form-register,
    .woocommerce-form-coupon,
    .woocommerce-form-login {
        padding: 40px 20px
    }
}

.woocommerce-form-register .form-group,
.woocommerce-form-coupon .form-group,
.woocommerce-form-login .form-group {
    margin-bottom: 20px
}

.woocommerce-form-register .form-group:last-child,
.woocommerce-form-coupon .form-group:last-child,
.woocommerce-form-login .form-group:last-child {
    margin-bottom: 0
}

.woocommerce-form-login {
    margin-bottom: 30px
}

.woocommerce-error {
    background-color: var(--error-color);
    color: #fff;
    list-style: none;
    padding: 10px 26px;
    margin: 0 0 30px 0;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px
}

nav.woocommerce-MyAccount-navigation li {
    border: 1px solid #ddd;
    margin: 0;
    border-top: none
}

nav.woocommerce-MyAccount-navigation li:first-child {
    border-top: 1px solid #ddd
}

nav.woocommerce-MyAccount-navigation li a {
    color: var(--title-color);
    font-weight: 700;
    padding: 7px 17px;
    display: block
}

nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
    color: var(--white-color);
    background-color: #122139;
}

.woocommerce-MyAccount-content h3 {
    margin-top: -0.3em
}

.woocommerce-MyAccount-content .btn {
    background-color: #122139;
    color: var(--white-color);
    font-size: 14px;
    padding: 10px 25px;
    font-weight: 700
}

.woocommerce-MyAccount-content .btn:hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

table.variations,
.woocommerce-grouped-product-list-item {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-bottom: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: none
}

table.variations td,
.woocommerce-grouped-product-list-item td {
    border: none;
    vertical-align: middle;
    padding: 0 5px
}

table.variations td:first-child,
.woocommerce-grouped-product-list-item td:first-child {
    padding: 0
}

table.variations label,
.woocommerce-grouped-product-list-item label {
    margin: 0;
    font-size: 14px;
    text-transform: capitalize
}

table.variations label a,
.woocommerce-grouped-product-list-item label a {
    color: var(--title-color)
}

table.variations label a:hover,
.woocommerce-grouped-product-list-item label a:hover {
    color: #122139;
}

table.variations .label,
.woocommerce-grouped-product-list-item .label {
    border: none
}

table.variations__label,
.woocommerce-grouped-product-list-item__label {
    border: none !important;
    font-weight: 600
}

table.variations__price,
.woocommerce-grouped-product-list-item__price {
    border: none !important
}

table.variations__price .price,
table.variations__price .amount,
.woocommerce-grouped-product-list-item__price .price,
.woocommerce-grouped-product-list-item__price .amount {
    font-size: 18px !important
}

table.variations del,
.woocommerce-grouped-product-list-item del {
    margin-left: 12px
}

.woocommerce-product-attributes th,
.woocommerce-product-attributes td {
    border: 1px solid var(--border-color)
}

.woocommerce-product-attributes th p:last-child,
.woocommerce-product-attributes td p:last-child {
    margin-bottom: 0
}

.woocommerce-grouped-product-list.group_table {
    border-collapse: collapse;
    margin-bottom: 15px
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
    font-size: 16px;
    color: var(--title-color)
}

.woocommerce-grouped-product-list.group_table label {
    margin: 0 0 0 10px;
    margin: 0 0 0 10px;
    font-family: var(--title-font);
    font-size: 18px
}

.woocommerce-grouped-product-list.group_table .qty-input {
    border-color: #e3e6e9
}

.woocommerce-grouped-product-list.group_table tr {
    border-bottom: 1px solid #e3e6e9
}

.woocommerce-grouped-product-list.group_table tr:last-child {
    border-bottom: none
}

.woocommerce-grouped-product-list.group_table td {
    padding: 30px 5px
}

table.variations {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

table.variations td {
    padding: 0
}

table.variations td.label {
    padding-right: 10px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

table.variations select {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    margin: 0;
    padding-right: 54px;
    padding-left: 20px;
    height: 50px
}

table.variations .reset_variations {
    margin-left: 16px;
    display: inline-block;
    position: absolute;
    left: 100%;
    bottom: 25px
}

.woosq-product .product .woocommerce-grouped-product-list-item__quantity,
.woosq-product .product .woocommerce-grouped-product-list-item__label,
.woosq-product .product .woocommerce-grouped-product-list-item__price {
    width: auto !important
}

.woocommerce-variation.single_variation {
    margin-bottom: 30px
}

.woocommerce-variation.single_variation .price {
    color: var(--title-color);
    font-weight: 700
}

.wooscp-table-items td.woocommerce-product-attributes-item__value {
    padding-left: 15px !important
}

.wooscp-table-items a.added_to_cart.wc-forward {
    margin-left: 15px;
    text-decoration: underline
}

.tinvwl_added_to_wishlist.tinv-modal.tinv-modal-open {
    z-index: 1111
}

table.woocommerce-product-attributes {
    margin-bottom: 30px
}

#woosq-popup .product_meta {
    margin-top: 20px
}

#woosq-popup .product_title {
    font-size: 24px;
    margin-bottom: 5px
}

#woosq-popup .single-product .product .actions {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 20px
}

#woosq-popup .single-product .product .actions>div {
    height: auto;
    overflow: visible;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

#woosq-popup .single-product .product .actions>div .quantity.style2.woocommerce-grouped-product-list-item__quantity {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.login-tab {
    margin-bottom: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.login-tab button.nav-link {
    background-color: var(--smoke-color);
    color: var(--title-color);
    padding: 11px 39px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 15px ​15px 0
}

.login-tab button.nav-link.active {
    background-color: #122139;
    color: var(--white-color)
}

.star-rating {
    overflow: hidden;
    position: relative;
    width: 100px;
    height: 1.2em;
    line-height: 1.2em;
    display: block;
    font-family: var(--icon-font);
    font-weight: 700;
    font-size: 14px
}

.star-rating:before {
    content: "\e28b\e28b\e28b\e28b\e28b";
    color: #e1e1e1;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    letter-spacing: 3px
}

.star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em
}

.star-rating span:before {
    content: "\e28b\e28b\e28b\e28b\e28b";
    top: 0;
    position: absolute;
    left: 0;
    color: #122139;
    letter-spacing: 3px
}

.rating-select label {
    margin: 0;
    margin-right: 10px
}

.rating-select p.stars {
    margin-bottom: 0;
    line-height: 1
}

.rating-select p.stars a {
    position: relative;
    height: 14px;
    width: 18px;
    text-indent: -999em;
    display: inline-block;
    text-decoration: none
}

.rating-select p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 14px;
    line-height: 1;
    font-family: var(--icon-font);
    content: "\e28b";
    font-weight: 400;
    text-indent: 0;
    color: var(--yellow-color)
}

.rating-select p.stars a:hover~a::before {
    content: "\e28b";
    font-weight: 400
}

.rating-select p.stars:hover a::before {
    content: "\e28b";
    font-weight: 700
}

.rating-select p.stars.selected a.active::before {
    content: "\e28b";
    font-weight: 700
}

.rating-select p.stars.selected a.active~a::before {
    content: "\e28b";
    font-weight: 400
}

.rating-select p.stars.selected a:not(.active)::before {
    content: "\e28b";
    font-weight: 700
}

@media (max-width: 767px) {

    .woocommerce-message,
    .woocommerce-info {
        font-size: 14px;
        line-height: 22px;
        padding: 10px 15px 10px 37px
    }

    .woocommerce-message:before,
    .woocommerce-info:before {
        font-size: 16px;
        top: 10px;
        left: 15px
    }
}

.as-product {
    text-align: center;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.as-product .product-title {
    font-size: 20px;
    margin: 10px 0 10px 0
}

.as-product .product-title a {
    color: inherit
}

.as-product .product-title a:hover {
    color: #122139;
}

.as-product .price {
    display: block;
    color: var(--body-color);
    font-weight: 500;
    margin-bottom: -0.4em;
    font-family: var(--body-font)
}

.as-product .price del {
    margin-left: 10px;
    color: #a9a9a9
}

.as-product .product-img {
    --space: 20px;
    background-color: var(--smoke-color);
    overflow: hidden;
    position: relative;
    margin: 0 0 25px 0;
    text-align: center;
    background-color: var(--smoke-color);
    z-index: 2;
    border-radius: 5px
}

.as-product .product-img:before {
    --space: 20px;
    content: '';
    height: calc(100% - var(--space)*2);
    width: calc(100% - var(--space)*2);
    border-radius: inherit;
    position: absolute;
    top: var(--space);
    left: var(--space);
    background-color: var(--title-color);
    z-index: 1;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.as-product .product-img img {
    width: 100%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.as-product .star-rating {
    margin: 0 auto 5px auto;
    width: 93px
}

.as-product .actions {
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 3;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.as-product .actions .icon-btn {
    --btn-size: 40px;
    font-size: 14px;
    border-color: var(--white-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    color: var(--white-color)
}

.as-product .actions .icon-btn:hover {
    color: var(--title-color);
    border-color: var(--white-color)
}

.as-product .actions>* {
    margin: 0 var(--icon-gap-x, 5px)
}

.as-product .actions>*>a {
    margin: 0
}

.as-product .icon-btn {
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.as-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.as-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block
}

.as-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.as-product .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    position: relative;
    top: 0;
    left: 0;
    line-height: inherit;
    margin: 0;
    font-size: 24px
}

.as-product .tinv-wishlist a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--white-color);
    color: var(--title-color);
    border-radius: 50%
}

.as-product .tinv-wishlist a:hover {
    background-color: #122139;
    color: var(--white-color)
}

.as-product .add_to_cart_button.added {
    display: none
}

.as-product .added_to_cart {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: var(--white-color);
    color: var(--white-color);
    font-size: 0;
    text-align: center;
    border-radius: 50%
}

.as-product .added_to_cart:after {
    content: "\f07a";
    position: relative;
    font-family: var(--icon-font);
    font-size: 16px;
    font-weight: 700
}

.as-product .added_to_cart:hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

.as-product .action-btn {
    background-color: var(--white-color);
    font-size: 14px;
    font-family: var(--title-font);
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    padding: 13px 25px
}

.as-product:hover .product-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.as-product:hover .product-img:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    visibility: visible;
    opacity: 0.7
}

.as-product:hover .actions {
    margin-top: 0;
    opacity: 1;
    visibility: visible
}

.as-product:hover .icon-btn {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.as-product.list-view {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    height: 100%
}

.as-product.list-view .product-img {
    width: 100%;
    max-width: 200px;
    margin: 0
}

.as-product.list-view .star-rating {
    margin: 0 auto 10px 0;
    width: 93px
}

.as-product.list-view .product-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid var(--border-color);
    border-left: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 25px
}

.as-product.list-view .actions {
    --btn-size: 35px;
    --btn-font-size: 13px;
    --icon-gap-x: 2px
}

.as-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.as-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 35px;
    height: 35px;
    line-height: 35px
}

.as-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.as-product.list-view .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    font-size: 20px
}

.as-product.list-view .tinv-wishlist a {
    width: 35px;
    height: 35px;
    line-height: 35px
}

.as-product.list-view .added_to_cart {
    width: 35px;
    height: 35px;
    line-height: 35px
}

.as-product.list-view .added_to_cart:after {
    font-size: 16px
}

.as-product.list-view .action-btn {
    padding: 8px 15px
}

.as-product.list-view .tag {
    top: 8px;
    right: 8px;
    padding: 0px 15px
}

.as-product.list-view .product-title {
    font-size: 18px;
    margin: 0 0 5px 0
}

.as-product.list-view .product-price {
    font-size: 14px
}

#productCarousel .slick-arrow {
    top: 37.5%
}

.mfp-content {
    margin: 1.5rem auto
}

.mfp-content .product-details-img {
    padding-top: 15px
}

.mfp-content .product-about {
    padding-top: 20px;
    padding-bottom: 20px
}

.mfp-content .container {
    position: relative
}

.mfp-content .product-big-img {
    margin-top: 20px;
    margin-bottom: 20px
}

.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.woosq-popup {
    max-height: 600px;
    max-width: 1220px
}

.woosq-popup .product_meta>span>a:after,
.woosq-popup .product_meta>span>span:after {
    display: none
}

.woosq-product>.product>div {
    max-height: 600px;
    min-height: 460px;
    height: auto
}

.as-sort-bar {
    padding: 10px;
    margin: 0 0 24px 0;
    background-color: var(--smoke-color);
    border-radius: 5px
}

.as-sort-bar .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 15px
}

.as-sort-bar select {
    height: 50px;
    border: 1px solid var(--border-color);
    background-color: var(--white-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 250px;
    font-size: 16px;
    margin: 0;
    color: var(--body-color)
}

.as-sort-bar .woocommerce-result-count {
    padding-left: 15px;
    margin-bottom: 0;
    color: var(--body-color)
}

.as-sort-bar .nav a {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border: 1px solid var(--border-color);
    background-color: var(--white-color);
    text-align: center;
    position: relative;
    font-family: var(--title-font);
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--body-color);
    margin: 0 0 0 10px
}

.as-sort-bar .nav a.active,
.as-sort-bar .nav a:hover {
    color: #122139;
}

.product-thumb-area {
    position: relative;
    margin-right: 10px
}

.product-thumb-area .product-thumb-tab {
    position: absolute;
    top: 0;
    left: 0
}

.product-thumb-tab {
    --thumb: 120px;
    --gap: 10px;
    max-width: var(--thumb);
    position: relative;
    z-index: 3;
    display: grid;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 100%
}

.tab-btn {
    top: 1px;
    position: relative;
}

.tab-btn .avtart-2 {
    position: absolute;
    right: 0;
    top: -33px;
    left: 5px;
}

.testi-card-tab .tab-btn .avtart-3 {
    top: -17px;
    position: absolute;
    left: 5px;
}

.product-thumb-tab .tab-btn {
    background-color: var(--white-color);
    cursor: pointer;
    height: var(--thumb);
    width: var(--thumb);
    padding: var(--gap);
    border-radius: 10px
}

.product-thumb-tab .tab-btn img {
    max-width: 100%;
    border-radius: inherit
}

.product-thumb-tab .tab-btn:not(:last-of-type) {
    margin-bottom: 20px
}

.product-thumb-tab .indicator {
    position: absolute;
    top: calc(var(--pos-y) + var(--gap));
    left: calc(var(--pos-x) + var(--gap));
    width: calc(var(--width-set) - var(--gap)*2);
    height: calc(var(--height-set) - var(--gap)*2);
    border: 2px solid #122139;
    border-radius: 10px;
    pointer-events: none;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.product-big-img {
    background-color: var(--smoke-color2);
    text-align: center;
    border-radius: 10px;
    overflow: hidden
}

.product-big-img .img {
    width: 100%
}

.product-big-img .img img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.quantity {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.quantity>.screen-reader-text {
    display: inline-block;
    font-weight: 600;
    color: var(--title-color);
    font-family: var(--title-font);
    margin: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-right: 10px
}

.quantity .qty-btn,
.quantity .qty-input {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: none;
    border-right: none;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    text-align: center;
    color: var(--body-color);
    font-size: 18px;
    font-weight: 600
}

.quantity .qty-btn:last-child,
.quantity .qty-input:last-child {
    border-right: none
}

.quantity .qty-btn {
    font-size: 16px
}

.product_meta {
    font-weight: 700;
    font-size: 16px;
    font-family: var(--body-font);
    margin: 35px 0 0 0
}

.product_meta>span {
    display: block;
    margin-bottom: 5px;
    color: var(--title-color);
    font-weight: bold
}

.product_meta>span:last-child {
    margin-bottom: 0
}

.product_meta>span a {
    color: inherit
}

.product_meta>span a:hover {
    color: #122139;
}

.product_meta>span>a,
.product_meta>span>span {
    position: relative;
    color: var(--body-color);
    font-weight: 400
}

.product_meta>span>a:after,
.product_meta>span>span:after {
    content: ",";
    margin-right: 5px
}

.product_meta>span>a:last-child:after,
.product_meta>span>span:last-child:after {
    display: none
}

.product_meta>span>a:first-child,
.product_meta>span>span:first-child {
    margin-left: 7px
}

.product-tab-style1 {
    border-bottom: 1px solid var(--smoke-color2);
    margin: 95px auto 40px auto;
    padding-bottom: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 24px
}

.woocommerce-Reviews .as-post-comment .text {
    margin-bottom: -0.5em
}

.woocommerce-Reviews .as-comments-wrap {
    padding: 0;
    box-shadow: none;
    margin-right: 0
}

.woocommerce-Reviews .as-comment-form {
    padding: 60px;
    background-color: var(--smoke-color);
    box-shadow: none;
    border-radius: 10px;
    margin-right: 0
}

.woocommerce-Reviews .as-comment-form input,
.woocommerce-Reviews .as-comment-form .form-control {
    background-color: var(--white-color)
}

.woocommerce-Reviews .as-comment-form .blog-inner-title {
    margin-bottom: 10px
}

.woocommerce-Reviews .comment-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 24px;
    margin-bottom: 40px
}

.woocommerce-Reviews .as-post-comment {
    border: 1px solid var(--border-color) !important;
    padding: 40px !important;
    box-shadow: 0px 4px 30px rgba(1, 15, 28, 0.06);
    margin-bottom: 24px;
    border-radius: 8px
}

.woocommerce-Reviews .as-post-comment .name {
    margin-bottom: 3px
}

.woocommerce-Reviews .as-post-comment .commented-on {
    margin-bottom: 12px
}

@media (max-width: 1199px) {
    .woocommerce-Reviews .as-post-comment {
        padding: 30px !important
    }
}

@media (max-width: 991px) {
    .woocommerce-Reviews .comment-list {
        grid-template-columns: repeat(1, 1fr)
    }

    .woocommerce-Reviews .as-comment-form {
        padding: 40px
    }
}

@media (max-width: 575px) {
    .woocommerce-Reviews .as-comment-form {
        padding: 40px 20px
    }
}

#productTabContent {
    margin-bottom: -10px
}

#additional_information {
    margin-bottom: 40px
}

.product-inner-list>ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.product-inner-list li {
    position: relative;
    padding-left: 15px
}

.product-inner-list li:before {
    content: "-";
    position: absolute;
    left: 0
}

.share-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--title-color);
    margin-bottom: 8px
}

.share-title i {
    color: #122139;
}

.summary-content .product-title,
.product-about .product-title {
    margin: 0 0 12px 0
}

.summary-content>.price,
.product-about>.price {
    font-family: var(--title-font);
    font-size: 20px;
    font-weight: 800;
    color: var(--title-color);
    display: inline-block;
    margin-bottom: 14px
}

.summary-content>.price del,
.product-about>.price del {
    color: var(--gray-color);
    font-weight: 500;
    margin-left: 15px
}

.summary-content .product-rating,
.product-about .product-rating {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    top: 2px;
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    margin-bottom: 14px
}

.summary-content .product-rating .star-rating,
.product-about .product-rating .star-rating {
    width: 80px;
    font-size: 12px;
    margin-right: 8px
}

.summary-content .woocommerce-review-link,
.product-about .woocommerce-review-link {
    color: var(--body-color)
}

.summary-content .checklist,
.product-about .checklist {
    margin: 30px 0 40px 0
}

.summary-content .actions,
.product-about .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    margin: 33px 0 27px 0
}

.summary-content .actions .as-btn .btn-icon,
.product-about .actions .as-btn .btn-icon {
    padding: 10.5px 15px 10.5px 15px
}

.summary-content .actions .icon-btn,
.product-about .actions .icon-btn {
    border-color: var(--border-color)
}

.summary-content .actions .icon-btn:hover,
.product-about .actions .icon-btn:hover {
    background-color: #122139;
    color: var(--white-color);
    border-color: #122139;
}

.summary-content .share,
.product-about .share {
    margin-top: 25px
}

.summary-content .as-social a,
.product-about .as-social a {
    --icon-size: 40px;
    line-height: 38px;
    font-size: 14px;
    border: 1px solid var(--border-color);
    border-radius: 0
}

.summary-content .as-social a:hover,
.product-about .as-social a:hover {
    border-color: #122139;
}

.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt {
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    border-radius: 0
}

.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.summary-content .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt::before,
.product-about .tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt::before {
    position: relative;
    top: 0;
    left: 0;
    line-height: inherit;
    margin: 0;
    font-size: 24px
}

.summary-content .tinv-wishlist a,
.product-about .tinv-wishlist a {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 0
}

.summary-content .tinv-wishlist a:hover,
.product-about .tinv-wishlist a:hover {
    background-color: #122139;
    color: var(--white-color)
}

.summary-content .quantity,
.product-about .quantity {
    position: relative
}

.summary-content .quantity:after,
.product-about .quantity:after {
    content: '';
    height: 100%;
    width: 1px;
    background-color: var(--border-color);
    position: absolute;
    top: 0;
    left: 55px
}

.summary-content .quantity .qty-input,
.product-about .quantity .qty-input {
    width: 112px;
    height: 56px;
    border: 1px solid var(--border-color);
    background-color: transparent;
    color: var(--title-color);
    padding: 0 21px;
    text-align: left;
    font-weight: 500;
    border-radius: 5px
}

.summary-content .quantity .qty-btn,
.product-about .quantity .qty-btn {
    color: var(--title-color);
    background-color: transparent;
    position: absolute;
    right: 21px;
    height: auto;
    width: auto;
    border: none
}

.summary-content .quantity .quantity-minus,
.product-about .quantity .quantity-minus {
    bottom: 8px
}

.summary-content .quantity .quantity-plus,
.product-about .quantity .quantity-plus {
    top: 8px
}

#description {
    margin-bottom: 30px
}

.product-details .as-comments-wrap {
    margin-top: 0
}

.product-details .border-title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px
}

.product-details .border-title:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 80px;
    background-color: #122139;
}

.product-inner-title {
    font-size: 32px;
    border-bottom: 1px solid var(--border-color);
    padding: 0 0 7px 0;
    margin: 0 0 30px 0
}

.related-product-wrapper {
    padding-top: 115px
}

@media (max-width: 1399px) {
    .as-product.list-view .product-img {
        max-width: 150px
    }
}

@media (max-width: 1299px) {
    .product-thumb-tab {
        --thumb: 100px;
        margin-left: -40px
    }

    .product-thumb-area {
        margin-right: 0
    }
}

@media (max-width: 1199px) {
    .as-sort-bar select {
        min-width: auto
    }

    .product-tab-style1 {
        margin: 55px 0 40px 0
    }

    .summary-content,
    .product-about {
        padding-left: 0
    }
    
}

@media (max-width: 991px) {
    .product-big-img {
        margin-bottom: 40px
    }

    .product-thumb-tab {
        margin-left: -10px
    }

    .as-product-box.list-view .product-img {
        max-width: 150px
    }

    .as-sort-bar .row {
        --bs-gutter-x: 20px
    }

    .as-sort-bar .nav a:last-child {
        margin-right: 0;
        padding-right: 0
    }

    .as-sort-bar .nav a:last-child:before {
        display: none
    }

    .woosq-product>.product .thumbnails {
        max-height: 400px;
        min-height: 200px;
        padding: 10px
    }
}

@media (max-width: 767px) {
    .as-sort-bar {
        text-align: center;
        padding: 10px 15px 15px 15px
    }

    .as-sort-bar .nav {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .as-sort-bar select {
        margin: 0 auto
    }

    .as-sort-bar .woocommerce-result-count {
        padding-left: 0
    }

    .as-product-box.list-view .product-img {
        max-width: 130px
    }

    .as-product-box.list-view .actions {
        --btn-size: 30px;
        --btn-font-size: 10px;
        --icon-gap-x: 2px
    }
}

@media (max-width: 575px) {
    .product-about .actions {
        gap: 15px 15px
    }

    .product-thumb-tab {
        --thumb: 74px;
        --gap: 6px
    }

    .product-thumb-tab .tab-btn {
        border-radius: 6px
    }

    .product-thumb-tab .tab-btn:not(:last-of-type) {
        margin-bottom: 10px
    }

    .product-thumb-tab .indicator {
        border-radius: 6px
    }

    .product-grid {
        max-width: 340px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 375px) {
    .as-product.list-view .product-img {
        max-width: 130px
    }
}

.woocommerce-cart-form {
    text-align: center
}

.cart_table {
    border: 1px solid #eaf0f2;
    margin-bottom: 45px
}

.cart_table thead {
    background-color: #ecf0f1
}

.cart_table thead th {
    border: none !important
}

.cart_table td:before,
.cart_table th {
    font-family: var(--title-font);
    color: var(--title-color);
    font-weight: 800;
    border: none;
    padding: 27px 15px
}

.cart_table td:before {
    content: attr(data-title);
    position: absolute;
    left: 15px;
    top: 50%;
    vertical-align: top;
    padding: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none
}

.cart_table td {
    border: none;
    border-bottom: 1px solid #f3f3f3;
    color: #8b8b8b;
    padding: 20px 10px;
    position: relative;
    vertical-align: middle
}

.cart_table .product-quantity {
    color: var(--title-color)
}

.cart_table .product-quantity input {
    position: relative;
    top: -2px
}

.cart_table .cart-productname {
    font-weight: 400;
    font-family: var(--body-font);
    color: var(--body-color)
}

.cart_table .cart-productimage {
    display: inline-block;
    border: 2px solid var(--smoke-color)
}

.cart_table .remove {
    color: #122139;
    font-size: 18px
}

.cart_table .quantity {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.cart_table .qty-btn {
    border: 2px solid var(--smoke-color) !important;
    background-color: transparent;
    color: #b8c6d0;
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 16px;
    border-radius: 4px
}

.cart_table .qty-btn:hover {
    background-color: #122139;
    color: var(--white-color)
}

.cart_table .qty-input {
    vertical-align: middle;
    border: 2px solid var(--smoke-color);
    width: 70px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    color: var(--title-color);
    font-weight: 700;
    margin: 0 10px;
    border-radius: 4px;
    padding: 0
}

.cart_table .qty-input::-moz-placeholder {
    color: var(--title-color)
}

.cart_table .qty-input::-webkit-input-placeholder {
    color: var(--title-color)
}

.cart_table .qty-input:-ms-input-placeholder {
    color: var(--title-color)
}

.cart_table .qty-input::-ms-input-placeholder {
    color: var(--title-color)
}

.cart_table .qty-input::placeholder {
    color: var(--title-color)
}

.cart_table .qty-input::-webkit-outer-spin-button,
.cart_table .qty-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.cart_table .actions {
    text-align: right;
    vertical-align: middle
}

.cart_table .actions>.as-btn {
    font-size: 16px;
    padding: 20px 28px;
    margin-right: 15px
}

.cart_table .actions>.as-btn:last-child {
    margin-right: 0
}

.cart_table .as-cart-coupon {
    float: left;
    margin: 0;
    width: 455px;
    max-width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.cart_table .as-cart-coupon input {
    width: calc(100% - 200px);
    margin-right: 10px
}

.cart_table .as-cart-coupon .as-btn {
    font-size: 16px;
    padding: 20px 25px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.cart_totals {
    border: 1px solid #ecf0f1
}

.cart_totals th,
.cart_totals td {
    vertical-align: top;
    padding: 20px 20px;
    border: none;
    border-bottom: 1px solid #ecf0f1;
    font-size: 14px;
    color: var(--title-color);
    width: 55%
}

.cart_totals th:first-child,
.cart_totals td:first-child {
    width: 45%;
    background-color: #f9fbfb;
    font-weight: 700;
    font-size: 14px;
    color: #333333
}

.cart_totals .shipping-calculator-button {
    display: inline-block;
    border-bottom: 1px solid;
    color: var(--title-color);
    font-weight: 700
}

.cart_totals .shipping-calculator-button:hover {
    color: #122139;
}

.cart_totals .woocommerce-shipping-destination {
    margin-bottom: 10px
}

.cart_totals .woocommerce-shipping-methods {
    margin-bottom: 0
}

.cart_totals .shipping-calculator-form {
    display: none
}

.cart_totals .shipping-calculator-form p:first-child {
    margin-top: 20px
}

.cart_totals .shipping-calculator-form p:last-child {
    margin-bottom: 0
}

.cart_totals .shipping-calculator-form .as-btn {
    padding: 5px 30px
}

.cart_totals .amount {
    font-weight: 700
}

.cart_totals .order-total .amount {
    color: #122139;
}

@media (max-width: 991px) {
    .cart_table th {
        padding: 23px 8px;
        font-size: 14px
    }

    .cart_table .cart-productname {
        font-size: 14px
    }

    .cart_table .as-cart-coupon {
        width: 100%;
        margin-bottom: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .cart_table .actions {
        text-align: center
    }
}

@media (max-width: 767px) {
    .cart_table {
        text-align: left;
        min-width: auto;
        border-collapse: separate;
        border-spacing: 0 20px;
        border: none
    }

    .cart_table thead {
        display: none
    }

    .cart_table td {
        padding: 15px;
        display: block;
        width: 100%;
        padding-left: 25%;
        text-align: right;
        border: 1px solid #f3f3f3;
        border-bottom: none
    }

    .cart_table td::before {
        display: block
    }

    .cart_table td:last-child {
        border-bottom: 1px solid #f3f3f3
    }

    .cart_table td.actions {
        padding-left: 15px;
        text-align: center
    }

    .cart_table td.actions>.as-btn {
        margin-top: 10px;
        margin-right: 0;
        display: block;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-left: auto;
        margin-right: auto
    }

    .cart_table td.actions>.as-btn:last-child {
        margin-right: auto
    }

    .cart_table .as-cart-coupon {
        width: 100%;
        text-align: center;
        float: none;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: block;
        padding-bottom: 10px
    }

    .cart_table .as-cart-coupon input {
        width: 100%;
        margin-bottom: 10px
    }

    .cart_totals th,
    .cart_totals td {
        padding: 15px 10px
    }

    .cart_totals th:first-child,
    .cart_totals td:first-child {
        width: 17%;
        line-height: 1.4
    }
}

.woocommerce-checkout .form-group,
.woocommerce-checkout .form-row {
    margin-bottom: 0
}

.woocommerce-checkout .form-select,
.woocommerce-checkout .select2-container,
.woocommerce-checkout .form-control {
    margin-bottom: 0
}

.woocommerce-checkout .select2-container--open .select2-dropdown--below {
    margin-top: -35px
}

.woocommerce-checkout .select2-container--open .select2-dropdown--above {
    position: relative;
    bottom: -30px
}

.woocommerce-checkout .select2-dropdown {
    border: 1px solid #e3e6e9;
    border-top: none
}

.woocommerce-checkout .select2-container--default .select2-selection--single {
    border-radius: 0
}

.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered,
.woocommerce-checkout .select2-container--default .select2-selection--single .form-control:focus {
    color: var(--body-color)
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #eee;
    padding: 0
}

.woocommerce-form-login select,
.woocommerce-form-login .form-select,
.woocommerce-form-login .form-control,
.woocommerce-form-login .select2,
.woocommerce-form-login .select2-container,
.woocommerce-form-coupon select,
.woocommerce-form-coupon .form-select,
.woocommerce-form-coupon .form-control,
.woocommerce-form-coupon .select2,
.woocommerce-form-coupon .select2-container,
.woocommerce-checkout select,
.woocommerce-checkout .form-select,
.woocommerce-checkout .form-control,
.woocommerce-checkout .select2,
.woocommerce-checkout .select2-container {
    margin-bottom: var(--bs-gutter-x)
}

#ship-to-different-address {
    margin-top: 15px
}

.select2-container--default .select2-selection--single {
    height: 60px;
    border: 1px solid #e3e6e9
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 60px;
    padding-left: 30px;
    padding-right: 25px
}

.woocommerce-billing-fields .form-row {
    margin-bottom: 0
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
    content: "\f107";
    font-family: var(--icon-font)
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin: 0;
    border: none;
    top: 0
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 60px;
    line-height: 60px;
    margin-right: 30px
}

span.select2-selection.select2-selection--single:focus {
    outline: none
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
    height: 40px;
    padding-left: 15px;
    font-size: 16px;
    background-position: right 13px center
}

.shipping-calculator-form .as-btn {
    font-size: 14px;
    padding: 0 20px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 40px
}

.checkout-ordertable th,
.checkout-ordertable td {
    border: 1px solid #ededed;
    text-align: right;
    padding: 5px 20px;
    vertical-align: top;
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50
}

.checkout-ordertable th {
    font-weight: 800;
    text-align: left
}

.checkout-ordertable ul {
    margin: 0;
    padding: 0
}

.checkout-ordertable .order-total .amount {
    color: #122139;
}

.checkout-ordertable input[type="hidden"]~label {
    color: #122139;
}

.woocommerce-checkout .form-group input:not(:last-child) {
    margin-bottom: var(--bs-gutter-x)
}

.woocommerce-checkout-payment {
    text-align: left
}

.woocommerce-checkout-payment ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.woocommerce-checkout-payment ul li {
    padding-top: 12px;
    border-bottom: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px
}

.woocommerce-checkout-payment ul input[type="radio"]~label {
    margin-bottom: 17px;
    color: var(--body-color)
}

.woocommerce-checkout-payment ul input[type="radio"]~label img {
    margin-bottom: -2px;
    margin-left: 10px
}

.woocommerce-checkout-payment .place-order {
    padding-top: 30px
}

.woocommerce-checkout-payment .payment_box {
    color: #a1b1bc;
    background-color: #ecf0f1;
    border: 1px solid #d8d8d8;
    border-bottom: none;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 4px;
    display: none
}

.woocommerce-checkout-payment .payment_box p {
    margin: 0
}

.as-checkout-wrapper form.woocommerce-form {
    margin-bottom: 25px
}

@media (max-width: 767px) {
    tfoot.checkout-ordertable th {
        display: none
    }

    .woocommerce-checkout-payment ul input[type="radio"]~label img {
        max-width: 150px
    }

    .checkout-ordertable th,
    .checkout-ordertable td {
        padding: 5px 20px 5px 60px
    }
}

.tinv-wishlist input[type=checkbox] {
    display: inline-block;
    opacity: 1;
    visibility: visible;
    vertical-align: middle;
    width: auto;
    height: auto
}

.tinv-wishlist .tinv-header {
    margin-top: -0.8rem
}

.tinv-wishlist .cart-empty {
    padding: 12px 25px;
    background-color: #eee;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px
}

.tinv-wishlist p.return-to-shop .button {
    display: inline-block;
    background-color: #122139;
    color: #fff;
    font-size: 14px;
    padding: 10px 25px;
    margin-top: 10px;
    font-weight: 700
}

.tinv-wishlist p.return-to-shop .button:Hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

.tinv-wishlist table {
    border: none
}

.tinv-wishlist table th {
    color: var(--title-color)
}

.tinv-wishlist table td,
.tinv-wishlist table th {
    padding: 15.3px 10px;
    border-bottom: 1px solid var(--border-color);
    text-align: center
}

.tinv-wishlist table thead {
    background-color: var(--smoke-color)
}

.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
    width: 40px;
    text-align: center
}

.tinv-wishlist .product-thumbnail {
    width: 110px
}

.tinv-wishlist .stock.in-stock {
    margin-bottom: 0
}

.tinv-wishlist ins {
    text-decoration: none
}

.tinv-wishlist .product-remove button {
    border: none;
    height: 22px;
    width: 22px;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    border-radius: 0;
    padding-top: 0
}

.tinv-wishlist .product-remove button i {
    line-height: 22px;
    font-size: 16px
}

.tinv-wishlist .tinvwl-mobile {
    display: none
}

.tinv-wishlist .social-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 295px;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.tinv-wishlist .social-buttons ul {
    padding-left: 0;
    margin-bottom: 0;
    margin-left: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 6px
}

.tinv-wishlist table.tinvwl-table-manage-list {
    font-size: 16px
}

.tinv-wishlist .product-stock .stock {
    display: block
}

.tinv-wishlist .product-stock span {
    display: inline
}

.tinv-wishlist .product-stock i {
    margin-right: 5px
}

.tinv-wishlist .tinv-modal .icon_big_times {
    margin-bottom: 5px;
    color: #122139;
}

.tinv-wishlist button.button {
    border: none;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    background-color: #122139;
    color: #fff;
    padding: 1px 15px;
    min-width: 140px
}

.tinv-wishlist button.button.mask-btn {
    padding: 0
}

.tinv-wishlist button.button .btn-text-mask {
    padding: 0.5px 21px
}

.tinv-wishlist button.button:hover {
    background-color: var(--title-color);
    color: #fff
}

.tinv-wishlist button.button i {
    font-size: 14px !important;
    margin-right: 3px !important
}

.tinv-wishlist th,
.tinv-wishlist td.product-name {
    font-size: 16px;
    font-weight: 700;
    font-family: var(--title-font)
}

.tinv-wishlist td.product-name a {
    color: var(--body-color)
}

.tinv-wishlist td.product-name a:hover {
    color: #122139;
}

.tinv-wishlist td.product-price del {
    margin-left: 8px;
    font-size: 0.9em
}

.tinv-wishlist .social-buttons>span {
    font-weight: 700;
    margin-right: 10px;
    font-family: var(--title-font);
    color: var(--title-color)
}

.tinv-wishlist .social-buttons li {
    display: inline-block;
    margin-right: 0
}

.tinv-wishlist .social-buttons li a.social {
    background-color: #122139;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    margin-left: 3px
}

.tinv-wishlist .social-buttons li a.social:first-child {
    margin-left: 0
}

.tinv-wishlist .social-buttons li a.social i {
    line-height: inherit
}

.tinv-wishlist .social-buttons li a.social:hover {
    background-color: var(--title-color);
    color: var(--white-color)
}

@media (max-width: 991px) {
    .tinvwl-full {
        display: none
    }

    .tinv-wishlist .tinvwl-mobile {
        display: block
    }

    .tinvwl-txt {
        display: none !important
    }

    .product-stock {
        width: 40px;
        text-align: center
    }
}

@media (max-width: 767px) {
    .tinv-wishlist table {
        table-layout: fixed;
        border-bottom: 1px solid var(--border-color)
    }

    .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove,
    .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
        display: none
    }

    .tinv-wishlist table td,
    .tinv-wishlist table th {
        border: 1px solid var(--border-color)
    }

    .tinv-wishlist table.tinvwl-table-manage-list tbody td {
        display: block;
        width: 100% !important;
        text-align: center
    }

    .product-name {
        text-align: center
    }

    .tinv-wishlist table td,
    .tinv-wishlist table th {
        border-bottom: none
    }

    .tinv-wishlist table tfoot {
        border-bottom: 1px solid var(--border-color)
    }

    .tinv-wishlist .social-buttons {
        max-width: 100%;
        margin-left: unset;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tinv-wishlist .social-buttons ul {
        margin-left: unset;
        margin-top: 5px
    }

    .tinvwl-txt {
        display: inline-block !important
    }
}

.appoitment-form select,
.appoitment-form .form-control,
.appoitment-form .form-select,
.appoitment-form textarea,
.appoitment-form input {
    background-color: var(--white-color);
    border: 1px solid var(--border-color)
}

.appoitment-form .chg-box select,
.appoitment-form .chg-box .form-control,
.appoitment-form .chg-box .form-select,
.appoitment-form .chg-box textarea,
.appoitment-form .chg-box input {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #FFFFFF;
}
.appoitment-form .chg-box select{
    color: rgb(170, 170, 170) !important;
}

.form-btn button {
    margin-top: 15px;
}

.contact-feature {
    padding: 22px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    background: var(--white-color);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 5px
}

.contact-feature-wrap {
    display: grid;
    grid-template-columns: auto auto;
    gap: 24px;
    border-top: 1px solid var(--border-color);
    padding-top: 40px;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto
}

.contact-feature .icon-btn {
    background-color: #122139;
    color: var(--white-color);
    font-size: 24px;
    box-shadow: 0px 6px 20px rgb(52 66 88 / 60%);
    border-radius: 5px;
    border: none
}

.contact-feature_label {
    margin-bottom: 0
}

.contact-feature_link {
    font-size: 20px;
    color: var(--title-color)
}

.contact-feature_link:hover {
    color: #122139;
}

.bg-blue {
    background-color: #0082d7 !important;
}

.bg-secondary-color {
    background-color: #273f5b !important;
}

.bg-blue-light {
    background-color: #98d5ff !important;
}

.bg-orange-light {
    background-color: #f89101 !important;
}

.bg-red {
    background-color: #ff3133 !important;
}

.bg-green {
    background-color: #01a23c !important;
}

.bg-secondary-color {
    background-color: #273f5b !important;
}

.page-single {
    text-align: start;
}

@media (max-width: 1299px) {
    .contact-feature {
        padding: 20px
    }
}

@media (max-width: 767px) {
    .contact-feature {
        gap: 15px;
        padding: 15px
    }

    .contact-feature-wrap {
        gap: 15px
    }
}

@media (max-width: 575px) {
    .contact-feature {
        padding: 15px
    }

    .contact-feature-wrap {
        grid-template-columns: auto
    }

    .contact-feature_link {
        font-size: 16px;
    }

    .contact-feature .icon-btn i {
        line-height: 2.3;
    }
}

.quote-form {
    background: var(--white-color);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 5px;
    padding: 50px
}

@media (max-width: 575px) {
    .quote-form {
        padding: 40px 20px
    }
}

.quote-form select,
.quote-form .form-control,
.quote-form .form-select,
.quote-form textarea,
.quote-form input {
    background-color: var(--smoke-color2)
}

.quote-form .form-group>i {
    background-color: var(--smoke-color2);
    width: 18px;
    text-align: right
}

.contact-infos {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px;
    box-shadow: 0px 4px 30px rgba(8, 14, 28, 0.08);
    border-radius: 5px;
    background-color: var(--white-color);
    line-height: 1.5;
}

.contact-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px;
    box-shadow: 0px 4px 30px rgba(8, 14, 28, 0.08);
    border-radius: 5px;
    background-color: var(--white-color);
    padding: 40px;
    height: 230px;
    line-height: 1.5;
}

.contact-info .box-title {
    margin-bottom: 10px
}

.contact-info_icon {
    display: inline-block;
    width: 80px;
    min-width: 80px;
    height: 80px;
    line-height: 80px;
    background-color: #1c1c1c;
    color: var(--white-color);
    text-align: center;
    font-size: 40px;
    position: relative;
    box-shadow: 0px 6px 20px #613fe399;
    border-radius: 5px
}

.contact-info_text {
    min-width: 180px;
    display: block;
    margin-bottom: -0.5em;
    font-family: var(--body-font)
}

.contact-info_text span,
.contact-info_text a {
    display: block;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content
}

.contact-info_text a {
    color: var(--body-color)
}

.contact-info_text a:hover {
    color: #122139;
}

.contact-form select,
.contact-form .form-control,
.contact-form .form-select,
.contact-form textarea,
.contact-form input {
    background-color: var(--white-color)
}

.contact-form .form-group>i {

    color: #122139;
    width: 18px;
    text-align: right
}

.map-sec {
    line-height: 0px
}

.map-sec iframe {
    -webkit-filter: grayscale(100);
    filter: grayscale(100);
    height: 600px
}

@media (max-width: 1299px) {
    .map-sec iframe {
        height: 450px
    }
}

@media (max-width: 575px) {
    .map-sec iframe {
        height: 300px
    }
}


@media (max-width: 375px) {
    .contact-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.img-box1 {
    position: relative;
    z-index: 2;
    margin-right: 18px;
    margin-left: 50px
}

.img-box1 .img1 img {
    width: 100%;
    max-width: 650px
}

.img-box1 .shape1 {
    position: absolute;
    bottom: 0px;
    right: 80px;
    z-index: -99999;
}

.img-box1 .shape1 img {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;

}

.img-box1 .year-counter {
    position: absolute;
    bottom: 30px;
    right: 0
}

.year-counter {
    height: 184px;
    width: 184px;
    /* background-color: #1c1c1c;
    border: 12px solid var(--white-color);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.1);
    border-radius: 50%;
    text-align: center;
    padding: 25px 0 */
}

.year-counter_number {
    color: var(--white-color);
    position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 24px;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            margin: 0;
    font-size: 4rem !important;
}
.year-counter {
    right: 15% !important;
}
.year-counter_text {
    color: var(--white-color);
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.1rem !important;
}

.about-feature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.about-feature-wrap {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    padding-bottom: 35px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 40px
}

.about-feature_title {
    font-size: 26px;
    margin-bottom: 7px
}

.about-feature_text {
    margin-bottom: 0;
    font-size: 1.1rem;
}

.call-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}

.call-btn .play-btn>i {
    --icon-size: 56px;
    background-color: #1c1c1c;
    color: var(--white-color)
}

.call-btn .play-btn:before,
.call-btn .play-btn:after {
    background-color: #122139;
}

.call-btn .play-btn:hover>i {
    background-color: #122139;
}

.call-btn .play-btn:hover:before,
.call-btn .play-btn:hover:after {
    background-color: #122139;
}

.call-btn .btn-text {
    font-size: 1.1rem;
    font-weight: 500;
    display: block
}

.call-btn .btn-title {
    font-size: 20px;
    color: var(--title-color);
    font-weight: 600
}

.call-btn a:hover {
    color: #122139;
}
.node-as-hero .call-btn a:hover{
    color: #FFFFFF;
}

.btn-group:has(.call-btn) {
    gap: 50px
}

@media (max-width: 767px) {
    .img-box1 {
        margin-right: 0;
        margin-left: 0
    }

    .img-box1 .year-counter {
        bottom: 0
    }
}

@media (max-width: 575px) {
    .btn-group:has(.call-btn) {
        gap: 30px
    }

    .about-feature-wrap {
        grid-template-columns: auto;
        padding-bottom: 25px;
        margin-bottom: 35px
    }
}

@media (min-width: 1300px) {
    .img-box3 {
        margin-right: 56px
    }
}

.img-box3 {
    position: relative;
    z-index: 2;
    line-height: 1px
}

.img-box3 .img1 {
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 15px
}

.img-box3 .img2 {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--white-color);
    padding: 10px 0 0 10px;
    border-radius: 10px;
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

.img-box3 .img2 img {
    border-radius: inherit
}

.img-box3 .img3 {
    position: absolute;
    left: -90px;
    top: 80px;
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

.img-box3 .img3 img {
    border-radius: 10px
}

.img-box3 .shape1 {
    position: absolute;
    top: 80px;
    left: -195px;
    z-index: -1;
    -webkit-animation: movingX 8s linear infinite;
    animation: movingX 8s linear infinite
}

@media (max-width: 1299px) {
    .img-box3 .img3 {
        left: -40px
    }
}

@media (max-width: 1199px) {
    .img-box3 {
        margin-left: 150px
    }
}

@media (max-width: 767px) {
    .img-box3 {
        margin-left: 0
    }
}

@media (max-width: 575px) {
    .img-box3 .img3 {
        left: -28px;
        max-width: 35%
    }

    .img-box3 .img2 {
        max-width: 70%
    }

    .img-box3 .shape1 {
        max-width: 50%;
        left: -30px;
        top: 40%
    }
}

.achivement-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--title-color);
    position: relative
}

.achivement-tab button {
    color: var(--white-color);
    border: none;
    padding: 0 5px;
    line-height: 56px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    position: relative;
    z-index: 2;
    background-color: transparent
}

.achivement-tab .indicator {
    position: absolute;
    top: var(--pos-y);
    left: var(--pos-x);
    height: var(--height-set);
    width: var(--width-set);
    background-color: #122139;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.achivement-tab .indicator:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #122139 transparent transparent transparent;
    z-index: 1
}

.achivement-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    margin: 30px
}

.achivement-box-area {
    background-color: var(--white-color);
    box-shadow: 0px 4px 50px rgba(8, 14, 28, 0.1)
}

.achivement-box_img {
    border-radius: 5px;
    overflow: hidden;
    min-width: 218px
}

.achivement-box_img img {
    width: 100%
}

.achivement-box .box-title {
    margin-bottom: 12px
}

.achivement-box_text {
    font-size: 14px;
    margin-bottom: 14px
}

@media (min-width: 1300px) {
    .img-box4 {
        margin-left: -90px;
        margin-right: 56px
    }
}

.img-box4 {
    position: relative;
    text-align: center
}

.img-box4 img {
    width: 100%;
    max-width: 750px
}

.img-box4 .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media (max-width: 767px) {
    .achivement-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .achivement-box_img {
        min-width: 100%
    }
}

@media (max-width: 375px) {
    .achivement-tab button {
        font-size: 14px;
        text-transform: capitalize
    }
}

.about-sec-v4 {
    padding-top: 67px
}

.img-box6 {
    position: relative
}

.img-box6 .shape1 {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

.img-box6 .shape2 {
    position: absolute;
    top: 0;
    left: -40px;
    -webkit-animation: moving 8s linear infinite;
    animation: moving 8s linear infinite
}

.color-animate {
    width: 100%;
    max-width: 500px;    
    background-color: #F2BA4C;
    opacity: 0.45;
    -webkit-filter: blur(300px);
    filter: blur(300px);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-animation: bgColor 8s ease-in-out infinite;
    animation: bgColor 8s ease-in-out infinite
}

@media (max-width: 991px) {
    .img-box6 {
        text-align: center
    }

    .img-box6 .shape2 {
        left: 0
    }

    .list-center {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto
    }

    .list-center.two-column {
        max-width: 500px
    }
}

@media (max-width: 575px) {

    .img-box6 .shape1,
    .img-box6 .shape2 {
        max-width: 130px
    }
}

.as-team {
    position: relative
}

.as-team .team-img {
    position: relative;
    overflow: hidden
}

.as-team .team-img img {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.as-team .team-desig {
    font-size: 14px;
    display: block;
    margin-bottom: -0.5em;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    color: #122139;
    text-align: start;
}

.as-team .team-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    position: absolute;
    top: 0; 
    left: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    z-index: 4
}
.as-team .team-social a {
    color: var(--white-color)
}

.as-team .team-social a:hover {
    color: #122139;
}

.as-team .as-social a {
    --icon-size: 36px;
    background-color: var(--white-color);
    color: #122139;
}

.as-team .as-social a:hover {
    background-color: #122139;
    color: var(--white-color)
}
.team-card .team-content.team-content-remove .as-team .box-title {
    font-size: 20px !important;
}
.as-team .box-title {
    margin: 7px auto;
    text-align: center;
    font-size: 17px !important;
}

.as-team:hover .team-img img {
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08)
}

.as-team:hover .team-social {
    visibility: visible;
    opacity: 1
}

.team-card {
    text-align: center;
    margin-left: 29px;
    margin-right: 4px;
}

.team-card .team-content {
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 20px 10px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    position: relative;
    z-index: 2;
    padding-bottom: 10px;
    max-width: 248px;
    margin: -50px auto 0 auto
}
.team-card .team-content.team-content-remove{   
    padding: 29px 12px !important;
    padding-bottom: 29px !important;
    max-width: 281px !important;
}
.team-card .team-img {
    border-radius: 10px
}

.team-card .team-social {
    position: absolute;
    top: unset;
    bottom: 100%;
    left: 18px;
    height: 0px;
    padding-top: 15px;
    max-width: calc(100% - 36px);
    background-color: var(--white-color);
    -webkit-clip-path: path("M105.5 0C58.2259 0 17.7021 28.902 0.634674 70H210.365C193.298 28.902 152.774 0 105.5 0Z");
    clip-path: path("M105.5 0C58.2259 0 17.7021 28.902 0.634674 70H210.365C193.298 28.902 152.774 0 105.5 0Z");
    z-index: -1
}

.team-card .team-social a {
    color: var(--title-color)
}

.team-card:hover .team-content {
    background-color: #1c1c1c;
    box-shadow: 0px 2px 13px rgb(42 53 70 / 60%);
}
.team-card:hover .team-content.team-content-remove {
    background: #FFFFFF;
}
.team-card:hover .team-content.team-content-remove h3 a{
    color: #2e363e !important;
}

.team-card:hover .box-title {
    color: #ffffff;
}

.team-card:hover .box-title a:hover {
    color: #ffffff;
}

.team-card:hover .team-desig {
    color: #ffffff;
}

.team-card:hover .team-social {
    height: 70px
}

.project-card .icon-btn i {
    line-height: 2.4;
}

.swiper-container {
    width: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    margin: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.team-sec {
    position: relative;
    background-color: var(--smoke-color);
    background-size: auto;
    background-position: bottom left;
    overflow: hidden
}

.team-sec .container .slick-slider .slick-list {
    height: 503px;
}

.team-sec .z-index-common .slick-slider .slick-arrow {
    top: 30%;
}

.bg-top-center .slider-shadow .slick-slider .slick-arrow {
    top: 55%;
}

.testi-card-area .slick-slider .slick-arrow {
    display: none !important;
}

@media (max-width: 575px) {
    .team-card {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto
    }
}

.team-box {
    position: relative;
    background-color: var(--white-color);
    border-radius: 10px;
    text-align: center;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.team-box .team-img {
    border-radius: 10px 10px 0 0
}

.team-box .as-social {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.team-box .as-social a {
    --icon-size: 36px;
    color: #122139;
    background-color: var(--white-color);
    border: none
}

.team-box .as-social a:hover {
    background-color: #122139;
    color: var(--white-color)
}

.team-box .as-social a:nth-child(2) {
    margin-right: 30px
}

.team-box .as-social a:nth-child(3) {
    margin-left: 30px
}

.team-box .team-content {
    padding: 26px 10px;
    position: relative;
    z-index: 2
}

.team-box .team-social {
    display: block;
    top: unset;
    bottom: 25px;
    text-align: center;
    width: 100%;
    visibility: visible;
    opacity: 1
}

.team-box .team-social .play-btn {
    cursor: pointer
}

.team-box .team-social .play-btn>i {
    --icon-size: 36px;
    background-color: #122139;
    color: var(--white-color);
    font-size: 20px
}

.team-box .team-social .play-btn:before,
.team-box .team-social .play-btn:after {
    background-color: #122139;
}

.team-box .team-social .play-btn:hover>i {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.team-box .team-social:hover .as-social {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.team-box:hover {
    background-color: #122139;
}

.team-box:hover .box-title {
    color: var(--white-color)
}

.team-box:hover .box-title a:hover {
    color: var(--white-color)
}

.team-box:hover .team-desig {
    color: var(--white-color)
}

@media (max-width: 575px) {
    .team-box {
        max-width: 340px;
        margin-left: auto;
        margin-right: auto
    }
}

.team-grid {
    position: relative;
    z-index: 2;
    background-color: var(--white-color);
    box-shadow: 0px 4px 15px rgba(8, 14, 28, 0.1);
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.team-grid .team-img {
    border-radius: 10px;
    margin-bottom: 25px
}

.team-grid .as-social {
    position: absolute;
    bottom: 42px;
    right: 0;
    max-width: 36px;
    opacity: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.team-grid .as-social a {
    margin-bottom: 6px
}

.team-grid .team-social {
    display: block;
    top: unset;
    bottom: 82px;
    right: 48px;
    left: unset;
    visibility: visible;
    opacity: 1;
    width: 36px
}

.team-grid .team-social .play-btn {
    cursor: pointer
}

.team-grid .team-social .play-btn>i {
    --icon-size: 36px;
    background-color: #122139;
    color: var(--white-color);
    font-size: 20px
}

.team-grid .team-social .play-btn:before,
.team-grid .team-social .play-btn:after {
    background-color: #122139;
}

.team-grid .team-social .play-btn:hover>i {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.team-grid .team-social:hover .as-social {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.team-grid .team-desig {
    margin-bottom: 0
}

.team-grid:hover {
    background-color: #122139;
}

.team-grid:hover .box-title {
    color: var(--white-color)
}

.team-grid:hover .box-title a:hover {
    color: var(--white-color)
}

.team-grid:hover .team-desig {
    color: var(--white-color)
}

.team-grid:hover .play-btn>i {
    background-color: var(--white-color);
    color: #122139;
}

.team-grid:hover .play-btn:before,
.team-grid:hover .play-btn:after {
    background-color: var(--white-color)
}

.team-featured {
    padding-left: 36px
}

.team-featured-img {
    border-radius: 10px;
    overflow: hidden
}

.team-featured-img img {
    width: 100%
}

.team-featured .team-title {
    margin-bottom: 5px;
    margin-top: -0.32em
}

.team-featured .team-desig {
    color: #122139;
    margin-bottom: 20px
}

.team-featured .team-bio {
    margin-bottom: 23px
}

.team-contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background-color: var(--smoke-color);
    border-radius: 5px
}

.team-contact-wrap {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    padding: 30px 0;
    margin-bottom: 30px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--smoke-color2)
}

.team-contact .icon-btn {
    --btn-size: 50px;
    border-color: #122139;
    background-color: #122139;
    color: var(--white-color)
}

.team-contact_label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0
}

.team-contact_link {
    color: var(--body-color);
    font-family: var(--body-font);
    font-size: 14px;
    display: inline-block
}

.team-contact a:hover {
    color: #122139;
}

@media (max-width: 1299px) {
    .team-featured {
        padding-left: 0
    }
}

@media (max-width: 767px) {
    .team-contact-wrap {
        grid-template-columns: auto auto
    }
}

@media (max-width: 575px) {
    .team-contact-wrap {
        grid-template-columns: auto
    }
}

.about-card {
    background-color: var(--white-color);
    box-shadow: 0px 0px 50px rgba(8, 14, 28, 0.1);
    border-radius: 10px;
    padding: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px
}

.about-card_img {
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-flex: 490px;
    -webkit-flex: 490px;
    -ms-flex: 490px;
    flex: 490px
}

.about-card_img img {
    width: 100%;
    border-radius: inherit
}

.about-card_box {
    -webkit-box-flex: 610px;
    -webkit-flex: 610px;
    -ms-flex: 610px;
    flex: 610px
}

.about-card_title {
    margin-top: -0.32em;
    margin-bottom: 7px
}

.about-card_desig {
    color: #122139;
    margin-bottom: 0
}

.about-card_top {
    margin-bottom: 22px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.about-card_text {
    margin-bottom: 30px
}

.team-info-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 40px
}

.team-info-wrap .contact-feature {
    background-color: transparent;
    padding: 0;
    box-shadow: none
}

.border-title {
    border-bottom: 1px solid var(--smoke-color2);
    margin-bottom: 30px;
    padding-bottom: 20px
}

.experience-box {
    padding: 30px 30px 30px 65px;
    border: 1px solid #F1F1F1;
    overflow: hidden;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #ddd;
}

.experience-box-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: none;
}

.experience-box_num {
    font-size: 12px;
    width: 60px;
    height: 60px;
    line-height: 65px;
    background-color: #122139;
    color: var(--white-color);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -13px;
    left: -13px
}

.experience-box_num::first-letter {
    font-size: 18px;
    font-weight: 600
}

.experience-box_title {
    font-family: var(--title-font);
    font-size: 18px;
    margin-bottom: 4px;
    margin-top: -0.2em
}

.experience-box_text {
    font-size: 14px;
    margin-bottom: -0.2em
}

@media (max-width: 1199px) {
    .border-title {
        margin-bottom: 24px;
        padding-bottom: 15px
    }

    .about-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .about-card_img {
        -webkit-box-flex: 100%;
        -webkit-flex: 100%;
        -ms-flex: 100%;
        flex: 100%
    }

    .about-card_img img {
        width: 100%
    }

    .about-card_box {
        -webkit-box-flex: 100%;
        -webkit-flex: 100%;
        -ms-flex: 100%;
        flex: 100%
    }
}

@media (max-width: 767px) {
    .experience-box {
        padding: 30px 30px 30px 50px
    }

    .about-card {
        padding: 20px
    }
}

@media (max-width: 575px) {
    .experience-box {
        padding: 30px 30px 30px 65px
    }

    .experience-box-wrap {
        grid-template-columns: repeat(1, 1fr)
    }

    .team-info-wrap {
        grid-template-columns: auto
    }
}

.testi-card-area {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

}

.testi-card-slide {
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 40px;
    max-width: 600px;
    position: relative
}

.testi-card-tab {
    position: relative;
    z-index: 2;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 5px 0;
    width: 56px
}

.testi-card-tab:after {
    content: '';
    height: 100%;
    width: 3px;
    position: absolute;
    top: 1px;
    left: 26.5px;
    background-color: #122139;
    z-index: -1
}

.testi-card-tab .tab-btn {
    cursor: pointer;
    width: 56px;
    border-radius: 99px
}

.testi-card-tab .tab-btn img {
    border-radius: inherit;
    width: 48px;
    height: 48px;
}

.testi-card-tab .indicator {
    position: absolute;
    top: calc(var(--pos-y) - 5px);
    left: calc(var(--pos-x) - 5px);
    width: calc(var(--width-set) + 10px);
    height: calc(var(--height-set) + 10px);
    border: 3px solid #122139;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    border-radius: 50%
}

.testi-card_quote {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.testi-card_profile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}

.testi-card_avater {
    border-radius: 99px;
    overflow: hidden
}

.testi-card_name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px
}

.testi-card_desig {
    margin-bottom: 0;
    display: block;
    color: #122139;
}

.testi-card_text {
    margin-bottom: 22px
}

.testi-card_review {
    color: #122139;
    font-size: 14px;
    margin-top: -0.4em;
    margin-bottom: 17px
}

.testi-card_review i {
    margin-right: 3px
}

@media (max-width: 767px) {
    .testi-card-area {
        display: block
    }

    .testi-card-tab {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%;
        max-width: 300px;
        margin: 30px auto 0 auto
    }

    .testi-card-tab:after {
        height: 3px;
        top: 26.5px;
        left: 0;
        width: 100%
    }

    .testi-card-tab .tab-btn {
        -webkit-box-flex: 56px;
        -webkit-flex: 56px;
        -ms-flex: 56px;
        flex: 56px;
        width: auto;
        background-color: #122139;
        max-width: 49px
    }

    .tab-btn .avtart-2,
    .testi-card-tab .tab-btn .avtart-3 {
        position: absolute;
        right: 0;
        top: 0;
        left: 0px;
    }
}

@media (max-width: 375px) {
    .testi-card-slide {
        padding: 40px 20px
    }

    .testi-card_name {
        font-size: 20px
    }

    .testi-card_avater {
        max-width: 60px
    }
}

.testi-box {
    margin-right: 11px !important;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--white-color);
    box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    gap: 30px;
    padding: 40px
}

.testi-box_img {
    min-width: 180px;
    text-align: center
}

.testi-box_img>img {
    border-radius: 10px;
    width: 100%
}

.testi-box_quote {
    height: 56px;
    width: 56px;
    line-height: 52px;
    background-color: #122139;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: -28px auto 0 auto
}

.testi-box_quote img {
    position: relative;
    margin-top: 17px;
    z-index: 1
}

.testi-box_quote:after,
.testi-box_quote:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #122139;
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.testi-box_quote:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.testi-box_avater {
    margin-bottom: 12px
}

.testi-box_avater img {
    border-radius: 999px
}

.testi-box .box-title {
    margin-bottom: 4px
}

.testi-box_desig {
    font-size: 14px;
    display: block;
    margin-bottom: -0.5em
}

.testi-box_text {
    margin-top: -0.45em;
    padding-bottom: 23px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--smoke-color2)
}

.testi-box_review {
    color: #122139;
    font-size: 14px;
    position: absolute;
    bottom: 55px;
    right: 40px
}

.testi-box_review i {
    margin-right: 3px
}

@media (max-width: 1199px) {
    .testi-box {
        max-width: 760px;
        margin-left: auto;
        margin-right: auto
    }

    .testi-box_review {
        bottom: 75px
    }
}

@media (max-width: 767px) {
    .testi-box {
        padding: 20px;
        gap: 20px
    }

    .testi-box_img {
        min-width: 140px
    }

    .testi-box_review {
        bottom: 30px
    }

    .testi-box_text {
        padding-bottom: 15px;
        margin-bottom: 25px
    }

    .slick-slide .col-set {
        text-align: center;
    }
}

@media (max-width: 575px) {
    .testi-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 60px;
        text-align: center;
        padding-bottom: 30px
    }

    .testi-box_img {
        min-width: 120px;
        max-width: 120px;
        margin-top: -60px
    }

    .testi-box_img>img {
        border-radius: 50%
    }

    .testi-box_review {
        position: static;
        margin-bottom: 10px;
        margin-top: -0.4em
    }

    .justify-content-between .col-6 {
        width: 100%;
    }

    .slick-slide img {
        text-align: center;
    }

    .slick-slide .col-set {
        text-align: center;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {


        right: 10px;
    }
}

.testi-grid {    
    position: relative;
    background-color: var(--white-color);
    box-shadow: 0px 6px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    padding: 120px 40px 40px 40px;
    margin-top: 50px
}

.testi-grid_img {
    background-color: var(--black-color2);
    width: 120px;
    text-align: center;
    border-radius: 10px;
    border: 10px solid var(--black-color2);
    position: absolute;
    top: -50px;
    left: 40px
}

.testi-grid_img>img {
    border-radius: 10px;
    width: 100%
}

.testi-grid_quote {
    height: 36px;
    width: 36px;
    line-height: 36px;
    background-color: #122139;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    bottom: -18px;
    left: calc(50% - 18px);
    z-index: 2;
    margin: -28px auto 0 auto
}

.testi-grid_quote img {
    position: relative;
    z-index: 1;
    margin-top: 11px;
}

.testi-grid_quote:after,
.testi-grid_quote:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #122139;
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.testi-grid_quote:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.testi-grid .box-title {
    margin-bottom: 4px
}

.testi-grid_desig {
    font-size: 14px;
    display: block;
    margin-bottom: -0.5em
}

.testi-grid_text {
    margin-top: -0.45em;
    margin-bottom: 25px
}

.testi-grid_review {
    color: #122139;
    font-size: 14px;
    position: absolute;
    top: 35px;
    right: 40px
}

.testi-grid_review i {
    margin-right: 3px
}

.testi-block-area {
    background-color: var(--white-color);
    padding: 100px 95px 100px 100px;
    max-width: 660px;
    border-radius: 20px;
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px
}

.testi-block-quote {
    position: absolute;
    bottom: 70px;
    right: 100px;
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

.testi-block-slide .slick-dots {
    text-align: left
}

.testi-block_profile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}

.testi-block_avater {
    border-radius: 99px;
    overflow: hidden
}

.testi-block .box-title {
    margin-top: 0;
    margin-bottom: 5px
}

.testi-block_desig {
    margin-bottom: 0;
    display: block;
    color: #122139;
}

.testi-block_text {
    font-size: 20px;
    margin-top: -0.5em;
    margin-bottom: 35px
}

@media (min-width: 1500px) {
    .testi-block-area {
        margin-left: -100px
    }
}

@media (max-width: 1299px) {
    .testi-block-area {
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 50px
    }

    .testi-block-quote {
        right: 50px;
        bottom: 30px
    }
}

@media (max-width: 767px) {
    .testi-block_text {
        font-size: 18px;
        margin-top: -0.45em;
        margin-bottom: 30px
    }
}

@media (max-width: 575px) {
    .testi-block-area {
        margin-top: 80px;
        margin-bottom: 80px;
        padding: 50px 20px;
        border-radius: 10px
    }

    .testi-block-slide .slick-dots {
        margin-top: 25px
    }

    .testi-block-quote {
        right: 10px
    }

    .testi-block_text {
        font-size: 16px;
        margin-bottom: 25px
    }

    .testi-block .box-title {
        font-size: 22px
    }

    .testi-block_profile {
        gap: 15px
    }

    .testi-block_avater {
        max-width: 70px
    }
}

@media (max-width: 375px) {
    .testi-block-quote {
        display: none
    }
}

.counter-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px
}

.counter-card_icon {
    width: 112px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    position: relative;
    padding-left: 15px;
    margin-bottom: 2px;
    z-index: 2
}

.counter-card_icon:before,
.counter-card_icon:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--black-color2);
    -webkit-clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
    clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
    z-index: -2
}

.counter-card_icon:after {
    background-color: var(--white-color);
    top: 2px;
    left: 10px;
    z-index: -1
}

.counter-card_icon img {
    margin-top: 24px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.counter-card_number {
    font-size: 40px;
    color: var(--white-color);
    font-weight: 900;
    margin-bottom: 8px;
    margin-top: -0.3em
}

.counter-card_text {
    font-weight: 500;
    color: var(--white-color);
    display: block;
    margin-bottom: -0.5em
}

.counter-card .icon {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.counter-card:hover .counter-card_icon img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.counter-card:hover .icon {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

@media (max-width: 1299px) {
    .counter-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center
    }

    .counter-card_number {
        margin-bottom: 4px
    }
}

@media (max-width: 375px) {
    .counter-card_number {
        font-size: 36px
    }

    .counter-card_text {
        font-size: 14px
    }
}

.counter-card-wrap {
    background-color: var(--black-color2);
    border-radius: 10px;
    padding: 65px 40px
}

.counter-card-wrap .counter-card_icon:before {
    background-color: #122139;
    opacity: 0.6
}

.counter-card-wrap .counter-card_number {
    font-weight: 700
}

@media (max-width: 575px) {
    .counter-card-wrap {
        padding: 65px 10px
    }
}

.blog-card {
    margin-left: 20px;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    background-color: var(--white-color)
}

.blog-card .box-title {
    margin-bottom: 22px
}

.blog-card .box-title a {
    background-image: -webkit-linear-gradient(right, #122139, #122139);
    background-image: linear-gradient(to left, #122139, #122139);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 0 2px;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out
}

.blog-card .box-title a:hover {
    background-size: 100% 2px
}

.blog-card .blog-img {
    overflow: hidden;
    box-shadow: 0px 10px 15px rgba(23, 27, 42, 0.06);
    border-radius: 10px 10px 0px 0px
}

.blog-card .blog-img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.blog-card .blog-meta {
    margin-bottom: 20px
}

.blog-card .blog-meta span>i,
.blog-card .blog-meta a>i {
    color: inherit
}

.blog-card .blog-content {
    padding: 30px 40px
}

.blog-card .blog-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
    padding-top: 25px
}

.blog-card .author {
    color: var(--body-color)
}

.blog-card .author img {
    margin-right: 5px
}

.blog-card .author:hover {
    color: #122139;
}

.blog-card .blog-text {
    margin-top: -0.5em;
    margin-bottom: 22px
}

.blog-card .line-btn {
    margin-bottom: 0;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--body-color)
}

.blog-card .line-btn:before {
    background-color: var(--body-color)
}

.blog-card .line-btn:hover {
    color: #122139;
}

.blog-card .line-btn:hover:before {
    background-color: #122139;
}

.blog-card:hover .blog-img img {
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08)
}

@media (max-width: 1299px) {
    .blog-card .blog-content {
        padding: 30px 35px
    }
}

@media (max-width: 991px) {
    .blog-card .blog-content {
        padding: 30px 30px
    }

    .blog-card .box-title {
        font-size: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .blog-card .blog-content {
        padding: 30px 40px
    }

    .blog-card .box-title {
        font-size: 24px;
    }
}

@media (max-width: 410px) {
    .blog-card .blog-content {
        padding: 30px 30px
    }

    .blog-card .box-title {
        font-size: 22px
    }
}

@media (max-width: 350px) {
    .blog-card .blog-content {
        padding: 30px 20px
    }

    .blog-card .box-title {
        font-size: 20px
    }
}

.brand-sec1 {
    position: relative;
    z-index: 4;
    border-radius: 10px;
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.brand-sec1:after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--title-color);
    border-radius: inherit;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 70px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 70px 50%, 0 11px);
    clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 70px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 70px 50%, 0 11px);
    z-index: -1
}

@media (max-width: 1500px) {
    .brand-sec1:after {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 40px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 40px 50%, 0 11px);
        clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 40px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 40px 50%, 0 11px)
    }
}

@media (max-width: 1299px) {
    .brand-sec1:after {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 30px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 30px 50%, 0 11px);
        clip-path: polygon(0 0, 100% 0, 100% 11px, calc(100% - 30px) 50%, 100% calc(100% - 11px), 100% 100%, 0 100%, 0 calc(100% - 11px), 30px 50%, 0 11px)
    }
}

@media (max-width: 767px) {
    .brand-sec1 {
        margin-left: 12px;
        margin-right: 12px
    }

    .brand-sec1:after {
        -webkit-clip-path: none;
        clip-path: none
    }
}

.brand-sec1 .slick-arrow {
    visibility: visible;
    opacity: 1
}

@media (max-width: 767px) {
    .brand-box.py-20 {
        padding-top: 0;
        padding-bottom: 0
    }
}

.checklist ul {
    padding-left: 0;
    list-style: none;
    text-align: left;
    margin-bottom: 0
}

.checklist li {
    color: var(--title-color);
    font-weight: 500;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.checklist li>i {
    color: #122139;
}

.checklist li:not(:last-child) {
    margin-bottom: 10px
}

.checklist.style2 li {
    font-size: 18px;
    font-weight: 700;
    color: var(--title-color);
    font-family: var(--title-font);
    gap: 15px;
    padding: 15px 0px;
}

.checklist.style2 li:not(:last-child) {
    margin-bottom: 26px
}

.checklist.style2 li>i {
    font-size: 20px;
    border-radius: 50%;
    border: 1px dashed #122139;
    padding: 9px
}

.checklist.style3 li:not(:last-child) {
    margin-bottom: 20px
}

.checklist.style3 li>i {
    font-size: 25px
}

.checklist.style4 li {
    color: #141D38;
    font-size: 18px;
}

.checklist.style4 li:not(:last-child) {
    margin-bottom: 20px
}

.checklist.style4 li>i {
    font-size: 25px
}

.checklist.style5 li {
    color: var(--body-color);
    font-family: var(--body-font);
    font-weight: 400
}

.checklist.style5 li:not(:last-child) {
    margin-bottom: 13px
}

.checklist.style5 li>i {
    font-size: 22px
}

@media (max-width: 1199px) {
    .checklist.mb-40 {
        margin-bottom: 32px
    }
}

@media (max-width: 1199px) {
    .checklist.mb-45 {
        margin-bottom: 35px
    }
}

.bg-img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%
}

.bg-img img {
    width: 100%;
    height: 100%
}

.as-video {
    position: relative;
    border-radius: 5px
}

.as-video img {
    border-radius: inherit
}

.as-video .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.as-video .play-btn>i {
    background-color: #122139;
    color: var(--white-color)
}

.icon-box {
    /* display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox; */
    gap: 10px
}

.btn-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px 30px
}

.video-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--body-color);
    text-transform: uppercase;
    line-height: 1.6
}

.video-btn .play-btn {
    --icon-size: 50px;
    margin-right: 15px
}

.video-btn .play-btn>i {
    background-color: #122139;
    color: var(--white-color);
    padding-right: 0.1em
}

.video-btn .play-btn:before {
    background-color: #122139;
}

.video-btn .small-text {
    font-size: 10px;
    text-decoration: underline;
    display: block
}

.video-btn .big-text {
    font-size: 14px;
    font-weight: 500
}

@media (max-width: 1199px) {
    p.mb-40 {
        margin-bottom: 35px
    }

    p.mb-45 {
        margin-bottom: 38px
    }
}

.img-box2 {
    text-align: center;
    margin-left: 85px;
    position: relative;
    z-index: 2
}

.img-box2:before,
.img-box2:after {
    content: '';
    position: absolute;
    background-color: #122139;
    width: 100%;
    height: 100%;
    -webkit-animation: morph 10s ease-in-out infinite;
    animation: morph 10s ease-in-out infinite;
    z-index: -1
}

.img-box2:before {
    top: -20px;
    left: -40px;
    opacity: 0.5;
    -webkit-animation-delay: 0.2;
    animation-delay: 0.2
}

.img-box2:after {
    right: -20px;
    bottom: -40px;
    background-color: var(--smoke-color2);
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    z-index: -2
}

.img-box2 .img1 img {
    -webkit-animation: morph 10s ease-in-out infinite;
    animation: morph 10s ease-in-out infinite;
    width: 100%;
    max-width: 760px
}

.two-column {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 700px;
    line-height: 1;
    gap: 22px;
}

@media (max-width: 1299px) {
    .img-box2 {
        margin-left: 0
    }

    .img-box2:before {
        left: -20px
    }

    .img-box2:after {
        right: -12px
    }
}

@media (max-width: 450px) {
    .two-column {
        grid-template-columns: auto;
        gap: 18px
    }

    .two-column.list-center {
        max-width: 230px
    }

    .checklist.style2 li:not(:last-child) {
        margin-bottom: 18px
    }
}

.feature-circle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 14px;
    max-width: 310px
}

.feature-circle-wrap {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 34px
}

.feature-circle_title {
    font-size: 20px;
    margin-bottom: 7px;
    font-size: 44px;
    margin-bottom: 7px;
    text-align: center;
    font-weight: 700;
}

.feature-circle_text {
    margin-bottom: 0px;
    font-size: 17px;
    text-align: center;
}

.feature-circle .progressbar {
    position: relative
}

.feature-circle .circle-num {
    font-size: 24px;
    font-weight: 700;
    color: var(--title-color);
    font-family: var(--title-font);
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: 15px;
    background-color: var(--white-color);
    border-radius: 999px;
    text-align: center
}

@media (min-width: 1300px) {
    .img-box5 {
        margin-left: 56px;
        margin-right: -77px
    }
}

.img-box5 {
    position: relative;
    z-index: 2;
    margin-bottom: -46px;
    margin-top: -70px
}
.img-box5.why-sec-img-box::before{
    background-image: url(../img/GameDevelopment/react/head7_rock.png);
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    width: 100%;
    z-index: 99;
    background-position: right bottom;
    height: 100%;
    right: 0;
    top: 0;
}
.img-box5 .year-counter {
    position: absolute;
    top: 20px;
    left: 40%;
    border-color: var(--smoke-color2);
    box-shadow: none;
    height: 155px;
    width: 155px;
    padding: 32px 0
}

.img-box5 .year-counter_number {
    font-size: 36px
}

.animate-shape1 {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1
}

.animate-shape1 svg {
    width: 100%;
    height: 100%
}

.why-sec-v2 {
    overflow-x: hidden;
    margin-top: 0;
    padding-top: 0;
}

@media (max-width: 1199px) {
    .img-box5 {
        max-width: 760px;
        margin-bottom: -20px;
        text-align: center;
        margin-left: auto;
        margin-right: auto
    }

    .img-box5 .animate-shape1 {
        max-width: 610px
    }
}

@media (max-width: 575px) {
    .feature-circle-wrap {
        grid-template-columns: auto;
        gap: 20px
    }
}

.img-box7,
.img-box8 {
    position: relative;
    margin-left: 56px
}

.img-box7 .shape1,
.img-box8 .shape1 {
    position: absolute;
    top: 0;
    left: 40px;
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

.img-box7 .shape2,
.img-box8 .shape2 {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-animation: moving 8s linear infinite;
    animation: moving 8s linear infinite
}

.img-box8 .shape1 {
    left: 0
}

.img-box8 .shape2 {
    -webkit-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite
}

.img-box8 .color-animate {
    top: -200px;
    max-width: 400px
}

.space {
    position: relative;
}

.space .moving {
    left: 10%;
    top: 80%;
}

.space .jump {
    right: 2%;
    top: 20%;
}

@media (max-width: 1299px) {

    .img-box7,
    .img-box8 {
        margin-left: 0
    }
}

@media (max-width: 991px) {

    .img-box7,
    .img-box8 {
        text-align: center
    }
}

@media (max-width: 575px) {
    .img-box7 .shape1 {
        max-width: 50px
    }

    .img-box7 .shape2 {
        max-width: 70px
    }

    .img-box8 .shape1 {
        max-width: 100px
    }

    .img-box8 .shape2 {
        max-width: 100px
    }
}

.accordion-card {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04);
    border-radius: 5px;
    overflow: hidden
}

.accordion-card:not(:last-child) {
    margin-bottom: 24px
}

.accordion-card .accordion-button {
    font-size: 18px;
    font-weight: 700;
    font-family: var(--title-font);
    border: 0;
    color: var(--title-color);
    background-color: var(--white-color);
    border-radius: 0;
    padding: 12px 45px 12px 30px;
    min-height: 56px;
    gap: 10px;
    margin-bottom: 0;
    text-align: left;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative
}

.accordion-card .accordion-button:after {
    content: "\2b";
    height: 100%;
    width: auto;
    line-height: 1;
    background-color: transparent;
    font-family: var(--icon-font);
    color: var(--title-color);
    font-weight: 700;
    font-size: 1em;
    display: grid;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: 30px;
    -webkit-clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0% 100%);
    padding-left: 10px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.accordion-card .accordion-button:focus {
    outline: none;
    box-shadow: none
}

.accordion-card .accordion-button:not(.collapsed) {
    color: var(--white-color);
    background-color: #122139;
    box-shadow: none;
    border-radius: 0
}

.accordion-card .accordion-button:not(.collapsed):after {
    content: '\f068';
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    color: var(--white-color)
}

.accordion-card .accordion-collapse {
    border: none
}

.accordion-card .accordion-body {
    border-radius: 0;
    background-color: var(--white-color);
    border: none;
    padding: 23px 30px 30px 30px
}

.accordion-card .faq-text {
    margin-bottom: -0.48em
}

.accordion-card .faq-img {
    height: 100%
}

.accordion-card .faq-img img {
    height: 100%;
    object-fit: cover
}

.accordion-card.style2 {
    box-shadow: none;
    border: 1px solid var(--smoke-color2);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06)
}

.accordion-card.style2:not(:last-child) {
    margin-bottom: 24px
}

.accordion-card.style2 .accordion-button {
    background-color: var(--white-color);
    box-shadow: none
}

.accordion-card.style2 .accordion-button:not(.collapsed) {
    border-bottom: none;
    color: #5e5959;
}

.accordion-card.style3 {
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(42, 77, 113, 0.04)
}

.accordion-card.style3:not(:last-child) {
    margin-bottom: 30px
}

.accordion-card.style3 .accordion-button {
    background-color: var(--white-color);
    box-shadow: none
}

.accordion-card.style3 .accordion-button:not(.collapsed) {
    color: var(--title-color);
    background-color: var(--white-color);
    box-shadow: none
}

.accordion-card.style3 .accordion-button:not(.collapsed):after {
    color: #122139;
}

.accordion-card.style3 .accordion-body {
    padding: 0 30px 30px 30px
}

.accordion-area.grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0 24px
}

.accordion-area.grid .accordion-card {
    width: calc(50% - 12px);
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start
}

@media (min-width: 1300px) {
    .faq-img {
        margin-left: 56px;
        margin-right: -48px
    }
}

.faq-img {
    text-align: center
}

.faq-img img {
    width: 100%;
    max-width: 700px
}

@media (max-width: 575px) {
    .accordion-card .accordion-button {
        font-size: 16px
    }
}

.faq-widget {
    background-color: var(--smoke-color);
    padding: 40px;
    margin-bottom: 40px;
    border-radius: 5px;
    text-align: center
}

.faq-widget .title {
    margin-top: -0.23em
}

.faq-form-wrap {
    margin-top: 70px
}

.faq-form-wrap .form-text {
    max-width: 500px;
    margin-bottom: 25px
}

@media (max-width: 991px) {
    .faq-form-wrap {
        margin-top: 45px
    }
}

@media (max-width: 375px) {
    .faq-widget {
        padding: 40px 20px
    }
}

.img-half {
    position: absolute;
    top: 0;
    width: 35%;
    height: 100%
}

.img-half img {
    height: 100%;
    object-fit: cover;
}

.img-half .img-left {
    left: 0
}

.as-video2:after {
    content: "";
    height: 140px;
    width: 140px;
    background-color: #080E1C;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 70px);
    right: -70px
}

.as-video2 .play-btn {
    position: absolute;
    right: -40px;
    top: calc(50% - 40px);
    z-index: 9;

}

.as-video2 .play-btn>i {
    --icon-size: 80px;
    color: #1c1c1c;
}
.as-video2 .play-btn>i:hover{
    cursor: pointer;
}
.as-video2 .play-btn:before,
.as-video2 .play-btn:after {
    background-color: #3f4754;
}

@media (max-width: 1199px) {
    .img-half {
        position: relative;
        width: 100%;
        height: auto
    }

    .as-video2:after {
        right: calc(50% - 70px);
        top: calc(100% - 70px)
    }

    .as-video2 .play-btn {
        position: absolute;
        top: calc(100% - 40px);
        right: calc(50% - 37px);
    }

    .img-half img {
        height: 50% !important;
    }
}

.cta-box {
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--smoke-color)
}

.cta-box_img {
    height: 100%;
}

.cta-box_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.cta-box_content {
    padding: 70px 70px 70px 55px;
    position: relative
}

.cta-box_icon {
    position: absolute;
    right: 70px;
    bottom: 70px
}

@media (max-width: 991px) {
    .cta-box {
        text-align: center
    }
}

@media (max-width: 575px) {
    .cta-box_content {
        padding: 16px 0px;
    }

    .cta-box_icon {
        bottom: 20px;
        right: 20px
    }

    .as-video2:after {
        right: calc(51% - 73px);
        top: calc(100% - 67px)
    }

    .as-video2 .play-btn>i {
        --icon-size: 55px;
    }

    .as-video2:after {
        height: 120px;
        width: 120px;
    }

    .as-video2:after {
        right: calc(51% - 73px);
        top: calc(100% - 67px);
    }

    .title-area {
        margin-top: 35px;
    }

    .team-sec .container .slick-slider .slick-list {
        height: 429px;
    }
}

.shape-icon {
    position: relative;
    z-index: 2;
    width: 90px;
    height: 85px;
    line-height: 85px;
    text-align: center
}

.shape-icon:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--smoke-color2);
    -webkit-clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
    clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
    z-index: -1;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.shape-icon .dots:before,
.shape-icon .dots:after {
    content: '';
    position: absolute;
    background-color: #122139;
    height: 24px;
    width: 23px;
    border-radius: 50%;
    transition: 0.4s ease-in-out;
}

.shape-icon .dots:before {
    top: 0;
    right: 9px
}

.shape-icon .dots:after {
    height: 12px;
    width: 11px;
    bottom: 0;
    left: 27px
}

.service-featured {
    text-align: center;
    position: relative;
    z-index: 2;
    top: 0;
    height: 550px;
    overflow: hidden;
}

.service-featured:before {
    content: '';
    height: 180px;
    width: 100%;
    position: absolute;
    top: 84%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 10px;
    z-index: -1;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.service-featured::after {
    position: absolute;
    width: 100%;
    height: 30%;
    left: 0;
    top: 0;
    content: "";
    background-image: linear-gradient(to top, #0c0c2100 0%, #1c1c1c 95%);
    z-index: 1;
    pointer-events: none;
}

.service-featured .shape-icon {
    margin: 0 auto 30px auto
}

.service-featured .icon-btn {
    border-radius: 99px;
    background-color: #122139;
    box-shadow: 0px 6px 20px rgb(39 54 78 / 45%);
    border: none;
    color: var(--white-color);
    position: absolute;
    bottom: -28px;
    left: calc(50% - 28px)
}

.service-featured .icon-btn:hover {
    background-color: var(--white-color);
    color: #122139;
}

@-webkit-keyframes verticalSlide {
    to {
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }
}

@keyframes verticalSlide {
    to {
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }
}

.service-featured_content {
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 15px 28px 15px;
    margin-bottom: 28px;
    margin-top: -127px;
    transition: all 0.2s;
}

.service-featured_content .Icon_img-one,
.service-featured_content .Icon_img-three {
    margin-top: 30px;
}

.service-featured_content img {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding: 0 20px;
    width: 280px;
    margin-bottom: 20px;
}


.service-featured_text {
    max-width: 255px;
    margin: 0 auto 31px auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.service-card {
    padding: 40px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    background-color: var(--white-color);
    border-radius: 10px;
    height: 450px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.service-card:before,
.service-card:after {
    content: '';
    position: absolute;
    height: 110px;
    width: 110px;
    background-color: #122139;
    opacity: 0.6;
    border-radius: 50%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    z-index: -1
}

.service-card:before {
    bottom: -73px;
    right: -28px
}

.service-card:after {
    right: -73px;
    bottom: -28px
}

.service-card .shape-icon {
    margin-bottom: 30px
}

.service-card .box-title a:hover {
    color: var(--smoke-color2)
}

.service-card .bg-shape {
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    pointer-events: none
}

.service-card .bg-shape img {
    width: 100%
}

.service-card_number {
    position: absolute;
    top: 25px;
    right: 40px;
    font-size: 100px;
    line-height: 1;
    font-weight: bold;
    color: var(--smoke-color2);
    opacity: 0.3
}

.service-card_text {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    margin-bottom: 22px
}

.service-card .as-btn {
    background-color: var(--smoke-color2);
    color: var(--title-color);
    padding: 12.5px 20px;
    box-shadow: none
}

.service-card .as-btn:before,
.service-card .as-btn:after {
    background-color: var(--smoke-color2)
}

.service-card:hover {
    width: 100%
}

.service-card:hover:before,
.service-card:hover:after {
    opacity: 1;
    height: 120%;
    width: 120%;
    border-radius: 0
}

.service-card:hover .shape-icon:before {
    background-color: var(--white-color)
}

.service-card:hover .bg-shape {
    bottom: 0;
    opacity: 1
}

.service-card:hover .box-title {
    color: var(--white-color)
}

.service-card:hover .as-btn {
    background-color: var(--white-color)
}

.service-card:hover .service-card_text {
    color: var(--white-color)
}

.service-sec {
    background-size: auto;
    background-position: top center;
    background-color: var(--smoke-color)
}

@media (max-width: 375px) {
    .service-card {
        padding: 40px 30px
    }
}

.owl-nav {
    display: none;
}

.service-box {
    position: relative;
    box-shadow: 0px 20px 2px 0px rgb(8 14 28 / 25%);
    background-color: #e0e0e066;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    margin: 12px;
    margin-bottom: 20px;
}

.service-box .bg-shape {
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    pointer-events: none
}

.service-box .bg-shape img {
    width: 100%
}

.service-box_img {
    border-radius: 10px 10px 0 0;
    overflow: hidden
}

.service-box_img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.service-box .box-title {
    margin-bottom: 12px
}

.service-box .box-title a:hover {
    color: var(--smoke-color2)
}

.service-box_text {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    max-width: 230px;
    margin: 0 auto 10px auto;
    font-size: 17px !important;
}

.service-box_icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: var(--white-color);
    margin: -40px auto 0 auto;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    border-radius: 50%
}

.service-box_icon img {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    padding-top: 20px !important;
}

.service-box_content {
    position: relative;
    height: 330px;
    padding: 0 10px 25px 10px
}

.service-box:hover {
    background-color: #1c1c1c;
}

.service-box:hover .bg-shape {
    bottom: 0;
    opacity: 1
}
.service-box:hover .bg-shape img{
    width: 100% !important;
}

.service-box:hover .box-title {
    color: var(--white-color)
}

.service-box:hover .link-btn {
    color: var(--white-color)
}

.service-box:hover .link-btn:before {
    background-color: var(--white-color);
    width: 100%
}

.service-box:hover .link-btn:hover:before {
    width: 70px
}

.service-box:hover .service-box_img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.service-box:hover .service-box_icon {
    background-color: #1c1c1c;
}

.service-box:hover .service-box_icon img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.service-box:hover .service-box_text {
    color: var(--white-color)
}

.service-grid {
    position: relative;
    margin-top: -65px;
}

#service-secs .owl-carousel .owl-item img {
    padding: 18px;
}
#owlCarousel.new-carosal.owl-carousel .owl-item img{
    width: 100% !important;
}
.service-grid .bg-shape {
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    pointer-events: none
}

.service-grid .bg-shape img {
    width: 395px;
}

.service-grid_content {
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.10);
    background-color: var(--white-color);
    border-radius: 10px;
    text-align: center;
    padding: 64px 15px 15px 15px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    overflow: hidden;
    height: 455px;
    width: 395px;
    margin: 0 auto;
}

#service-secs .service-grid_icon img {
    width: 41px !important;
}

.new-service-content {
    height: 190px;
    width: 247px;
    margin-bottom: 30px;
}

.new-service-grid {
    margin-bottom: 20px;
}

#service-secs .owl-carousel.owl-drag .owl-item {
    width: 0 !important;
}

.service-grid_icon {
    height: 80px;
    width: 80px;
    line-height: 80px;
    background-color: #1c1c1c;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 0 auto 0 auto;
    -webkit-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px)
}

.service-grid_icon img {
    position: relative;
    z-index: 2;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    margin-top: 15px;
}

.service-grid_icon:after,
.service-grid_icon:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #122139;
    z-index: -2;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.service-grid_icon:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.service-grid .box-title {
    margin-bottom: 12px
}

.service-grid_text {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    max-width: 306px;
    margin: 0 auto 22px auto;
    font-size: 1.1rem;
}

.service-grid .as-btn {
    padding: 15.5px 18px;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    max-width: 87px;
    margin: 0 auto;
}

.service-grid:hover .bg-shape {
    left: 0;
    bottom: 0;
    opacity: 1
}


.service-grid:hover .as-btn {
    background-color: var(--white-color);
    color: #ffff;
}
.service-grid:hover .as-btn:hover { 
    color: var(--white-color)
}
.service-grid .as-btn.style2:hover a.read-mores,.service-grid .as-btn.style2:hover i{
    color: #1c1c1c !important;
}

.service-grid:hover .service-grid_icon {
    background-color: var(--white-color)
}

.service-grid:hover .service-grid_icon img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);    
}

.service-grid:hover .service-grid_icon:before,
.service-grid:hover .service-grid_icon:after {
    background-color: var(--white-color)
}

.service-grid:hover .service-grid_text {
    color: var(--white-color)
}

#service-secs .service-grid_text {
    font-size: 15px;
}

.service-3d {
    text-align: center;
    background: var(--white-color);
    border-radius: 10px;
    padding: 40px 12px;
    margin-right: 20px;
    height: 500px;
    position: relative;
}

.service-3d_text {
    max-width: 315px;
    margin: 0 auto 22px auto;
    position: relative;
}

.service-3d_icon {
    margin-bottom: 30px
}

.service-3d_icon img {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.service-3d .as-btn {
    padding: 15.5px 18px;
    background-color: var(--smoke-color2);
    color: var(--title-color);
    box-shadow: none;
    right: 140px;
    position: absolute;
    border: 1px solid var(--smoke-color);
    bottom: 16px;
    position: absolute;
}

.service-3d .as-btn:before,
.service-3d .as-btn:after {
    background-color: #122139;
}

.service-3d .as-btn:hover {
    color: var(--white-color)
}

.service-3d .as-btn:hover:before,
.service-3d .as-btn:hover:after {
    background-color: #122139;
}

.service-3d:hover .service-3d_icon img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.page-title {
    margin-top: -0.22em;
    font-size: 40px;
    margin-bottom: 20px
}

.page-img {
    margin-bottom: 40px;
    border-radius: 5px;
    overflow: hidden
}

.page-single {
    margin-bottom: 30px
}

.page-single .page-content p {
    font-size: 1.2rem;
}

.service-feature {
    background-color: var(--white-color);
    padding: 30px 25px 30px 30px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 10px 30px rgba(8, 14, 28, 0.06);
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px
}

.service-feature-wrap {
    display: grid;
    grid-template-columns: auto auto;
    gap: 25px
}

.service-feature_icon {
    background: #122139;
    box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
    border-radius: 5px;
    width: 90px;
    padding-top: 13px;
    height: 90px;
    line-height: 90px;
    text-align: center
}

.service-feature_title {
    font-size: 20px;
    margin-bottom: 12px
}

.service-feature_text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0
}

@media (max-width: 1299px) {
    .page-title {
        font-size: 38px
    }
}

@media (max-width: 1299px) {
    .service-feature {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        gap: 20px;
        padding: 30px 10px
    }

    .service-feature_text {
        margin-bottom: -0.5em;
        max-width: 260px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 1199px) {
    .page-title {
        font-size: 32px
    }
}

@media (max-width: 767px) {
    .page-title {
        font-size: 28px
    }
}

@media (max-width: 575px) {
    .page-title {
        font-size: 24px
    }

    .service-feature-wrap {
        grid-template-columns: auto
    }
}

.checklist li.unavailable>i {
    color: var(--body-color)
}

.price-card {
    --space-x: 40px;
    --space-y: 40px;
    background-color: var(--smoke-color);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 2
}

.price-card_top {
    background-color: var(--title-color);
    position: relative;
    z-index: 2;
    padding: var(--space-y) 0;
    overflow: hidden;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.price-card_top:before,
.price-card_top:after {
    content: '';
    position: absolute;
    height: 110px;
    width: 110px;
    background-color: #122139;
    opacity: 0.6;
    border-radius: 50%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    z-index: -1
}

.price-card_top:before {
    top: -73px;
    left: -28px
}

.price-card_top:after {
    left: -73px;
    top: -28px
}

.price-card_title {
    font-size: 30px;
    margin-top: -0.3em;
    margin-bottom: 4px;
    color: var(--white-color);
    padding: 0 var(--space-x);
    position: relative;
    z-index: 3
}

.price-card_text {
    color: var(--white-color);
    padding: 0 var(--space-x) 28px var(--space-x);
    margin-bottom: 35px;
    border-bottom: 2px solid;
    -webkit-border-image: -webkit-linear-gradient(right, var(--title-color) 27%, #122139 100%);
    border-image: linear-gradient(to left, var(--title-color) 27%, #122139 100%);
    border-image-slice: 1;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.price-card_price {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin: -0.09em 0;
    color: var(--white-color);
    padding: 0 var(--space-x);
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.price-card_price .duration {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    margin-bottom: 0;
    position: relative;
    top: -1px;
    left: -4px
}

.price-card .particle {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 190px;
    height: 165px;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    -webkit-clip-path: path("M182 165L0.5 164L11 111L51 55.5L110 13L182 0V165Z");
    clip-path: path("M182 165L0.5 164L11 111L51 55.5L110 13L182 0V165Z")
}

.price-card_content {
    padding: var(--space-y) var(--space-x)
}

.price-card .checklist {
    margin: -5px 0 32px 0
}

.price-card .as-btn {
    background-color: var(--title-color);
    box-shadow: none
}

.price-card .as-btn:before,
.price-card .as-btn:after {
    background-color: #122139;
}

.price-card:hover .particle {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.price-card:hover .price-card_top:before,
.price-card:hover .price-card_top:after {
    opacity: 1;
    height: 120%;
    width: 120%;
    border-radius: 0;
    background-color: #122139;
}

.price-card:hover .price-card_text {
    -webkit-border-image: -webkit-linear-gradient(right, #122139 27%, var(--white-color) 100%);
    border-image: linear-gradient(to left, #122139 27%, var(--white-color) 100%);
    border-image-slice: 1
}

@media (max-width: 375px) {
    .price-card {
        --space-x: 30px;
        --space-y: 30px
    }

    .price-card_text {
        font-size: 14px
    }

    .price-card_title,
    .price-card_price {
        font-size: 26px
    }
}

@media (max-width: 350px) {
    .price-card {
        --space-x: 20px;
        --space-y: 40px
    }
}

.gallery-card .gallery-img {
    position: relative;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden
}

.gallery-card .gallery-img:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--black-color2);
    opacity: 0.8;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    z-index: 1
}

.gallery-card .gallery-img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.gallery-card .gallery-img .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0)
}

.gallery-card .gallery-img .play-btn>i {
    font-size: 24px
}

.gallery-card:hover .gallery-img:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.gallery-card:hover .gallery-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.gallery-card:hover .gallery-img .play-btn {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.project-card {
    --space: 30px;
    position: relative;
    overflow: hidden
}

.project-card .project-img {
    overflow: hidden;
    border-radius: 10px;
    z-index: 2;    
    position: relative;
}

.project-card .project-img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.footer-contact .footer-contact_icon i {
    line-height: 2.5 !important;
    color: #1c1c1c;
}

.project-card .project-img:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: -webkit-linear-gradient(top, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #1c1c1c 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 40, 0) 0%, rgba(0, 0, 40, 0.140476) 43.1%, #1c1c1c 100%);
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    z-index: 1;
    border-radius: inherit
}

.project-card .project-content-wrap {
    position: absolute;
    left: var(--space);
    bottom: -30px;
    width: 100%;
    max-width: calc(100% - var(--space)*2 - 28px);
    z-index: 3;
    opacity: 0;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.project-card .project-content {
    background-color: #1c1c1c;
    background-size: auto;
    background-position: right center;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    padding: 26px 30px
}

.project-card .box-title {
    color: var(--white-color);
    margin-bottom: 2px;
    font-size: 1.5rem !important;
}

.project-card .box-title a:hover {
    color: var(--smoke-color2)
}

.project-card .project-subtitle {
    margin-bottom: -0.5em;
    color: var(--white-color);
    font-size: 1.2rem !important;
}

.project-card .icon-btn {
    position: absolute;
    right: -28px;
    top: calc(50% - 28px);
    background-color: var(--white-color);
    border: none;
    color: #122139;
    font-size: 24px
}

.project-card .icon-btn:hover {
    background-color: #8f8f8f;
    color: #FFFFFF;
}

.project-card:hover .project-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.project-card:hover .project-img:before {
    height: 100%;
    opacity: 1
}

.project-card:hover .project-content-wrap {
    bottom: var(--space);
    opacity: 1;
    margin: 0 14px auto;
}

@media (max-width: 375px) {
    .project-card {
        --space: 15px
    }
}

@media (max-width: 360px) {
    .project-card .box-title {
        font-size: 22px
    }

    .project-card .project-content {
        padding: 26px 20px
    }
}

.project-grid {
    background-color: var(--white-color);
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    margin-bottom: 20px;
    height: 490px;
}

.project-grid_img {
    position: relative;
    z-index: 2;
    border-radius: 10px 10px 0 0;
    overflow: hidden
}

.project-grid_img:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--black-color2);
    opacity: 0.8;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    z-index: 1
}

.project-grid_img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.project-grid_img .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0)
}

.project-grid_img .play-btn>i {
    font-size: 24px
}

.project-grid_text {
    margin-bottom: -0.5em
}

.project-grid_content {
    padding: 40px
}

.project-grid:hover .project-grid_img:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.project-grid:hover .project-grid_img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.project-grid:hover .project-grid_img .play-btn {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
}

.project-box {
    position: relative;
    overflow: hidden;
    border-radius: 10px
}

.project-box .project-img {
    overflow: hidden;
    border-radius: 10px 10px 0 0
}

.project-box .project-img img {
    width: 100%;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out
}

.project-box .project-content {
    background-color: var(--white-color);
    padding: 22px 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative
}

.project-box .box-title {
    margin-bottom: 3px
}

.project-box .project-subtitle {
    margin-bottom: -0.5em;
    color: #122139;
}

.project-box .icon-btn {
    background-color: #1c1c1c;
    border: none;
    color: var(--white-color);
    font-size: 24px;
    border-radius: 8px
}

.project-box .icon-btn:hover {
    background-color: var(--title-color)
}

.project-box:hover .project-img img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

@media (max-width: 360px) {
    .project-box .box-title {
        font-size: 22px
    }

    .project-box .project-content {
        padding: 26px 20px
    }
}

.project-inner-box {
    background-color: var(--smoke-color);
    border-radius: 5px;
    padding: 30px
}

@media (max-width: 375px) {
    .project-inner-box {
        padding: 20px
    }
}

.skill-feature:not(:last-child) {
    margin-bottom: 35px
}

.skill-feature_title,
.skill-feature .progress-value {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: -0.2em;
    font-family: var(--title-font);
    color: var(--title-color)
}
.skill-feature_title{
    margin-bottom: 0 !important;
}

.skill-feature .progress {
    position: relative;
    height: 10px;
    background-color: var(--white-color);
    overflow: visible;
    border-radius: 100px
}

.skill-feature .progress-bar {
    background-color: #122139;
    height: 4px;
    margin: 3px;
    border-radius: inherit;
    position: relative;
    overflow: visible
}

.skill-feature .progress-value {
    position: absolute;
    top: -34px;
    right: 0
}

.skill-feature.style2:not(:last-child) {
    margin-bottom: 32px
}

.skill-feature.style2 .progress {
    background-color: var(--smoke-color2)
}

@media (min-width: 1430px) {
    .video-box1 {
        margin-right: -105px
    }
}

@media (min-width: 1300px) {
    .video-box1 {
        margin-left: 30px
    }
}

.video-box1 {
    position: relative;
    text-align: center
}

.video-box1 img {
    width: 100%;
    max-width: 650px
}

.video-box1 .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}



.process-card {
    position: relative;
    box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
    border-radius: 10px;
    background-color: var(--white-color);
    text-align: center;
    padding: 30px 20px;
    max-width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto
}

.process-card-area {
    position: relative;
}

.process-card-area .new-line {
    top: 77% !important;
    left: -19% !important;
}

.process-card-area .process-line .old-img {
    position: absolute;
    left: 85px;
    top: 40%;
    text-align: center
}

.process-card-area .process-line .new-img {
    right: 64px;
    position: absolute;
    width: 35%;
    top: 44%;
}

.process-card-area .position-top {
    top: 12px;
    bottom: 0;
    position: absolute;
    left: 108px;
}

.process-card-wrap {
    padding: 30px 0 0 30px;
}

.process-card-wrap:has(.pulse) {
    padding: 12px 12px
}

.process-card-wrap:has(.pulse):nth-child(even) {
    padding-top: 60px
}

.process-card:has(.pulse) {
    margin-top: 52px
}

.process-card .box-title {
    font-weight: 600 !important;
    margin-bottom: 14px !important;
}

.process-card_number,
.process-card .pulse {
    height: 60px;
    width: 60px;
    line-height: 60px;  
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -30px;
    left: -30px;
    background: #000000b0;
    z-index: 2;
    font-size: 26px;
    font-weight: 700;
    color: var(--white-color)
}

.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #122139;
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s
}

.process-card_number:after,
.process-card .pulse:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.process-card .pulse {
    width: 42px;
    height: 42px;
    text-align: center;
    line-height: 1.7;
    position: absolute;
    top: -70px;
    left: calc(50% - 25px);
    bottom: -75px;
}

.process-card .pulse:before,
.process-card .pulse:after {
    -webkit-animation-name: ripple2;
    animation-name: ripple2
}

.process-card_icon {
    margin-bottom: 24px
}

.process-card_text {
    margin-bottom: -0.53em;
    font-size: 1rem;
}

@media (max-width: 1199px) {
    .process-card-area .process-line {
        display: none
    }

    .process-card-wrap:has(.pulse):nth-child(even) {
        padding-top: 30px
    }

    .process-card:has(.pulse) {
        margin-top: 8px
    }

    .process-card .pulse {
        top: -8px
    }
}

@media (max-width: 575px) {
    .process-card-wrap:has(.pulse):nth-child(even) {
        padding-top: 0
    }
}

.particle-1 {
    max-width: 440px;
    height: 390px
}

.particle-2 {
    max-width: 800px;
    height: 790px;
}

.particle-2.small {
    max-width: 515px;
    height: 490px
}

.particle-3 {
    max-width: 600px;
    height: 470px
}

.box-particle {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 180px;
    height: 100px;
    z-index: -1
}

.price-particle {
    max-width: 190px;
    height: 165px
}

.body-particle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1
}

.feature-card {
    text-align: center;
    border: 2px solid #7378877a;
    border-radius: 10px;
    padding: 40px 12px;
    height: 425px;
    margin: 10px;      
}

.feature-card .shape-icon {
    width: 120px;
    height: 115px;
    line-height: 115px;
    margin: 0 auto 30px auto;
    -webkit-filter: drop-shadow(0px 10px 30px rgba(8, 14, 28, 0.06));
    filter: drop-shadow(0px 10px 30px rgba(8, 14, 28, 0.06))
}

.feature-card .shape-icon img {
    margin-top: 20px;
}

.feature-card .shape-icon:before {
    background-color: var(--white-color);
    -webkit-clip-path: path("M6.35418 25.7699C-3.10248 43.0115 -1.601 65.2669 8.18807 82.317C21.8699 106.149 51.9755 119.386 78.1565 111.915C104.338 104.443 123.425 76.1006 119.484 48.8163C112.307 -0.867846 31.1988 -19.5262 6.35418 25.7699Z");
    clip-path: path("M6.35418 25.7699C-3.10248 43.0115 -1.601 65.2669 8.18807 82.317C21.8699 106.149 51.9755 119.386 78.1565 111.915C104.338 104.443 123.425 76.1006 119.484 48.8163C112.307 -0.867846 31.1988 -19.5262 6.35418 25.7699Z")
}

.feature-card_text {
    max-width: 315px;
    margin: 0 auto -0.5em auto
}

.px-5 {
    padding-right: 5px;
    padding-left: 5px
}

.px-10 {
    padding-right: 10px;
    padding-left: 10px
}

.px-15 {
    padding-right: 15px;
    padding-left: 15px
}

.px-20 {
    padding-right: 20px;
    padding-left: 20px
}

.px-25 {
    padding-right: 25px;
    padding-left: 25px
}

.px-30 {
    padding-right: 30px;
    padding-left: 30px
}

.px-35 {
    padding-right: 35px;
    padding-left: 35px
}

.px-40 {
    padding-right: 40px;
    padding-left: 40px
}

.px-45 {
    padding-right: 45px;
    padding-left: 45px
}

.px-50 {
    padding-right: 50px;
    padding-left: 50px
}

.py-5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.py-10 {
    padding-top: 10px;
    padding-bottom: 10px
}

.py-15 {
    padding-top: 15px;
    padding-bottom: 15px
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px
}

.py-25 {
    padding-top: 25px;
    padding-bottom: 25px
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 30px
}

.py-35 {
    padding-top: 35px;
    padding-bottom: 35px
}

.py-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.py-45 {
    padding-top: 45px;
    padding-bottom: 45px
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.pt-5 {
    padding-top: 5px
}

.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}

.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pb-5 {
    padding-bottom: 5px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px
}

.pl-5 {
    padding-left: 5px
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-25 {
    padding-left: 25px
}

.pl-30 {
    padding-left: 30px
}

.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}

.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pr-5 {
    padding-right: 5px
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-25 {
    padding-right: 25px
}

.pr-30 {
    padding-right: 30px
}

.pr-35 {
    padding-right: 35px
}

.pr-40 {
    padding-right: 40px
}

.pr-45 {
    padding-right: 45px
}

.pr-50 {
    padding-right: 50px
}

.pb-60 {
    padding-bottom: 60px
}

.mx-5 {
    margin-right: 5px;
    margin-left: 5px
}

.mx-10 {
    margin-right: 10px;
    margin-left: 10px
}

.mx-15 {
    margin-right: 15px;
    margin-left: 15px
}

.mx-20 {
    margin-right: 20px;
    margin-left: 20px
}

.mx-25 {
    margin-right: 25px;
    margin-left: 25px
}

.mx-30 {
    margin-right: 30px;
    margin-left: 30px
}

.mx-35 {
    margin-right: 35px;
    margin-left: 35px
}

.mx-40 {
    margin-right: 40px;
    margin-left: 40px
}

.mx-45 {
    margin-right: 45px;
    margin-left: 45px
}

.mx-50 {
    margin-right: 50px;
    margin-left: 50px
}

.my-5 {
    margin-top: 5px;
    margin-bottom: 5px
}

.my-10 {
    margin-top: 10px;
    margin-bottom: 10px
}

.my-15 {
    margin-top: 15px;
    margin-bottom: 15px
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.my-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.my-35 {
    margin-top: 35px;
    margin-bottom: 35px
}

.my-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.my-45 {
    margin-top: 45px;
    margin-bottom: 45px
}

.my-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.mt-5 {
    margin-top: 5px
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mb-5 {
    margin-bottom: 5px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}

.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.mr-5 {
    margin-right: 5px
}

.mr-10 {
    margin-right: 10px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}

.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}

.mb-60 {
    margin-bottom: 60px
}

.mt-n1 {
    margin-top: -.25rem
}

.mt-n2 {
    margin-top: -.6rem
}

.mt-n3 {
    margin-top: -0.8rem
}

.mt-n4 {
    margin-top: -1.5rem
}

.mt-n5 {
    margin-top: -3rem
}

.mb-n1 {
    margin-bottom: -.25rem
}

.mb-n2 {
    margin-bottom: -.6rem
}

.mb-n3 {
    margin-bottom: -0.8rem
}

.mb-n4 {
    margin-bottom: -1.5rem
}

.mb-n5 {
    margin-bottom: -3rem
}

.space,
.space-top {
    padding-top: var(--section-space);
    z-index: 0;
}

.space,
.space-bottom {
    padding-bottom: var(--section-space)
}

.space-extra,
.space-extra-top {
    padding-top: calc(var(--section-space) - 30px)
}

.space-extra,
.space-extra-bottom {
    padding-bottom: calc(var(--section-space) - 30px)
}

.space-extra2,
.space-extra2-top {
    padding-top: calc(var(--section-space) - 40px)
}

.space-extra2,
.space-extra2-bottom {
    padding-bottom: calc(var(--section-space) - 40px)
}

@media (max-width: 991px) {

    .space,
    .space-top {
        padding-top: var(--section-space-mobile)
    }

    .space-bottom {
        padding-bottom: var(--section-space-mobile)
    }

    .space-extra,
    .space-extra-top {
        padding-top: calc(var(--section-space-mobile) - 30px)
    }

    .space-extra,
    .space-extra-bottom {
        padding-bottom: calc(var(--section-space-mobile) - 30px)
    }

    .space-top-md-none {
        padding-top: 0
    }

    .space-extra2,
    .space-extra2-top {
        padding-top: 70px
    }

    .space-extra2,
    .space-extra2-bottom {
        padding-bottom: 70px
    }

    .service-featured_text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .service-featured_content {
        max-width: 589px;
    }
}

.progressbar {
    display: inline-block;
    width: 100px;
    margin: 25px;
}

.circle {
    width: 180px;
    margin: 0 auto;
    margin-top: 10px;
    display: inline-block;
    position: relative;
    text-align: center;
}

#project-card .owl-carousel.owl-drag .owl-item {
    padding: 20px !important;
}

.circle canvas {
    vertical-align: middle;
    border-radius: 50%;
}

.circle div {
    position: absolute;
    top: 28%;
    left: 122%;
    margin: -20px 0 0 -86px;
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 31px;

}

.circle strong i {
    font-style: normal;
    font-size: 0.6em;
    font-weight: normal;
}

.circle span {
    display: block;
    color: #000000;
    margin-top: 12px;
    position: absolute;
    top: 28px;
    left: 37px;
}

.contact-info_icon i {
    line-height: 2;
}

.modal-body {
    padding: 0 !important;
}

.modaltwo .modal-header .close {
    position: absolute;
    left: -31px;
    height: 60px;
    top: 39px;
    border: 5px solid #ffffff;
    border-radius: 52px;
    width: 60px;
    background-color: #116dde;
    z-index: 9;
}

.mopen .modal-header .close {
    right: -26px;
    top: 38px;
    position: absolute;
    height: 60px;
    top: 39px;
    border: 5px solid #ffffff;
    border-radius: 52px;
    width: 60px;
    background-color: #116dde;
    z-index: 9;
    transition: all 0.5s;
}

.modaltwo .modal-header .close span,
.mopen .modal-header .close span {
    font-size: 45px;
    color: #ffffff;
}

.widget_shopping_cart .woocommerce-mini-cart li {
    display: flex;
    transition: all 0.8s;
    width: 375px !important;
    font-family: 'Ananda Black Personal Use' !important;
}

.widget_shopping_cart .woocommerce-mini-cart li.active i {
    display: block;
}

.widget_shopping_cart .woocommerce-mini-cart li i {
    font-size: 40px;
    left: 61px;
    position: absolute;
    display: none;
    color: #116dde;
    margin-top: 16px;
}

.widget_shopping_cart .woocommerce-mini-cart li:hover i {
    display: block !important;
}

.widget_shopping_cart .woocommerce-mini-cart li:focus i {
    display: block !important;
}

.widget_shopping_cart .woocommerce-mini-cart li i a:hover .widget_shopping_cart .woocommerce-mini-cart li i {
    display: block !important;
}

/* Header */
/* Header */
.large-header {
    position: relative;
    width: 100%;
    background: #333;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
}

#large-header {
    background-image: url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg');
}

.main-title {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #f9f1e9;
    text-align: center;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

.demo-1 .main-title {
    text-transform: uppercase;
    font-size: 4.2em;
    letter-spacing: 0.1em;
}

.main-title .thin {
    font-weight: 200;
}

@media only screen and (max-width : 768px) {
    .demo-1 .main-title {
        font-size: 3em;
    }
}

.bg {

    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    background-image: linear-gradient(-45deg,
            rgba(59, 173, 227, 1) 0%,
            rgba(87, 111, 230, 1) 25%,
            rgba(152, 68, 183, 1) 51%,
            rgba(255, 53, 127, 1) 100%);
    animation: AnimateBG 20s ease infinite;
}

@keyframes AnimateBG {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

/* Hide my submenus by default */
.my-menu ul.active:first-child {
    display: block !important;
}

.my-menu ul {
    display: none;
    position: absolute;
    left: 645px;
    top: 0;
}

/* Position submenus relative to parent list item */
.woocommerce-mini-cart-item .my-menu li:first-child {
    position: relative !important;
}

.my-menu li:focus-within>.set-positions,
.my-menu li:hover>.set-positions {
    list-style-type: disc;
    background-color: #FFFFFFFF;
    padding-top: 123px;
    padding-left: 35px;
    height: 1185px;
    padding-right: 27px;
}

.my-menu li:focus-within>.set-positions span,
.my-menu li:hover>.set-positions span {

    font-size: 27px;
    font-weight: 600;
    color: #141D38;
}

.my-menu li:focus-within>.set-positions li a,
.my-menu li:hover>.set-positions li a {
    color: #141D38;

    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    width: 473px;
    font-size: 20px !important;
    color: #4a4a4a !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border: none !important;
}

.my-menu li ul li.active a {
    border-bottom: 1px solid #FFFFFFFF;

}

.widget_shopping_cart .woocommerce-mini-cart li a.game-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.web-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.mobile-dev,
.widget_shopping_cart .woocommerce-mini-cart li a.node,
.widget_shopping_cart .woocommerce-mini-cart li a.react,
.widget_shopping_cart .woocommerce-mini-cart li a.unity,
.widget_shopping_cart .woocommerce-mini-cart li a.uiux {
    font-family: 'Ananda Black Personal Use' !important;
}

.my-menu li ul.active li.set-box a {
    font-size: 20px !important;
    color: #4a4a4a !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border: none !important;

}

.my-menu li ul.active span,.my-menu li ul span {
    font-size: 27px;
    font-weight: 600;
    color: #141D38;
    font-family: 'Ananda Black Personal Use' !important;
}
.my-menu li ul .under-line {
    border: 1px solid #dee2e6;
    margin-top: 30px;
}

.my-menu li ul.active li {
    color: #000000 !important;
    width: 375px !important;
    font-family: 'Ananda Black Personal Use' !important;
}

.my-menu li ul.active {
    display: block !important;
    list-style-type: disc;
    background-color: #FFFFFFFF;
    padding-top: 123px;
    padding-left: 35px;
    height: 1185px;
}

.my-menu li:focus-within>ul {
    display: block;
}

.my-menu li:hover>ul {
    display: block;
}

/* DEMO STYLES */
ul {
    padding: 0;
}

.my-menu {

    justify-content: center;
    margin: 100px auto;
    max-width: 600px;
    width: 40%;
}

.my-menu a {
    display: block;
    padding: .5em 0;
    white-space: nowrap;
}

.my-menu a:hover {
    text-decoration: none;
}

.container:before {
    content: "";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 125vmax;
    height: 125vmax;
    border-radius: 100%;
    background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
    box-shadow: 0 0 20px pink;
    transition: all 500ms cubic-bezier(.49, .23, 0, .99);
    opacity: 0;

}

.completed:before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
}

svg {
    position: sticky;
    transform: rotate(-90deg);
}

svg circle {
    stroke: rgba(0, 0, 0, 0.1);
    stroke-width: 10px;
    stroke-linecap: round;
    fill: transparent;
}

svg .progress-bar {
    stroke: url(#grad);
    transition: stroke-dashoffset 0 ease-in-out;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
}

.pct {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
}

.widget_shopping_cart_content .submenu ul.show,
.widget_shopping_cart_content .submenu-one ul.show,
.widget_shopping_cart_content .submenu-two ul.show,
.widget_shopping_cart_content .submenu-three ul.show {
    display: block;
    list-style-type: "disc";
    background-color: "#FFFFFFFF";
    padding-top: "192px";
    padding-left: "35px";
    height: "1073px";
    color: "#141D38";
}

.widget_shopping_cart_content {
    display: flex;
}

.widget_shopping_cart_content .submenu ul,
.widget_shopping_cart_content .submenu-one ul,
.widget_shopping_cart_content .submenu-two ul,
.widget_shopping_cart_content .submenu-three ul {
    display: none;
}

canvas {
    display: block;
    vertical-align: bottom;
}

/* ---- tsparticles container ---- */
#tsparticles {

    width: 100% !important;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.home-concept {
    background: transparent url(../img/GamesImg/ludo_images/home-concept.png) no-repeat center 0;
    width: 100%;
    height: auto;
    background-size: contain;
    position: relative;
  }
  
  .home-concept .sun{
      background: transparent url(../img/GamesImg/ludo_images/home-concept-icons.png) no-repeat 0 0;
      width: 60px;   
      height: 56px;
      display: block;
      position: absolute;
      left: 0;
      top: 65px;
  }
  .home-concept .cloud {
    background: transparent url(../img/GamesImg/ludo_images/home-concept-icons.png) no-repeat 100% 0;
    width: 116px;
    height: 56px;
    display: block;
    position: absolute;
    left: 53%;
    top: 35px;
    animation: chili 3s infinite;
  }
  @keyframes chili{
    0%{
        transform: translatey(0);
    }
    50%{
        transform: translatey(-50px);
    }
    100%{
        transform: translatey(0);
    }
  }
  .home-concept .process-image{
    background: transparent url(../img/GamesImg/ludo_images/home-concept-item.png) no-repeat 0 0;
    width: 160px;
    margin: 120px auto 0;
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
  }
  .home-concept .project-image{
    background: transparent;
    background: transparent url(../img/GamesImg/ludo_images/home-concept-item.png) no-repeat 100% 0;
    width: 350px;
    margin: 20px 0 0 -30px;
    padding-bottom: 45px;
    position: relative;
    z-index: 1
  }
  .home-concept .process-image img{
    border-radius: 150px;
    margin: 7px 8px;
    width: auto;
    height: auto;
    max-width: 145px;
    max-height: 145px;
  }
  .home-concept strong {
    color: #08c;
  }
  .home-concept strong {
    display: block;
    font-family: shadows into light,cursive;
    font-size: 1.7em;
    font-weight: 400!important;
    position: relative;
    margin-top: 30px;
  }
  .home-concept .process-image {
    background: transparent;
    background: transparent url(../img/GamesImg/ludo_images/home-concept-item.png) no-repeat 0 0;
    width: 160px;
    margin: 120px auto 0;
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
  }
  .fc-slideshow {
    position: relative;
    width: 338px;
    height: 338px;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px;
    top: 6px;
  }
  .fc-slideshow img {
    border-radius: 50%;
  }
  .fc-slideshow {
    position: relative;
    width: 338px;
    height: 338px;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px;
    top: 6px
  }
  
  .fc-slideshow nav {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10000;
    display: none
  }
  
  .fc-slideshow nav>div {
    position: absolute;
    height: 100%;
    top: 0;
    width: 50%
  }
  
  .fc-slideshow nav .fc-left {
    left: 0
  }
  
  .fc-slideshow nav .fc-right {
    right: 0
  }
  
  .fc-slideshow nav>div span {
    height: 33%;
    width: 100%;
    position: absolute;
    left: 0
  }
  
  .fc-slideshow nav>div span:first-child {
    top: 0
  }
  
  .fc-slideshow nav>div span:nth-child(2) {
    top: 33%
  }
  
  .fc-slideshow nav>div span:nth-child(3) {
    top: 66%
  }
  
  .fc-slideshow nav i,
  .no-csstransforms3d .fc-slideshow nav>div span {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    pointer-events: none;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .2s ease;
    -moz-backface-visibility: hidden;
    -moz-transition: all .2s ease;
    backface-visibility: hidden;
    transition: all .2s ease
  }

  
.github {
    bottom: 10px;
    right: 10px;
    position: fixed;
    border-radius: 10px;
    background: #fff;
    padding: 0 12px 6px 12px;
    border: 1px solid #000;
}

.github a:hover,
.github a:link,
.github a:visited,
.github a:active {
    color: #000;
    text-decoration: none;
}

.github img {
    height: 30px;
}

.github #gh-project {
    font-size: 20px;
    padding-left: 5px;
    font-weight: bold;
    vertical-align: bottom;
}

.errow-round {
    position: relative;
}

.errow-round .errow {
    position: absolute;
    right: 30px;
    bottom: 10px;
    font-size: 29px;
    color: #122139;
    padding: 15px;
    border-radius: 32px;
    cursor: pointer;
    transition: all 300ms linear !important;
    color: #000;
}

.errow-round .errow i {
    right: 12px;
    position: absolute;
    top: 9px;
    color: #122139;
}

#btn-back-to-top {
    position: absolute;
    bottom: 21px;
    z-index: 999;
    font-size: 23px;
    width: 51px;
    height: 56px;
    transform: translateY(45px);
    transition: all 300ms linear !important;
}

#btn-back-to-top i {
    color: #122139;
    position: absolute;
    right: 20px;
}

.errow-round .errow.show {
    position: fixed !important;
    transition: all 300ms linear !important;
    transform: translateY(0) !important;
    visibility: visible;
}

/* dsfhvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbvgbdh */

svg {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;

    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);

    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}


.shape {
    fill: none;
    stroke: #1d1d1b;
}

#tutorial {
    position: fixed;
    overflow: visible;
    width: 15%;
    left: 40%;
    top: 100px;
    transform: rotate(-150deg);
}

/* new one scss */
.blok:nth-of-type(odd) {
    background-color: white;
}

.blok:nth-of-type(even) {
    background-color: black;
}

@-webkit-keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

.paginacontainer {
    height: 3000px;
}

/* #Progress
    ================================================== */

.progress-wrap {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.progress-wrap::after {
    position: absolute;
    font-family: 'unicons';
    content: '\e84b';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.progress-wrap:hover::after {
    opacity: 0;
}

.progress-wrap::before {
    position: absolute;
    font-family: 'unicons';
    content: '\e84b';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 0;
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.progress-wrap:hover::before {
    opacity: 1;
}

.progress-wrap svg path {
    fill: none;
}

.progress-wrap svg.progress-circle path {
    stroke: #122139;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.banner-top-rating {
    display: flex;
    margin: 0 -40px;
    margin-bottom: 25px;
}
.theme-color {
    color: #00a3ff;
}@keyframes rotateIt {
    to {
        transform: rotate(-360deg);
    }
}

.title.large-height {
    line-height: 70px;
    font-weight: 800;
    letter-spacing: 10px;
}

@media only screen and (max-width: 767px) {
    .title.large-height {
        line-height: 36px;
        font-size: 32px;
    }


}

.title .gradient-title {
    color: #00a3ff;
    font-size: 80px;
}

@media only screen and (max-width: 767px) {
    .banner-top-rating {
        flex-direction: inherit;
    }
}
.banner-top-rating .banner-badge-top .icon{
    width: 100% !important;
    text-align: start;
}
.banner-top-rating .banner-badge-top {
    margin: 0 40px !important;
}

@media only screen and (max-width: 767px) {
    .banner-top-rating .banner-badge-top {
        margin-top: 30px !important;
    }
}

.banner-top-rating .banner-badge-top .icon {
    margin-bottom: 8px !important;
}

.banner-top-rating .banner-badge-top .subtitle {
    text-transform: uppercase;
    letter-spacing: 1px;
}
/* particles in react */
.banner {
    height: 0;
    width: 100%;
    position: relative;
}

#particle-canvas {
    top: 0;
    left: 0;
}

#half-particles canvas {
    right: -384px;
    width: 1057px;
    height: 734px;
}

#btn-back-to-top {
    scroll-behavior: smooth;
}

#particales canvas {
    right: 16px;
    width: 1309px;
}

/* prgress-circles */
.pb {
    position: fixed;
    top: 0;
    height: 5px;
    width: 100%;
    background: #ddd;
}

.pc {
    position: fixed;
    bottom: 0;
    right: 23px;
    z-index: 9999;
    visibility: hidden;
    transform: translateY(75px);
    transition: all 0.2s !important;
}

.pc.show {
    visibility: visible;
    transform: translateY(0px);
}

.bg {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #ddd;
    margin-bottom: 20px;
}

.ol {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background: #fff;
    position: absolute;
    margin-top: 8px;
    margin-left: 8px;
}

.content {
    margin-top: 20px;
}

.about-area {
    position: relative;
    z-index: 1;
    padding-bottom: 175px;
    overflow: hidden;
}

.about-area .container-fluid {
    padding-left: 0;
}

.about-image {
    position: relative;
}

.about-image img {
    border-radius: 5px;
    max-width: 100%;
    height: auto;
}

@media only screen and (min-width: 1800px) {
    .about-image img:nth-child(2) {
        position: absolute;
        right: 30px;
        bottom: -100px;
    }

    .about-image .shape {
        position: absolute;
        top: -30px;
        right: 170px;
        z-index: -1;
    }

    .about-content {
        max-width: 530px;
        padding-left: 0;
    }
}

.about-content span::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: #f12711;
    left: -35px;
    top: 12px;
}

.about-content span {
    font-size: 16px;
    color: #f12711;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 35px;
    font-weight: 600;
}

.about-content h2 {
    font-size: 42px;
    margin-top: 16px;
    margin-bottom: 14px;
    color: #0d1820;
}

.about-content p {
    margin-bottom: 0;
    color: #777777;
}

.about-content .about-text {
    margin-top: 30px;
    position: relative;
    padding-left: 50px;
}

.about-content .about-text .icon img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    height: auto;
}

.about-content .about-text h3 {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #0d1820;
}

.about-content .signature {
    background-color: #f2f2f2;
    position: relative;
    padding: 20px 20px 20px 210px;
    margin-top: 30px;
    border-left: 3px solid #f12711;
    display: inline-block;
}

.about-content .signature img {
    position: absolute;
    left: 20px;
    top: 46px;
    max-width: 100%;
    height: 50px;
    opacity: 0.4;
}

.about-shape {
    position: absolute;
    right: 0;
    bottom: 100px;
}

.about-shape img {
    max-width: 100%;
    height: auto;
}

.features-area {
    position: relative;
    z-index: 1;
}

.features-area::before {
    position: absolute;
    content: "";
    background: #1c1c1c;
    height: 20%;
    width: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
}

.features-area .container {
    background-color: #ffffff;
    padding: 50px;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    max-width: 1400px;
    margin: auto;
}

.features-area.bg-color::before {
    display: none;
}

.features-area .section-title-two {
    max-width: 885px;
}

.features-content {
    background-color: #f2f2f2;
    padding: 30px;
    padding-left: 90px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-bottom: 15px;
}

.features-content:last-child {
    margin-bottom: 20px;
}

.features-content .image {
    position: relative;
}

.features-content .image img {
    position: absolute;
    left: -50px;
    top: 5px;
}

.features-content h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.features-content p {
    margin-bottom: 0;
}

.features-content:hover {
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
}

.section-title-two .title h2 {
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.features-area .section-title-two {
    max-width: 885px;
    text-align: right;
    margin: 0 auto !important;
}

.section-title-two .title {
    padding-right: 40px;
    position: relative;
}

.section-title-two .title::before {
    content: "";
    position: absolute;
    top: 32px;
    right: 0;
    height: 100px;
    width: 1px;
    background-color: #cdcdcd;
}

.section-title-two .title span {
    font-size: 16px;
    color: #f12711;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    font-weight: 600;
}

.section-title-two .text {
    padding-left: 10px;
    text-align: left;
}

.owl-theme .owl-stage-outer .owl-item .col-xl-3 {
    width: 100% !important;
}

.owl-stage-outer .owl-item .col-md-6.col-xl-4 {
    height: 600px !important;
}

/*================================================
Apps Area CSS
=================================================*/
.apps-area {
    position: relative;
    z-index: 1;
}

.apps-area .section-title-two {
    text-align: right;
    max-width: 900px;
    margin-bottom: 0;
}

.apps-area .section-title-two .title span {
    color: #ffffff;
}

.apps-area .section-title-two .title span::before {
    background-color: #ffffff;
}

.apps-area .section-title-two .title h2 {
    color: #ffffff;
}

.apps-area .section-title-two .text p {
    color: #ffffff;
}

.apps-area .section-title {
    margin-bottom: 0;
    max-width: 650px;
}

.apps-area .section-title span {
    color: #ffffff;
}

.apps-area .section-title span::before {
    background-color: #ffffff;
}

.apps-area .section-title h2 {
    color: #ffffff;
}

.apps-area.bg-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-attachment: fixed;
    padding-bottom: 175px;
}

.apps-area.bg-image .container {
    background-color: transparent;
    padding: unset;
}

.apps-area.bg-image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .77;
}

.apps-area.bg-image .section-title h2 {
    margin-bottom: 0;
}

.apps-area.bg-image .app-list li {
    margin-left: 50px;
    margin-right: 50px;
}

.apps-area .shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.app-list {
    padding-left: 0;
    margin-bottom: 0;
    text-align: center;
}

.app-list li {
    list-style-type: none;
    display: inline-block;
    margin-right: 30px;
    margin-left: 30px;
}

.app-list li:last-child {
    margin-right: 0;
}

.app-list .sound a img {
    margin-top: -30px;
}

.app-list .call a img {
    margin-top: 40px;
}

.app-list .twitter a img {
    margin-top: 60px;
}

.app-list .youtube a img {
    margin-top: 60px;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
    background-color: #c1d5e74d;
}

.overview-area .container-fluid {
    max-width: 1920px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.overview-content h2 {
    font-size: 32px;
    margin-bottom: 10px;
}

.overview-content p {
    margin-bottom: 0;
}

.overview-content .btn-one {
    display: inline-block;
    padding: 15px 35px;
    background-color: #0865f8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-top: 25px;
}

.overview-content .btn-one i {
    position: relative;
    top: 2px;
    font-size: 18px;
}

.overview-content .btn-one:hover {
    background-color: #f12711;
}

.overview-content .btn-two {
    display: inline-block;
    padding: 15px 35px;
    background-color: #f12711;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-top: 25px;
}

.overview-content .btn-two i {
    position: relative;
    top: 2px;
    font-size: 18px;
}

.overview-content .btn-two:hover {
    background-color: #0865f8;
}

.owl-carousel .owl-item img {
    width: auto !important;
    margin: 0 auto;
}

.accordion-item:last-of-type,
.accordion-item:not(:first-of-type) {
    margin-top: 12px;
}

.accordion-button:not(.collapsed) {
    color: #122139 !important;
}

.accordion-item {
    border: 1px solid #d8dbe0 !important;
}

.accordion-collapse {
    border: none;
}

.accordion-button:focus {
    box-shadow: none !important;
}

/*-------------------------
 Banner Styles  
----------------------------*/
/*-------------------------
 Banner Styles  
----------------------------*/
.with-particles {
    position: relative;
    z-index: 1;
}

.height-950 {
    height: 950px;
}

#particles-js {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}

.border-radious-none {
    border-radius: 0 !important;
}

.game-banner-short-slick-wrapper .slick-dots {
    bottom: 50px !important;
}

.game-banner-short-slick-wrapper .slider-style-7 .product-share-wrapper .profile-share a img {
    max-width: 40px;
    border: 2px solid #ffffff14;
}

.game-banner-short-slick-wrapper .slider-style-7 .rn-banner-wrapper .item-description button {
    border-radius: 5px;
}

.game-banner-short-slick-wrapper .slider-style-7 .rn-banner-wrapper .item-description {
    padding: 25px;
    background: #9999992e;
    border-radius: 5px;
    backdrop-filter: blur(15px);
}

.banner-gallery-wrapper {
    max-height: 700px;
    overflow: hidden;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-gallery-wrapper {
        max-height: 450px;
    }
}

@media only screen and (max-width: 767px) {
    .banner-gallery-wrapper {
        max-height: 350px;
    }
}

.banner-gallery-wrapper::before {
    position: absolute;
    width: 100%;
    height: 30%;
    left: 0;
    bottom: 0;
    content: "";
    background-image: linear-gradient(to bottom, #0c0c2100 0%, #171721 95%);
    z-index: 1;
    pointer-events: none;
}

.banner-gallery-wrapper::after {
    position: absolute;
    width: 100%;
    height: 30%;
    left: 0;
    top: 0;
    content: "";
    background-image: linear-gradient(to top, #0c0c2100 0%, #171721 95%);
    z-index: 1;
    pointer-events: none;
}

.ptb--60 {
    padding: 60px 0;
}


.banner-gallery-wrapper .banner-gallery-loop {
    display: flex;
    align-items: center;
    margin: 0 -20px;
    justify-content: end;
}

.banner-gallery-wrapper .banner-gallery {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding: 0 20px;
    width: 280px;
}

.banner-gallery-wrapper .banner-gallery img {
    transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    display: block;
    width: 100%;
    margin-bottom: 30px;
}

.banner-gallery-wrapper .banner-gallery-1 {
    -webkit-animation: verticalSlide 115s linear infinite;
    animation: verticalSlide 90s linear infinite;
}

.banner-gallery-wrapper .banner-gallery-2 {
    -webkit-animation: verticalSlide 115s linear infinite;
    animation: verticalSlide 70s linear infinite;
}

.banner-gallery-wrapper .banner-gallery-3 {
    -webkit-animation: verticalSlide 115s linear infinite;
    animation: verticalSlide 65s linear infinite;
}

.banner-gallery-wrapper .banner-gallery-4 {
    -webkit-animation: verticalSlide 115s linear infinite;
    animation: verticalSlide 80s linear infinite;
}

.banner-gallery-wrapper .banner-gallery-5 {
    -webkit-animation: verticalSlide 115s linear infinite;
    animation: verticalSlide 50s linear infinite;
}

.banner-read-thumb {
    position: absolute;
    bottom: 50px;
    left: 50px;
}

.banner-read-thumb h4 {
    margin-bottom: 10px;
}

.bg_image--23 {
    background-image: url(../img/about/GameDevlopment/bg-image-23.jpg);
}

.bg_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.with-down-shadow {
    position: relative;
    z-index: 1;
}

.with-down-shadow::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    content: "";
    background-image: linear-gradient(to bottom, #0c0c2100 0%, #181822 87%);
    z-index: -1;
    pointer-events: none;
}

.with-down-shadow::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background-image: linear-gradient(to top, #0c0c2100 0%, #171721 87%);
    z-index: -1;
    pointer-events: none;
}

.banner-xii-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;
}

.banner-xii-wrapper .banner-content-wrapper {
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.banner-xii-wrapper .banner-content-wrapper .pre-title {
    margin-bottom: 10px;
    color: #00a3ff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}

.banner-xii-wrapper .banner-content-wrapper .title {
    margin-bottom: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
}

.banner-xii-wrapper .banner-content-wrapper .post-title {
    margin-bottom: 30px;
    color: #acacac;
}

.banner-xii-wrapper .banner-content-wrapper a.btn {
    margin-bottom: 0;
}

.banner-xii-wrapper .banner-content-wrapper .wallet-image-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-right: -15px;
    flex-wrap: wrap;
}

.avatar {
    position: relative;
    width: 75px !important;
}

.avatar::before {
    --translate-y: calc(-100% - 8px);
    content: attr(data-tooltip);
    color: #ffffff;
    padding: 5px 8px;
    background: #212e48;
    width: max-content;
    border-radius: 5px;
    text-align: center;
}

.avatar::after,
.avatar::before {
    --scale: 0;
    --arrow-size: 8px;
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

.avatar:hover::before,
.avatar:hover::after {
    --scale: 1;
    z-index: 9;
}

.banner-xii-wrapper .banner-content-wrapper .wallet-image-wrapper img {
    padding: 10px;
    background: #24243557;
    margin-right: 15px;
    max-width: 60px;
    border-radius: 6px;
    margin-bottom: 15px;
    border: 1px solid #ffffff14;
    z-index: 1;
    cursor: pointer;
    vertical-align: middle;
    height: auto;
}

.avatar::after {
    --translate-y: calc(-1 * 8px);
    content: "";
    border: 8px solid transparent;
    border-top-color: #212e48;
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-area {
    background-image: url(../img/bg/choose-us-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
}

.why-choose-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.9 !important;
}

.why-choose-area .section-title-two {
    text-align: right;
    max-width: 858px;
    margin-bottom: 40px;
}

.why-choose-area .section-title-two .title span {
    color: #ffffff;
}

.why-choose-area .section-title-two .title span::before {
    background-color: #ffffff;
}

.why-choose-area .section-title-two .title h2 {
    color: #ffffff;
}

.why-choose-area .section-title-two .text {
    margin-top: 10px;
}

.why-choose-area .section-title-two .text p {
    color: #ffffff;
}

.why-choose-area .section-title span {
    color: #ffffff;
}

.why-choose-area .section-title span::before {
    background-color: #ffffff;
}

.why-choose-area .section-title h2 {
    color: #ffffff;
}

.why-choose-area .section-title p {
    color: #ffffff;
}

.why-choose-area.bg-center {
    background-image: none;
    background-color: #101921;
}

.why-choose-area.bg-center .shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10%;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    z-index: -1;
}

.why-choose-area.bg-center .single-choose-item img {
    opacity: 0.6;
}

.why-choose-area .section-title.text-right {
    max-width: 400px;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 0;
}

.why-choose-area.bg-background {
    background-image: url(../img/bg/choose-us-bg-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
}

.why-choose-area.bg-background::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.5;
}

.why-choose-area.bg-background .single-choose-item {
    margin-bottom: 0;
}

.why-choose-area.bg-background .single-choose-item img {
    opacity: 0.5;
}

.why-choose-area.bg-background .single-choose-item .title {
    left: 25px;
    right: 25px;
}

.single-choose-item {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    border-radius: 5px;
}

.single-choose-item img {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    border-radius: 2px;
    opacity: 0.5;
}

.single-choose-item .title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 20px;
    z-index: 1;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.single-choose-item .title h3 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 500;
}

.single-choose-item .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #1F1F22;
    border-radius: 5px;
    padding: 25px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
    visibility: hidden;
    margin-top: 0;
    margin-left: 0;
}

.single-choose-item .content .inner-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 20px;
    right: 20px;
    margin: 0 auto;
}

.single-choose-item .content .inner-content h3 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 1.4;
}

.single-choose-item .content .inner-content h3 a {
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
}

.single-choose-item .content .inner-content p {
    color: #ffffff;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 15px;
}

.single-choose-item:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.single-choose-item:hover .content {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-choose-item:hover .title {
    opacity: 0;
    visibility: hidden;
}

.single-choose-item .content .inner-content h3 a {
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
}

.section-title {
    text-align: center;
    max-width: 100%;
    margin: 0 auto 40px;
}

.section-title span {
    font-size: 16px;
    color: #f12711;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    font-weight: 600;
}

.section-title span::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: #f12711;
    left: -35px;
    top: 12px;
}

.section-title h2 {
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #ffffff;
}

.section-title p {
    margin-bottom: 0;
}

.section-title.left {
    max-width: 530px;
}

.section-title.left h2 {
    margin-bottom: 0;
}

.section-title.right {
    text-align: left;
    max-width: 100%;
    position: relative;
}

.section-title.right span {
    margin-left: 36px;
}

.section-title.right .learn-btn {
    display: inline-block;
    padding: 12px 30px;
    background-color: #0d1820;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    position: absolute;
    bottom: 0;
    right: 0;
}

.section-title.right .learn-btn i {
    position: relative;
    top: 2px;
    font-size: 18px;
}

.section-title.right .learn-btn:hover {
    background-color: #0865f8;
}

.section-title.right .contact-btn {
    display: inline-block;
    padding: 12px 30px;
    background-color: #ffffff;
    color: #0d1820;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    position: absolute;
    bottom: 0;
    right: 0;
}

.section-title.right .contact-btn i {
    position: relative;
    top: 2px;
    font-size: 18px;
}

.section-title.right .contact-btn:hover {
    background-color: #0865f8;
    color: #ffffff;
}

.section-title .read-btn {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #f12711;
    margin-top: 12px;
    text-transform: uppercase;
}

.section-title .read-btn:hover {
    color: #0865f8;
}

.section-title .read-btn i {
    position: relative;
    top: 2px;
}

.section-title-two {
    text-align: right;
    max-width: 800px;
    margin: 0 auto 40px;
}

.section-title-two .title {
    padding-right: 40px;
    position: relative;
}

.section-title-two .title::before {
    content: "";
    position: absolute;
    top: 32px;
    right: 0;
    height: 100px;
    width: 1px;
    background-color: #cdcdcd;
}

.section-title-two .title span {
    font-size: 16px;
    color: #f12711;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    font-weight: 600;
}

.section-title-two .title span::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: #f12711;
    left: -35px;
    top: 12px;
}

.section-title-two .title h2 {
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.section-title-two .text {
    padding-left: 10px;
    text-align: left;
}

.section-title-two .text p {
    margin-bottom: 0;
}

/*================================================
Software Area CSS
=================================================*/
.software-area {
    background-color: #000000;
}

.software-item {
    margin-bottom: 30px;
    background-color: #1F1F22;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 30px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    height: 416px;
    position: relative;
}

.software-item .image {
    margin-bottom: 20px;
}

.software-item h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.software-item p {
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 0;
    color: #ffffff;
}

.applink {
    margin: 5px auto;
}

.applink .as-btn.style3 {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;
}

.applink .as-btn {
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    background-color: black;
    color: #FFFFFF !important;
    font-family: var(--body-font);
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border: 1px solid #ffff;
    padding: 12.5px 8px;
    min-width: 50px;
    border-radius: 4px;
    width: 90px;
    margin-left: 12px;
    margin: 0 auto;
}

.applink .as-btn a {
    color: #ffffff !important;
    font-weight: 600;
}

.applink .as-btn:hover a {
    color: #122139 !important;
}

.software-item .read-btn {
    display: inline-block;
    padding: 14px 30px;
    background-color: #0865f8;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    border: 1px solid #0865f8;
    border-radius: 2px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
}

.software-item:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.software-item:hover .read-btn {
    background-color: transparent;
    color: #ffffff;
}

.software-item h3 a {
    color: #ffffff !important;
}

.modal {
    transition: all 0.6s ease-in !important;
}

.modal.show {
    display: block !important;
    overflow: hidden;
    z-index: 9999;
}
.hideContactForm{
    animation: upsetanirevers .5s !important;
}
.openContactForm {
    display: block !important;  
    overflow: hidden;
    animation: upsetani .5s;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.75);
}
.openContactForm.fade:not(.show){
    opacity: 1;
}
.mopen.show, .modal-video.show {
    display: block !important;
    transition: all 0.6s ease-in;
    overflow: hidden;
    animation: show .5s;
    z-index: 9999;
    padding: 15px 18px;
}
.modal-video .close-icon-popup i{
    background-color: #000000;
    padding: 11px 17px;
    font-size: 33px;
    border-radius: 30px;
}
.modal-video.show .modal-dialog{
    max-width: 730px !important;
}
.sidemenu-wrapper.side-model.show {
    background: none !important;
}

.modal.fade.modaltwo .modal-dialog {
    right: -2;
    position: absolute;
    top: -30px;
}

.modal-title {
    font-weight: 700 !important;
}

.form-control.is-invalid {
    border: 1px solid red;
}

.invalid-feedback {
    color: red;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #7638ff;
    text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #7638ff;
}

.xdsoft_noselect {
    z-index: 999999 !important;
    margin-top: 10px;
    margin-left: 14px;
}

.applink {
    display: grid;
}

.applink i {
    font-size: 25px;
    margin-bottom: 10px;
    color: #ffffff;
}

@keyframes show {
    from {

        transform: scale(0);
        opacity: 0;
        z-index: 1020;
    }

    to {

        transform: scale(1);
        opacity: 1;
        z-index: 99;
    }
}

@keyframes hide {
    from {
        transform: scale(1);
        opacity: 1;
        z-index: 99;
    }

    to {
        transform: scale(0);
        opacity: 0;
        z-index: 1020;
    }
}

@keyframes upsetani {
    from {
        transform: translateY(-100px);
        opacity: 0;
        z-index: 1020;
    }

    to {
        transform: translateY(0);
        opacity: 1;
        z-index: 99;
    }
}
@keyframes upsetanirevers {
    from {
        transform: translateY(0);
        opacity: 1;
        z-index: 99;
    }

    to {
        transform: translateY(-100px);
        opacity: 0;
        z-index: 1020;
    }
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
    z-index: 999999;
}

#games {
    transition: all 0.5s;
}

.form-control:focus {
    box-shadow: none !important;
}

/* ----------Preloadercss start----- */
.loader {
    display: none;
    /* background: #ffffff;
    width: 100%;
    height: 100%;
    line-height: 50px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    font-weight: 900;
    letter-spacing: 0.2em;
    z-index: 9999999;
    transition: all 0.5s; */
}

.loader span {
    position: absolute;
    width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    text-transform: uppercase;
}

.loader span::before,
.loader span::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #8f8f8f;
    position: absolute;
    animation: load 0.7s infinite alternate ease-in-out;
    transition: all 0.5s;
}

.loader span::before {
    top: 0;
}

.loader span::after {
    bottom: 0;
}

@keyframes load {
    0% {
        left: 0;
        height: 30px;
        width: 15px;
    }

    50% {
        height: 8px;
        width: 40px;
    }

    100% {
        left: 235px;
        height: 30px;
        width: 15px;
    }
}

/* ----------Preloadercss end----- */
#section {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.moretext {
    display: none;
}

.moreless-button:focus #section {
    transition: all 0.5s;
    overflow: auto !important;
    display: block !important;
    -webkit-line-clamp: 10;
}

.circle {
    width: 100px;
    margin: 6px 20px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
}

.box {
    width: 100%;
}

.box h2 {
    display: block;
    text-align: center;
}

.box .chart {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 40px;
    line-height: 160px;
    height: 160px;
    color: #fff;
}

.box canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: 100%;
}

.new-service-grid-text {
    font-size: 15px;
}

.magnific-img img {
    width: 100%;
    height: auto;
}

.mfp-bottom-bar,
* {
    font-family: var(--body-font);
}

.magnific-img {
    display: inline-block;
    width: 32.3%;
}

a.image-popup-vertical-fit {
    cursor: -webkit-zoom-in;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.98;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-arrow-left:before {
    border-right: none !important;
}

.mfp-arrow-right:before {
    border-left: none !important;
}

button.mfp-arrow,
.mfp-counter {
    opacity: 0 !important;
    transition: opacity 200ms ease-in, opacity 2000ms ease-out;
}

.mfp-container:hover button.mfp-arrow,
.mfp-container:hover .mfp-counter {
    opacity: 1 !important;
}


/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

.text-animation {
    font-size: 36px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.contact-form.ajax-contact .form-control .d-mobile.form-date-new{
    display: none !important;
}
@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

    /**
       * Remove all paddings around the image on small screen
       */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

/*================================================
Sales Area CSS
=================================================*/
.sales-area {
    background-image: url(../img/team/sales-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-attachment: fixed;
}

.sales-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .77;
}

.sales-area .shape {
    position: absolute;
    left: 0;
    right: -2;
    bottom: -4;
}

.sales-content span {
    font-size: 16px;
    color: #f12711;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 35px;
    font-weight: 600;
}

.sales-content span::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: #f12711;
    left: -35px;
    top: 12px;
}

.sales-content h2 {
    font-size: 50px;
    color: #ffffff;
    margin-top: 12px;
    margin-bottom: 10px;
}

.sales-content p {
    margin-bottom: 0;
    color: #c6c6c6;
    font-size: 1.2rem;
}

.sales-content .list {
    padding-left: 0;
    margin-top: 30px;
    margin-bottom: 0;
}

.sales-content .list li {
    list-style-type: none;
    font-size: 17px;
    color: #ffffff;
    margin-bottom: 10px;
    padding-left: 8px;
}

.sales-content .list li:last-child {
    margin-bottom: 0;
}

.sales-content .list li i {
    position: relative;
    top: 2px;
    right: 7px;
    color: #f12711;
}

.sales-box {
    padding: 25px;
    position: relative;
    z-index: 1;
    background-color: rgba(20, 33, 42, 0.83);
    border: 1px solid #21333f;
    margin-bottom: 30px;
}

.sales-box .image img {
    margin-bottom: 20px;
}

.sales-box h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: #ffffff;
    line-height: 1.5;
}

.sales-box p {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    color: #ffffff;
}

.sales-box::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 00%;
    background: #ffffff;
    border-radius: 0;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.sales-box:hover::before {
    height: 100%;
}

.sales-box:hover h3 {
    color: #0d1820;
}

.sales-box:hover p {
    color: #0d1820;
}

/*================================================
  CTR Area CSS
  =================================================*/
.ctr-area {
    position: relative;
    z-index: 9;
}

.ctr-area .container {
    background-image: url(../img/team/ctr-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
}

.ctr-content img {
    margin-bottom: 20px;
    width: 280px;
}

.ctr-content h2 {
    font-size: 38px;
    margin-bottom: 0;
    color: #ffffff;
}

.ctr-btn {
    text-align: center;
    position: relative;
}

.ctr-btn .btn-one {
    display: inline-block;
    padding: 14px 30px;
    background-color: #ffffff;
    color: #0d1820;
    font-size: 16px;
    font-weight: 400;
    border-radius: 1px;
    border: 1px solid #ffffff;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-right: 12px;
}

.ctr-btn .btn-one i {
    position: relative;
    top: 2px;
}

.ctr-btn .btn-one:hover {
    background-color: transparent;
    color: #ffffff;
}

.ctr-btn .btn-two {
    display: inline-block;
    padding: 14px 30px;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 1px;
    border: 1px solid #ffffff;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.ctr-btn .btn-two i {
    position: relative;
    top: 2px;
}

.ctr-btn .btn-two:hover {
    background-color: #ffffff;
    color: #0d1820;
}

/*================================================
  Conversational Area CSS
  =================================================*/
.conversational-area {
    background-color: #0d1820;
    position: relative;
    margin-top: -120px;
    z-index: 1;
    padding-top: 200px;
}

.conversational-area .shape {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.conversational-content span {
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-left: 35px;
    font-weight: 600;
}

.conversational-content span::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: #ffffff;
    left: -35px;
    top: 12px;
}

.conversational-content h2 {
    font-size: 35px;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 0;
}

.conversational-content .list {
    padding-left: 0;
    margin-bottom: 0;
}

.conversational-content .list li {
    padding-left: 0;
    position: relative;
    list-style-type: none;
    color: #c8c8c8;
    padding-left: 45px;
    margin-top: 30px;
}

.conversational-content .list li i {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: #3b596f;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    border-radius: 30px;
    position: absolute;
    left: 0;
    top: 1px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.conversational-content .list li:hover i {
    background-color: #ffffff;
    color: #f12711;
}

.conversational-content .conversational-btn {
    display: inline-block;
    padding: 14px 30px;
    background-color: #0865f8;
    color: #ffffff;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    margin-top: 30px;
}

.conversational-content .conversational-btn i {
    position: relative;
    top: 2px;
}

.conversational-content .conversational-btn:hover {
    background-color: #f12711;
}

.conversational-image {
    text-align: center;
}

/*================================================
Platform Area CSS
=================================================*/
.platform-area .container-fluid {
    max-width: 1450px;
    margin: auto;
}

.tab .tabs_item {
    display: none;
}

.tab .tabs_item:first-child {
    display: block;
}

.platform-list-tab .tabs {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.platform-list-tab .tabs li {
    list-style-type: none;
    margin-bottom: 10px;
    font-size: 25px;
}

.platform-list-tab .tabs li:last-child {
    margin-bottom: 0;
}

.platform-list-tab .tabs li a {
    display: inline-block;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #0d1820;
    background-color: transparent;
    border: 1px solid #f2f2f2;
    padding: 14px 30px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    width: 100%;
    border-radius: 5px;
}

.platform-list-tab .tabs li a i {
    font-size: 18px;
    position: relative;
    top: 2px;
}

.platform-list-tab .tabs li a.bg-f12711 {
    border-left: 3px solid #f12711;
}

.platform-list-tab .tabs li a.bg-0067ff {
    border-left: 3px solid #0865f8;
}

.platform-list-tab .tabs li a.bg-body {
    border-left: 3px solid #122139;
}

.platform-list-tab .tabs li a.bg-1b2b37 {
    border-left: 3px solid #0d1820;
}

.platform-list-tab .tabs li.current a {
    color: #ffffff;
    background-color: #0865f8;
    border-left: 3px solid #0865f8;
}

.platform-list-tab .tabs.left li.current a {
    color: #ffffff;
    background-color: #f12711;
    border-left: 3px solid #f12711;
}

.platform-list-tab .tab_content .tabs_item .platform-content h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
}

.platform-list-tab .tab_content .tabs_item .platform-content p {
    margin-bottom: 0;
    font-size: 16px;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text {
    margin-top: 30px;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text.bar {
    position: relative;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text.bar::before {
    position: absolute;
    right: 5px;
    top: 6px;
    width: 1px;
    content: '';
    height: 100%;
    background-color: #e9f6ff;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text .image {
    margin-bottom: 20px;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text p {
    font-size: 15px;
    margin-bottom: 0;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text .platform-btn {
    display: inline-block;
    font-size: 18px;
    color: #f12711;
    font-weight: 400;
    margin-top: 20px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text .platform-btn:hover {
    letter-spacing: 1px;
}

.platform-list-tab .tab_content .tabs_item .platform-content .platform-text .platform-btn i {
    position: relative;
    top: 2px;
}

.feature-grid_icon img {
    background-color: #ebf3f5;
    border-radius: 16px;
}

.new-features-content {
    height: 310px;
    width: 302px;
    margin-top: 20px;
}

.title-box {
    max-width: 1060px;
    margin: 0 25px auto;
}

.title-box .heading-title h2 {
    font-size: 42px !important;
    font-weight: 600 !important;
}

.title-box .box-title p {
    font-weight: 300;
    margin-top: 30px;
}

.head-text {
    color: #FFFFFF;
    line-height: 2.5;
}

.new-service-grid:hover .head-text {
    color: #2c394c;
}

#process-sec .process-card-area .work-progress .new-wrap {
    margin: 30px auto;
}

.new_main_lan {
    display: none;
}

.Icon_img_one {
    margin-top: 10px;
}

.Icon_img_two {
    margin-top: 10px;
}

#section #article p {
    font-size: 1.2rem !important;
}

#article.article p {
    font-size: 1.2rem !important;
}

.new_get_in_touch {
    font-size: 1.2rem !important;
}

.hero-style3 .hero-text {
    font-size: 1.3rem !important;
}

.title-box .box-title p {
    font-size: 1.3rem;
}

.sub-space {
    padding-bottom: 80px;
    padding-top: 80px;
}

.p-choose {
    font-size: 1.3rem;
}

.ctn-new {
    border: 2px solid #D9D9D9;
    padding: 22px 15px 40px 15px;
}

.sub-menu.as-submenu {
    display: none;
}

.sub-menu.as-submenu.show {
    display: block !important;
}

.sub-menu .as-m-game::before {
    content: '\f105';
    font-family: var(--icon-font);
    position: absolute;
    left: 0;
    top: 12px;
    margin-right: 10px;
    display: inline-block;
}

.Game-sub-menu-one {
    display: none;
}

.g-m-one.show .Game-sub-menu-one.Game-as-menu-one {
    display: block !important;
}

.Game-sub-menu-one.visit {
    display: block !important;
}

.sky-btn {
    background-image: linear-gradient(#00AFF0, #1bb5ec);
    margin: 0 0 0 13px;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1s;
    cursor: pointer;
}

.sky-btn i {
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 2px;
}

/* ====================================================================================== */
.clear-all:after,
.clear-all:before {
    content: '';
    display: table;
    clear: both;
}

.cm-header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 20px 15px;
}



.cm-menu-inner ul {

    list-style: none;
}

.cm-menu-inner>ul {
    display: block;
}

.cm-menu-inner>ul>li {
    float: left;
    padding: 0 10px;
}

.cm-menu-inner>ul li a {
    display: block;
    color: #000000;
    padding: 10px 15px;
    text-decoration: none;
}

@media(min-width: 992px) {

    .as-btn.Appointment{
        font-size: 13px;
    }
    .cm-menu-inner ul ul {
        position: absolute;
        top: 100%;
        left: 0;
        animation: show .5s !important;
        background: #000;
        min-width: 180px;
        opacity: 0;
        visibility: hidden;
        transition: all 300ms linear;
    }

    .cm-menu-inner ul li.has-child {
        position: relative;
    }

    .cm-menu-inner ul li.has-child:hover>ul {
        opacity: 1;
        visibility: visible;
    }

    .cm-menu-inner ul ul ul {
        left: 100%;
        top: 0;
    }

    .cm-menu-inner {
        display: flex;
        justify-content: center;
    }

    .cm-menu-inner {
        display: flex !important;
    }

    .cm-menu-inner ul {
        display: block !important;
    }

    .cm-menu-btn,
    .child-menu-btn {
        display: none;
    }

}

@media(max-width: 991px) {
    .chose-us{
        margin-top: 30px !important;
    }

    .cm-menu-inner {
        position: absolute;
        width: 100%;
        left: 0;
    }

    .cm-menu-inner>ul>li {
        float: none;
        padding: 0;
    }

    .cm-menu-inner ul li>a {
        border-bottom: 1px solid #fff;
    }

    .cm-menu-inner ul ul>li>a {
        padding-left: 25px;
    }

    .cm-menu-inner ul ul ul>li>a {
        padding-left: 35px;
        font-size: 14px;
    }

    .cm-menu-btn {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: #fff;
        cursor: pointer;
    }

    li.has-child {
        position: relative;
    }

    .child-menu-btn {
        color: #000;
        position: absolute;
        right: 0;
        line-height: 40px;
        border-left: 1px solid #000;
        width: 460px;
        text-align: center;
        cursor: pointer;
        top: 12px;
    }

    .cm-menu-inner ul ul {
        display: none;              
    }

    .cm-menu-inner ul ul.menu-ul.sub-menu.d-block{
        animation: show 0.5s;
    }
}


.cm-header-wrap {
    z-index: 11;
}

.banner {
    position: relative;
    z-index: 10;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.banner h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 16px;
    border-radius: 5px;
    color: #fff;
    font-family: Oswald, sans-serif;
    letter-spacing: 1px;
    width: 700px;
    text-transform: capitalize;
}

.banner h1 a {
    color: #fff;
    text-decoration: none;
    text-decoration: underline;
    display: inline-block;
    padding-top: 20px;
    font-size: 45px;
    text-shadow: 9px -1px 6px #000;
    transition: all 300ms ease;
}


.banner h1 a:hover {
    text-shadow: 5px -1px 14px #000;
}

@media(max-width: 767px) {

    .banner h1 {
        max-width: 90%;
        width: 100%;
    }

}

.menu-item-has-childrens.services.show .child-menu-btn::before {
    transform: rotate(90deg) !important;
}

.chose-us p {
    font-size: 1.4rem;
}

.feature-main .features-list {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin: 0 auto;
}

.feature-main .features-list li span {
    display: block;
    background-color: #122139;
    color: #FFFFFF;
    border-radius: 5px;
    z-index: 1;
    position: relative;
    transition: all 0.5s;
    font-weight: 800;
    padding: 17px 50px;
    font-size: 20px;
    font-size: 16.5px;
    width: auto;
    cursor: pointer;
    margin-top: 15px;
}

.feature-main .features-list li span i {
    font-size: 15px;
    color: #122139;
    background-color: #FFFFFF;
    width: 23px;
    height: 23px;
    line-height: 1.3;
    transition: all 0.5s;
    text-align: center;
    border-radius: 3px;
    position: absolute;
    left: 13px;
    border: 2px solid #122139;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.feature-main .features-list li span:hover {
    color: #122139;
    transform: translateY(0);
    border: 1px solid #122139;
    background-color: #FFFFFF;
}

.feature-main .features-list li span:hover i {
    color: #ffff !important;
    background-color: #122139;
}
#project-card .fa-plus:before{
    line-height: 2.5;
}

.feature-main .features-list li {
    margin: 0 100px auto;
}

.feature-main .features-list li .spn-style3 {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;
}

.feature-main .features-list li .spn-style3 {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #122139;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-o
}

/* ======================================================================================= */
section {
    padding: 0;
}

.process-card .process-card_text {
    font-size: 1rem !important;
}

.as-menu-wrapper .as-menu-toggle {
    right: -37.5px;
    width: 45px;
    height: 45px;
}

.header-lines-decoration {
    width: 100%;
    top: 0;
    height: 6px;
}

.header-lines-decoration span {
    height: 100%;
    width: 14%;
    float: left;
}

.header-lines-decoration span:first-child,
.header-lines-decoration span:last-child {
    width: 15%;
}

.new_main_lan {
    display: none !important;
}

.bg-blue {
    background-color: #0082d7 !important;
}

.bg-secondary-color {
    background-color: #273f5b !important;
}

.bg-blue-light {
    background-color: #98d5ff !important;
}

.bg-orange-light {
    background-color: #f89101 !important;
}

.bg-red {
    background-color: #ff3133 !important;
}

.bg-green {
    background-color: #01a23c !important;
}

.bg-secondary-color {
    background-color: #273f5b !important;
}

.as-btn.style3.send-con-dtl {
    font-size: 12px;
    padding: 20.5px 14px;
}

.new-spc .title-box {
    text-align: start;
    margin: 0 auto;
}

.new-service-grid {
    margin-top: 0 !important;
}
.business-modal-mobile{
    display: none;
}

@media only screen and (max-width: 1799px) {
    .service-grid_content {
        width: auto !important;
    }

    .as-widget-about .about-text {
        max-width: 250px;
        display: inline-block;
    }

    .widget-area {
        width: auto !important;
    }

    .footer-widget .widget_title {
        display: inline-block;
    }
}

@media only screen and (max-width: 1499px) {
    .service-featured_content img {
        width: 170px;
    }
}

@media only screen and (min-width: 1199px) {
    .service-grid_content {
        width: auto !important;
    }

    .service-grid_icon {
        height: 70px;
        width: 70px;
    }

    p {
        font-size: 20px !important;
    }

    .img-box1 .img1 {
        text-align: center;
    }

    .year-counter {
        right: 24% !important;
    }

}

@media only screen and (max-width: 992px) {
    .animated {
        opacity: 1 !important;
        animation-duration: 1s;
    }

    .year-counter {
        right: 18% !important;
    }

    .service-grid_content p {
        font-size: 0.90rem !important;
    }

    .service-grid_icon {
        height: 65px;
        width: 65px;
    }

    .service-grid_icon img {
        width: 35px;
        margin-top: 15px;
    }

    .service-grid .box-title a {
        font-size: 24px;
    }

    .as-btn.style3.Appointment {
        padding: 16.5px 11px !important;
        font-size: 11px !important;
    }
    .as-btn.style3.Appointment:hover{
        color: #FFFF !important;
    }

    .over-btn {
        margin-top: 20px;
    }

    .child-menu-btn {
        top: 2px !important;
    }

    footer .container {
        max-width: 100% !important;
    }

    .banner-gallery-wrapper .banner-gallery-loop{
        justify-content: center;
    }
    .padding-contorler-am-slide{
        padding-left: 0 !important;
    }
    .srv-text .heading-main{
        font-size: 1.2rem !important;
    }
    .srv-text p{
        font-size: 0.85rem !important;
    }
    .cv-gaming-services .cv-gaming-banner .cv-gaming-banner-inner .cv-gaming-img{
        right: -14% !important;
    }
    .component-card__description p:last-child{
        font-size: 14px !important;
    }
    .component-card__content{
        padding: 29px 19px 11px;
    }
}

@media only screen and (max-width: 767px) {
    .service-grid_content {
        padding: 49px 9px 15px 15px;
    }

    .service-grid_icon {
        bottom: 18px;
    }

    .service-grid_content p {
        font-size: 0.85rem !important;
    }

    .service-grid .box-title a {
        font-size: 20px;
    }

    .as-btn {
        font-size: 13px;
        font-weight: 500;
        padding: 18px 11px;
        /* min-width: 130px; */
        min-width: 90%;
    }

    .as-btn.style2 {
        padding: 18px 28px;
    }

    .service-featured {
        height: 300px;
    }

    .service-featured:before {
        top: 68%;
    }

    .service-featured::after {
        top: -10px;
    }

    .service-featured_content {
        margin-top: 0 !important;
    }
    .padding-contorler-am-slide-right{
        padding-left: 30px;
    }
    .title.large-height{
        line-height: 50px !important;
    }
    .srv-text .heading-main{
        font-size: 1.5rem !important; 
    }
    .srv-text p{
        font-size: 1.1rem !important;
    }
    .Mian-img img{
        position: inherit;
    }
    .Mian-img{
        text-align: center;
    }
    .footer-widget.widget_nav_menu a {
        font-size: 17px;
    }
    .benefits-2d__item-text{
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 576px) {
    .new_main_lan {
        display: block !important;
    }

    .as-menu-toggle {
        margin-right: 0;
    }

    .service-grid_content {
        padding: 64px 9px 15px 15px;
    }

    .main-lan {
        display: none;
    }

    .as-menu-wrapper .as-menu-toggle {
        right: -23.5px;
    }

    .service-featured {
        height: 250px;
    }

    .service-grid_icon {
        bottom: 0;
    }

    .service-grid_content p,
    p {
        font-size: 1rem !important;
    }

    .service-featured_content img {
        width: 100px !important;
        padding: 0 12px;
    }

    .mt-40 {
        margin-top: 20px;
    }

    .pt-40 {
        padding-top: 10px !important;
    }

    .over-lan {
        margin-top: 30px !important;
    }

    .pt-100 {
        padding-top: 30px !important;
    }

    .title-area {
        margin-bottom: 10px;
    }

    .service-grid {
        margin-top: 5px !important;
    }

    .service-grid_content {
        height: 360px;
    }

    .year-counter {
        right: 15% !important;
    }

    .year-counter_text {
        font-size: 0.80rem !important;
    }

    .sales-content {
        text-align: start;
    }

    .counter-card-wrap .mt-30 {
        margin-top: 30px;
    }

    .footer-widget {
        margin-bottom: 10px;
    }

    .img-box5,
    .img-box2 {
        margin-top: 10px;
    }

    .hero-text {
        /* font-size: 0.90rem !important; */
        font-size: 1.20rem !important;
    }

    .as-btn {
        font-size: 13px;
        font-weight: 500;
        padding: 15.5px 11px;
        min-width: 120px;
    }

    .as-btn.style2 {
        /* padding: 18px 28px; */
        padding: 15px 28px;
    }
    .service-grid_content .as-btn.style2 {
        padding: 15px 11px;
    }
    .space,
    .space-top {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .mb-30 {
        margin-bottom: 20px;
    }

    .as-btn.style3.Appointment {
        padding: 15.5px 10px !important;
        font-size: 10px !important;
    }
    .as-btn.style3.Appointment:hover{
        color: #FFFF !important;
    }

    .as-video2:after {
        right: calc(51% - 68px);
        top: calc(100% - 68px);
    }

    .as-video2 .play-btn {
        position: absolute;
        top: calc(100% - 42px);
        right: calc(50% - 31px);
    }

    .service-grid_icon img {
        width: 28px;
        margin-top: 20px;
    }

    .process-card .process-card_text {
        font-size: 1rem !important;
    }

    .contact-infos {
        padding: 30px 0;
    }

    .as-menu-toggle {
        height: 45px;
        width: 45px;
    }

    .service-featured_content {
        padding: 0;
    }

    .footer-wrapper .newsletter-form {
        margin-top: 150px !important;
    }

    .newsletter-wrap .newsletter-text {
        text-align: center;
    }    
    .header-layout1 .header-top {
        padding: 3px !important;
    }

    .as-menu-toggle {
        margin-right: 0 !important;
    }

    .new-spc .title-box .heading-title h2 {
        font-size: 30px !important;
    }

    .feature-main .features-list li span {
        font-size: 13.5px;
    }

    .sub-space {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .modaltwo .modal-header .close.react-cls span {
        position: absolute;
        left: 10px !important;
        bottom: -9px !important;
    }

    .footer-contact_icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        font-size: 25px;
    }

    .hero-style4 {
        margin-bottom: 50px;
    }
    .title.large-height{
        font-size: 25px !important;
        line-height: 39px !important;
        letter-spacing: 3px !important;
    }
    .banner-top-rating{
        margin-bottom: 10px !important;
    }
    .banner-top-rating .banner-badge-top .subtitle{
        font-size: 11px;
    }
    .unity-service .title-area h2 {
        font-size: 29px;
    }
    .unity-service .title-area p{
        font-size: 1rem !important
    }
    .unity-service .srv-main .srv-text {
        padding: 23px 14px;
    }
    .ptb--60{
        padding: 35px 15px !important;
    }
    .unity-service .srv-main .srv-text h3{
        font-size: 1.3rem !important;
    }
    .unity-service .srv-main .srv-text p{
        font-size: 1rem;
    }
    .srv-main .mb-35{
        margin-bottom: 6px !important;
    }
    .component-card{
        margin-bottom: 35px !important;
    }
    .unity-service .our-lang .our-lan-img{
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .ptb--6.mt-50.mb-50{
        margin-bottom: 25px !important;
    }
    .service-grid .bg-shape img{
        width: 100% !important;
    }
    .feature-circle{
        gap: 60px;
    }
    .feature-circle .circle-big svg{
        left: 30px;
    }
    .process-card{
        margin-top: 30px;
    }
}

@media only screen and (max-width: 1299px) {
    .srv-text .heading-main{
        font-size: 1.5rem;
    }
    .srv-text p{
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 460px) {
    .cv-gaming-services .cv-gaming-banner .cv-gaming-banner-inner .cv-gaming-img {
        right: -18% !important;
    }
    .service-grid .as-btn{
        max-width: 130px;
        bottom: 13px;
    }
}
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 992px) and (max-width: 1199.95px) {

  .pb-200 { padding-bottom: 180px; }
  .pb-190 { padding-bottom: 170px; }
  .pb-180 { padding-bottom: 160px; }
  .pb-170 { padding-bottom: 150px; }
  .pb-160 { padding-bottom: 140px; }
  .pb-150 { padding-bottom: 130px; }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/ 

  /* Header H6 */
  h6.h6-xs { font-size: 1rem; }        
  h6.h6-sm { font-size: 1.05rem; } 
  h6.h6-md { font-size: 1.075rem; }   
  h6.h6-lg { font-size: 1.1rem; }  
  h6.h6-xl { font-size: 1.125rem; }   

  /* Header H5 */
  h5.h5-xs { font-size: 1.15rem; }  
  h5.h5-sm { font-size: 1.2rem; }  
  h5.h5-md { font-size: 1.25rem; }    
  h5.h5-lg { font-size: 1.35rem; } 
  h5.h5-xl { font-size: 1.45rem; }   

  /* Header H4 */
  h4.h4-xs { font-size: 1.6rem; }  
  h4.h4-sm { font-size: 1.7rem; }    
  h4.h4-md { font-size: 1.8rem; }   
  h4.h4-lg { font-size: 1.9rem; }   
  h4.h4-xl { font-size: 2rem; }   

  h3.h3-xs { font-size: 2.1rem; }  
  h3.h3-sm { font-size: 2.2rem; }  
  h3.h3-md { font-size: 2.3rem; }   
  h3.h3-lg { font-size: 2.4rem; } 
  h3.h3-xl { font-size: 2.5rem; }    

  /* Header H2 */
  h2.h2-xs { font-size: 2.6rem; } 
  h2.h2-sm { font-size: 2.7rem; } 
  h2.h2-md { font-size: 2.8rem; }  
  h2.h2-lg { font-size: 2.9rem; }  
  h2.h2-xl { font-size: 3.125rem; }   

  h2.h2-title-xs { font-size: 3.4rem; } 
  h2.h2-title-sm { font-size: 3.65rem; }  
  h2.h2-title-md { font-size: 3.9rem; }
  h2.h2-title-lg { font-size: 4.16rem; }    
  h2.h2-title-xl { font-size: 4.42rem; } 

  /* Paragraphs */
  p { font-size: 1rem; }     
  p.p-md { font-size: 1.05rem; } 
  p.p-lg { font-size: 1.075rem; }  
  p.p-xl { font-size: 1.1rem; }

  p.p-title-xs { font-size: 1.125rem; } 
  p.p-title-sm { font-size: 1.15rem; }  
  p.p-title-md { font-size: 1.2rem; }  
  p.p-title-lg { font-size: 1.25rem; }   
  p.p-title-xl { font-size: 1.3rem; } 

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.875rem; }

  /* Watch Video Link */
  .watch-video-link { width: 50px; height: 50px; margin-right: 12px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 48px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 2px; }

  /* Video Popup Icon */
  .video-btn-xl { width: 110px; height: 110px; margin-top: -55px; margin-left: -55px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, 
  .video-btn.video-btn-xl [class^="flaticon-"]:after {line-height: 110px!important; margin-left: 8px; }

  /* Store Badges */
  .store img, .footer .store img { max-height: 45px; }
  .store.header-store img { max-height: 42px; }
  span.os-version { font-size: 1rem; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.875rem; } 
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; }  
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; } 
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.5625rem; } 
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.85rem; }  
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.1rem; }  
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.35rem; } 
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.6rem; }
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 2.9rem; } 
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.125rem; } 
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.4rem; }  
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.65rem; }  
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 3.9rem; } 
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 4.175rem; }  
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4.45rem; } 
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.7rem; }  
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 4.95rem; } 
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 5.2rem; }  
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 5.5rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 5.75rem; }  
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 6rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 6.25rem; }   
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 6.5rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 74px; height: 74px; }
  .ico-rounded-sm { width: 79px; height: 79px; }
  .ico-rounded-md { width: 84px; height: 84px; }
  .ico-rounded-lg { width: 89px; height: 89px; }
  .ico-rounded-xl { width: 94px; height: 94px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 74px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 79px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 84px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 89px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 94px!important; }

  /* PNG Icons */
  .ico-125 img { width: 105px; height: 105px; }
  .ico-120 img { width: 100px; height: 100px; }
  .ico-115 img { width: 96px; height: 96px; }
  .ico-110 img { width: 92px; height: 92px; }
  .ico-105 img { width: 88px; height: 88px; }
  .ico-100 img { width: 84px; height: 84px; }
  .ico-95 img { width: 80px; height: 80px; }
  .ico-90 img { width: 76px; height: 76px; }
  .ico-85 img { width: 72px; height: 72px; }
  .ico-80 img { width: 67px; height: 67px; }
  .ico-75 img { width: 62px; height: 62px; }
  .ico-70 img { width: 58px; height: 58px; }
  .ico-65 img { width: 54px; height: 54px; }
  .ico-60 img { width: 50px; height: 50px; }
  .ico-55 img { width: 46px; height: 46px; }
  .ico-50 img { width: 42px; height: 42px; }
  .ico-45 img { width: 38px; height: 38px; }
  .ico-40 img { width: 34px; height: 34px; }
  .ico-35 img { width: 30px; height: 30px; }
  .ico-30 img { width: 25px; height: 25px; }
  .ico-25 img { width: 21px; height: 21px; }
  .ico-20 img { width: 18px; height: 18px; }

  /* Section Id */
  .section-id { font-size: 1.075rem; margin-bottom: 30px; }

  /* Section Title */
  .title-04 { padding-right: 3%; }
  .title-01 p { padding: 0 10%; margin-top: 15px; }
  .rtl-direction .title-01 p { padding: 0 5%; margin-top: 15px; }

  .section-title.mb-40 { margin-bottom: 40px; }
  .section-title.mb-50 { margin-bottom: 45px; }
  .section-title.mb-55 { margin-bottom: 50px; }
  .section-title.mb-60 { margin-bottom: 55px; }
  .section-title.mb-65 { margin-bottom: 55px; }
  .section-title.mb-70 { margin-bottom: 60px; }
  .section-title.mb-75 { margin-bottom: 65px; }
  .section-title.mb-80 { margin-bottom: 70px; }
  .section-title.mb-85 { margin-bottom: 75px; }

  /* Text Block */
  .tabs-section .txt-block.left-column, 
  .content-section .txt-block.left-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }
  .tabs-section .txt-block.right-column,
  .content-section .txt-block.right-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }

  .content-5.content-section .txt-block.left-column {padding-left: 0;}
  .content-5.content-section .txt-block.right-column { padding-right: 0; }

  /* Text Block Typography */
  .txt-block h3, .txt-block h2, .content-14-txt h4, .content-14-txt h3 { margin-bottom: 22px; }
  h5.sm-title { margin-top: 16px; margin-bottom: 16px; }
  .txt-block .btn, .txt-block .stores-badge { margin-top: 15px; }

  .content-5.content-section .txt-block.left-column,
  .content-5.content-section .content-section .img-block.left-column { padding-right: 0; }

  .content-5.content-section .txt-block.right-column,
  .content-5.content-section .content-section .img-block.right-column { padding-right: 0; }

  /* Text Block Rating */
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating, .stars-rating p { font-size: 1rem; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .desktoplogo img { max-height: 34px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 90%!important; left: 8%; }
  

  .wsmenu > .wsmenu-list > li > a { font-size: 16px; margin: 0 3px; padding: 10px 15px; }
  .wsmenu > .wsmenu-list > li a.btn { font-size: 16px; line-height: 30px; margin-top: 14px; padding: 4px 23px; }
  .wsmenu > .wsmenu-list > li a.header-store { margin: 10px 0 0 12px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { font-size: 16px; }
  .wsmegamenu .post-summary a {  font-size: 15px; }

  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.3rem; } 

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  /* Hero-1 */
  #hero-1 { padding-top: 130px; padding-bottom: 100px; }
  .hero-1-txt { padding-left: 20px; }
  .hero-1-txt h2 { margin-bottom: 25px; }
  .hero-1-txt p.p-xl { font-size: 1.175rem; padding-right: 0; margin-bottom: 35px; }

  /* Hero-2 */
  #hero-2 { padding-top: 140px; }
  .hero-2-txt p.p-xl { font-size: 1.175rem; padding-right: 0; margin-bottom: 35px; }
  .hero-2-img { padding: 0 20px; }

  /* Hero-3 */
  .hero-3-txt h2 { margin-bottom: 25px; }
  .hero-3-txt p { margin-bottom: 35px; }

  /* Hero-4 */
  #hero-4-form { padding: 45px 35px; margin-left: 40px; margin-right: 25px; }

  /* Hero-5 */
  .hero-5-txt { padding-left: 15%; }
  .hero-5-img { margin-left: 0; }

  /* Hero-6 */
  .hero-6-txt { padding-left: 15px; padding-right: 0; }
  #hero-6 .quick-form { margin: 40px 5px 0 0; }
  #hero-6 .quick-form .btn { padding: 13px 32px; }

  /* Hero-7 */
  .hero-7-txt { margin-top: -30px; padding-left: 15px; padding-right: 0; }
  .hero-7-img { margin-left: 30px; }

  /* Hero-8 */
  .hero-8-txt { padding-left: 30px; padding-right: 10px; }
  .hero-8-img { padding: 0; }
  #hero-8 .quick-form { margin: 40px 5px 0 0; }
  #hero-8 .quick-form .btn { padding: 13px 32px; }

  /* Hero-9 */
  #hero-9 { padding-top: 160px; padding-bottom: 20px; }
  #hero-9-form { padding: 45px 30px; margin-left: -40px; margin-right: 40px; }
  #hero-9-form h4.h4-xs { font-size: 1.65rem; }

  /* Hero-10 */
  .hero-10-txt p { margin-bottom: 30px; }
  .hero-10-img { padding: 0 25px; margin-top: 50px; }

  /* Hero-11 */
  .hero-11-txt { padding-left: 25px; padding-right: 15px; }

  /* Hero-12 */
  .hero-12-txt h2 { margin-bottom: 25px; }
  .hero-12-txt p { font-size: 1.25rem; padding-right: 5%; }
  #hero-12:after { height: 38%; top: 62%; }
  .hero-12-img { margin-top: 40px; }

  /* Hero-13 */
  .hero-13-title { margin-bottom: 35px; }
  .hero-13-txt { padding-right: 5%; margin-top: 10px; }
  .hero-section .tools-list.mt-50 { margin-top: 30px; }
  .hero-13-img { margin: 0 -0 -60px -45px; }

  /* Hero-14 */
  .hero-14-txt { padding-left: 15px; }
  .hero-14-txt h2 { margin-bottom: 15px; }
  .hero-14-txt p.p-xl { margin-bottom: 30px; }

  /* Hero-15 */
  .hero-15-img { padding: 0 25px; margin-top: 60px; }

  /* Hero-16 */
  .hero-16-txt { padding-left: 15px; padding-right: 25px; }

  /* Hero-17 */
  .hero-17-txt { margin-top: 40px; padding-right: 0; }
  .hero-17-img { margin-bottom: -100px; }

  /* Hero-18 */
  #hero-18-form { padding: 0 11% 0 25px; }
  #hero-18-form p.p-lg { margin-top: 20px; margin-bottom: 30px; }
  #hero-18-form .form-btn { margin-top: 10px; }
  #hero-18-form .advantages { margin-top: 10px; }
  .hero-18-img { padding: 0 30px 0 40px; }

  /* Hero-19 */
  .hero-19-txt { margin-top: 0; }
  .hero-19-img { margin-left: 25px; margin-bottom: -70px; }

  /* Hero-20 */
  .hero-20-txt h2 { margin-bottom: 25px; }
  .hero-20-txt p { margin-bottom: 30px; }

  /* Hero-21 */
  #hero-21 { padding-top: 150px; }
  .hero-21-txt h2 { padding: 0 5%; }
  .hero-21-txt p.p-xl { padding: 0 10%; }

  #hero-21 .quick-form { margin: 40px 10% 0; }

  /* Hero-22 */
  .hero-22-wrapper { padding: 60px 50px 20px; }
  .hero-22-wrapper .img-block { margin-left: -20px; }

  /* Hero-23 */
  #hero-23 { padding-top: 150px; }
  .hero-23-txt { padding-left: 20px; }
  .hero-23-txt p.p-xl { font-size: 1.175rem; padding-right: 0; }

  /* Hero-24 */
  .hero-24-txt h2.tra-header { top: -20px; left: -24%; font-size: 20rem; }
  .hero-24-img img { padding: 0 10%; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-1 .txt-block p { padding-right: 0; }
  .about-2-title { padding-right: 0; }
  .about-2-images img { max-height: 344.5px; }

  /* Features */
  .fbox-2-wrapper .col, .fbox-5-wrapper .col, .fbox-8-wrapper .col { padding: 0 8px; }
  .fbox-7-wrapper { padding-right: 20px; }
  .fbox-3, .fbox-4 { padding: 0; }
  .fbox-5 { padding: 50px 25px; } 
  .fbox-6 { padding: 40px 35px; }
  .fbox-7 { padding: 35px 25px; } 

  .fbox-txt { padding-left: 8px; }
  .fbox-4-wide .fbox-txt { width: 84%; }
  .fbox-4-wide .fbox-txt { padding-left: 0; }
  .fbox-4-wide .fbox-ico { width: 16%; }
  .fbox-img { margin-left: 10%; margin-right: 10%; padding: 40px 12% 45px; }

  /* Content */
  #content-4 { padding-top: 60px; }
  #content-5.mb-40 { margin-bottom: 30px; }
  .content-5-wrapper { padding: 90px 55px 50px; }

  .content-8 .section-title.mb-75 { margin-bottom: 50px; }

  .cbox { margin-right: -14px; }
  .cbox.mb-40 { margin-bottom: 30px; }
  .cbox-2 { width: 340px; min-width: 340px; padding: 20px 20px; }
  .cbox-2.mt-20.mb-30 { margin-top: 10px; }
  .cbox-txt { padding-left: 24px; }
  .cbox-ico { margin-top: 4px; }

  .cbox-4.left-column, .cbox-4.right-column { bottom: -5px; right: 25px; width: 53%; padding: 25px 20px; }
  .cbox-4.right-column { left: 25px; right: 0; bottom: -20px; }
  .cbox-5 { top: -40px; left: -10px; width: 58%; padding: 25px 20px; }
  .cbox-6 { bottom: -10px; right: 15px; width: 58%; padding: 25px 20px; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1.1rem; padding-right: 5%; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.95rem; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 15px; margin-bottom: 15px; }
  .cbox-4 .btn, .cbox-5 .btn, .cbox-6 .btn { font-size: 0.95rem; padding: 11px 30px; margin-bottom: 8px; }

  .cb-holder { padding: 40px 30px; }
  .cb-single-box h2.statistic-number { font-size: 3.15rem; }
  .statistic-number sup { font-size: 2.5rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: 15px; top: -20px; }
  .cb-shape-2 { left: 10px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 12%; }

  .content-10-btn { margin-top: 50px; }

  .content-4-img { margin-top: -60px; }
  .content-7-img { margin-right: -110%; }
  .content-7-img img { max-width: 100%; }
  .content-8-img { margin-bottom: -150px; }

  /* Projects */
  .more-projects { margin-top: 60px; }

  /* Tabs */
  .tabs-1 {  margin: 0 auto 50px; }
  .tabs-1 li { font-size: 1rem; padding: 9px 30px; }
  #tabs-2 .tabs-1 li { padding: 30px 25px; }

  .tabs-1 li span { font-size: 1rem; }

  /* Statistic */
  #statistic-2 { padding-top: 90px; padding-bottom: 90px; }
  .statistic-1-wrapper .statistic-block { padding: 10px 20px; }
  .statistic-block-txt { width: 68%; padding-left: 15px; }
  .statistic-block-digit { width: 32%; float: left; }
  .statistic-block .txt-block-rating.ico-15 [class*="flaticon-"]:before,
  .statistic-block .txt-block-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.075rem; }

  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 50px; }
  .rtl-direction .statistic-block-txt { padding-left: 0; padding-right: 15px; }

  /* Reviews */
  .reviews-2-wrapper .col { padding: 0 8px; }
  .review-1 { padding: 30px 20px; margin: 0 6px 20px; }  
  .review-2 { padding: 30px 20px; } 
  .review-3 { padding: 40px 20px; }
  .review-4 {  padding: 40px 35px; margin: 0 10px 40px; }

  .right-column #rw-3-1, .right-column #rw-3-3 { margin-left: 10px; margin-right: -10px; }
  .right-column #rw-3-2 { margin-left: -20px; }
  .left-column #rw-3-1, .left-column #rw-3-3 { margin-right: 10px; margin-left: -10px; }
  .left-column #rw-3-2 { margin-right: -20px; }

  .review-1-txt .author-data, .review-2-txt .author-data { margin-left: -15px; }

  .review-avatar img, .owl-item .review-avatar img, .review-2 .review-avatar img, .review-3-avatar img { width: 50px; height: 50px; }
  .review-3-avatar { width: 17%; text-align: left; }
  .review-3-txt { width: 83%; }
  .review-author { padding: 0 0 0 10px; } 

  .review-3-txt { padding-left: 0; }
  .review-3-txt p { padding-right: 0; }

  .rtl-direction .review-3-txt { padding-left: 0; padding-right: 0; }

  /* Pricing */
  .pricing-1-row, .pricing-2-row, .pricing-3-row { padding: 0; }

  .pricing-1-table { padding: 40px 25px 35px; }
  .pricing-2-table { padding: 30px 25px 35px; }
  .pricing-3-table { padding: 45px 25px; }

  .pricing-plan-title h6 { padding: 5px 12px; right: -3px; top: -5px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 1rem; }

  .pricing-plan span { font-size: 2.8rem; }
  .pricing-3-table .pricing-plan span { font-size: 4.16rem; }
  .pricing-plan sup { font-size: 1.9rem; }
  .pricing-3-table .pricing-plan sup { font-size: 2.4rem; top: -8px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.4rem; top: -20px; }
  .pricing-plan sup.validity span { font-size: 2.3rem; }
  .pricing-plan sup.validity { font-size: 1.1rem; }

  .pricing-1-table .pricing-plan p { padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { margin-top: 10px; }

  .badge-wrapper { top: -19px; }
  .highlight-badge { padding: 10px 40px; }

  .pricing-notice p { padding: 0 5%; }

  .payment-methods.pc-30 { padding: 0; }
  .payment-icons li { padding-right: 8px; }
  .payment-methods p { font-size: 0.95rem; }

  .pricing-compare { margin-top: 40px; }
  .pricing-compare .table-responsive { margin-top: 40px; margin-bottom: 25px; }

  /* Brands */
  .brands-2-wrapper { padding: 0 4%; }
  #brands-1 .brand-logo { padding: 0 15px; }
  #brands-2 .brand-logo { padding: 0 5px; }
  .brands-section p { padding: 0; }

  /* FAQs */
  .faqs-1-questions { padding: 0 5%; }
  .questions-holder.pr-15 { padding-right: 0; }
  .questions-holder.pl-15 { padding-left: 0; }

  /* Call To Action */
  .cta-1-wrapper { padding: 40px 50px; }
  #cta-3 { margin-top: 90px; }
  #cta-4 { margin-bottom: 90px; }
  .cta-3-wrapper { padding: 40px 60px; margin-top: -100px; }
  .cta-4-wrapper { padding: 40px 50px; margin-bottom: -100px; }
  .cta-5-wrapper { padding: 50px 30px; }
  .cta-7-wrapper { padding: 50px 60px; margin: 0 3%; }
  .cta-8-wrapper { padding: 0; }
  .cta-8-wrapper .col { padding: 0 8px; }
  .cta-11-wrapper { padding: 50px 45px; }

  .cta-10-txt { padding-right: 5%; }
  .rtl-direction .cta-10-txt { margin-right: 5%; }
  .rtl-direction .cta-11-txt { padding-right: 0; }

  .cta-2-btn, .cta-3-btn, .cta-4-btn { margin-top: 20px; }
  .os-btn { width: 310px; min-width: 310px; padding: 25px 20px; }

  .cta-10-img { margin-left: -50px; }

  /* Download */
  span.version-data { font-size: 1.9rem; }
  span.release-date { font-size: 1.1rem; }

  /* Newsletter */
  #newsletter-2 .newsletter-txt.pc-20, #newsletter-2 .newsletter-form { padding: 0 15px; }
  .newsletter-wrapper { padding: 30px 40px; }
  #newsletter-1 .newsletter-form { margin-top: 40px; }
  .newsletter-section .form-control { height: 50px; font-size: 1.125rem; }
  .newsletter-section .btn { height: 50px; padding: 13px 30px; }

  /* Blog */
  #blog-1 .col { padding-left: 8px; padding-right: 8px; }

  /* Blog Listing */
  .posts-wrapper { padding: 0; }
  .featured-post { margin: 0 0 50px 0; }
  #blog-page .blog-post-wide .blog-post-txt { padding: 0 55px 0 40px; } 
  .featured-badge { top: 15px; right: 15px; width: 40px; height: 40px; }
  .featured-badge.ico-25 [class*="flaticon-"]:before, 
  .featured-badge.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; line-height: 38px!important; }

  /* Single Post */
  .single-post-title, .single-post-txt, .post-share-links, .comments-wrapper { padding: 0; }
  .about-post-author { padding: 80px 0; }

  #single-post p.post-tag { margin-bottom: 30px;} 
  #single-post .post-data { margin-top: 30px; margin-bottom: 30px; }
  #single-post .post-author-avatar img { width: 54px; height: 54px; }

  .post-inner-img { margin-top: 50px; margin-bottom: 45px; }
  .author-senoff img { width: 80px; height: 80px; }
  .author-senoff-txt p { padding-right: 0; }

  .comment-form .form-control { height: 52px; font-size: 1.0625rem; }

  /* Contacts */
  #contacts-1 .title-02.pc-20 { padding: 0; }
  #contacts-2 .title-01 h3 { padding: 0 5%; }
  .contact-form .form-control, .contact-form .form-select { height: 56px; }
  #contacts-2 .contact-form span { font-size: 16px; }

  /* Footer */
  .footer-info p { padding-right: 0; }
  .rtl-direction #footer-1 .footer-info p { padding-left: 0; }
  #footer-1 .footer-links { padding-left: 0; }
  .rtl-direction #footer-1 .footer-links { padding-left: 0; }
  .rtl-direction #footer-3 .footer-links { padding-left: 0; }
  .rtl-direction .footer-links { padding-right: 0; }
  .footer-form { padding-left: 0; }

  /* Inner Page Hero */
  .inner-page-hero { padding-top: 170px; }
  .page-hero-section { padding-top: 130px; padding-bottom: 90px; }
  .page-hero-section h3 { padding: 0 10%; }
  .page-hero-section p { padding: 0 15%; }

  /* Terms Page */
  .terms-title { margin-bottom: 50px; }
  .terms-box.mt-60 { margin-top: 50px; }
  .terms-box, .terms-box h5 {  margin-bottom: 25px; }

  /* Register Page */
  #reset-password-2 { padding-top: 140px; }

  #reset-password-2.reset-password-section .register-form-title h4 { font-size: 1.75rem; }
  #reset-password-2.reset-password-section .register-form-title p { font-size: 1.025rem; padding: 0; }

  .login-page-logo { margin-bottom: 50px; }
  .login-page-logo img { max-height: 45px; }

  .reset-password-section .form-data { margin-top: 30px; }
  .form-check .form-check-label { font-size: 1rem; }

  .reset-password-section .sign-in-footer { margin-top: 30px; }

  .sign-in-notice { padding: 0 12%; margin-top: 50px; }

  .login-separator-txt:before { left: -780%; }
  .login-separator-txt:after { right: -780%; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -900%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -900%; }

  #login-3 .login-separator-txt:before { left: -730%; }
  #login-3 .login-separator-txt:after { right: -730%; }

  #signup-3 .login-separator-txt:before { left: -770%; }
  #signup-3 .login-separator-txt:after { right: -770%; }

  #signup-3 .register-form { margin-left: -25px; }
  .register-form form, #login-2 .register-form form, #signup-2 .register-form form { margin: 0; }

  #reset-password-2 .register-form .form-control { height: 52px; }
  #reset-password-2 .register-form .btn { height: 52px; line-height: 52px; padding: 0; }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 768px) and (max-width: 991.95px) { 

  .os-btn-ico.text-white.me-4{padding: 7px 11px; border-radius: 10px;}
  .wow { animation-name: none!important; visibility: visible !important; }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/ 

  .mt-100 { margin-top: 80px; }
  .mb-100 { margin-bottom: 80px; }

  .wide-100 { padding-top: 80px; padding-bottom: 80px; }
  .wide-90 { padding-top: 80px; padding-bottom: 70px; }
  .wide-80 { padding-top: 80px; padding-bottom: 60px; }
  .wide-70 { padding-top: 80px; padding-bottom: 50px; }
  .wide-60 { padding-top: 80px; padding-bottom: 40px; }
  .wide-50 { padding-top: 80px; padding-bottom: 30px; }
  .wide-40 { padding-top: 80px; padding-bottom: 20px; }
  .wide-30 { padding-top: 80px; padding-bottom: 10px; }
  .wide-20 { padding-top: 80px; padding-bottom: 0; }

  .mt-inverse-40, .mt-inverse-50 { margin-top: 0; }

  .pt-100 { padding-top: 80px; }
  .pt-90 { padding-top: 70px; } 
  .pt-80 { padding-top: 60px; } 
  .pt-70 { padding-top: 50px; } 
  .pt-60 { padding-top: 40px; } 
  .pt-50 { padding-top: 30px; } 
  .pt-40 { padding-top: 20px; } 
  .pt-30 { padding-top: 10px; }

  .pb-200 { padding-bottom: 180px; }
  .pb-190 { padding-bottom: 170px; }
  .pb-180 { padding-bottom: 160px; }
  .pb-170 { padding-bottom: 150px; }
  .pb-160 { padding-bottom: 140px; }
  .pb-150 { padding-bottom: 130px; }
  .pb-100 { padding-bottom: 80px; } 
  .pb-90 { padding-bottom: 70px; } 
  .pb-80 { padding-bottom: 60px; } 
  .pb-70 { padding-bottom: 50px; } 
  .pb-60 { padding-bottom: 40px; } 
  .pb-50 { padding-bottom: 30px; } 
  .pb-40 { padding-bottom: 20px; } 
  .pb-30 { padding-bottom: 10px; } 

  .pl-5, .pl-10, .pl-15, .pl-20, .pl-25, .pl-30, .pl-35, .pl-40, .pl-45, .pl-50, .pl-55, .pl-60 { padding-left: 0; }
  .pr-5, .pr-10, .pr-15, .pr-20, .pr-25, .pr-30, .pr-35, .pr-40, .pr-45, .pr-50, .pr-55, .pr-60 { padding-right: 0; }
  .pc-10, .pc-15, .pc-20, .pc-25, .pc-30, .pc-35, .pc-40, .pc-45, .pc-50, .pc-55, .pc-60 { padding-left: 0; padding-right: 0; }

  .bg-scroll, .bg-01, .bg-02, .bg-03, .bg-04, .bg-05, .bg-06, .bg-07, .bg-08 { background-attachment: scroll!important; }

  /* Header H6 */
  h6.h6-xs { font-size: 1rem; }        
  h6.h6-sm { font-size: 1.05rem; } 
  h6.h6-md { font-size: 1.07rem; }   
  h6.h6-lg { font-size: 1.1rem; }  
  h6.h6-xl { font-size: 1.125rem; }   

  /* Header H5 */
  h5.h5-xs { font-size: 1.15rem; }  
  h5.h5-sm { font-size: 1.175rem; } 
  h5.h5-md { font-size: 1.2rem; }  
  h5.h5-lg { font-size: 1.25rem; }  
  h5.h5-xl { font-size: 1.3rem; }  

  .rtl-direction h5.h5-xs { font-size: 1.4rem; }   
  .rtl-direction h5.h5-sm { font-size: 1.4rem; }  
  .rtl-direction h5.h5-md { font-size: 1.4rem; }   
  .rtl-direction h5.h5-lg { font-size: 1.4rem; }  
  .rtl-direction h5.h5-xl { font-size: 1.4rem; }  

  /* Header H4 */
  h4.h4-xs { font-size: 1.4rem; } 
  h4.h4-sm { font-size: 1.5rem; }   
  h4.h4-md { font-size: 1.6rem; }
  h4.h4-lg { font-size: 1.7rem; } 
  h4.h4-xl { font-size: 1.8rem; } 

  h3.h3-xs { font-size: 1.9rem; }
  h3.h3-sm { font-size: 2rem; } 
  h3.h3-md { font-size: 2.1rem; } 
  h3.h3-lg { font-size: 2.2rem; }
  h3.h3-xl { font-size: 2.25rem; } 

  /* Header H2 */
  h2.h2-xs { font-size: 2.35rem; }
  h2.h2-sm { font-size: 2.45rem; } 
  h2.h2-md { font-size: 2.55rem; }  
  h2.h2-lg { font-size: 2.65rem; } 
  h2.h2-xl { font-size: 2.85rem; } 

  h2.h2-title-xs { font-size: 3.05rem; } 
  h2.h2-title-sm { font-size: 3.3rem; } 
  h2.h2-title-md { font-size: 3.5rem; } 
  h2.h2-title-lg { font-size: 3.75rem; }  
  h2.h2-title-xl { font-size: 4rem; }

  /* Paragraphs */
  p { font-size: 0.95rem; }  
  p.p-md { font-size: 1rem; }   
  p.p-lg { font-size: 1.05rem; }  
  p.p-xl { font-size: 1.05rem; }

  p.p-title-xs { font-size: 1.1rem; } 
  p.p-title-sm { font-size: 1.125rem; }  
  p.p-title-md { font-size: 1.15rem; }  
  p.p-title-lg { font-size: 1.175rem; }   
  p.p-title-xl { font-size: 1.2rem; } 

  .rtl-direction p.p-md { font-size: 1.125rem; }  
  .rtl-direction p.p-lg { font-size: 1.125rem; } 
  .rtl-direction p.p-xl { font-size: 1.15rem; } 

  /* Buttons */
  .btn { font-size: 1rem; padding: 12px 30px; }
  .btn.btn-md, .btn.btn-lg { font-size: 1.05rem; padding: 13px 30px; }
  .btn-transparent { padding: 12px 6px; }
  .btn.btn-md.btn-transparent, .btn.btn-lg.btn-transparent { padding: 13px 6px; }

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.875rem; }
  .btn.ico-20.ico-left span { top: 3px;right: 5px; }

  /* Watch Video Link */
  .watch-video-link { width: 50px; height: 50px; margin-right: 12px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 48px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 2px; }

  /* Video Popup Icon */
  .video-btn-xl { width: 100px; height: 100px; margin-top: -50px; margin-left: -50px; }
  .video-btn-lg { width: 86px; height: 86px; margin-top: -43px; margin-left: -43px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, 
  .video-btn.video-btn-xl [class^="flaticon-"]:after {line-height: 100px!important; margin-left: 8px; }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, 
  .video-btn.video-btn-lg [class^="flaticon-"]:after {line-height: 86px!important; margin-left: 7px; }

  /* Store Badges */
  .store img { max-height: 45px; }
  .footer .store img { max-height: 45px; }
  .store.header-store img { max-height: 48px; }
  span.os-version { font-size: 0.95rem; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.875rem; }   
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1rem; } 
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.25rem; }  
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.45rem; } 
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.65rem; } 
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 1.9rem; } 
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.15rem; }
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.35rem; } 
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 2.6rem; } 
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 2.81rem; }
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.05rem; } 
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.3rem; } 
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 3.5rem; } 
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 3.75rem; } 
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4rem; }  
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.25rem; } 
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 4.45rem; } 
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 4.7rem; }  
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 4.95rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 5.15rem; }  
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 5.4rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 5.65rem; }  
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 5.9rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 70px; height: 70px; }
  .ico-rounded-sm { width: 75px; height: 75px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 85px; height: 85px; }
  .ico-rounded-xl { width: 90px; height: 90px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 70px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 75px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 80px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 85px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 90px!important; }

  /* PNG Icons */
  .ico-125 img { width: 94px; height: 94px; }
  .ico-120 img { width: 90px; height: 90px; }
  .ico-115 img { width: 86px; height: 86px; }
  .ico-110 img { width: 82px; height: 82px; }
  .ico-105 img { width: 80px; height: 80px; }
  .ico-100 img { width: 75px; height: 75px; }
  .ico-95 img { width: 71px; height: 71px; }
  .ico-90 img { width: 68px; height: 68px; }
  .ico-85 img { width: 64px; height: 64px; }
  .ico-80 img { width: 60px; height: 60px; }
  .ico-75 img { width: 56px; height: 56px; }
  .ico-70 img { width: 53px; height: 53px; }
  .ico-65 img { width: 49px; height: 49px; }
  .ico-60 img { width: 45px; height: 45px; }
  .ico-55 img { width: 42px; height: 42px; }
  .ico-50 img { width: 38px; height: 38px; }
  .ico-45 img { width: 34px; height: 34px; }
  .ico-40 img { width: 30px; height: 30px; }
  .ico-35 img { width: 26px; height: 26px; }
  .ico-30 img { width: 23px; height: 23px; }
  .ico-25 img { width: 20px; height: 20px; }
  .ico-20 img { width: 16px; height: 16px; }

  /* Section Id */
  .section-id { font-size: 1.05rem; margin-bottom: 25px; }
  .section-id.txt-upcase { font-size: 0.85rem; }

  /* Section Title */
  .title-04 { padding-right: 3%; }
  .title-01 p { padding: 0 6%; margin-top: 15px; }
  .title-02 p { padding: 0; margin-top: 15px; }

  .section-title.ico-35 [class*="flaticon-"]:before, .section-title.ico-35 [class*="flaticon-"]:after { font-size: 1.5rem; } 

  .section-title.mb-40 { margin-bottom: 40px; }
  .section-title.mb-50 { margin-bottom: 45px; }
  .section-title.mb-55 { margin-bottom: 50px; }
  .section-title.mb-60 { margin-bottom: 55px; }
  .section-title.mb-65 { margin-bottom: 55px; }
  .section-title.mb-70 { margin-bottom: 60px; }
  .section-title.mb-75 { margin-bottom: 65px; }
  .section-title.mb-80 { margin-bottom: 70px; }
  .section-title.mb-85 { margin-bottom: 75px; }

  /* Text Block */
  .tabs-section .txt-block.left-column, 
  .content-section .txt-block.left-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }
  .tabs-section .txt-block.right-column,
  .content-section .txt-block.right-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }
  .content-5.content-section .txt-block.left-column {padding-left: 0; }
  .content-5.content-section .txt-block.right-column { padding-right: 0; }
  .tabs-section .img-block.left-column, .content-section .img-block.left-column { padding-left: 0; padding-right: 10px; }
  .tabs-section .img-block.right-column, .content-section .img-block.right-column { padding-left: 10px; padding-right: 0; }

  /* Text Block Typography */
  .txt-block h3, .txt-block h2, .content-14-txt h4, .content-14-txt h3 { margin-bottom: 20px; }
  h5.sm-title { margin-top: 16px; margin-bottom: 16px; }
  .txt-block .btn, .content-14-txt .btn, .txt-block .stores-badge { margin-top: 15px; }

  /* Text Block Rating */
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating, .stars-rating p { font-size: 0.95rem; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .rtl-direction .header .wsmobileheader { text-align: left!important; }
  .rtl-direction .wsmenu > .wsmenu-list > li { float: none; }

  .headtoppart { display: none; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: #fff; }
  .rtl-direction .tra-menu .wsmobileheader, .rtl-direction .white-menu .wsmobileheader { background-color: #fff; }
  .dark-menu .wsmobileheader { background-color: #222227; }

  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after { background: #eee; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li.mg_link:hover > a:after { display: none; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }

  .wsmenu > .wsmenu-list > li > a { font-size: 19px; padding: 7px 32px 6px 20px; margin: 0; }
  .rtl-direction .wsmenu > .wsmenu-list > li > a { font-size: 19px; padding: 7px 42px 6px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a, .dark-menu .wsmenu > .wsmenu-list > li > a { color: #666; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { margin: 23px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 28px; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .link-list > li > a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .link-list > li > a:before { font-size: 1.05rem; left: 5px; top: 14px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .link-list > li > a:hover:before, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .link-list > li > a:hover:before { left: 17px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 0; }
  .rtl-direction .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 22%; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 100%!important; }
  .rtl-direction .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 100%!important; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { color: #666; font-size: 18px; padding: 12px 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { 
    background-color: transparent; 
    padding: 12px 0 12px 8px; 
    text-decoration: none; 
  }

  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li.fst-li > a { padding: 0 0 12px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover { padding: 12px 0 12px 4px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li.fst-li a:hover {padding: 0 0 12px 4px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .title p,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title p { display: block; font-size: 18px; margin: 0 0 5px 0; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .title { font-size: 20px; padding: 0; }

  .wsmenu > .wsmenu-list > li.header-phone > a { font-size: 19px; padding: 7px 32px 6px 15px; margin: 0; }
  .wsmenu > .wsmenu-list > li.header-phone > a span { width: 24px; height: 24px;line-height: 25px;top: 3px; }

  .wsmenu > .wsmenu-list > li a.btn { 
    height: 64px; 
    font-size: 19px;
    line-height: 60px; 
    font-weight: 500;
    padding: 0 17px; 
    margin: 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li a.header-store { margin: 25px 0 0 12px; display: block;float: none; border-bottom: none; }

  .wsmenu > .wsmenu-list > li.header-socials { display: block; margin-top: 5px; }
  .white-color.header-socials span { color: #463853!important; }
  .header-socials a { line-height: 90px!important; margin-left: 25px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.6rem; } 

  .wsmenu > .wsmenu-list > li a.btn.btn-tra-white { background-color: #eee; border-color: #eee; color: #666; }
  .wsmobileheader .smllogo { display: block; margin-top: 19px; padding-left: 22px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 40px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 5px; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu:before, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu:before { display: none; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  /*------------------------------------------*/
  /*  HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  /* Hero Brands */
  .hero-brands {margin-top: 40px; padding: 0 5%; } 
  .hero-brands p {margin-bottom: 30px;}
  .hero-brands .brand-logo {padding: 0 15px; }

  /* Hero-1 */
  #hero-1 { padding-top: 70px; padding-bottom: 30px; }
  .hero-1-txt { margin-right: -30px; }
  .hero-1-txt h2 { margin-bottom: 25px; }
  .hero-1-txt p.p-xl { font-size: 1.15rem; padding-right: 0; margin-bottom: 35px; }
  .hero-1-img { margin-right: -60%; padding-left: 10px; margin-bottom: -40px; }

  /* Hero-2 */
  #hero-2 { padding-top: 80px; margin-bottom: 50px; }
  .hero-2-txt { padding-left: 10px; }
  .hero-2-txt p.p-xl { font-size: 1.05rem; padding-right: 0; margin-bottom: 35px; }
  .hero-2-img { margin-left: -55%; padding: 0 10px 0 0; }

  /* Hero-3 */
  #hero-3 { padding-top: 80px; padding-bottom: 0; margin-bottom: 40px; }
  .hero-3-txt { text-align: center; margin-top: 0; padding: 0 12%; }
  .hero-3-txt h2 { padding: 0 6%; margin-bottom: 25px; }
  .hero-3-txt p.p-xl { padding: 0 4%; margin-bottom: 35px; }
  .hero-3-img { margin: 40px 0 -50px; }

  /* Hero-4 */
  #hero-4 { padding-top: 80px; padding-bottom: 40px; }
  .hero-4-txt { padding-left: 0; }
  #hero-4-form { padding: 35px 25px; margin-left: 20px; margin-right: 0; }
  #hero-4-form p { margin-bottom: 20px; }
  #hero-4-form .form-btn.mt-10 { margin-top: 0; }

  /* Hero-5 */
  #hero-5 { padding-top: 60px; }
  .hero-5-txt { padding: 0 5%; }
  .hero-5-img { margin-right: -55%; }

  /* Hero-6 */
  #hero-6 { padding-top: 80px; padding-bottom: 0; margin-bottom: 80px; }
  .hero-6-txt { text-align: center; padding: 0 5%; }
  .hero-6-txt h2 { margin-bottom: 25px; }
  #hero-6 .quick-form { margin: 40px 8% 50px; }
  #hero-6 .quick-form .btn { padding: 13px 50px; }
  .hero-6-img { margin-bottom: -80px; padding: 0 10%; }

  /* Hero-7 */
  #hero-7 { padding-top: 80px; }
  .hero-7-txt { text-align: center;  margin-top: 0; padding: 0; }
  .hero-7-txt ul.simple-list { list-style: none; margin-left: 0; padding: 0 10%; }
  .hero-7-img { margin: 50px 0 -60px; }
  .hero-7-img img { max-width: 100%; display: block; }

  /* Hero-8 */
  #hero-8 { padding-top: 80px; padding-bottom: 50px; margin-bottom: 50px; }
  .hero-8-txt { text-align: center; padding: 0 10%; }
  .hero-8-img { padding: 0 10%; margin-bottom: -100px; }
  .hero-8-txt h2 { margin-bottom: 25px; }
  .hero-8-txt p { padding: 0 6%; }
  #hero-8 .quick-form { margin: 40px 4% 60px; }
  #hero-8 .quick-form .btn { padding: 13px 50px; }

  /* Hero-9 */
  #hero-9 { padding-top: 80px; padding-bottom: 40px; }
  #hero-9-form { padding: 40px 30px; margin-left: 30px; margin-right:0; }
  #hero-9-form h4.h4-xs { font-size: 1.65rem; }

  /* Hero-10 */
  #hero-10 { padding-top: 80px; }
  .hero-10-txt { padding: 0 5%; }
  .hero-10-txt p { margin-bottom: 30px; }
  .hero-10-img { padding: 0; margin-top: 50px; }

  /* Hero-11 */
  #hero-11 { padding-top: 80px; }
  .hero-11-txt { padding: 0; }
  .hero-11-img { margin-left: -55%; padding: 0 10px 0 0; }

  /* Hero-12 */
  #hero-12 { padding-top: 80px; }
  .hero-12-txt { text-align: center; padding: 0 10%; }
  .hero-12-txt h2 { margin-bottom: 25px; }
  .hero-12-txt p { font-size: 1.175rem; padding-right: 0; margin-bottom: 0; }
  #hero-12:after { height: 35%; top: 65%; }
  .hero-12-img { margin-top: 40px; }

   /* Hero-13 */
   #hero-13 { padding-top: 80px; margin-bottom: 30px; }
   .hero-13-title { margin-bottom: 15px; }
  .hero-13-txt { padding: 0; margin-top: 15px; }
  .hero-section .tools-list.mt-50 { margin-top: 25px; }
  .hero-section .tools-list h6 { margin-bottom: 15px; }
  .hero-13-img { margin: 0 -35% -30px -35px; }

  /* Hero-14 */
  #hero-14 { padding-top: 80px; padding-bottom: 80px; }
  .hero-14-txt { padding-left: 0; }
  .hero-14-txt h2 { margin-bottom: 15px; }
  .hero-14-txt p.p-xl { margin-bottom: 25px; }
  .hero-14-img { margin: 0 -70% -100px -10%; }

  /* Hero-16 */
  #hero-16 { padding-top: 80px; }
  .hero-16-txt { padding: 0; }
  .hero-16-img { margin-left: -55%; padding: 0 10px 0 0; }

  /* Hero-17 */
  #hero-17 { padding-top: 80px; }
  .hero-17-title.mb-50 { margin-bottom: 40px; }
  .hero-17-txt { margin-top: 40px; padding-right: 15px; }
  .hero-17-img { margin-bottom: -80px; }

  /* Hero-15 */
  #hero-15 { padding-top: 80px; }
  .hero-15-txt { padding: 0 5%; }
  .hero-15-txt h2 { margin-bottom: 25px; }
  .hero-15-img { padding: 0; margin-top: 45px; margin-bottom: -15%; }

  /* Hero-18 */
  #hero-18 { padding-top: 80px; padding-bottom: 20px; }
  #hero-18:after { width: 100%; left: 0; } 
  #hero-18-form { padding: 0 30px 0 0; }
  #hero-18-form p.p-lg { margin-top: 15px; margin-bottom: 25px; }
  #hero-18-form .form-btn { margin-top: 5px; }
  #hero-18-form .advantages { margin-top: 10px; }
  .hero-18-img { padding-left: 0; margin-right: -50%; }

  /* Hero-19 */
  #hero-19 { padding-top: 80px; }
  .hero-19-txt { margin-top: 0; }
  .hero-19-txt p.p-xl { margin-bottom: 35px; }
  .hero-19-img { margin-left: 25px; margin-bottom: -100px; }

  /* Hero-20 */
  #hero-20 { background-position: left center; padding-top: 80px; padding-bottom: 80px; }
  .hero-20-txt { padding-right: 10%; }
  .hero-20-txt h2 { margin-bottom: 20px; }
  .hero-20-txt p { padding-right: 0; margin-bottom: 30px; }

  /* Hero-21 */
  #hero-21 { padding-top: 80px; }
  .hero-21-txt p.p-xl { padding: 0; }

  #hero-21 .quick-form { margin: 40px 0 0; }

  /* Hero-22 */
  #hero-22 .txt-block { margin-top: -30px; }
  #hero-22 .img-block { margin-right: -60%; }
  .hero-22-wrapper { padding: 60px 50px 20px; }
  .hero-22-wrapper .img-block { margin-left: -20px; }

  /* Hero-23 */
  #hero-23 { padding-top: 80px; padding-bottom: 0; margin-bottom: 60px; }
  .hero-23-txt { text-align: center; margin-bottom: 50px; }
  .hero-23-txt p.p-xl { font-size: 1.175rem; padding: 0 10%; margin-bottom: 25px; }
  .hero-23-img { margin: 0 5% -60px; }

  /* Hero-24 */
  .hero-24-txt { padding-top: 80px; padding-bottom: 0; }
  .hero-24-txt h5 { padding: 0 5%; margin-bottom: 20px; }
  .hero-24-txt h2.tra-header { top: -20px; left: -15%; font-size: 17rem; }
  .hero-24-img img { padding: 0 15%; }

  /* Hero Request Form */
  .request-form .form-control { height: 50px; font-size: 17px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-1 .txt-block p { padding-right: 0; }
  #about-2 { margin-bottom: 100px; }
  .about-2-title { padding-right: 0; }
  .about-2-title p { margin-top: 20px; padding-right: 0; }

  .about-2-images { margin-top: 40px; margin-bottom: -100px; }
  .about-2-images img { max-height: 254.5px; }

  /* Features */
  #features-7 { padding-bottom: 50px; }
  .fbox-2-wrapper .col, .fbox-6-wrapper .col, .fbox-8-wrapper .col { padding: 0 8px; }
  .fbox-5-wrapper .col { padding: 0; }
  .fbox-7-wrapper { padding-right: 0; }
  #features-7 .txt-block { text-align: center; padding: 0; margin-bottom: 40px; }
  .rtl-direction #features-7 .txt-block { text-align: right; }
  #features-7 .txt-block p { padding: 0 8%;  }
  .rtl-direction #features-7 .txt-block p { padding: 0;  }
  .fbox-1 { padding: 0 10%; }
  .fbox-3 { padding: 0 5%; }
  .fbox-4 { padding: 0 4%; }
  .rtl-direction .fbox-4 { padding: 0; }
  .fbox-5 { padding: 35px 20px; }
  .fbox-6 { text-align: center; padding: 35px 15px; }
  .rtl-direction .fbox-6 { text-align: right; padding: 35px 20px; }
  #fb-6-2 { margin-bottom: 16px; } 
  .fbox-7 { text-align: center; padding: 45px 25px; } 
  .rtl-direction .fbox-7 { text-align: right; padding: 40px 40px; }
  .fbox-7.mt-40 { margin-top: 0; }
  #fb-7-1, #fb-7-2, #fb-7-3, #fb-7-4 { margin-bottom: 30px; }

  .fbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 20px; }
  .rtl-direction .fbox-txt { width: 100%; text-align: right; padding: 0; margin-top: 10px; }
  .fbox-ico { text-align: center; width: 100%; float: none; margin-top: 0; }
  .fbox-img { margin-left: 8%; margin-right: 8%; padding: 40px 10% 45px; }

  /* Content */
  #content-4 { padding-top: 60px; }
  #content-5.mb-40 { margin-bottom: 0; }
  .content-5 { padding-top: 80px; padding-bottom: 40px; }
  .content-5-wrapper { padding: 0; }
  .content-5.ws-wrapper { background-color: #f4f4f9; }
  .content-5.lg-wrapper { background-color: #f6f7f8; }
  .content-5.snow-wrapper { background-color: #fbfbfd; }
  .content-7 .txt-block { margin-bottom: 40px; }

  .content-8 .section-title.mb-75 { margin-bottom: 60px; }

  .cbox.mb-40 { margin-bottom: 30px; }
  .cbox-2 { width: 320px; min-width: 320px; padding: 20px 20px; }
  .cbox-2.mt-20.mb-30 { margin-top: 0; }
  .cbox-txt { padding-left: 20px; }
  .rtl-direction .cbox-txt { padding-right: 20px; }

  .cbox-4.left-column, .cbox-4.right-column { bottom: -10px; right: 20px; width: 55%; padding: 20px 15px; }
  .cbox-4.right-column { left: 20px; right:  0; }
  .cbox-5 { top: -40px; left: 35%; width: 58%; padding: 20px 15px; }
  .cbox-6 { bottom: -10px; right: 15px; width: 56%; padding: 20px 15px; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1.1rem; padding-right: 12%; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.95rem; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 15px; margin-bottom: 15px; }
  .cbox-4 .btn, .cbox-5 .btn, .cbox-6 .btn { font-size: 0.95rem; padding: 11px 30px; margin-bottom: 8px; }

  .cb-wrapper { margin: 0 10% 70px; }
  .slim-column { margin: 0 6%; }
  .cb-holder { padding: 40px 30px; }
  .statistic-number sup { font-size: 2.5rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: 15px; top: -20px; }
  .cb-shape-2 { left: 10px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 12%; }

  .content-10-btn { margin-top: 50px; }

  .content-4-img.left-column { margin-left: -55%; }
  .content-section .img-block.right-column, .content-4-img.right-column { margin-right: -55%; }

  .content-4-img { margin-top: -60px; }
  .content-7-img { margin-left: 20px; margin-right: -140%; }
  .content-7-img img { max-width: 100%; }
  .content-8-img { margin-bottom: -80px; }

  /* Projects */
  #projects-2 { padding-bottom: 40px; }
  #projects-2 .masonry-image { width: 50%; padding: 0 8px; margin-bottom: 40px; }
  #projects-1 .project-txt { padding-left: 4%; padding-right: 0; }
  .more-projects { margin-top: 50px; }

  .rtl-direction #projects-1 .project-txt { padding-left: 0; padding-right: 4%; }
  .projects-section .more-btn { margin-top: 10px; }

  /* Tabs */
  #tabs-1 .tabs-1 {  margin: 0 auto 40px; }
  #tabs-2 { padding-bottom: 0; }
  #tabs-2 .tabs-nav.pc-15 { padding: 0; }
  .tabs-1 { margin: 0 auto 50px; }
  .tabs-1 li { padding: 9px 30px; margin: 0 5px; }
  #tabs-2 .tabs-1 li { padding: 30px 20px; }

  .tabs-1 li span { font-size: 1rem; }

  .tabs-section .img-block.left-column { margin-left: -60%; padding-right: 20px; }
  .tabs-section .img-block.right-column { margin-right: -60%; padding-left: 20px; }

  /* Statistic */
  #statistic-1.pt-70.pb-70 { padding-top: 60px; padding-bottom: 60px; }
  #statistic-2 { text-align: center; padding-bottom: 80px; }
  .rtl-direction #statistic-2 { text-align: right; }
  #statistic-2 .txt-block { padding: 0 8%; margin-bottom: 40px; }
  .rtl-direction #statistic-2 .txt-block { padding: 0; margin-bottom: 40px; }
  #statistic-2 h2 { font-size: 3.5rem; }
  #statistic-2 .txt-block h4 { font-size: 1.85rem; }
  .statistic-1-wrapper .statistic-block { padding: 10px; }

  #statistic-4 .statistic-block { display: block!important; }
  .statistic-block-txt { width: 100%; display: inline-block!important; padding-left: 0; padding-right: 5%; }
  .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 10px; }

  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 0; }
  .rtl-direction .statistic-block-txt { width: 100%; padding-left: 0; padding-right: 0; }
  .rtl-direction .statistic-block-digit { width: 100%; float: none; }

  .rtl-direction #statistic-4 .statistic-block-txt { margin-bottom: 15px; }

  /* Reviews */
  .reviews-4-holder { padding-top: 50px; padding-bottom: 40px; }
  #reviews-3 .txt-block { text-align: center; padding: 0 7%; }
  .rtl-direction #reviews-3 .txt-block { text-align: right; padding: 0 5%; }

  .review-2 { padding: 35px 40px; margin-left: 5%; margin-right: 5%; }  
  .review-4 { padding: 35px 30px; }

  #rw-2-1, #rw-2-2 { margin-bottom: 40px; }
  .right-column #rw-3-1, .right-column #rw-3-2, .right-column #rw-3-3,
  .left-column #rw-3-1, .left-column #rw-3-2, .left-column #rw-3-3 { margin-right: 5%; margin-left: 5%; }

  .review-2 .review-avatar img, .review-4 .review-avatar img { width: 60px; height: 60px; }

  .review-3-txt { padding-left: 0; }
  .review-3-txt p { padding-right: 0; }

  /* Pricing */
  .pricing-1-row .col, .pricing-2-row .col, .pricing-3-row .col { padding-left: 8px; padding-right: 8px; }

  .pricing-1-table { padding: 40px 20px 35px; }
  .pricing-2-table { padding: 30px 20px 35px; }
  .pricing-3-table { padding: 45px 18px; }

  .pricing-plan-title h6 { font-size: 0.95rem; right: -5px; }
  .pricing-1-table .pricing-plan h5 { margin-bottom: 20px; }
  .pricing-3-table .pricing-plan h6 { margin-bottom: 25px; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 0.925rem; }

  .rtl-direction .pricing-plan-title h6 {width: auto; right: 50%;}

  .pricing-plan span { font-size: 2.5rem; }
  .pricing-3-table .pricing-plan span { font-size: 3.75rem; }
  .pricing-plan sup { font-size: 1.7rem; top: -4px; }
  .pricing-3-table .pricing-plan sup { font-size: 2.2rem; top: -6px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.2rem; top: -18px; }
  .pricing-plan sup.validity span { font-size: 2.1rem; }
  .pricing-plan sup.validity { font-size: 1rem; }

  .pricing-1-table .pricing-plan p { padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { margin-top: 10px; }

  .badge-wrapper { top: -17px; }
  .highlight-badge { padding: 9px 32px; }

  .payment-icons li { padding-right: 2px; }
  .payment-icons.ico-50 img { width: auto;  max-width: inherit; max-height: 31px; }

  .pricing-notice p { padding: 0; }

  .pricing-compare { margin-top: 40px; }
  .pricing-compare .table-responsive { margin-top: 40px; margin-bottom: 25px; }

  /* Brands */
  .brands-2-wrapper { padding: 0; }
  .brands-2-wrapper .col { padding: 0 8px; }

  .brands-section h4 { padding: 0; margin-bottom: 35px; }
  .brands-section p { padding: 0 10%; margin-bottom: 35px; }
  #brands-1 .brand-logo { padding: 0 15px; }
  #brands-2 .brand-logo { padding: 0; }

  /* FAQs */
  #faqs-2 .question { margin-bottom: 30px; }
  .accordion-item .accordion-thumb:after, 
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.75rem; top: 25px; right: 5px; }

  /* Call To Action */
  .cta-section .text-end { text-align: center!important; }
  .cta-section.pb-20, .cta-section.pb-30 { padding-bottom: 0; }
  #cta-2 { text-align: center; }
  #cta-3 { margin-top: 120px; }
  #cta-4 { margin-bottom: 120px; }
  .cta-3-wrapper { margin-top: -130px; }
  .cta-4-wrapper { margin-bottom: -130px; }
  #cta-4.mb-20 { margin-bottom: 0; }
  #cta-10 { padding-top: 80px; padding-bottom: 80px; }
  .cta-1-wrapper { text-align: center; padding: 40px 15%; }
  .cta-1-wrapper { text-align: center;  padding: 50px 15%; }
  .cta-3-wrapper { text-align: center; padding: 40px 15%; }
  .cta-4-wrapper { text-align: center; padding: 40px 8%; }
  .cta-5-wrapper { padding: 50px 50px; }
  .cta-7-wrapper { text-align: center; padding: 40px 10%; margin: 0; }
  .cta-11-wrapper { text-align: center; padding: 70px 60px; }

  .cta-6-txt { padding: 0 10%; }
  .cta-10-txt { text-align: center; padding: 0 12%; margin-bottom: 50px; }
  .cta-11-txt { margin-bottom: 40px; }

  .cta-2-txt p { padding: 0 5%; }

  .cta-section .stores-badge { margin-top: 25px; }
  .cta-1-btn, .cta-3-btn, .cta-4-btn, .cta-7-btn { margin-top: 20px; }
  .cta-2-btn { margin-top: 25px; }
  .os-btn {width: 310px; min-width: 310px; padding: 25px 20px; }

  .rtl-direction .cta-section .text-start { text-align: center!important; }

  /* Newsletter */
  #newsletter-1.pb-20, #newsletter-1.pb-30 { padding-bottom: 0; }
  #newsletter-1 .container { padding-left: 15px; padding-right: 15px; }
  #newsletter-1 { text-align: center; }
  #newsletter-2 { text-align: center; padding-top: 50px; }
  .newsletter-wrapper { padding: 50px 30px 30px; }
  #newsletter-1 .newsletter-txt { padding: 0; }
  #newsletter-1 .newsletter-form { margin-top: 30px; }
  .newsletter-section .newsletter-form, #newsletter-2 .newsletter-form { padding: 0 10%; margin-top: 35px; }

  .newsletter-section .form-control { height: 50px; font-size: 1.1rem; }
  .newsletter-section .btn { height: 50px; }

  /* Blog */
  #bp-1-3, #bp-2-3 { display: none; }
  #blog-page .masonry-image { width: 50%; padding: 0 8px; }

  .blog-1-post .blog-post-txt { margin-top: 30px; padding-left: 10px; padding-right: 0; }
  .blog-2-post .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-wide .blog-post-txt { margin-top: 0; }

  .rtl-direction .blog-1-post .blog-post-txt { padding-left: 0; padding-right: 10px; }
  .rtl-direction .blog-2-post .blog-post-txt { padding-left: 0; padding-right: 5%; }
  .rtl-direction #blog-page .blog-post-txt { padding-left: 0; padding-right: 5%; }

  /* Blog Listing */
  .posts-wrapper { padding: 0; }
  .featured-post { margin: 0 0 40px 0; }

  #blog-page .title-02.mb-85 { margin-bottom: 70px; }
  #blog-page .blog-post-wide .blog-post-txt { padding: 40px 60px; }

  /* Single Post */
  #post-comments { padding-bottom: 70px; }
  .single-post-title, .single-post-txt, .post-share-links, .comments-wrapper { padding: 0; }
  .about-post-author { padding: 80px 0; }

  #single-post p.post-tag { margin-bottom: 30px;} 
  #single-post .post-data { margin-top: 30px; margin-bottom: 30px; }
  #single-post .post-author-avatar img { width: 54px; height: 54px; }

  .post-inner-img { margin-top: 50px; margin-bottom: 45px; }
  .author-senoff img { width: 80px; height: 80px; }
  .author-senoff-txt { padding-left: 30px; }
  .author-senoff-txt p { padding-right: 0; }

  .post-comments img { width: 60px; height: 60px; }
  .comment-body { margin-left: 20px; }

  .comment-form { margin-top: 50px; }
  .comment-form .form-control { height: 52px; font-size: 1rem; }

  /* Contacts */
  #contacts-2 .title-01 h3 { padding: 0 5%; }
  #contacts-1-form { padding: 45px 30px 35px; margin: 0 10px 40px; }
  .contact-form .form-control, .contact-form .form-select { height: 56px; font-size: 1rem; }
  #contacts-2 .contact-form span { font-size: 16px; }

  /* Footer */
  .footer { padding-top: 80px; }
  .footer-info { margin-bottom: 50px; }
  img.footer-logo { max-height: 38px; }
  .footer-info p { padding-right: 10%; }
  #footer-1 .footer-links { padding-left: 0; }
  .rtl-direction .footer-links { padding-right: 0; }

  .footer p.p-md { font-size: 1.05rem; } 
  .bottom-footer p { font-size: 1rem; } 

  #footer-2 .footer-form, #footer-4 .footer-form { margin-top: 20px; }

  /* Inner Page Hero */
  .inner-page-hero { margin-top: 80px; padding-top: 80px; }
  .page-hero-section { margin-top: 80px; padding-top: 70px; padding-bottom: 70px; }
  .page-hero-section h3 { padding: 0 10%; }
  .page-hero-section p { padding: 0 15%; margin-top: 15px; }

  /* Terms Page */
  .terms-title { margin-bottom: 50px; }
  .terms-box.mt-60 { margin-top: 50px; }
  .terms-box, .terms-box h5 {  margin-bottom: 25px; }

  /* Register Page */
  #login-1, #signup-1, #login-2, #signup-2, #login-3, #signup-3, #reset-password-1, #reset-password-2 { padding-top: 80px; padding-bottom: 50px; }
  #login-3 { padding-top: 80px; }

  .reg-nav .wsmobileheader { display: none; }

  #reset-password-2.reset-password-section .register-form-title h4 { font-size: 1.6rem; }
  #reset-password-2.reset-password-section .register-form-title p { font-size: 1rem; padding: 0; }

  .login-page-logo { margin-bottom: 50px; }
  .login-page-logo img { max-height: 45px; }

  #reset-password-2.reset-password-section .form-data {margin-top: 25px; }
  .form-check .form-check-label, p.forgot-password { font-size: 1rem; }

  .register-form p.create-account { font-size: 1rem; margin-top: 35px; }

  .sign-in-notice { padding: 0 12%; margin-top: 40px; }
  .sign-in-notice p { font-size: 1rem; }

  #reset-password-1 .sign-in-footer { margin-top: 40px; }
  #reset-password-2 .sign-in-footer { margin-top: 30px; }

  .login-separator-txt:before { left: -780%; }
  .login-separator-txt:after { right: -780%; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -900%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -900%; }

  #login-3 .login-separator-txt:before { left: -970%; }
  #login-3 .login-separator-txt:after { right: -970%; }

  #signup-3 .login-separator-txt:before { left: -970%; }
  #signup-3 .login-separator-txt:after { right: -970%; }

  #login-3 .register-form, #signup-3 .register-form { padding: 0 15%; }
  .register-form form, #login-2 .register-form form, #signup-2 .register-form form, #reset-password-2 .register-form form { margin: 0; }
  .register-form .btn { height: 56px; font-size: 1.05rem; }

  #reset-password-2 .register-form .form-control { height: 50px; }
  #reset-password-2 .register-form .btn { height: 50px; line-height: 50px; padding: 0; }

  .register-form .btn-facebook, .register-form .btn-google { padding: 0; line-height: 56px; }
  .btn-google img, .btn-facebook img { width: 18px; height: 18px; margin-top: -3px; margin-right: 5px; }

  .login-3-img, .signup-3-img { display: none; }
  .reset-password-img { margin-top: 25px; margin-left: -30%; }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 767px) { 

  .os-btn-ico.text-white.os-new-btn-ico{padding: 7px 11px; border-radius: 10px;}
  .wow { animation-name: none !important; visibility: visible !important; }
  .wave-shape-bottom { bottom: -2px; }

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/ 

  .mt-100 { margin-top: 80px; }
  .mb-100 { margin-bottom: 80px; }

  .wide-100 { padding-top: 80px; padding-bottom: 80px; }
  .wide-90 { padding-top: 80px; padding-bottom: 70px; }
  .wide-80 { padding-top: 80px; padding-bottom: 60px; }
  .wide-70 { padding-top: 80px; padding-bottom: 50px; }
  .wide-60 { padding-top: 80px; padding-bottom: 40px; }
  .wide-50 { padding-top: 80px; padding-bottom: 30px; }
  .wide-40 { padding-top: 80px; padding-bottom: 20px; }
  .wide-30 { padding-top: 80px; padding-bottom: 10px; }
  .wide-20 { padding-top: 80px; padding-bottom: 0; }

  .mt-inverse-40, .mt-inverse-50 { margin-top: 0; }

  .pt-100 { padding-top: 80px; }
  .pt-90 { padding-top: 70px; } 
  .pt-80 { padding-top: 60px; } 
  .pt-70 { padding-top: 50px; } 
  .pt-60 { padding-top: 40px; } 
  .pt-50 { padding-top: 30px; } 
  .pt-40 { padding-top: 20px; } 
  .pt-30 { padding-top: 10px; }

  .pb-200 { padding-bottom: 180px; }
  .pb-190 { padding-bottom: 170px; }
  .pb-180 { padding-bottom: 160px; }
  .pb-170 { padding-bottom: 150px; }
  .pb-160 { padding-bottom: 140px; }
  .pb-150 { padding-bottom: 130px; }
  .pb-100 { padding-bottom: 80px; } 
  .pb-90 { padding-bottom: 70px; } 
  .pb-80 { padding-bottom: 60px; } 
  .pb-70 { padding-bottom: 50px; } 
  .pb-60 { padding-bottom: 40px; } 
  .pb-50 { padding-bottom: 30px; } 
  .pb-40 { padding-bottom: 20px; } 
  .pb-30 { padding-bottom: 10px; } 

  .pl-5, .pl-10, .pl-15, .pl-20, .pl-25, .pl-30, .pl-35, .pl-40, .pl-45, .pl-50, .pl-55, .pl-60 { padding-left: 0; }
  .pr-5, .pr-10, .pr-15, .pr-20, .pr-25, .pr-30, .pr-35, .pr-40, .pr-45, .pr-50, .pr-55, .pr-60 { padding-right: 0; }
  .pc-10, .pc-15, .pc-20, .pc-25, .pc-30, .pc-35, .pc-40, .pc-45, .pc-50, .pc-55, .pc-60 { padding-left: 0; padding-right: 0; }

  .bg-scroll, .bg-01, .bg-02, .bg-03, .bg-04, .bg-05, .bg-06, .bg-07, .bg-08 { background-attachment: scroll!important; }

  /* Section Title */
  .section-title.mb-40 { margin-bottom: 35px; }
  .section-title.mb-50 { margin-bottom: 40px; }
  .section-title.mb-55 { margin-bottom: 40px; }
  .section-title.mb-60 { margin-bottom: 40px; }
  .section-title.mb-65 { margin-bottom: 40px; }
  .section-title.mb-70 { margin-bottom: 45px; }
  .section-title.mb-75 { margin-bottom: 45px; }
  .section-title.mb-80 { margin-bottom: 45px; }
  .section-title.mb-85 { margin-bottom: 55px; }

  .title-02 p { margin-top: 15px; }

  /* Text Block */
  .tabs-section .txt-block.left-column, 
  .content-section .txt-block.left-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }
  .tabs-section .txt-block.right-column,
  .content-section .txt-block.right-column { padding-left: 0; padding-right: 0; margin-bottom: 40px; }
  .content-5.content-section .txt-block.left-column {padding-left: 0; }
  .content-5.content-section .txt-block.right-column { padding-right: 0; }
  .tabs-section .img-block.left-column, .content-section .img-block.left-column { padding-left: 0; padding-right: 0; }
  .tabs-section .img-block.right-column, .content-section .img-block.right-column { padding-left: 0; padding-right: 0; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .rtl-direction .header .wsmobileheader { text-align: left!important; }
  .rtl-direction .wsmenu > .wsmenu-list > li { float: none; }

  .headtoppart { display: none; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: #fff; }
  .dark-menu .wsmobileheader { background-color: #222227; }

  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after { background: #eee; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li.mg_link:hover > a:after { display: none; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }

  .wsmenu > .wsmenu-list > li > a { font-size: 19px; padding: 7px 32px 6px 20px; margin: 0; }
  .rtl-direction .wsmenu > .wsmenu-list > li > a { font-size: 19px; padding: 7px 42px 6px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a, .dark-menu .wsmenu > .wsmenu-list > li > a { color: #666; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { margin: 23px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 28px; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .link-list > li > a:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .link-list > li > a:before { font-size: 1.05rem; left: 5px; top: 14px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .link-list > li > a:hover:before, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .link-list > li > a:hover:before { left: 17px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 0; }
  .rtl-direction .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 22%; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 100%!important; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { color: #666; font-size: 18px; padding: 12px 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { 
    background-color: transparent; 
    padding: 12px 0 12px 8px; 
    text-decoration: none; 
  }

  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li.fst-li > a { padding: 0 0 12px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover { padding: 12px 0 12px 4px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li.fst-li a:hover {padding: 0 0 12px 4px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 .title p,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title p { display: block; font-size: 18px; margin: 0 0 5px 0; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .title { font-size: 20px; padding: 0; }

  .wsmenu > .wsmenu-list > li.header-phone > a { font-size: 19px; padding: 7px 32px 6px 15px; margin: 0; }
  .wsmenu > .wsmenu-list > li.header-phone > a span { width: 24px; height: 24px;line-height: 25px;top: 3px; }

  .wsmenu > .wsmenu-list > li a.btn { 
    height: 64px; 
    font-size: 19px;
    line-height: 60px; 
    font-weight: 500;
    padding: 0 17px; 
    margin: 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li a.header-store { margin: 25px 0 0 12px; display: block;float: none; border-bottom: none; }

  .wsmenu > .wsmenu-list > li.header-socials { display: block; margin-top: 5px; }
  .white-color.header-socials span { color: #463853!important; }
  .header-socials a { line-height: 90px!important; margin-left: 25px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.6rem; } 

  .wsmenu > .wsmenu-list > li a.btn.btn-tra-white { background-color: #eee; border-color: #eee; color: #666; }
  .wsmobileheader .smllogo { display: block; margin-top: 19px; padding-left: 22px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 40px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 5px; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu:before, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75:before,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu:before { display: none; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  .hero-section ul.simple-list { list-style: none; margin-left: 0; }
  .hero-logo-rounded.mb-45 { margin-bottom: 30px; }
  .hero-section .watch-video { text-align: left!important; }

  /* Hero-1 */
  #hero-1 { padding-top: 80px; padding-bottom: 0; margin-bottom: 50px; }
  .hero-1-txt { text-align: center; margin-bottom: 40px; }
  .hero-1-txt h2 { margin-bottom: 20px; }
  .hero-1-txt p.p-xl { padding-right: 0; margin-bottom: 30px; }
  .hero-1-img { margin-left: 0; margin-bottom: -50px; }

  /* Hero-2 */
  #hero-2 { padding-top: 80px; margin-bottom: 50px; }
  .hero-2-txt { text-align: center; margin-bottom: 40px; }
  .hero-2-txt p.p-xl { padding: 0; margin-bottom: 35px; }
  .hero-2-img { padding: 0; }

  /* Hero-3 */
  #hero-3 { padding-top: 80px; padding-bottom: 0; margin-bottom: 40px; }
  .hero-3-txt { text-align: center; margin-top: 0; }
  .hero-3-txt h2 { margin-bottom: 25px; }
  .hero-3-txt p { margin-bottom: 35px; }
  .hero-3-img { margin: 40px 0 -50px; }

  /* Hero-4 */
  #hero-4 { padding-top: 80px; padding-bottom: 0; }
  .hero-4-txt { text-align: center; padding-left: 0; margin-bottom: 40px; }
  #hero-4-form { margin-right: 0; }

  /* Hero-5 */
  #hero-5 { padding-top: 60px; }
  .hero-5-txt { padding: 0; margin-top: -200px; }
  .hero-5-img { margin: 0; }

  /* Hero-6 */
  #hero-6 { padding-top: 80px; padding-bottom: 0; margin-bottom: 80px; }
  .hero-6-txt { text-align: center; padding: 0; }
  #hero-6 .quick-form { margin: 40px 5% 60px; }
  #hero-6 .quick-form .btn { padding: 13px 50px; }
  .hero-6-img { margin-bottom: -80px; }

  /* Hero-7 */
  #hero-7 { padding-top: 80px; }
  .hero-7-txt { text-align: center;  margin-top: 0; padding: 0; }
  .hero-7-img { margin: 40px 0 -60px; }
  .hero-7-img img { max-width: 100%; display: block; }

  /* Hero-8 */
  #hero-8 { padding-top: 80px; padding-bottom: 50px; margin-bottom: 50px; }
  .hero-8-txt { text-align: center; padding: 0; }
  .hero-8-img { padding: 0 5%; margin-bottom: -100px; }
  #hero-8 .quick-form { margin: 40px 5% 60px; }
  #hero-8 .quick-form .btn { padding: 13px 50px; }

  /* Hero-9 */
  #hero-9 { padding-top: 80px; padding-bottom: 0; background-position: left center; }
  #hero-9-form { margin-right: 0; }

  /* Hero-10 */
  #hero-10 { padding-top: 80px; margin-bottom: 80px; }
  .hero-10-txt { padding: 0; }
  .hero-10-txt p { margin-bottom: 30px; }
  .hero-10-img { padding: 0; margin: 50px 0 -80px; }

  /* Hero-11 */
  #hero-11 { padding-top: 80px; }
  .hero-11-txt { padding: 0; }

  /* Hero-12 */
  #hero-12 { padding-top: 80px; }
  .hero-12-txt { text-align: center; }
  .hero-12-txt p { padding-right: 0; margin-bottom: 0; }
  .hero-12-img { margin-top: 40px; }

  /* Hero-13 */
  #hero-13 { padding-top: 80px; margin-bottom: 30px; }
  .hero-13-title { text-align: center; margin-bottom: 30px; }
  .hero-13-txt { text-align: center; padding: 0; margin-top: 0; }
  .hero-section .tools-list.mt-50 { margin-top: 25px; }
  .hero-section .tools-list h6 { margin-bottom: 15px; }
  .hero-13-img { margin: 40px 0 -30px 0; }

  /* Hero-14 */
  #hero-14 { padding-top: 80px; padding-bottom: 80px; }
  .hero-14-txt { margin-top: 0; padding-left: 0; }
  .hero-14-txt h2 { margin-bottom: 15px; }
  .hero-14-txt p.p-xl { margin-bottom: 25px; }

  /* Hero-15 */
  #hero-15 { padding-top: 80px; }
  .hero-15-txt { padding: 0; }
  .hero-15-img { padding: 0; margin-top: 50px; }

  /* Hero-16 */
  #hero-16 { padding-top: 80px; }
  .hero-16-txt { padding: 0; }

  /* Hero-17 */
  #hero-17 { padding-top: 80px; }
  .hero-17-title.mb-50 { margin-bottom: 25px; text-align: center; }
  .hero-17-txt { margin-top: 0; padding-right: 0; text-align: center; margin-bottom: 40px; }
  .hero-17-txt .os-btn { margin: 0 auto 20px; }
  .hero-17-img { margin-bottom: -80px; }
  .hero-17-img img { max-width: 100%; display: block; }

  /* Hero-18 */
  #hero-18 { text-align: center; padding-top: 80px; padding-bottom: 20px; }
  #hero-18:after { width: 100%; left: 0; } 
  #hero-18-form { padding: 0 10%; }
  #hero-18-form p.p-lg { margin-top: 15px; margin-bottom: 25px; }
  #hero-18-form .advantages { margin-top: 10px; }
  .hero-18-img { padding: 0 10%; margin-top: 50px; }

  /* Hero-19 */
  #hero-19 { overflow: visible; padding-top: 80px; margin-bottom: 50px; }
  .hero-19-txt { text-align: center; margin-top: 0; }
  .hero-19-img { margin: 40px 0 -60px; }
  .hero-19-img img { max-width: 100%; display: block; }

  /* Hero-20 */
  #hero-20 { background-position: left center; padding-top: 80px; padding-bottom: 80px; }
  .hero-20-txt { text-align: center; }
  .hero-20-txt p { padding-right: 0; margin-bottom: 25px; }

  /* Hero-21 */
  #hero-21 { padding-top: 80px; }

  /* Hero-23 */
  #hero-23 { padding-top: 50px; padding-bottom: 0; margin-bottom: 60px; }
  .hero-23-txt { text-align: center; margin-bottom: 50px; }
  .hero-23-txt p.p-xl { padding: 0; }
  .hero-23-img { margin-bottom: -60px; }

  /* Hero Request Form */
  .request-form .form-control { height: 50px; text-align: center; font-size: 17px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* About */
  #about-1 .txt-block { padding: 0; }
  .about-2-title { padding-right: 0; }

  .about-2-images { margin-top: 0; }
  .about-2-images img { width: auto; max-width: inherit; max-height: 100%; margin-top: 30px; }

  /* Features */
  .fbox-7-wrapper { padding-right: 0; }
  #features-7 .txt-block { text-align: center; padding: 0; margin-bottom: 40px; }

  .rtl-direction .fbox-4 { padding: 0 3%; }
  .fbox-5 { padding: 0 60px; }
  .fbox-5.bg-white { padding: 45px 60px; margin-top: 10px; margin-bottom: 60px; }
  .fbox-6 { text-align: center; padding: 45px 30px; }
  #fb-6-1, #fb-6-3 { margin-bottom: 40px; } 
  .fbox-7 { text-align: center; padding: 45px 60px; } 
  .fbox-7.mt-40 { margin-top: 0; }

  .fbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 20px; }
  .rtl-direction .fbox-txt { width: 83%; text-align: right; padding: 0; margin-top: 0; }
  .fbox-ico { text-align: center; width: 100%; float: none; margin-top: 0; }
  .rtl-direction .fbox-ico { width: 17%; margin-top: 8px; }

  /* Content */
  .content-4 { padding-top: 0; margin-bottom: 65px; }
  .content-4 .bg-inner { padding-bottom: 0; }
  .content-5 { padding-top: 80px; padding-bottom: 40px; }
  .content-5-wrapper { padding: 0; }
  .content-5.ws-wrapper { background-color: #f4f4f9; }
  .content-5.lg-wrapper { background-color: #f6f7f8; }
  .content-5.snow-wrapper { background-color: #fbfbfd; }
  .content-9 { margin-bottom: 80px; }

  .content-8 .section-title.mb-75 { margin-bottom: 40px; }

  .content-section .top-row.pb-30, .content-section .top-row.pb-50 { padding-bottom: 20px; }
  .cbox { margin-bottom: 40px; }
  .cbox-ico { float: none; }
  .rtl-direction .cbox-ico { margin-top: 6px; }
  .cbox-txt { text-align: center; padding-left: 0; margin-top: 15px; }
  .rtl-direction .cbox-txt { text-align: right; padding-left: 0; margin-top: 15px; }

  .content-4 .txt-block { text-align: center; padding: 80px 8% 0; margin-top: 0; }
  
  .content-5 ul.simple-list { list-style: none; margin-left: 0; }
  .content-7 .txt-block { margin-bottom: 35px; }

  .content-9-txt .btn { margin-top: 20px; }
  .content-10-btn { margin-top: 50px; }

  .content-4-img { margin-top: 30px; margin-bottom: -65px; }
  .content-7-img { margin-left: 0; }
  .content-7-img img { max-width: 100%; }
  .content-8-img { margin-bottom: -30px; }
  .content-9-img { margin-bottom: -80px; }  

  /* Projects */
  #projects-1 .masonry-image, #projects-2 .masonry-image { width: 100%; padding: 0; margin-bottom: 50px; }
  #projects-1 .project-txt, #projects-2 .project-txt { padding-left: 4%; padding-right: 0; }

  .rtl-direction #projects-1 .project-txt, .rtl-direction #projects-2 .project-txt { padding-left: 0; padding-right: 4%; }

  /* Tabs */
  #tabs-1 .tabs-1, #tabs-2 .tabs-1 { margin: 0 auto 35px; }
  #tabs-2 .tabs-1 li { text-align: center; }
  .tabs-1 li { display: block; margin-bottom: 15px; }

  /* Statistic */
  #statistic-1 { padding-top: 80px; padding-bottom: 80px; }
  #statistic-2 { text-align: center; padding-bottom: 80px; }
  .statistic-1-wrapper .statistic-block { text-align: center; padding: 0 10%; }
  #sb-1-1, #sb-1-2, .bg-white #sb-1-1, .bg-white #sb-1-2 { border-right: none; margin-bottom: 40px; }
  #sb-2-1, #sb-2-2 { margin-bottom: 40px; }
  #statistic-2 .txt-block { margin-bottom: 30px; }

  #statistic-4 .statistic-block { display: block!important; text-align: center; }
  #sb-4-1, #sb-4-2 { margin-bottom: 40px; }
  .statistic-block-txt { width: 100%; display: inline-block!important; padding-left: 0; padding-right: 5%; }
  .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 10px; }

  /* Reviews */
  .review-3 { padding: 30px 50px 30px 20px; }
  #rw-2-1, #rw-2-2 { margin-bottom: 40px; }
  .right-column #rw-3-1, .right-column #rw-3-2, .right-column #rw-3-3,
  .left-column #rw-3-1, .left-column #rw-3-2, .left-column #rw-3-3 { margin-right: 0; margin-left: 0; }

  .review-3-txt { padding-left: 5px; }
  .review-3-txt p { padding-right: 0; }
  .review-3-avatar img { width: 60px; height: 60px; }

  /* Picing */
  .pricing-1-row .col, .pricing-2-row .col { text-align: center; }
  .pricing-1-table.rel, .pricing-3-table.rel { margin-top: 15px; }

  .pricing-compare { margin-top: 40px; }
  .pricing-compare .table-responsive { margin-top: 40px; margin-bottom: 25px; }

  .pricing-notice p { padding: 0 5%; }
  .payment-methods { text-align: center; }

  /* FAQs */
  #faqs-2 .question { margin-bottom: 30px; }
  .accordion-item .accordion-thumb:after,
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.8rem; top: 25px; right: 0; }

  .more-questions { margin-top: 20px; }
  #faqs-1 .more-questions { margin-top: 40px; }

  /* Call To Action */
  .cta-section .text-end { text-align: center!important; }
  .cta-section.pb-20, .cta-section.pb-30 { padding-bottom: 0; }
  #cta-2 { text-align: center; }
  #cta-4.mb-20 { margin-bottom: 0; }
  #cta-10 { padding-top: 80px; padding-bottom: 80px; }
  .cta-1-wrapper { text-align: center; padding: 40px 15%; }
  .cta-1-wrapper { text-align: center;  padding: 50px 15%; }
  .cta-3-wrapper { text-align: center; padding: 40px 15%; }
  .cta-4-wrapper { text-align: center; padding: 40px 8%; }
  .cta-5-wrapper { padding: 50px; }
  .cta-7-wrapper { text-align: center; padding: 40px 10%; margin: 0; }
  .cta-11-wrapper { text-align: center; padding: 70px 60px; }
  .cta-top-box { margin-bottom: 30px; }

  .cta-6-txt { padding: 0 10%; }
  .cta-10-txt { text-align: center; padding: 0 12%; margin-bottom: 50px; }
  .cta-11-txt { margin-bottom: 40px; }

  .cta-2-txt p { padding: 0 5%; }

  .cta-section .stores-badge { margin-top: 25px; }
  .cta-1-btn, .cta-3-btn, .cta-4-btn, .cta-7-btn { margin-top: 20px; }
  .cta-2-btn { margin-top: 10px; }
  .os-btn {width: 310px; min-width: 310px; padding: 25px 20px; }

  .rtl-direction .cta-section .text-start { text-align: center!important; }

  /* Download */
  .version-release { margin-bottom: 40px; }
  .release-data h4 { margin-bottom: 25px; }
  .release-data {padding-bottom: 25px; margin-bottom: 25px; } 
  .release-download { position: relative; right: 0; bottom: 0; margin-top: 20px; }

  /* Newsletter */
  #newsletter-1.pb-20, #newsletter-1.pb-30 { padding-bottom: 0; }
  #newsletter-1 .container { padding-left: 15px; padding-right: 15px; }
  #newsletter-1 { text-align: center; }
  #newsletter-2 { text-align: center; padding-top: 40px; }
  .newsletter-wrapper { padding: 45px 30px 25px; }
  #newsletter-1 h4 {margin-bottom: 15px; }
  .newsletter-section .newsletter-form { margin-top: 35px; }

  /* Blog Listing */
  .posts-wrapper { padding: 0; }
  .featured-post { margin: 0 0 40px 0; }

  #blog-page .blog-post-wide .blog-post-txt { padding: 40px 60px; }

  /* Single Post */
  .single-post-title, .single-post-txt, .post-share-links, .comments-wrapper { padding: 0; }
  .single-post-title { margin-bottom: 25px; }
  .post-share-links { margin-top: 60px; }
  .about-post-author { padding: 80px 0; }

  .single-post-title h3, .single-post-title h2 { padding-right: 0; }

  #single-post p.post-tag { margin-bottom: 30px;} 
  #single-post .post-data { margin-top: 30px; margin-bottom: 30px; }
  #single-post .post-author-avatar img { width: 54px; height: 54px; }

  .post-inner-img { margin-top: 50px; margin-bottom: 45px; }
  .post-inner-img .top-img { margin-bottom: 40px; }

  .post-share-list.text-end { text-align: left!important; margin-top: 35px; margin-left: 8px; } 
  .post-tags-list span a { font-size: 0.95rem; padding: 4px 10px; }

  .author-senoff img { width: 70px; height: 70px; }
  .author-senoff-txt { padding-left: 25px; }
  .author-senoff-txt p { padding-right: 0; }

  #post-comments { padding-bottom: 70px; }
  .post-comments h5.h5-lg { margin-bottom: 50px; }
  .post-comments img { width: 60px; height: 60px; }
  .comment-body { margin-left: 20px; }

  .comment-form .form-control { height: 52px; font-size: 1rem; }

  /* Contacts */
  #contacts-1-form { padding: 45px 30px 35px; margin: 0 10% 30px; }
  #contacts-1 .wave-shape-top { display: none; }
  #contacts-1 .section-title { margin-bottom: 40px; }
  #contacts-1 .section-title h3 { color: #fff; }
  #contacts-1.bg-lightgrey .section-title h3 { color: #2f353e; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; font-size: 1rem; }
  #contacts-2 .contact-form span { font-size: 15px; }

  /* Footer */
  .rtl-direction #footer-1 .footer-info p { padding-left: 0; }
  .rtl-direction #footer-3 .footer-links { padding-left: 0; }
  .rtl-direction .footer-links { padding-right: 0; }
  .rtl-direction .footer .bottom-footer-list { text-align: right!important; }

  /* Inner Page Hero */
  .inner-page-hero { margin-top: 80px; padding-top: 80px; }
  .page-hero-section { margin-top: 80px; padding-top: 70px; padding-bottom: 70px; }
  .page-hero-section p { padding: 0; margin-top: 15px; }

  /* Terms Page */
  .terms-title { margin-bottom: 50px; }
  .terms-box.mt-60 { margin-top: 50px; }
  .terms-box, .terms-box h5 {  margin-bottom: 25px; }

  /* Register Page */
  #login-1, #signup-1, #login-2, #signup-2, #login-3, #signup-3, #reset-password-1, #reset-password-2 { padding-top: 80px; padding-bottom: 50px; }
  #login-3 { padding-top: 80px; }

  .reg-nav .wsmobileheader { display: none; }

  .login-page-logo { margin-bottom: 50px; }
  .login-page-logo img { max-height: 45px; }

  #reset-password-1 .sign-in-footer { margin-top: 40px; }
  #reset-password-2 .sign-in-footer { margin-top: 30px; }

  .login-3-img, .signup-3-img { display: none; }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 480px) and (max-width: 767.95px) {



  .ts-0 { padding-top: 80px; }

  /* Header H6 */
  h6.h6-xs { font-size: 1rem; }        
  h6.h6-sm { font-size: 1.05rem; } 
  h6.h6-md { font-size: 1.1rem; }   
  h6.h6-lg { font-size: 1.125rem; }  
  h6.h6-xl { font-size: 1.15rem; }  

  .rtl-direction h6.h6-xs { font-size: 1.1875rem; }   
  .rtl-direction h6.h6-sm { font-size: 1.1875rem; }  
  .rtl-direction h6.h6-md { font-size: 1.1875rem; }   
  .rtl-direction h6.h6-lg { font-size: 1.1875rem; }  
  .rtl-direction h6.h6-xl { font-size: 1.1875rem; }  

  /* Header H5 */
  h5.h5-xs { font-size: 1.1875rem;}  
  h5.h5-sm { font-size: 1.1875rem; } 
  h5.h5-md { font-size: 1.1875rem; }  
  h5.h5-lg { font-size: 1.1875rem; }  
  h5.h5-xl { font-size: 1.1875rem; }  

  .rtl-direction h5.h5-xs { font-size: 1.3rem; }   
  .rtl-direction h5.h5-sm { font-size: 1.3rem; }  
  .rtl-direction h5.h5-md { font-size: 1.3rem; }   
  .rtl-direction h5.h5-lg { font-size: 1.3rem; }  
  .rtl-direction h5.h5-xl { font-size: 1.3rem; }  

  /* Header H4 */
  h4.h4-xs { font-size: 1.2rem; } 
  h4.h4-sm { font-size: 1.25rem; }   
  h4.h4-md { font-size: 1.33rem; }
  h4.h4-lg { font-size: 1.42rem; } 
  h4.h4-xl { font-size: 1.5rem; } 

  h3.h3-xs { font-size: 1.6rem; }
  h3.h3-sm { font-size: 1.67rem; } 
  h3.h3-md { font-size: 1.75rem; } 
  h3.h3-lg { font-size: 1.835rem; }
  h3.h3-xl { font-size: 1.875rem; } 

  /* Header H2 */
  h2.h2-xs { font-size: 1.96rem; }
  h2.h2-sm { font-size: 2.04rem; } 
  h2.h2-md { font-size: 2.125rem; }  
  h2.h2-lg { font-size: 2.2rem; } 
  h2.h2-xl { font-size: 2.375rem; } 

  h2.h2-title-xs { font-size: 2.5rem; } 
  h2.h2-title-sm { font-size: 2.75rem; } 
  h2.h2-title-md { font-size: 2.9rem; } 
  h2.h2-title-lg { font-size: 3.125rem; }  
  h2.h2-title-xl { font-size: 3.3rem; }

  /* Paragraphs */
  p { font-size: 0.95rem; }  
  p.p-md { font-size: 1rem; }   
  p.p-lg { font-size: 1.05rem; }  
  p.p-xl { font-size: 1.05rem; }

  p.p-title-xs { font-size: 1.07rem; } 
  p.p-title-sm { font-size: 1.1rem; }  
  p.p-title-md { font-size: 1.125rem; }  
  p.p-title-lg { font-size: 1.15rem; }   
  p.p-title-xl { font-size: 1.175rem; } 

  .rtl-direction p.p-md { font-size: 1.1rem; }  
  .rtl-direction p.p-lg { font-size: 1.1rem; } 
  .rtl-direction p.p-xl { font-size: 1.1rem; } 

  /* Buttons */
  .btn { font-size: 1rem; padding: 12px 30px; }
  .btn.btn-md, .btn.btn-lg { font-size: 1.05rem; padding: 13px 30px; }
  .btn-transparent { padding: 12px 4px; }
  .btn.btn-md.btn-transparent, .btn.btn-lg.btn-transparent { padding: 13px 4px; }

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.875rem; }
  .btn.ico-20.ico-left span { top: 3px;right: 5px; }

  /* Watch Video Link */
  .watch-video-link { width: 50px; height: 50px; margin-right: 12px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 48px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 2px; }

  /* Video Button */
  .video-btn-xl { width: 100px; height: 100px; margin-top: -50px; margin-left: -50px; }
  .video-btn-lg { width: 90px; height: 90px; margin-top: -45px;margin-left: -45px; }
  .video-btn-md { width: 70px; height: 70px; margin-top: -35px; margin-left: -35px; }
  .video-btn-sm { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 5rem; line-height: 100px!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 90px!important; margin-left: 8px;
  }

  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 3.5rem; line-height: 70px!important; margin-left: 6px;
  }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, .video-btn.video-btn-sm [class^="flaticon-"]:after {
    font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-xl:hover:before { left: -18px;right: -18px;top: -18px;bottom: -18px; }
  .video-btn.video-btn-lg:hover:before { left: -18px;right: -18px;top: -18px;bottom: -18px; }
  .video-btn.video-btn-md:hover:before { left: -12px; right: -12px; top: -12px; bottom: -12px; }
  .video-btn.video-btn-sm:hover:before { left: -12px; right: -12px; top: -12px; bottom: -12px; }

  .store img { max-height: 44px; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.875rem; }   
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1rem; } 
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.25rem; }  
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.45rem; } 
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.65rem; } 
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 1.9rem; } 
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.15rem; }
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.35rem; } 
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 2.6rem; } 
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 2.81rem; }
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.05rem; } 
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.3rem; } 
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 3.5rem; } 
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 3.75rem; } 
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4rem; }  
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.25rem; } 
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 4.45rem; } 
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 4.7rem; }  
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 4.95rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 5.15rem; }  
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 5.4rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 5.65rem; }  
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 5.9rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 70px; height: 70px; }
  .ico-rounded-sm { width: 75px; height: 75px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 85px; height: 85px; }
  .ico-rounded-xl { width: 90px; height: 90px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 70px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 75px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 80px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 85px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 90px!important; }

  /* PNG Icons */
  .ico-125 img { width: 94px; height: 94px; }
  .ico-120 img { width: 90px; height: 90px; }
  .ico-115 img { width: 86px; height: 86px; }
  .ico-110 img { width: 82px; height: 82px; }
  .ico-105 img { width: 80px; height: 80px; }
  .ico-100 img { width: 75px; height: 75px; }
  .ico-95 img { width: 71px; height: 71px; }
  .ico-90 img { width: 68px; height: 68px; }
  .ico-85 img { width: 64px; height: 64px; }
  .ico-80 img { width: 60px; height: 60px; }
  .ico-75 img { width: 56px; height: 56px; }
  .ico-70 img { width: 53px; height: 53px; }
  .ico-65 img { width: 49px; height: 49px; }
  .ico-60 img { width: 45px; height: 45px; }
  .ico-55 img { width: 42px; height: 42px; }
  .ico-50 img { width: 38px; height: 38px; }
  .ico-45 img { width: 34px; height: 34px; }
  .ico-40 img { width: 30px; height: 30px; }
  .ico-35 img { width: 26px; height: 26px; }
  .ico-30 img { width: 23px; height: 23px; }
  .ico-25 img { width: 20px; height: 20px; }
  .ico-20 img { width: 16px; height: 16px; }

  /* Section Id */
  .section-id { font-size: 1.05rem; margin-bottom: 25px; }
  .section-id.txt-upcase { font-size: 0.925rem; }
  .section-title .section-id, .section-title-left .section-id { margin-bottom: 20px; }

  /* Section Title */
  .title-04 { text-align: center; padding: 0 5%; }
  .section-title h3 { padding: 0 5%; }
  .title-01 p { padding: 0 6%; margin-top: 15px; }
  .title-02 p { padding: 0 5%; margin-top: 15px; }

  .section-title.ico-35 [class*="flaticon-"]:before, .section-title.ico-35 [class*="flaticon-"]:after { font-size: 1.5rem; } 

  /* Text Block Rating */
  .txt-block-rating [class*="flaticon-"]:before, .txt-block-rating [class*="flaticon-"]:after { font-size: 1rem; }
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating, .stars-rating p { font-size: 0.95rem; }

  /*------------------------------------------*/
  /*.  HERO  
  /*------------------------------------------*/

  /* Hero Brands */
  .hero-brands p { margin-bottom: 25px; }

  /* Hero-1 */
  .hero-1-txt { padding: 0 4%; margin-bottom: 30px; }
  .hero-1-txt p.p-xl { font-size: 1.125rem; padding-right: 0; margin-bottom: 30px; }
  .hero-1-img { padding: 0 5%; }

  /* Hero-2 */
  #hero-2 { margin-bottom: 50px; }
  .hero-2-txt { padding: 0 5%; }
  .hero-2-txt h2 { margin-bottom: 25px; }
  .hero-2-txt p.p-xl { font-size: 1.125rem; padding: 0; margin-bottom: 35px; }
  .hero-2-img { padding: 0 8%; }

  /* Hero-3 */
  .hero-3-txt { padding: 0 5%; }
  .hero-3-txt h2 { padding: 0 4%; }

  /* Hero-4 */
  .hero-4-txt { padding: 0 5%; }
  #hero-4-form { text-align: center; margin-left: 5%; margin-right: 5%; }

  /* Hero-5 */
  .hero-5-txt { padding: 0 20%; margin-top: 40px; }
  .hero-5-list h4.h4-xl { font-size: 1.55rem; }
  .hero-5-list h5.h5-xl { font-size: 1.4rem; }

  /* Hero-6 */
  .hero-6-txt { padding: 0 3%; }
  .hero-6-txt h2 { margin-bottom: 25px; }
  #hero-6 .quick-form { margin: 40px 4% 50px; }
  #hero-6 .quick-form .btn { padding: 13px 30px; }
  .hero-6-img { padding: 0 6%; }

  /* Hero-7 */
  .hero-7-txt h2 { padding: 0 10%;}
  .hero-7-txt ul.simple-list { padding: 0 10%; }
  .hero-7-txt .btns-group { margin-top: 30px; }

  /* Hero-8 */
  .hero-8-txt { padding: 0 8%; }
  .hero-8-txt h2 { margin-bottom: 25px; }
  #hero-8 .quick-form { margin: 40px 0 60px; }
  #hero-8 .quick-form .btn { padding: 13px 30px; }

  /* Hero-9 */
  #hero-9 { background-position: center center; }
  #hero-9-form { text-align: center; padding: 40px 35px; margin-left: 10%; margin-right: 10%; }
  #hero-9-form h4.h4-xs { font-size: 1.65rem; }

  /* Hero-10 */
  .hero-10-txt p { padding: 0; }
  .hero-10-img { padding: 0; margin-top: 50px; }

  /* hero-11 */
  .hero-11-txt { text-align: center; padding: 0 5%; margin-bottom: 40px; }
  .hero-11-txt .os-btn { margin: 0 auto 20px; } 
  .hero-11-img { padding: 0 10%; }

  /* Hero-12 */
  #hero-12:after { height: 25%; top: 80%; }
  .hero-12-txt { padding: 0 5%; }
  .hero-12-txt h2 { margin-bottom: 25px; }
  .hero-12-txt p { font-size: 1.15rem; padding-right: 0; }
  #hero-12:after { height: 30%; top: 70%; }
  .hero-12-txt .hero-logo-rounded { margin-bottom: 30px; }
  .hero-12-img { margin-top: 35px; }

  /* Hero-13 */
  .hero-13-title { margin-bottom: 20px; }

  /* Hero-15 */
  .hero-15-txt h2 { margin-bottom: 25px; }
  .hero-15-txt p { padding: 0; }
  .hero-15-img { padding: 0; margin-top: 40px; margin-bottom: -12%; }

  /* hero-16 */
  .hero-16-txt { text-align: center; padding: 0 5%; margin-bottom: 40px; }
  .hero-16-img { padding: 0 10%; }

  /* Hero-17 */
  .hero-17-img { margin-bottom: -50px; }

  /* Hero-18 */
  #hero-18-form .form-btn { margin-top: 5px; }

  /* Hero-19 */
  #hero-19 { margin-bottom: 60px; }
  .hero-19-txt h2 { margin-bottom: 20px; }
  .hero-19-txt p.p-xl { padding: 0 5%; margin-bottom: 30px; }
  .hero-19-img { margin-top: 30px; }

  /* Hero-20 */
  .hero-20-txt h2 { margin-bottom: 20px; }
  .hero-20-txt p { padding: 0 10%; margin-bottom: 25px; }

  /* Hero-21 */
  .hero-21-txt p.p-xl { padding: 0; }
  #hero-21 .quick-form { margin: 40px 4% 0; }

  /* Hero-22 */
  .hero-22-wrapper { padding: 80px 60px 0; text-align: center; margin-bottom: 80px; }
  .hero-22-wrapper .txt-block { margin-bottom: 50px; }

  /* Hero-23 */
  .hero-23-txt { padding: 0 4%; margin-bottom: 40px; }
  .hero-23-txt p.p-xl { font-size: 1.125rem; padding: 0; }
  .hero-23-img { margin: 0 5% -60px; }

  /* Hero-24 */
  .hero-24-txt { padding-top: 80px; padding-bottom: 0; }
  .hero-24-txt h5 { padding: 0 5%; margin-bottom: 20px; }
  .hero-24-txt h2.tra-header { top: -20px; left: 3%; font-size: 15rem; }
  .hero-24-img img { padding: 0 15% 0 25%; }

  /* Hero Quick Form */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 50px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-2 { margin-bottom: 100px; }
  .about-2-title { padding-right: 0; }
  .about-2-title p { margin-top: 16px; padding-right: 0; }
  .about-2-images { margin-top: -5px; margin-bottom: -100px; }

  /* Features */
  #features-7 .txt-block p { padding: 0 5%; }

  .fbox-2 { padding: 0 5%; }
  .fbox-3 { padding: 0 8%; }
  .fbox-4 { padding: 0 10%; }

  .fbox-6 h5 { padding: 0 8%; }
  .fbox-8 p { padding: 0 5%; }

  .fbox-img { margin-left: 20%; margin-right: 20%; padding: 40px 12% 40px; } 

  /* Content */
  .cbox { padding: 0 5%; }
  .content-5 .cbox { padding: 0 10%; }
  .rtl-direction .content-5 .cbox { padding: 0 3%; }
  .cbox-2 { width: 330px; min-width: 330px; padding: 20px; }
  .cbox-2.mt-20.mb-30 { margin-top: 10px; }

  .rtl-direction .content-5 ul.simple-list { list-style: disc; }

  .cbox-4.left-column, .cbox-4.right-column { bottom: 10px; right: 20px; width: 48%; padding: 25px 20px; }
  .cbox-4.right-column { left: 20px; right: 0; bottom: 10px; }
  .cbox-5 { top: -30px; left: -10px; width: 50%; padding: 25px 20px; }
  .cbox-6 { bottom: 10px; right: 15px; width: 50%; padding: 25px 20px; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1.1rem; padding-right: 5%; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.95rem; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 15px; margin-bottom: 15px; }
  .cbox-4 .btn, .cbox-5 .btn, .cbox-6 .btn { font-size: 0.95rem; padding: 11px 30px; margin-bottom: 8px; }

  .cb-wrapper { margin: 0 0 70px; }
  .cb-single-box h2.statistic-number { font-size: 3rem; }
  .statistic-number sup { font-size: 2.5rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: 25px; top: -20px; }
  .cb-shape-2 { left: 10px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 12%; }

  .content-4 .txt-block p { margin-bottom: 10px; }

  .content-section .img-block.left-column, .content-section .img-block.right-column { padding: 0 4%; }
  .rtl-direction .content-section .img-block.left-column, 
  .rtl-direction .content-section .img-block.right-column { text-align: right; padding: 0; }
  .content-4-img { padding: 0 6%; margin-top: 0; }
  .rtl-direction .content-4-img { padding: 0; margin-top: 0; }

  /* Projects */
  #projects-2 { padding-bottom: 40px; }
  #projects-2 .masonry-image { margin-bottom: 40px; }
  #projects-1 .project-details, #projects-2 .project-details { padding: 0 2%; }
  #projects-1 .more-btn, #projects-2 .more-btn { margin-top: 0; }

  .rtl-direction #projects-1 .project-txt, .rtl-direction #projects-2 .project-txt { padding-left: 0;padding-right: 0; }

  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 30px; }
  .project-data span { margin-right: 10px; }

  .project-image.top-img { margin-bottom: 30px; }
  .more-projects { margin-top: 40px; }

  /* Tabs */
  .tabs-1 li span { font-size: 1rem; }
  .tabs-section .img-block.left-column, .tabs-section .img-block.right-column { padding: 0 4%; }

  /* Statistic */
  .statistic-1-wrapper .statistic-block { padding: 0 8%; }
  .statistic-1-wrapper h2.statistic-number { font-size: 2.85rem; }
  #statistic-2 h2, #statistic-4 h2 { font-size: 3rem; }
  .statistic-3-wrapper h3.statistic-number { font-size: 2.65rem; }
  #statistic-2 .txt-block { padding: 0; }
  #statistic-2 .txt-block h4 { font-size: 1.65rem; }
  #statistic-2 .txt-block-rating, #statistic-4 .statistic-block p.mt-20 { margin-top: 10px; }

  .rtl-direction #statistic-2 { text-align: right; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 0; }

  .rtl-direction .statistic-block-txt { margin-bottom: 15px;}

  /* Reviews */
  .reviews-4-holder {padding-top: 40px; padding-bottom: 30px; }
  #reviews-3 .txt-block { text-align: center; padding: 0 5%; }
  .review-2 .review-avatar img { width: 50px; height: 50px; }

  .review-4 { margin: 0 10px 20px; }

  /* Pricing */
  .pricing-1-table { padding: 40px 20% 35px; margin-left: 6%; margin-right: 6%; }
  .pricing-2-table { padding: 30px 15% 35px; margin-left: 6%; margin-right: 6%; }
  .pricing-3-table { padding: 45px 15%; margin-left: 6%; margin-right: 6%; }

  .pricing-plan-title { text-align: left; }
  .rtl-direction .pricing-plan-title { text-align: right; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }

  .pricing-1-table .pricing-plan h5 { margin-bottom: 20px; }
  .pricing-3-table .pricing-plan h6 { margin-bottom: 25px; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 1rem; }

  .pricing-plan span { font-size: 3rem; }
  .pricing-3-table .pricing-plan span { font-size: 4rem; }
  .pricing-plan sup { font-size: 1.75rem; top: -5px; }
  .pricing-3-table .pricing-plan sup { font-size: 2.25rem; top: -8px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.25rem; top: -16px; }
  .pricing-plan sup.validity span { font-size: 2rem; }
  .pricing-plan sup.validity { font-size: 1.25rem; }

  .pricing-1-table .pricing-plan p { padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { margin-top: 10px; }

  .badge-wrapper { top: -19px; }
  .highlight-badge { padding: 10px 40px; }

  .payment-methods { text-align: center; margin-top: 0; padding: 0 10%; }
  .payment-methods h6 { margin-bottom: 10px; }

  .pricing-notice { margin-top: 15px; }
  .pricing-notice p { padding: 0 5%; }

  /* Brands */
  .brands-2-wrapper { padding: 0; }

  .brands-section h4 { padding: 0; margin-bottom: 25px; }
  .brands-section p { padding: 0; margin-bottom: 35px; }
  #brands-1 .brand-logo { padding: 0 15px; }
  #brands-2 .brand-logo { padding: 0 10px; }

  /* FAQs */
  .rtl-direction .accordion-item .accordion-thumb:after, 
  .rtl-direction .accordion-item.is-active .accordion-thumb:after { top: 22px;right: 98%; }

  /* Call To Action */
  #cta-3 { margin-top: 110px; }
  #cta-4 { margin-bottom: 140px; }
  .cta-1-wrapper { text-align: center; padding: 40px 10%; }
  .cta-3-wrapper { text-align: center; padding: 40px 10%;  margin-top: -120px; }
  .cta-4-wrapper { margin-bottom: -150px; }
  .cta-5-wrapper { padding: 50px 30px; }
  .cta-6-txt { padding: 0 5%; }
  .cta-7-wrapper { padding: 40px 5%; }
  .cta-10-txt { text-align: center; padding: 0 6%; margin-bottom: 40px; }

  .cta-2-txt h4 { font-size: 1.75rem; }
  .cta-10-txt h3 { padding: 0 3%; }
  .cta-2-txt p { padding: 0 3%; margin-bottom: 10px; }
  .cta-9-txt p.p-xl { padding: 0 15%; margin-bottom: 30px; }

  .cta-box {padding: 30px 30px 35px; }
  .os-btn {width: 310px; min-width: 310px; padding: 22px 20px; }

  .cta-10-img { margin-left: 6%; margin-right: 6%; }

  /* Newsletter */
  #newsletter-1 h4.h4-xl { font-size: 1.75rem; }
  #newsletter-2 h4.h4-xl { font-size: 1.75rem; }
  .newsletter-wrapper { padding: 40px 40px 20px; }
  #newsletter-1 .newsletter-txt { padding: 0 10%; }
  #newsletter-2 .newsletter-txt { padding: 0 15%; }
  #newsletter-1 .newsletter-form { padding: 0 5%; margin-top: 15px; }
  #newsletter-2 .newsletter-form { padding: 0 12%; }
  .newsletter-section .form-control { 
    display: block; width: 100%!important; 
    text-align: center; 
    height: 50px; 
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 1rem; 
    margin-right: 0; 
    margin-bottom: 15px; 
    -webkit-border-radius: 5px; -moz-border-radius: 5px;-o-border-radius: 5px; border-radius: 5px;
  }

  #newsletter-2.newsletter-section .form-control { font-size: 1.05rem; border: 1px solid #ccc; }
  #newsletter-2.bg-lightgrey.newsletter-section .form-control, 
  #newsletter-2.bg-dark.newsletter-section .form-control { font-size: 1rem; border-bottom: 1px solid #ccc; }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .newsletter-section .form-control:focus { border: 1px solid #998da0; }

  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { 
    display: block; 
    height: 50px; 
    width: 100%!important; margin: 0; 
  }

  .rtl-direction .newsletter-section .input-group .input-group-btn {order: 2; }
  .rtl-direction .newsletter-section .input-group .email {order: 1; } 
  .rtl-direction #newsletter-2.newsletter-section .form-control { margin-left: 0; } 
  
  /* Blog */
  #blog-page .masonry-image { width: 100%; padding: 0; }
  .blog-1-post, .blog-2-post { margin-left: 2%; margin-right: 2%; }

  .blog-1-post .blog-post-txt { margin-top: 30px; padding-left: 10px; padding-right: 0; }
  .blog-2-post .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-wide .blog-post-txt { margin-top: 0; }

  /* Blog Listing */
  #blog-page .blog-post-wide .blog-post-txt { padding: 35px 45px; }

  /* Contacts */
  #contacts-1 { padding-top: 80px; }
  #contacts-1-form { padding: 30px 20px 20px; margin: 0 10% 30px; }

  /* Footer */
  .footer { padding-top: 80px; }
  img.footer-logo { max-height: 35px; }
  .footer-info p { padding-right: 0; }
  #footer-1 .footer-links { padding-left: 0; }
  .footer hr { margin-top: 10px; }
  
  .footer p.p-md { font-size: 1.05rem; } 
  .bottom-footer p { font-size: 1rem; } 

  .bottom-footer-list.text-end { text-align: left!important; margin-top: 5px; }

   /* Inner Page Hero */
  .page-hero-section { padding-top: 60px; padding-bottom: 60px; }
  .page-hero-section h3 { padding: 0 5%; }
  .page-hero-section p { padding: 0; margin-top: 15px; }

  /* Terms Page */
  #terms-page.inner-page-hero { margin-top: 70px; padding-top: 70px; }
  .terms-title { margin-bottom: 60px; }
  .terms-title p { margin-top: 15px; }
  .terms-box { margin-bottom: 20px; }
  .terms-box h5 { margin-bottom: 15px; }

  /* Register Page */
  .register-form-title h4, .register-form-title h3 { font-size: 1.85rem; margin-bottom: 10px; }
  .reset-password-section .register-form-title p { padding: 0 3%; }

  #reset-password-1 .login-page-logo img { max-height: 44px; }

  .form-check .form-check-label, p.forgot-password { font-size: 1rem; }

  .register-form p.create-account { font-size: 1rem; margin-top: 35px; }

  .sign-in-notice { padding: 0 17%; margin-top: 35px; }
  .sign-in-notice p { font-size: 1rem; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -980%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -980%; }

  #login-2 .login-separator-txt:before, #signup-2 .login-separator-txt:before { left: -880%; }
  #login-2 .login-separator-txt:after, #signup-2 .login-separator-txt:after { right: -880%; }

  #login-3 .login-separator-txt:before { left: -970%; }
  #login-3 .login-separator-txt:after { right: -970%; }

  #signup-3 .login-separator-txt:before { left: -970%; }
  #signup-3 .login-separator-txt:after { right: -970%; }

  #login-2 .register-form form, #signup-2 .register-form form { margin: 0 10px; }
  #reset-password-1 .register-form form, #reset-password-2 .register-form form { margin: 0 30px; }
  .register-form .form-control { height: 56px; }
  .register-form .btn { height: 56px; font-size: 1.05rem; }

  .register-form .btn-facebook, .register-form .btn-google { padding: 0; line-height: 56px; }
  .btn-google img, .btn-facebook img { width: 18px; height: 18px; margin-top: -3px; margin-right: 5px; }

  .reset-password-img { margin: 0 3% 50px; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 414px) and (max-width: 479.95px) {

  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }

  h6, h5, h4, h3 { line-height: 1.35; }

  /* Header H6 */
  h6.h6-xs { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-sm { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-md { font-size: 1.0625rem; }  /* 17px */
  h6.h6-lg { font-size: 1.0625rem; }  /* 17px */
  h6.h6-xl { font-size: 1.0625rem; }  /* 17px */

  .rtl-direction h6.h6-xs { font-size: 1.125rem; }   
  .rtl-direction h6.h6-sm { font-size: 1.125rem; }  
  .rtl-direction h6.h6-md { font-size: 1.125rem; }   
  .rtl-direction h6.h6-lg { font-size: 1.125rem; }  
  .rtl-direction h6.h6-xl { font-size: 1.125rem; }

  /* Header H52 */
  h5.h5-xs { font-size: 1.125rem; }   
  h5.h5-sm { font-size: 1.125rem; }  
  h5.h5-md { font-size: 1.125rem; }     
  h5.h5-lg { font-size: 1.125rem; }   
  h5.h5-xl { font-size: 1.125rem; }   /* 18px */

  .rtl-direction h5.h5-xs { font-size: 1.25rem; }   
  .rtl-direction h5.h5-sm { font-size: 1.25rem; }  
  .rtl-direction h5.h5-md { font-size: 1.25rem; }   
  .rtl-direction h5.h5-lg { font-size: 1.25rem; }  
  .rtl-direction h5.h5-xl { font-size: 1.25rem; } 

  /* Header H4 */
  h4.h4-xs { font-size: 1.25rem; }   
  h4.h4-sm { font-size: 1.25rem; }     
  h4.h4-md { font-size: 1.25rem; }  
  h4.h4-lg { font-size: 1.25rem; }   
  h4.h4-xl { font-size: 1.25rem; }    /* 20px */

  /* Header H3 */
  h3.h3-xs { font-size: 1.5rem; } 
  h3.h3-sm { font-size: 1.5rem; }   
  h3.h3-md { font-size: 1.5rem; }  
  h3.h3-lg { font-size: 1.5rem; } 
  h3.h3-xl { font-size: 1.5rem; }     /* 24px */ 

  /* Header H2 */
  h2.h2-xs { font-size: 1.625rem; }  
  h2.h2-sm { font-size: 1.625rem; }  
  h2.h2-md { font-size: 1.625rem; }  
  h2.h2-lg { font-size: 1.625rem; }  
  h2.h2-xl { font-size: 1.625rem; }   /* 26px */

  h2.h2-title-xs { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-sm { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-md { font-size: 2rem; }      /* 32px */
  h2.h2-title-lg { font-size: 2rem; }      /* 32px */
  h2.h2-title-xl { font-size: 2.125rem; }  /* 34px */ 

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; }     
  p { font-size: 0.9375rem; }       
  p.p-md { font-size: 0.9375rem; }      
  p.p-lg { font-size: 1rem; }  
  p.p-xl { font-size: 1rem; } 

  p.p-title-xs { font-size: 1.05rem; } 
  p.p-title-sm { font-size: 1.05rem; } 
  p.p-title-md { font-size: 1.05rem; }   
  p.p-title-lg { font-size: 1.1rem; }  
  p.p-title-xl { font-size: 1.1rem; } 

  .rtl-direction p.p-md { font-size: 1.1rem; }  
  .rtl-direction p.p-lg { font-size: 1.1rem; } 
  .rtl-direction p.p-xl { font-size: 1.1rem; } 

  /* Button */
  .btn, .btn.btn-md, .btn.btn-lg { font-size: 0.9375rem; padding: 12px 32px; }
  .btn-transparent, .btn.btn-md.btn-transparent, .btn.btn-lg.btn-transparent { padding: 8px 4px; }

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.75rem; }

  /* Watch Video Link */
  .watch-video-link { width: 48px; height: 48px; margin-right: 11px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 46px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 1px; }

  /* Video Button */
  .video-btn-xl { width: 70px; height: 70px; margin-top: -35px; margin-left: -35px; }
  .video-btn-lg { width: 70px; height: 70px; margin-top: -35px;margin-left: -35px; }
  .video-btn-md { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }
  .video-btn-sm { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, .video-btn.video-btn-sm [class^="flaticon-"]:after {
    font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-xl:hover:before, 
  .video-btn.video-btn-lg:hover:before { left: -16px; right: -16px; top: -16px; bottom: -16px; }
  .video-btn.video-btn-md:hover:before,
  .video-btn.video-btn-sm:hover:before { left: -12px; right: -12px; top: -12px; bottom: -12px; }

  /* Store Badges */
  .store { margin-right: 10px; }
  .store img { max-height: 42px; }
  .footer .store img { max-height: 42px; }
  span.os-version { font-size: 0.9375rem; line-height: 1.25; font-weight: 300; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }   
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.1rem; }     
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.375rem; }  
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.65rem; }   
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.925rem; }  
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.2rem; }     
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.475rem; }  
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.75rem; }   
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.025rem; }  
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.3rem; }     
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.575rem; }  
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.85rem; }   
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.125rem; }  
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 4.4rem; }   
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4.675rem; }  
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.95rem; }    
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.225rem; }  
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 5.5rem; }   
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 5.775rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.05rem; } 
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 6.325rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 6.6rem; }   
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 6.875rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 70px; height: 70px; }
  .ico-rounded-sm { width: 75px; height: 75px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 85px; height: 85px; }
  .ico-rounded-xl { width: 95px; height: 95px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 70px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 75px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 80px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 85px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 95px!important; }

  /* PNG Icons */
  .ico-125 img { width: 110px; height: 110px; }
  .ico-120 img { width: 105px; height: 105px; }
  .ico-115 img { width: 101px; height: 101px; }
  .ico-110 img { width: 97px; height: 97px; }
  .ico-105 img { width: 92px; height: 92px; }
  .ico-100 img { width: 88px; height: 88px; }
  .ico-95 img { width: 84px; height: 84px; }
  .ico-90 img { width: 79px; height: 79px; }
  .ico-85 img { width: 75px; height: 75px; }
  .ico-80 img { width: 70px; height: 70px; }
  .ico-75 img { width: 66px; height: 66px; }
  .ico-70 img { width: 62px; height: 62px; }
  .ico-65 img { width: 57px; height: 57px; }
  .ico-60 img { width: 53px; height: 53px; }
  .ico-55 img { width: 48px; height: 48px; }
  .ico-50 img { width: 44px; height: 44px; }
  .ico-45 img { width: 40px; height: 40px; }
  .ico-40 img { width: 35px; height: 35px; }
  .ico-35 img { width: 31px; height: 31px; }
  .ico-30 img { width: 26px; height: 26px; }
  .ico-25 img { width: 22px; height: 22px; }
  .ico-20 img { width: 20px; height: 18px; }

  /* Section Id */
  .section-id { font-size: 1rem; margin-bottom: 22px; }
  .section-id.txt-upcase { font-size: 0.825rem; }
  .section-title .section-id, .section-title-left .section-id { margin-bottom: 20px; }

  /* Section Title */
  .title-04 { text-align: center; padding: 0; }
  .title-01 p { padding: 0 3%; margin-top: 15px; }
  .title-02 p { padding: 0; }
  .section-title.ico-35 [class*="flaticon-"]:before, .section-title.ico-35 [class*="flaticon-"]:after { font-size: 1.2rem; } 

  /* Text Block Typography */
  .txt-block h3, .txt-block h2, .content-14-txt h4, .content-14-txt h3 { margin-bottom: 16px; }
  h5.sm-title { margin-top: 15px; margin-bottom: 15px; }
  .txt-block .btn, .content-14-txt .btn, .txt-block .stores-badge { margin-top: 15px; }
  .txt-block .btns-group .btn.mr-15 { margin-left: 15px; margin-right: 15px; }

  .advantages li { padding-left: 0px; }
  .advantages li:after { padding-left: 3px; top: -1px; }

  /* Terms Text */
  .terms-txt a { display: block; }

  /* Tools List */
  .tools-list span { padding-right: 4px; }

  /* Text Block Rating */
  .txt-block-rating { text-align: center; }
  .txt-block-rating [class*="flaticon-"]:before, .txt-block-rating [class*="flaticon-"]:after { font-size: 1rem; }
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating { font-size: 0.9375rem; }
  .stars-rating p { font-size: 0.9375rem; padding: 0 10%; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .wsmenu > .wsmenu-list { width: 345px; margin-right: -345px; }
  .wsactive .wsmobileheader { margin-right: 345px; }
  .overlapblackbg { width: calc(100% - 345px); }

  .wsanimated-arrow { padding: 13px 30px 14px 0px; margin: 25px 18px 0 0; }

  .wsmobileheader .smllogo { display: block; margin-top: 21px; padding-left: 18px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 38px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  /* Hero App Logo */
  .hero-logo-rounded span { font-size: 0.915rem; }

  /* Hero Brands */
  .hero-brands { margin-top: 30px; padding: 0 5%; } 
  .hero-brands p { padding: 0 5%; margin-bottom: 20px; }
  .hero-brands .brand-logo {padding: 0 15px; }

  /* Hero-1 */
  .hero-1-txt { padding: 0 6%; margin-bottom: 30px; }
  .hero-1-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-1-txt p.p-xl { font-size: 1.05rem; margin-bottom: 30px; }
  .hero-1-img { padding: 0 5%; }
  #hero-1 .btns-group .btn.mr-15 { margin: 0 15px 15px; }

  /* Hero-2 */
  #hero-2 { margin-bottom: 50px; }
  .hero-2-txt { padding: 0; }
  .hero-2-txt h2 { font-size: 1.85rem; margin-bottom: 15px; }
  .hero-2-txt p.p-xl { font-size: 1.05rem; padding: 0; margin-bottom: 30px; }
  .hero-2-img { padding: 0 5%; }

  /* Hero-3 */
  .hero-3-txt h2 { font-size: 2rem; padding: 0 5%; margin-bottom: 25px; }
  .hero-3-txt p.p-xl { padding: 0 2%; margin-bottom: 30px; }

  .hero-3-img { margin-right: 0; margin-bottom: -40px; }

  /* Hero-4 */
  .hero-4-txt { padding: 0 3%; }
  #hero-4-form { padding: 40px 25px 30px; text-align: center; margin-left: 0; margin-right: 0; }
  .hero-4-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  #hero-4-form h4 { font-size: 1.35rem; }
  #hero-4-form p { margin-bottom: 25px; }
  #hero-4-form .form-btn.mt-10 { margin-top: 0; }

  /* Hero-5 */
  .hero-5-txt { padding: 0 12%; margin-top: 40px; }
  .hero-5-list li { padding: 14px 5px; }
  .hero-5-list li.first-li { padding: 23px 5px 16px; }
  .hero-5-list li.last-li { padding: 16px 5px 20px; }
  .hero-5-list h4.h4-xl { font-size: 1.45rem; }
  .hero-5-list h5.h5-xl { font-size: 1.35rem; }

  /* Hero-6 */
  .hero-6-txt { padding: 0; }
  .hero-6-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  #hero-6 .quick-form { margin: 30px 2% 40px; }
  #hero-6 .quick-form .btn { padding: 13px 30px; }

  /* Hero-7 */
  #hero-7 { margin-bottom: 30px; }
  .hero-7-txt { padding: 0 5%; }
  .hero-7-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-7-txt .btns-group { margin-top: 30px; }
  .hero-7-txt .btns-group .btn.mr-15 { margin: 0 15px 15px; }
  .hero-7-img { margin: 40px 0 -40px; }

  /* Hero-8 */
  .hero-8-txt { padding: 0; }
  .hero-8-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-8-img { padding: 0; }
  #hero-8 .quick-form { margin: 30px 2% 50px; }
  #hero-8 .quick-form .btn { padding: 13px 30px; }

  /* Hero-9 */
  #hero-9 { background-position: center center; }
  #hero-9-form { text-align: center; padding: 40px 25px 25px; margin-left: 0; margin-right: 0; }
  #hero-9-form h4.h4-xs { font-size: 1.5rem; }
  #hero-9-form .form-btn.mt-10 { margin-top: 0; }

  /* Hero-10 */
  #hero-10 { margin-bottom: 60px; }
  .hero-10-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-10-txt p { padding: 0 3%; margin-bottom: 30px; }
  .hero-10-txt .btns-group .btn.mr-15 { margin: 0 15px 15px; }
  .hero-10-img { padding: 0; margin: 35px 0 -60px; }

  /* Hero-11 */
  .hero-11-txt { text-align: center; padding: 0 5%; margin-bottom: 40px; }
  .hero-11-txt h2 { font-size: 1.65rem; margin-bottom: 20px; }
  .hero-11-txt .os-btn { margin: 0 auto 20px; } 
  .hero-11-img { padding: 0 5%; }

  /* Hero-12 */
  #hero-12:after { height: 25%; top: 80%; }
  .hero-12-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-12-txt p { font-size: 1.05rem; padding-right: 0; }
  #hero-12:after { height: 27%; top: 73%; }
  .hero-12-txt .hero-logo-rounded { margin-bottom: 30px; }
  .hero-12-img { margin-top: 35px; }

  /* Hero-13 */
  .hero-13-title { margin-bottom: 15px; }
  .hero-13-title h2 { font-size: 1.8rem; }

  /* Hero-14 */
  .hero-14-txt{ text-align: center; }
  .hero-14-txt h2 { font-size: 1.85rem; margin-bottom: 15px; }

  /* Hero-15 */
  #hero-15 { overflow: visible; margin-bottom: 40px; }
  .hero-15-txt h2 { font-size: 1.85rem; margin-bottom: 25px; }
  .hero-15-txt p { padding: 0 3%; margin-bottom: 30px; }
  .hero-15-txt .btns-group .btn.mr-15 { margin: 0 15px 15px; }
  .hero-15-img { padding: 0; margin-top: 35px; margin-bottom: -40px; }

  /* Hero-16 */
  .hero-16-txt { text-align: center; padding: 0 5%; margin-bottom: 40px; }
  .hero-16-txt h2 { font-size: 1.9rem; margin-bottom: 20px; }
  .hero-16-txt .stores-badge { margin-bottom: 15px; }
  .hero-16-img { padding: 0 5%; }

  /* Hero-17 */
  .hero-17-title h2 { font-size: 1.85rem;  }
  .hero-17-img { margin-bottom: -30px; }

  /* Hero-18 */
  #hero-18-form { padding: 0 3%; }
  #hero-18-form h2 { font-size: 1.85rem;  }
  #hero-18-form p.p-lg { margin-top: 20px; margin-bottom: 25px; }
  #hero-18-form .form-btn { margin-top: 5px; }
  .hero-18-img { padding: 0 5%; margin-top: 40px; }

  /* Hero-19 */
  #hero-19 { margin-bottom: 50px; }
  .hero-19-txt { text-align: center; margin-top: 0; }
  .hero-19-txt h2 { font-size: 1.85rem; margin-bottom: 15px; }
  .hero-19-txt p.p-xl { margin-bottom: 25px;  padding: 0 3%; }
  .hero-19-img { margin: 30px 0 -50px; }

  /* Hero-20 */
  .hero-20-txt h2 { font-size: 1.8rem; margin-bottom: 20px; }
  .hero-20-txt { padding: 0; }
  .hero-20-txt p { padding: 0; margin-bottom: 25px; }
  .hero-20-txt .btn.mr-15 { margin: 0 5% 15px; }

  /* Hero-21 */
  .hero-21-txt h2 { font-size: 1.85rem; padding: 0 5%; }
  .hero-21-txt p.p-xl { padding: 0 3%; }
  #hero-21 .quick-form { margin: 30px 6% 0; }

  /* Hero-22 */
  .hero-22-wrapper { padding: 80px 15px 0; text-align: center; margin: 0 -15px 80px; 
  -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; }
  .hero-22-wrapper .txt-block { padding: 0 15px; margin-bottom: 40px; }
  .hero-22-wrapper .txt-block h3 { font-size: 1.8rem; }
  .hero-22-wrapper .img-block { margin-left: 3%; margin-right: 3%; }

  /* Hero-23 */
  .hero-23-txt { padding: 0; margin-bottom: 40px; }
  .hero-23-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-23-txt p.p-xl { font-size: 1.05rem; margin-bottom: 25px; }
  .hero-23-img { margin: 0 2% -60px; }

  /* Hero-24 */
  .hero-24-txt { padding-top: 80px; padding-bottom: 0; }
  .hero-24-txt h5 { padding: 0 5%; margin-bottom: 20px; }
  .hero-24-txt h2.tra-header { top: -20px; left: -2%; font-size: 13rem; }
  .hero-24-img img { padding: 0 15% 0 25%; }

  /* Hero Request Form */
  .request-form .form-control { height: 50px; font-size: 16px; }

  /* Hero Quick Form */
  .hero-section .quick-form .input-group { background-color: transparent; padding: 0; }
  .hero-section .quick-form .form-control, .rtl-direction .hero-section .quick-form .form-control { 
    background-color: #fff; text-align: center; height: 50px; font-size: 16px;
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .rtl-direction .hero-section .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 5px; border-bottom-left-radius: 5px; 
  }

  .hero-section .quick-form .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .hero-section .quick-form .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px; border-bottom-right-radius: 5px; 
  }

  .hero-section .quick-form .input-group-btn { display: block; width: 100%!important; }
  .hero-section .quick-form .btn { display: block; height: 50px; width: 100%!important; font-size: 16px; margin-top: 15px; 
     margin-left: 0; -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .rtl-direction .input-group .input-group-btn {order: 2; }
  .rtl-direction .input-group .email {order: 1; } 

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-2 { margin-bottom: 100px; }
  #about-1 .txt-block { padding: 0; }
  .about-2-title p { margin-top: 16px; }
  .about-2-images { margin-top: -10px; margin-bottom: -100px; }

  /* Features */
  #features-7 .txt-block p { padding: 0 2%; }

  .fbox-1, .fbox-3, .fbox-4 { padding: 0 8%; }
  .fbox-2 { padding: 0 5%; }
  .fbox-5 { padding: 0 15%; }
  .fbox-5.bg-white { padding: 40px 12%; margin: 10px 3% 60px; }
  .fbox-6 { padding: 45px 8%; margin-left: 3%; margin-right: 3%; }
  .fbox-6 h5, .fbox-8 p { padding: 0 5%; }
  .fbox-7 { padding: 45px 10%; margin-left: 3%; margin-right: 3%; } 

  .rtl-direction .fbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 10px; }
  .rtl-direction .fbox-ico { width: 100%; float: none; text-align: center!important; }

  .fbox-img { margin-left: 12%; margin-right: 12%; padding: 40px 10% 40px; } 
  .more-btn { margin-top: 5px; }

  /* Content */
  .content-5.division { padding-left: 0; padding-right: 0; }
  .content-5 { padding-top: 80px; padding-bottom: 40px; }
  .content-5-wrapper { padding: 0; }
  .content-5.content-section .txt-block.right-column, .content-5.content-section .txt-block.left-column { padding: 0; }
  .content-5.content-section .txt-block.left-column .cbox p { padding: 0 5%; }
  .rtl-direction .content-5 ul.simple-list { list-style: disc; }

  .content-10 .title-02 p { padding: 0 2%; }
  .content-section .top-row.pb-30,  .content-section .top-row.pb-50 { padding-bottom: 0; }

  .content-6 ul.simple-list { list-style: none; margin-left: 0; }

  .cbox-2 { width: 320px; min-width: 320px; padding: 20px 30px; }
  .cbox-2.mt-20.mb-30 { margin-top: 10px; }
  .cbox-4.left-column, .cbox-4.right-column { bottom: 15px; right: 5px; width: 58%; padding: 20px 15px; }
  .cbox-4.right-column { left: 5px; right: 0; }
  .cbox-5 { top: -20px; left: -20px; width: 62%; padding: 20px 15px; }
  .cbox-6 { bottom: -10px; right: -15px; width: 62%; padding: 20px 15px; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1rem; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.85rem; }
  .cbox-4 .text-center.mt-20, .cbox-5 .text-center.mt-20, .cbox-6 .text-center.mt-20 { margin-top: 10px; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 8px; margin-bottom: 8px; }
  .cbox-4 .btn, .cbox-5 .btn,.cbox-6 .btn { font-size: 0.85rem; margin-bottom: 8px; }

  .rtl-direction .cbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 10px; }
  .rtl-direction .cbox-ico { width: 100%; float: none; text-align: center!important; }

  .cb-wrapper { margin: 0 0 70px; }
  .cb-holder { padding: 50px 30px; margin: 0 6% 40px; }
  .cb-box-rounded { padding: 20px 15px; }
  .cb-single-box h2.statistic-number { font-size: 2.5rem; }
  .statistic-number sup { font-size: 2.25rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: -15px; top: -20px; }
  .cb-shape-2 { left: 10px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 12%; }

  .content-4 .txt-block p { margin-bottom: 5px; }
  .content-section .advantages li { padding-left: 3px; }
  .content-section .advantages li:after { top: -1px; padding-left: 7px; } 

  .content-10-btn { margin-top: 40px; }
  
  .content-section .img-block.left-column, .content-section .img-block.right-column { padding: 0; }
  .content-4-img { margin-top: 0; padding: 0; }

  /* Projects */
  #projects-2 { padding-bottom: 40px; }
  #projects-2 .masonry-image { margin-bottom: 40px; }
  #projects-1 .project-details, #projects-2 .project-details { padding: 0 3%; }
  #projects-1 .project-txt, #projects-2 .project-txt { padding: 0; }
  #projects-2 .more-btn.mt-20 { margin-top: 0; }

  .rtl-direction #projects-1 .project-txt, .rtl-direction #projects-2 .project-txt { padding-left: 0;padding-right: 0; }

  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 30px; }
  .project-data span { margin-right: 10px; }

  .project-image.top-img { margin-bottom: 30px; }
  .more-projects { margin-top: 40px; }

  /* Tabs */
  #tabs-1 .tabs-1 {  margin: 0 auto 30px; }
  #tabs-1 .txt-block { margin-bottom: 40px; }
  .tabs-1 li { padding: 9px 30px; }
  #tabs-2 .tabs-1 li { margin: 0 3% 15px; padding: 30px 35px; }

  .tabs-1 li span { font-size: 1rem; }

  /* Statistic */
  .statistic-1-wrapper .statistic-block { padding: 0; }
  #sb-2-3 { margin-bottom: 40px; }
  #statistic-4 .col-md-4 { width: 50%; float: left; }
  h2.statistic-number, #statistic-3 h3.statistic-number { font-size: 2.5rem; }
  #statistic-2 h2.statistic-number, #statistic-4 h2.statistic-number { font-size: 2.85rem; }
  #statistic-1 .statistic-block h5 { margin-top: 15px; margin-bottom: 12px; }
  #statistic-2 { padding-bottom: 80px; }
  #statistic-2 .col-lg-3 { margin-bottom: 30px; }
  #statistic-2 .txt-block { padding: 0; margin-bottom: 30px; }
  #statistic-2 .txt-block h4, #statistic-2 .txt-block h3 { padding: 0 8%; }
  #statistic-2 .txt-block-rating, #statistic-4 .statistic-block p.mt-20 { margin-top: 10px; }
  #statistic-2 .txt-block h4 { font-size: 1.5rem; }
  #statistic-2 .txt-block p { padding: 0 5%; }
  .statistic-block-link { margin-top: 15px; }

  .rtl-direction  #statistic-2 .col-lg-3 { margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 0; margin-top: 30px; margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block p { margin-top: 10px; }
  .rtl-direction .statistic-block-txt { padding-left: 0; padding-right: 0; }
  .rtl-direction #statistic-4 h6, #statistic-4 h5 { line-height: 1.4; padding-left: 0; margin-bottom: 0; margin-bottom: 15px;}

  /* Reviews */
  .reviews-3-column { margin-left: 3%; margin-right: 3%; }  
  .reviews-4-holder { padding-top: 0; padding-bottom: 0; }
  .reviews-4-holder:after { display: none; }

  #reviews-3 .txt-block { text-align: center; }
  #reviews-3 .txt-block p { padding: 0 3%; }

  .review-2 { margin-left: 3%; margin-right: 3%; }
  .review-3 { padding: 35px 30px;  }  
  
  .review-2 .review-avatar img, .review-4 .review-avatar img { width: 50px; height: 50px; }
  .review-3-avatar { width: 100%; display: inline-block!important; float: none; margin-bottom: 20px; margin-top: 0; }
  .review-3-txt { width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .review-3-txt p { padding-right: 0; }

  /* Pricing */
  .pricing-1-row .col, .pricing-2-row .col { text-align: center; }

  .pricing-1-table { padding: 40px 40px 35px; margin-left: 3%; margin-right: 3%; }
  .pricing-2-table { padding: 30px 45px 35px; margin-left: 3%; margin-right: 3%; }
  .pricing-3-table { padding: 45px 15%; margin-left: 3%; margin-right: 3%; }

  .pricing-1-table.rel, .pricing-3-table.rel { margin-top: 15px; }

  .pricing-plan-title { text-align: left; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 1rem; }

  .pricing-1-table .pricing-plan h5 { margin-bottom: 20px; }
  .pricing-3-table .pricing-plan h6 { margin-bottom: 25px; }

  .pricing-plan span { font-size: 2.85rem; }
  .pricing-3-table .pricing-plan span { font-size: 3.75rem; }
  .pricing-plan sup { font-size: 1.75rem; top: -5px; }
  .pricing-3-table .pricing-plan sup { font-size: 2.25rem; top: -8px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.25rem; top: -16px; }
  .pricing-plan sup.validity span { font-size: 2rem; }
  .pricing-plan sup.validity { font-size: 1.25rem; }

  .pricing-1-table .pricing-plan p { font-size: 1.05rem; padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { font-size: 1.05rem; margin-top: 10px; }
  .pricing-2-table .features p, .pricing-3-table .features p { font-size: 1.05rem; }

  .badge-wrapper { top: -19px; }
  .highlight-badge { padding: 10px 40px; }

  .payment-methods { text-align: center; margin-top: 0; }
  .payment-methods h6 { margin-bottom: 10px; }
  .pbox p { padding: 0 3%; }

  .pricing-notice { margin-top: 15px; }
  .pricing-notice p { padding: 0 5%; }

  .rtl-direction .pricing-plan-title { text-align: right; }

  /* Team */
  .team-member { margin-left: 5%; margin-right: 5%; }
  .team-member-data h5 { font-size: 1.25rem; }
  .team-member-data p { font-size: 1.125rem; }

  /* Brands */
  .brands-2-wrapper { padding: 0; }

  .brands-section h4 { padding: 0; margin-bottom: 25px; }
  .brands-section p { padding: 0; margin-bottom: 35px; }
  #brands-1.brands-section p { margin-bottom: 25px; padding: 0 5%; }
  #brands-1 .brand-logo { padding: 0 20px; }
  #brands-2 .brand-logo { padding: 0 15px; }

  /* FAQs */
  .accordion-item .accordion-thumb:after,
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.8rem; top: 23px; right: 0; }

  #faqs-2 .question { margin-bottom: 30px; }
  .accordion-thumb { padding: 20px 0; }
  .accordion-thumb h5 { line-height: 1.4; padding-right: 5%; }
  .rtl-direction .accordion-thumb h5 { line-height: 1.4; padding-left: 5%; padding-right: 0; }
  #faqs-2 .question h5 { line-height: 1.4; margin-bottom: 10px; }

  .rtl-direction .accordion-item .accordion-thumb:after,
  .rtl-direction .accordion-item.is-active .accordion-thumb:after {top: 21px;right: 97%;}

  .more-questions a { display: block; margin-top: 5px; }

  .more-questions { margin-top: 10px; }
  #faqs-1 .more-questions { margin-top: 30px; }

  /* Call To Action */
  .cta-1-wrapper { padding: 35px 8%; }
  .cta-2-txt p { padding: 0; }

  /* Download */
  span.version-data { font-size: 2rem; margin-right: 10px; }
  span.release-date span { margin-left: 10px; }

  /* Call To Action */
  #cta-6, #cta-9, #cta-10 { padding-top: 60px; padding-bottom: 60px; }
  #cta-1, #cta-5, #cta-11 { margin: 0 -15px; }
  #cta-1.division, #cta-3.division, #cta-4.division, #cta-5.division, #cta-11.division { padding-left: 0; padding-right: 0; }

  #cta-3 { margin-top: 110px; }
  #cta-4 { margin-bottom: 110px; }
  .cta-3-wrapper { padding: 40px 5%; margin-top: -120px; }
  .cta-4-wrapper { padding: 40px 10%; margin-bottom: -120px; }

  .cta-1-wrapper { padding: 50px 8%; }
  .cta-5-wrapper { padding: 60px 30px; }
  .cta-6-txt { padding: 0; }
  .cta-10-txt { padding: 0; margin-bottom: 40px; }
  .cta-11-wrapper { padding: 60px 30px; }

  .cta-1-wrapper, .cta-5-wrapper { -webkit-border-radius: 0;-moz-border-radius: 0;-o-border-radius: 0;border-radius: 0;
  -webkit-box-shadow: 0 0;-moz-box-shadow: 0 0;box-shadow: 0 0; }

  .cta-box {padding: 35px 30px; }
  .cta-txt {width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .cta-ico {width: 100%; display: inline-block!important; float: none; margin-top: 0; margin-bottom: 15px; }

  .cta-1-txt h4, .cta-3-txt h4 { font-size: 1.55rem; padding: 0 5%;  }
  .cta-2-txt h4, .cta-4-txt h4 { font-size: 1.55rem; padding: 0 10%; margin-bottom: 10px; }
  .cta-5-txt h3 { font-size: 1.625rem; padding: 0; margin-bottom: 10px; }
  .cta-6-txt h3 { font-size: 1.625rem; margin-bottom: 20px; }
  .cta-6-txt h4, .cta-6-txt h3, .cta-6-txt h2 { margin-bottom: 20px; padding: 0 3%; }
  .cta-9-txt h2 { padding: 0; margin-bottom: 15px; }
  .cta-10-txt h3 { padding: 0 5%; }
  .cta-11-txt h3 { font-size: 1.625rem; margin-bottom: 10px; }
  .cta-2-txt p { padding: 0 8%; margin-bottom: 20px; }
  .cta-9-txt p.p-xl { padding: 0 5%; margin-bottom: 30px; }

  .cta-10-img { margin-left: 5%; margin-right: 5%; }

  .cta-6-txt .btns-group { margin-bottom: 25px; }
  .cta-section .stores-badge { text-align: center; margin-top: 10px; padding: 0 5%; }
  .cta-section .stores-badge .store { float: none; margin-top: 15px; margin-right: 0; }
  .os-btn {width: 300px; min-width: 300px; padding: 25px 20px; }

  .cta-section .advantages li { padding-left: 2px; }
  .cta-section .advantages li:after { top: -1px; padding-left: 6px; } 

  /* Newsletter */
  #newsletter-1 h4.h4-xl { font-size: 1.5rem; }
  #newsletter-2 h4.h4-xl { font-size: 1.5rem; }
  #newsletter-1 { margin: 0 -15px; }
  #newsletter-1.division { padding-left: 0; padding-right: 0; }
  .newsletter-wrapper { border: none; padding: 40px 40px 20px;-webkit-border-radius: 0;-moz-border-radius: 0;
  -o-border-radius: 0;border-radius: 0;-webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0; }

  .newsletter-section hr { margin: 20px 0 0; }
  .newsletter-section .newsletter-txt h4 { font-size: 1.35rem; }
  #newsletter-2 .newsletter-txt { padding: 0 10%; }
  #newsletter-1 .newsletter-form { margin-top: 10px; }
  #newsletter-2 .newsletter-form { margin-top: 25px; padding: 0 8%; }
  .newsletter-section .form-control { 
    display: block; width: 100%!important; 
    text-align: center; 
    height: 48px; 
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 0.95rem; 
    margin-right: 0; 
    margin-bottom: 15px; 
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px;
  }

  #newsletter-2.newsletter-section .form-control { font-size: 1rem; border: 1px solid #ccc; }
  #newsletter-2.bg-lightgrey.newsletter-section .form-control, 
  #newsletter-2.bg-dark.newsletter-section .form-control { font-size: 1rem; border-bottom: 1px solid #ccc; }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .newsletter-section .form-control:focus { border: 1px solid #998da0; }

  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { 
    display: block; 
    height: 48px; 
    width: 100%!important; margin: 0; 
  }

  .rtl-direction #newsletter-2.newsletter-section .form-control { margin-left: 0; } 

  /* Blog */
  #blog-page .masonry-image { width: 100%; padding: 0 3%; }
  .blog-1-post, .blog-2-post, .featured-post { margin-left: 3%; margin-right: 3%; }
  
  .blog-1-post .blog-post-txt { margin-top: 30px; padding-left: 10px; padding-right: 0; }
  .blog-2-post .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-txt { margin-top: 30px; padding-left: 3%; padding-right: 0; }
  #blog-page .blog-post-wide .blog-post-txt { margin-top: 0; }

   h5.posts-category { margin-bottom: 30px; margin-left: 3%; margin-right: 3%; }

  /* Blog Listing */
  #blog-page .title-02.mb-85 { margin-bottom: 50px; }
  #blog-page .blog-post-wide .blog-post-txt { padding: 30px 30px; }

  /* Single Post */
  #single-post .post-data { margin-top: 30px; margin-bottom: 35px; }
  #single-post .post-author-avatar img { width: 44px; height: 44px; margin: 0 8px 0 0; }
  .single-post-txt h4, .single-post-txt h3 { margin-bottom: 20px; }
  .single-post-txt h5 { margin-top: 17px; margin-bottom: 20px; }

  #single-post .quote.mt-30.mb-35 { margin-top: 20px; margin-bottom: 25px; }
  .post-inner-img { margin-top: 30px; margin-bottom: 35px; }

  .post-share-links { margin-top: 40px; }
  .post-tags-list span a { font-size: 0.9rem; padding: 4px 8px; margin-right: 3px; }

  .about-post-author { padding: 60px 4%; }
  .author-senoff img { width: 70px; height: 70px; float: none; }
  .author-senoff-txt { padding-left: 0; padding-top: 25px; }
  .author-follow-btn { font-size: 0.95rem; padding: 4px 12px; top: 15px; right: 15px; }

  .post-comments img { width: 50px; height: 50px; }
  .comment-body { margin-left: 15px; }

  .comment-form { margin-top: 40px; }
  .comment-form .form-control { height: 48px; font-size: 1rem; }
  .comment-form textarea { min-height: 150px; }
  .comment-form textarea.form-control { padding: 15px 20px; }

  /* Contacts */
  #contacts-1 { padding-top: 80px; }
  #contacts-2 .title-01 h3 { font-size: 1.625rem; padding: 0 5%; }
  #contacts-1-form { padding: 25px 20px 15px; margin: 0 0 30px; }
  #contacts-2 .contact-form p { margin-bottom: 10px; }
  #contacts-2 .contact-form span { line-height: 1.3; margin-bottom: 15px; }

  .contact-form .form-control, .contact-form .form-select { height: 52px; }
  .contact-form textarea { min-height: 170px; }

  /* Footer */
  .footer { padding-top: 80px; padding-bottom: 30px; }
  #footer-1 .col-sm-6, #footer-2 .col-sm-6, #footer-3 .col-sm-4, #footer-4 .col-sm-6 { width: 50%; float: left; }
  .rtl-direction #footer-3 .col-sm-4.pl-50 { width: 50%; margin-left: 48%; }

  #footer-1 .footer-links { padding-left: 0; }
  img.footer-logo { max-height: 35px; }
  .footer hr { margin-top: 10px; }

  .footer-info p { padding-right: 0; }
  .footer h6, .footer h5 { margin-bottom: 20px; }
  .footer p.p-md, .bottom-footer p { font-size: 1rem; } 
  .footer-socials.ico-25 a { margin-right: 12px; }
  .footer-socials.ico-25 [class^="flaticon-"]:before, .footer-socials.ico-25 [class^="flaticon-"]:after { font-size: 1.3rem; }

  .footer-form .newsletter-form .input-group { padding: 0 2px; }
  .footer-form .form-control { height: 50px; font-size: 1rem; }
  .footer-form .btn { height: 50px; padding: 0 15px; }

  .bottom-footer { margin-top: 0; padding-top: 30px; }
  .bottom-footer-list.text-end { text-align: left!important; margin-top: 5px; }

  .bottom-footer-list li { padding-left: 2px; }
  .bottom-footer-list li:after { top: -1px; padding-left: 6px; } 

  /* Inner Page Hero */
  .page-hero-section { padding-top: 55px; padding-bottom: 55px; }
  .page-hero-section p { padding: 0; margin-top: 15px; }

  /* Pagination */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* Terms Page */
  #terms-page.inner-page-hero { margin-top: 70px; padding-top: 70px; }
  .terms-title { margin-bottom: 60px; }
  .terms-title p { margin-top: 15px; }
  .terms-box { margin-bottom: 20px; }
  .terms-box h5 { margin-bottom: 15px; }

  /* Register Page */
  #login-2 .register-form-title, #signup-2 .register-form-title { margin-bottom: 20px; }
  .reset-password-section .register-form-title { margin-bottom: 25px; }

  .register-form-title h4, .register-form-title h3 { font-size: 1.85rem; margin-bottom: 10px; }
  .reset-password-section .register-form-title h4 { font-size: 1.65rem; line-height: 1.3; margin-bottom: 14px; }
  .reset-password-section .register-form-title p { padding: 0 3%; }
  .register-form-title p { font-size: 0.95rem; }

  .login-separator-txt { font-size: 0.95rem; }

  .login-page-logo { margin-bottom: 40px; }
  #reset-password-1 .login-page-logo {margin-bottom: 30px; }
  .login-page-logo img { max-height: 40px; }
  #reset-password-1 .login-page-logo img {max-height: 40px; }

  .form-data { display: block!important; text-align: center; }
  #signup-1 .form-data, #signup-2 .form-data, #signup-3 .form-data { text-align: left; }
  .form-check { display: inline-block; margin: 0 auto 10px; }
  .form-check .form-check-label, p.forgot-password { font-size: 0.9375rem; }

  .register-form p.create-account { font-size: 0.95rem; margin-top: 30px; }

  .sign-in-notice { padding: 0 8%; margin-top: 30px; }
  .sign-in-notice p { font-size: 0.9375rem; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -850%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -850%; }

  #login-2 .login-separator-txt:before, #signup-2 .login-separator-txt:before { left: -790%; }
  #login-2 .login-separator-txt:after, #signup-2 .login-separator-txt:after { right: -790%; }

  #login-3 .login-separator-txt:before, #signup-3 .login-separator-txt:before { left: -850%; }
  #login-3 .login-separator-txt:after, #signup-3 .login-separator-txt:after { right: -850%; }

  #login-2 .register-form form, #signup-2 .register-form form { padding: 45px 10px 50px; }
  .register-form form, #login-2 .register-form form, #signup-2 .register-form form, #reset-password-1 .register-form form { margin: 0; }
  .register-form .form-control { height: 50px; font-size: 1rem; }
  .register-form .btn { height: 50px; font-size: 1rem; }

  .btn-show-pass { top: 16px; right: 15px; }
  .btn-show-pass.ico-20 [class^="flaticon-"]:before, .btn-show-pass.ico-20 [class^="flaticon-"]:after { font-size: 1.05rem; }

  .register-form .btn-facebook, .register-form .btn-google { padding: 0; line-height: 50px; }
  .btn-google img, .btn-facebook img { width: 18px; height: 18px; margin-top: -3px; margin-right: 5px; }

  .reset-password-img { margin: 0 0 50px; }

  /* Scroll To Top */
  #scrollUp { width: 38px; height: 38px; bottom: 25px; right: 10px; }





}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 321px) and (max-width: 413.95px) {

  #stlChanger { display: none; }

  .division { padding-left: 0; padding-right: 0; }
  .wave-shape-bottom { bottom: -2px; }

  .ts-0 { padding-top: 80px; }

  h6, h5, h4, h3 { line-height: 1.35; }

  /* Header H6 */
  h6.h6-xs { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-sm { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-md { font-size: 1.0625rem; }  /* 17px */
  h6.h6-lg { font-size: 1.0625rem; }  /* 17px */
  h6.h6-xl { font-size: 1.0625rem; }  /* 17px */

  .rtl-direction h6.h6-xs { font-size: 1.125rem; }   
  .rtl-direction h6.h6-sm { font-size: 1.125rem; }  
  .rtl-direction h6.h6-md { font-size: 1.125rem; }   
  .rtl-direction h6.h6-lg { font-size: 1.125rem; }  
  .rtl-direction h6.h6-xl { font-size: 1.125rem; }

  /* Header H52 */
  h5.h5-xs { font-size: 1.125rem; }   
  h5.h5-sm { font-size: 1.125rem; }  
  h5.h5-md { font-size: 1.125rem; }     
  h5.h5-lg { font-size: 1.125rem; }   
  h5.h5-xl { font-size: 1.125rem; }   /* 18px */

  .rtl-direction h5.h5-xs { font-size: 1.25rem; }   
  .rtl-direction h5.h5-sm { font-size: 1.25rem; }  
  .rtl-direction h5.h5-md { font-size: 1.25rem; }   
  .rtl-direction h5.h5-lg { font-size: 1.25rem; }  
  .rtl-direction h5.h5-xl { font-size: 1.25rem; }

  /* Header H4 */
  h4.h4-xs { font-size: 1.25rem; }   
  h4.h4-sm { font-size: 1.25rem; }     
  h4.h4-md { font-size: 1.25rem; }  
  h4.h4-lg { font-size: 1.25rem; }   
  h4.h4-xl { font-size: 1.25rem; }    /* 20px */

  /* Header H3 */
  h3.h3-xs { font-size: 1.5rem; } 
  h3.h3-sm { font-size: 1.5rem; }   
  h3.h3-md { font-size: 1.5rem; }  
  h3.h3-lg { font-size: 1.5rem; } 
  h3.h3-xl { font-size: 1.5rem; }     /* 24px */ 

  /* Header H2 */
  h2.h2-xs { font-size: 1.625rem; }  
  h2.h2-sm { font-size: 1.625rem; }  
  h2.h2-md { font-size: 1.625rem; }  
  h2.h2-lg { font-size: 1.625rem; }  
  h2.h2-xl { font-size: 1.625rem; }   /* 26px */

  h2.h2-title-xs { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-sm { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-md { font-size: 2rem; }      /* 32px */
  h2.h2-title-lg { font-size: 2rem; }      /* 32px */
  h2.h2-title-xl { font-size: 2.125rem; }  /* 34px */ 

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; }     
  p { font-size: 0.9375rem; }       
  p.p-md { font-size: 0.9375rem; }      
  p.p-lg { font-size: 1rem; }  
  p.p-xl { font-size: 1rem; } 

  p.p-title-xs { font-size: 1.05rem; } 
  p.p-title-sm { font-size: 1.05rem; } 
  p.p-title-md { font-size: 1.05rem; }   
  p.p-title-lg { font-size: 1.1rem; }  
  p.p-title-xl { font-size: 1.1rem; } 

  .rtl-direction p.p-md { font-size: 1.1rem; }  
  .rtl-direction p.p-lg { font-size: 1.1rem; } 
  .rtl-direction p.p-xl { font-size: 1.1rem; } 

  /* Button */
  .btn, .btn.btn-md, .btn.btn-lg { font-size: 0.9375rem; padding: 12px 32px; }
  .btn-transparent, .btn.btn-md.btn-transparent, .btn.btn-lg.btn-transparent { padding: 8px 4px; }

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.75rem; }
  .btn.ico-20.ico-left span { right: 5px; }

  /* Watch Video Link */
  .watch-video-link { width: 48px; height: 48px; margin-right: 11px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 46px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 1px; }

  /* Video Button */
  .video-btn-xl { width: 70px; height: 70px; margin-top: -35px; margin-left: -35px; }
  .video-btn-lg { width: 70px; height: 70px; margin-top: -35px;margin-left: -35px; }
  .video-btn-md { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }
  .video-btn-sm { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, .video-btn.video-btn-sm [class^="flaticon-"]:after {
    font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-xl:hover:before, 
  .video-btn.video-btn-lg:hover:before { left: -16px; right: -16px; top: -16px; bottom: -16px; }
  .video-btn.video-btn-md:hover:before,
  .video-btn.video-btn-sm:hover:before { left: -12px; right: -12px; top: -12px; bottom: -12px; }

  /* Store Badges */
  .store { margin-right: 10px; }
  .store img { max-height: 44px; }
  .footer .store img { max-height: 48px; }
  span.os-version { font-size: 0.9375rem; line-height: 1.25; font-weight: 300; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }   
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.1rem; }     
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.375rem; }  
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.65rem; }   
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.925rem; }  
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.2rem; }     
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.475rem; }  
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.75rem; }   
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.025rem; }  
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.3rem; }     
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.575rem; }  
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.85rem; }   
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.125rem; }  
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 4.4rem; }   
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4.675rem; }  
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.95rem; }    
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.225rem; }  
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 5.5rem; }   
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 5.775rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.05rem; } 
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 6.325rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 6.6rem; }   
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 6.875rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 70px; height: 70px; }
  .ico-rounded-sm { width: 75px; height: 75px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 85px; height: 85px; }
  .ico-rounded-xl { width: 95px; height: 95px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 70px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 75px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 80px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 85px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 95px!important; }

  /* PNG Icons */
  .ico-125 img { width: 110px; height: 110px; }
  .ico-120 img { width: 105px; height: 105px; }
  .ico-115 img { width: 101px; height: 101px; }
  .ico-110 img { width: 97px; height: 97px; }
  .ico-105 img { width: 92px; height: 92px; }
  .ico-100 img { width: 88px; height: 88px; }
  .ico-95 img { width: 84px; height: 84px; }
  .ico-90 img { width: 79px; height: 79px; }
  .ico-85 img { width: 75px; height: 75px; }
  .ico-80 img { width: 70px; height: 70px; }
  .ico-75 img { width: 66px; height: 66px; }
  .ico-70 img { width: 62px; height: 62px; }
  .ico-65 img { width: 57px; height: 57px; }
  .ico-60 img { width: 53px; height: 53px; }
  .ico-55 img { width: 48px; height: 48px; }
  .ico-50 img { width: 44px; height: 44px; }
  .ico-45 img { width: 40px; height: 40px; }
  .ico-40 img { width: 35px; height: 35px; }
  .ico-35 img { width: 31px; height: 31px; }
  .ico-30 img { width: 26px; height: 26px; }
  .ico-25 img { width: 22px; height: 22px; }
  .ico-20 img { width: 20px; height: 18px; }

  /* Section Id */
  .section-id { font-size: 1rem; margin-bottom: 22px; }
  .section-id.txt-upcase { font-size: 0.825rem; }
  .section-title .section-id, .section-title-left .section-id { margin-bottom: 20px; }

  /* Section Title */
  .title-04 { text-align: center; padding: 0; }

  .section-title h3 { padding: 0 5%; }
  .section-title.title-04 h3 { padding: 0; }
  .title-01 p { padding: 0; margin-top: 15px; }
  .section-title.ico-35 [class*="flaticon-"]:before, .section-title.ico-35 [class*="flaticon-"]:after { font-size: 1.2rem; } 

  /* Text Block Typography */
  .txt-block h3, .txt-block h2, .content-14-txt h4, .content-14-txt h3 { margin-bottom: 16px; }
  h5.sm-title { margin-top: 15px; margin-bottom: 15px; }
  .txt-block .btn, .content-14-txt .btn, .txt-block .stores-badge { margin-top: 15px; }
  .txt-block .btns-group .btn.mr-15 { margin-left: 15px; margin-right: 15px; }

  .advantages li { display: block!important; text-align: center; padding-left: 0;  }  
  .advantages li:after {  display: none; }
  .advantages li p { display: block; float: none; margin-bottom: 0; margin-top: 5px; }

  /* Terms Text */
  .terms-txt a { display: block; }

  /* Tools List */
  .tools-list span { padding-right: 4px; }

  /* Text Block Rating */
  .txt-block-rating { text-align: center; }
  .txt-block-rating [class*="flaticon-"]:before, .txt-block-rating [class*="flaticon-"]:after { font-size: 1rem; }
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating { font-size: 0.9375rem; }
  .stars-rating p { font-size: 0.9375rem; padding: 0 10%; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .wsmenu > .wsmenu-list { width: 290px; margin-right: -290px; }
  .wsactive .wsmobileheader { margin-right: 290px; }
  .overlapblackbg { width: calc(100% - 290px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 18px 0 0; }

  .wsmobileheader .smllogo { display: block; margin-top: 22px; padding-left: 18px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 36px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  /* Hero App Logo */
  .hero-logo-rounded span { font-size: 0.875rem; }

  /* Hero Brands */
  .hero-brands { margin-top: 30px; padding: 0; } 
  .hero-brands p { padding: 0 5%; margin-bottom: 20px; }
  .hero-brands .brand-logo { padding: 0 15px; }

  /* Hero-1 */
  .hero-1-txt { padding: 0 4%; margin-bottom: 30px; }
  .hero-1-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  .hero-1-txt p.p-xl { font-size: 1.05rem; padding-right: 0; margin-bottom: 30px; }
  .hero-1-img { padding: 0 5%; }

  /* Hero-2 */
  #hero-2 { margin-bottom: 50px; }
  .hero-2-txt { padding: 0; }
  .hero-2-txt h2 { font-size: 1.75rem; margin-bottom: 15px; }
  .hero-2-txt p.p-xl { font-size: 1.05rem; padding: 0 5%; margin-bottom: 30px; }
  .hero-2-img { padding: 0 3%; }

  /* Hero-3 */
  .hero-3-txt h2 { font-size: 1.85rem; padding: 0; margin-bottom: 25px; }
  .hero-3-txt p.p-xl { padding: 0; margin-bottom: 30px; }

  .hero-3-img { margin-right: 0; margin-bottom: -40px; }

  /* Hero-4 */
  .hero-4-txt { padding: 0; }
  .hero-4-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  #hero-4-form { padding: 40px 15px 30px; text-align: center; margin-left: 0; margin-right: 0; }
  #hero-4-form h4 { font-size: 1.35rem; }
  #hero-4-form p { margin-bottom: 25px; }
  #hero-4-form .form-btn.mt-10 { margin-top: 0; }
  #hero-4-form .form-btn .btn { font-size: 1.05rem; }

  /* Hero-5 */
  .hero-5-txt { padding: 0 8%; margin-top: 40px; }
  .hero-5-list li { padding: 14px 5px; }
  .hero-5-list li.first-li { padding: 23px 5px 16px; }
  .hero-5-list li.last-li { padding: 16px 5px 20px; }
  .hero-5-list h4.h4-xl { font-size: 1.4rem; }
  .hero-5-list h5.h5-xl { font-size: 1.3rem; }

  /* Hero-6 */
  .hero-6-txt { padding: 0; }
  .hero-6-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  #hero-6 .quick-form { margin: 30px 2% 40px; }
  #hero-6 .quick-form .btn { padding: 13px 30px; }

  /* Hero-7 */
  #hero-7 { margin-bottom: 30px; }
  .hero-7-txt { padding: 0; }
  .hero-7-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-7-txt .btns-group { margin-top: 30px; }
  .hero-7-img { margin: 40px 0 -40px; }

  /* Hero-8 */
  .hero-8-txt { padding: 0; }
  .hero-8-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-8-img { padding: 0; }
  #hero-8 .quick-form { margin: 30px 2% 50px; }
  #hero-8 .quick-form .btn { padding: 13px 30px; }

  /* Hero-9 */
  #hero-9 { background-position: right center; }
  .col-md-7 #hero-9-form { margin: 0 -10px; }
  #hero-9-form { text-align: center; padding: 35px 15px 25px; margin-left: 0; margin-right: 0; }
  #hero-9-form h4.h4-xs { font-size: 1.35rem; }
  #hero-9-form .form-btn.mt-10 { margin-top: 0; }
  #hero-9-form .form-btn .btn { font-size: 1.05rem; }

  /* Hero-10 */
  #hero-10 { margin-bottom: 60px; }
  .hero-10-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-10-txt p { padding: 0; margin-bottom: 30px; }
  .hero-10-img { padding: 0; margin: 35px 0 -60px; }

  /* Hero-11 */
  .hero-11-txt { text-align: center; padding: 0 6%; margin-bottom: 40px; }
  .hero-11-txt h2 { font-size: 1.7rem; margin-bottom: 20px; }
  .hero-11-txt .os-btn { margin: 0 auto 20px; } 
  .hero-11-img { padding: 0 5%; }

  /* Hero-12 */
  #hero-12:after { height: 25%; top: 80%; }
  .hero-12-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-12-txt p { font-size: 1.05rem; padding-right: 0; }
  .hero-12-txt .hero-logo-rounded { margin-bottom: 30px; }
  .hero-12-img { margin-top: 30px; }

  /* Hero-13 */
  .hero-13-title { margin-bottom: 15px; }
  .hero-13-title h2 { font-size: 1.85rem; }

  /* Hero-14 */
  .hero-14-txt{ text-align: center; }
  .hero-14-txt h2 { font-size: 1.85rem; margin-bottom: 15px; }

  /* Hero-15 */
  #hero-15 { overflow: visible; margin-bottom: 40px; }
  .hero-15-txt h2 { font-size: 1.85rem; margin-bottom: 25px; }
  .hero-15-txt p { padding: 0; margin-bottom: 30px; }
  .hero-15-img { padding: 0; margin-top: 35px; margin-bottom: -40px; }

  /* Hero-16 */
  .hero-16-txt { text-align: center; padding: 0; margin-bottom: 40px; }
  .hero-16-txt h2 { font-size: 1.85rem; margin-bottom: 20px; }
  .hero-16-txt p { padding: 0 5%; }
  .hero-16-txt .stores-badge { margin-bottom: 15px; } 
  .hero-16-img { padding: 0 5%; }

  /* Hero-17 */
  .hero-17-title h2 { font-size: 1.85rem;  }
  .hero-17-txt .os-btn { margin-left: 6%; margin-right: 6%; }
  .hero-17-img { margin-bottom: -30px; }

  /* Hero-18 */
  #hero-18-form { padding: 0; }
  #hero-18-form h2 { font-size: 1.85rem;  }
  #hero-18-form p.p-lg { margin-top: 20px; margin-bottom: 25px; }
  #hero-18-form .form-btn { margin-top: 5px; }
  .hero-18-img { padding: 0 3%; margin-top: 40px; }

  /* Hero-19 */
  #hero-19 { margin-bottom: 50px; }
  .hero-19-txt { text-align: center; margin-top: 0; }
  .hero-19-txt h2 { font-size: 1.85rem; margin-bottom: 15px; }
  .hero-19-txt p.p-xl { padding: 0 3%; margin-bottom: 25px; }
  .hero-19-img { margin: 30px 0 -50px; }

  /* Hero-20 */
  .hero-20-txt h2 { font-size: 1.85rem; padding: 0 3%; margin-bottom: 15px; }
  .hero-20-txt { padding: 0 5%; }
  .hero-20-txt p { padding: 0 3%; margin-bottom: 25px; }

  /* Hero-21 */
  .hero-21-txt h2 { font-size: 1.8rem; padding: 0 5%; }
  .hero-21-txt p.p-xl { padding: 0; }
  #hero-21 .quick-form { margin: 30px 4% 0; }

  /* Hero-22 */
  .hero-22-wrapper { padding: 80px 15px 0; text-align: center; margin: 0 -15px 80px; 
  -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; }
  .hero-22-wrapper .txt-block { padding: 0 15px; margin-bottom: 40px; }
  .hero-22-wrapper .txt-block h3 { font-size: 1.8rem; }
  .hero-22-wrapper .img-block { margin-left: 3%; margin-right: 3%; }

  /* Hero-23 */
  .hero-23-txt { padding: 0 3%; margin-bottom: 40px; }
  .hero-23-txt h2 { font-size: 1.75rem; padding: 0 3%; margin-bottom: 20px; }
  .hero-23-txt p.p-xl { font-size: 1.05rem; padding: 0 3%; margin-bottom: 25px; }
  .hero-23-img { margin: 0 4% -60px; }

  /* Hero-24 */
  .hero-24-txt { padding-top: 80px; padding-bottom: 0; }
  .hero-24-txt h3, .hero-24-txt h2 { padding-bottom: 5px; }
  .hero-24-txt h5 { padding: 0 5%; margin-bottom: 20px; }
  .hero-24-txt h2.tra-header { top: -20px; left: -10%; font-size: 13rem; }
  .hero-24-img img { padding: 0 15% 0 25%; }

  /* Hero Buttons */
  .hero-section .btns-group .btn.mr-15 { margin: 0 20px 15px; }

  /* Hero Request Form */
  .request-form .form-control { height: 48px; font-size: 16px; }

  /* Hero Quick Form */
  .hero-section .quick-form .input-group { background-color: transparent; padding: 0; }
  .hero-section .quick-form .form-control { background-color: #fff; text-align: center; height: 50px; font-size: 16px;
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .hero-section .quick-form .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .hero-section .quick-form .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px; border-bottom-right-radius: 5px; 
  }

  .rtl-direction .hero-section .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 5px; border-bottom-left-radius: 5px; 
  }

  .hero-section .quick-form .input-group-btn { display: block; width: 100%!important; }
  .hero-section .quick-form .btn { display: block; height: 50px; width: 100%!important; font-size: 16px; margin-top: 15px; 
    margin-left: 0; -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .rtl-direction .input-group .input-group-btn {order: 2; }
  .rtl-direction .input-group .email {order: 1; } 

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-2 { margin-bottom: 100px; }
  #about-1 .txt-block { padding: 0; }
  .about-2-title p { margin-top: 16px; }
  .about-2-images { margin-top: -10px; margin-bottom: -100px; }

  /* Features */
  #features-7 .txt-block p { padding: 0 3%; }

  .fbox-1 { padding: 0 8%; }
  .fbox-2 { padding: 0 5%; }
  .fbox-3, .fbox-4 { padding: 0 5%; } 
  .fbox-5 { padding: 0 12%; }
  .fbox-5.bg-white { padding: 40px 10%; margin: 10px 3% 60px; }
  .fbox-6 { padding: 45px 10%; margin-left: 3%; margin-right: 3%; }
  .fbox-6 h5 { padding: 0 3%; }
  .fbox-7 { padding: 45px 8%; margin-left: 3%; margin-right: 3%; } 
  .fbox-8 p { padding: 0 5%; }

  .rtl-direction .fbox-txt { width: 100%; text-align: center; padding: 0 3%; margin-top: 10px; }
  .rtl-direction .fbox-ico { width: 100%; float: none; text-align: center!important; }

  .fbox-img { margin-left: 12%; margin-right: 12%; padding: 40px 10% 40px; } 
  .more-btn { margin-top: 5px; }

  /* Content */
  .content-5.division { padding-left: 0; padding-right: 0; }
  .content-5 { padding-top: 80px; padding-bottom: 40px; }
  .content-5-wrapper { padding: 0; }
  .content-5.content-section .txt-block.left-column { padding: 0; }
  .content-5.content-section .txt-block.right-column { padding: 0; }

  .content-section .top-row.pb-30,  .content-section .top-row.pb-50 { padding-bottom: 0; }
  .content-8 .section-title h2 { padding: 0 3%; }

  .content-6 ul.simple-list { list-style: none; margin-left: 0; }

  .cbox { padding: 0 5%; }
  .content-5 .cbox { padding: 0 5%; }
  .cbox-2 { width: 320px; min-width: 320px; padding: 20px 30px; }
  .cbox-2.mt-20.mb-30 { margin-top: 10px; }
  .cbox-4.left-column, .cbox-4.right-column { bottom: 15px; right: 5px; width: 60%; padding: 20px 10px; }
  .cbox-4.right-column { left: 5px; right: 0; }
  .cbox-5 { top: -20px; left: -20px; width: 66%; padding: 20px 10px; }
  .cbox-6 { bottom: -10px; right: -15px; width: 66%; padding: 20px 10px; }

  .rtl-direction .cbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 10px; }
  .rtl-direction .cbox-ico { width: 100%; float: none; text-align: center!important; }
  .rtl-direction .cbox-3-txt { display: block!important; padding-left: 0; padding-right: 38px; }
  .rtl-direction .content-5 ul.simple-list { list-style: disc; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1rem; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.85rem; }
  .cbox-4 .text-center.mt-20, .cbox-5 .text-center.mt-20, .cbox-6 .text-center.mt-20 { margin-top: 10px; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 8px; margin-bottom: 8px; }
  .cbox-4 .btn, .cbox-5 .btn,.cbox-6 .btn { font-size: 0.85rem; margin-bottom: 8px; }

  .cb-wrapper { margin: 0 0 70px; }
  .cb-holder { padding: 50px 25px; margin: 0 5% 40px; }
  .cb-box-rounded { padding: 20px 15px; }
  .cb-single-box h2.statistic-number { font-size: 2.5rem; }
  .statistic-number sup { font-size: 2.25rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: -15px; top: -20px; }
  .cb-shape-2 { left: 10px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 12%; }

  .content-4 .txt-block p { margin-bottom: 5px; }
  .content-section .advantages li { padding-left: 3px; }
  .content-section .advantages li:after { top: -1px; padding-left: 7px; } 

  .content-10-btn { margin-top: 40px; }
  
  .content-section .img-block.left-column, .content-section .img-block.right-column { padding: 0; }
  .content-4-img { margin-top: 0; padding: 0; }

  /* Projects */
  #projects-2 { padding-bottom: 40px; }
  #projects-2 .masonry-image { margin-bottom: 40px; }
  #projects-1 .project-details, #projects-2 .project-details { padding: 0 2%; }
  #projects-1 .project-txt, #projects-2 .project-txt { padding: 0; }
  #projects-2 .more-btn.mt-20 { margin-top: 0; }

  .rtl-direction #projects-1 .project-txt, .rtl-direction #projects-2 .project-txt { padding-left: 0;padding-right: 0; }

  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 30px; }
  .project-data span { margin-right: 10px; }

  .project-image.top-img { margin-bottom: 30px; }
  .more-projects { margin-top: 50px; }

  /* Tabs */
  #tabs-1 .tabs-1 {  margin: 0 auto 40px; }
  #tabs-1 .txt-block { margin-bottom: 40px; }
  .tabs-1 li { padding: 9px 30px; }
  #tabs-2 .tabs-1 li { margin: 0 3% 15px; padding: 30px 20px; }

  .tabs-1 li span { font-size: 1rem; }

  /* Statistic */
  #statistic-1.pt-70.pb-70 { padding-top: 60px; padding-bottom: 60px; }
  .statistic-1-wrapper .statistic-block { padding: 0 8%; }
  #sb-2-3 { margin-bottom: 40px; }
  h2.statistic-number, #statistic-3 h3.statistic-number { font-size: 2.5rem; }
  #statistic-2 h2.statistic-number, #statistic-4 h2.statistic-number { font-size: 2.75rem; }
  #statistic-1 .statistic-block h5 { margin-top: 15px; margin-bottom: 12px; }
  #statistic-2 { padding-bottom: 80px; }
  #statistic-2 .col-lg-3 { margin-bottom: 30px; }
  #statistic-4 .statistic-block { padding: 0 12%; }
  #statistic-2 .txt-block { padding: 0; margin-bottom: 30px; }
  #statistic-2 .txt-block h4 { font-size: 1.5rem; }
  #statistic-2 .txt-block-rating, #statistic-4 .statistic-block p.mt-20 { margin-top: 10px; }
  .statistic-block-link { margin-top: 15px; }

  .rtl-direction  #statistic-2 .col-lg-3 { margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 0; margin-top: 30px; margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block p { margin-top: 10px; }
  .rtl-direction .statistic-block-txt { padding-left: 0; padding-right: 0; }
  .rtl-direction #statistic-4 h6, #statistic-4 h5 { line-height: 1.4; padding-left: 0; margin-bottom: 0; margin-bottom: 15px;}

  /* Reviews */
  .reviews-4-holder { padding-top: 0; padding-bottom: 0; }
  .reviews-4-holder:after { display: none; }
  .review-1-txt, .review-2-txt { width: 100%; padding-left: 0; }
  #reviews-3 .txt-block { text-align: center; }
  #reviews-3 .txt-block p { padding: 0 3%; }

  .rtl-direction .review-1-txt, .rtl-direction .review-2-txt, .rtl-direction .review-3-txt { padding-left: 0; padding-right: 0; }

  .review-1 { padding: 30px 20px; margin: 0 10px 20px; }  
  .review-2 { padding: 30px 20px; margin-left: 3%; margin-right: 3%; }  
  .review-3 { padding: 30px 50px; } 
  .review-4 { padding: 25px 30px ; margin: 0 3% 20px; } 

  .right-column #rw-3-1, .right-column #rw-3-2, .right-column #rw-3-3, 
  .left-column #rw-3-1, .left-column #rw-3-2, .left-column #rw-3-3 {
    margin-right: 3%;
    margin-left: 3%;
  }

  .review-1-ico, .review-2-ico { width: 10%; margin-top: 0; margin-bottom: 5px; }

  .review-2 .review-avatar img { width: 50px; height: 50px; }
  .review-3-avatar { width: 100%; display: inline-block!important; float: none; margin-bottom: 20px; margin-top: 0; }
  .review-3-txt { width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .review-3-txt p { padding-right: 0; }
  .review-3-txt a { display: block; margin-top: 10px; }

  .review-4 .review-avatar img { width: 60px; height: 60px; }

  /* Team */
  .team-member { margin-left: 5%; margin-right: 5%; }
  .team-member-data h5 { font-size: 1.25rem; }
  .team-member-data p { font-size: 1.125rem; }

  /* Pricing */
  .pricing-1-row .col, .pricing-2-row .col { text-align: center; }

  .pricing-1-table { padding: 40px 35px 35px; margin-left: 3%; margin-right: 3%; }
  .pricing-2-table { padding: 30px 40px 35px; margin-left: 3%; margin-right: 3%; }
  .pricing-3-table { padding: 45px 40px; margin-left: 3%; margin-right: 3%; }

  .pricing-1-table.rel, .pricing-3-table.rel { margin-top: 15px; }

  .pricing-plan-title { text-align: left; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 1rem; }

  .rtl-direction .pricing-plan-title { text-align: right; }
  .rtl-direction .pricing-plan-title h6 {width: auto; right: 55%;}

  .pricing-1-table .pricing-plan h5 { margin-bottom: 20px; }
  .pricing-3-table .pricing-plan h6 { margin-bottom: 25px; }

  .pricing-plan span { font-size: 2.75rem; }
  .pricing-3-table .pricing-plan span { font-size: 3.75rem; }
  .pricing-plan sup { font-size: 1.75rem; top: -5px; }
  .pricing-3-table .pricing-plan sup { font-size: 2.25rem; top: -8px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.25rem; top: -16px; }
  .pricing-plan sup.validity span { font-size: 2rem; }
  .pricing-plan sup.validity { font-size: 1.25rem; }

  .pricing-1-table .pricing-plan p { font-size: 1rem; padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { font-size: 1rem; margin-top: 10px; }
  .pricing-2-table .features p, .pricing-3-table .features p { font-size: 1rem; }

  .badge-wrapper { top: -19px; }
  .highlight-badge { padding: 10px 40px; }

  .payment-methods { text-align: center; margin-top: 0; }
  .payment-methods h6 { margin-bottom: 10px; }
  .payment-icons li { padding-right: 3px; }

  .pricing-notice { margin-top: 0; }
  .pricing-notice p { padding: 0 3%; }

  /* Brands */
  .brands-2-wrapper { padding: 0; }

  .brands-section h4 { padding: 0; margin-bottom: 25px; }
  .brands-section p { padding: 0 5%; margin-bottom: 35px; }
  #brands-1.brands-section p { margin-bottom: 25px; padding: 0 5%; }
  #brands-1 .brand-logo { padding: 0 20px; }
  #brands-2 .brand-logo { padding: 0 10px; }

  /* FAQs */
  .accordion-item .accordion-thumb:after,
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.8rem; top: 23px; right: 0; }

  #faqs-2 .question { margin-bottom: 30px; }
  .accordion-thumb { padding: 20px 0; }
  .accordion-thumb h5 { line-height: 1.4;  padding-right: 10%; }
  #faqs-2 .question h5 { line-height: 1.4; margin-bottom: 10px; }

  .more-questions a { display: block; margin-top: 5px; }

  .more-questions { margin-top: 10px; }
  #faqs-1 .more-questions { margin-top: 30px; }

  .rtl-direction .accordion-thumb h5 { line-height: 1.4; padding-left: 5%; padding-right: 0; }
  .rtl-direction .accordion-item .accordion-thumb:after,
  .rtl-direction .accordion-item.is-active .accordion-thumb:after {top: 21px;right: 97%;}

  /* Download */
  span.version-data { font-size: 1.75rem; margin-right: 10px; }
  span.release-date span { font-size: 1.125rem; margin-left: 10px; }

  /* Call To Action */
  #cta-6, #cta-9, #cta-10 { padding-top: 60px; padding-bottom: 60px; }
  #cta-1, #cta-5, #cta-11 { margin: 0 -15px; }

  #cta-3 { margin-top: 110px; }
  #cta-4 { margin-bottom: 110px; }
  .cta-3-wrapper { padding: 40px 5%; margin-top: -120px; }
  .cta-4-wrapper { padding: 40px 10%; margin-bottom: -120px; }

  .cta-1-wrapper { padding: 50px 8%; }
  .cta-5-wrapper { padding: 60px 30px; }
  .cta-6-txt { padding: 0; }
  .cta-10-txt { padding: 0 5%; margin-bottom: 40px; }
  .cta-11-wrapper { padding: 60px 30px; }

  .cta-1-wrapper, .cta-5-wrapper { -webkit-border-radius: 0;-moz-border-radius: 0;-o-border-radius: 0;border-radius: 0;
  -webkit-box-shadow: 0 0;-moz-box-shadow: 0 0;box-shadow: 0 0; }

  .cta-box {padding: 35px 30px; }
  .cta-txt {width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .cta-ico {width: 100%; display: inline-block!important; float: none; margin-top: 0; margin-bottom: 15px; }

  .cta-1-txt h4, .cta-3-txt h4 { font-size: 1.5rem; padding: 0 5%;  }
  .cta-2-txt h4, .cta-4-txt h4 { font-size: 1.5rem; padding: 0 5%; margin-bottom: 10px; }
  .cta-5-txt h3{ font-size: 1.625rem; padding: 0 8%; }
  .cta-6-txt h4, .cta-6-txt h3, .cta-6-txt h2 { margin-bottom: 20px; }
  .cta-9-txt h2 { padding: 0 10%; margin-bottom: 15px; }
  .cta-2-txt p { padding: 0 6%; margin-bottom: 20px; }
  .cta-9-txt p.p-xl { padding: 0 5%; margin-bottom: 30px; }

  .cta-6-txt .btns-group { margin-bottom: 25px; }
  .cta-section .btn.mr-15 { margin: 0 5% 15px; }
  .cta-section .stores-badge { text-align: center; margin-top: 10px; padding: 0 5%; }
  .cta-section .stores-badge .store { float: none; margin-top: 15px; margin-right: 0; }
  .os-btn { width: auto; min-width: auto; padding: 20px; margin: 0 15px 20px; }

  /* Newsletter */
  #newsletter-1 h4.h4-xl, #newsletter-2 h4.h4-xl { font-size: 1.4rem; }
  #newsletter-1 { margin: 0 -15px; }
  .newsletter-wrapper { border: none; padding: 40px 30px 20px;-webkit-border-radius: 0;-moz-border-radius: 0;
  -o-border-radius: 0;border-radius: 0;-webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0; }

  .newsletter-section hr { margin: 20px 0 0; }
  #newsletter-2 .newsletter-txt { padding: 0 10%; }
  #newsletter-1 .newsletter-form { margin-top: 10px; }
  #newsletter-2 .newsletter-form { margin-top: 25px; padding: 0 8%; }
  .newsletter-section .form-control { 
    display: block; width: 100%!important; 
    text-align: center; 
    height: 48px; 
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 0.95rem; 
    margin-right: 0; 
    margin-bottom: 15px; 
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px;
  }

  #newsletter-2.newsletter-section .form-control { font-size: 1rem; border: 1px solid #ccc; }
  #newsletter-2.bg-lightgrey.newsletter-section .form-control, 
  #newsletter-2.bg-dark.newsletter-section .form-control { font-size: 1rem; border-bottom: 1px solid #ccc; }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .newsletter-section .form-control:focus { border: 1px solid #998da0; }

  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { 
    display: block; 
    height: 48px; 
    width: 100%!important; margin: 0; 
  }

  .rtl-direction #newsletter-2.newsletter-section .form-control { margin-left: 0; } 

  /* Blog */
  #blog-page .masonry-image { width: 100%; padding: 0 2%; }
  .blog-1-post, .blog-2-post, .featured-post { margin-left: 2%; margin-right: 2%; }

  .blog-1-post .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  .blog-2-post .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  #blog-page .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  #blog-page .blog-post-wide .blog-post-txt { margin-top: 0; }

  h5.posts-category { margin-left: 2%; margin-right: 2%; }

  /* Blog Listing */
  #blog-page .title-02.mb-85 { margin-bottom: 50px; }
  h5.posts-category { margin-bottom: 30px; }
  #blog-page .blog-post-wide .blog-post-txt { padding: 30px 30px; }

  /* Single Post */
  #single-post .post-data { margin-top: 20px; margin-bottom: 30px; }
  #single-post .post-author-avatar img { display: none; }
  .single-post-txt h4, .single-post-txt h3 { margin-bottom: 20px; }
  .single-post-txt h5 { margin-top: 17px; margin-bottom: 20px; }

  #single-post .quote.mt-30.mb-35 { margin-top: 20px; margin-bottom: 25px; }
  .post-inner-img { margin-top: 30px; margin-bottom: 35px; }

  .post-share-links { margin-top: 40px; }
  .post-tags-list span a { font-size: 0.85rem; padding: 3px 6px; margin-right: 2px; }

  .about-post-author { padding: 60px 4%; }
  .author-senoff img { width: 70px; height: 70px; float: none; }
  .author-senoff-txt { padding-left: 0; padding-top: 25px; }
  .author-follow-btn { font-size: 0.95rem; padding: 4px 12px; top: 15px; right: 15px; }

  .post-comments img { width: 50px; height: 50px; }
  .comment-body { margin-left: 15px; }

  .comment-form { margin-top: 40px; }
  .comment-form .form-control { height: 48px; font-size: 1rem; }
  .comment-form textarea { min-height: 150px; }
  .comment-form textarea.form-control { padding: 15px 20px; }

  /* Contacts */
  #contacts-1 { padding-top: 80px; }
  #contacts-1-form { padding: 25px 20px 15px; margin: 0 0 30px; }
  #contacts-2 .contact-form p { margin-bottom: 10px; }
  #contacts-2 .contact-form span { line-height: 1.3; margin-bottom: 15px; }

  .contact-form .form-control, .contact-form .form-select { height: 52px; }
  .contact-form textarea { min-height: 170px; }

  /* Footer */
  .footer { padding-top: 80px; padding-bottom: 30px; }
  #footer-1 .col-sm-6, #footer-2 .col-sm-6, #footer-3 .col-sm-4, #footer-4 .col-sm-6 { width: 50%; float: left; }
  .rtl-direction #footer-3 .col-sm-4.pl-50 { width: 50%; margin-left: 48%; }

  #footer-1 .footer-links { padding-left: 0; }
  img.footer-logo { max-height: 35px; }
  .footer hr { margin-top: 10px; }

  .footer-info p { padding-right: 0; }
  .footer h6, .footer h5 { margin-bottom: 20px; }
  .footer p.p-md, .bottom-footer p { font-size: 1rem; } 
  .footer-socials.ico-25 a { margin-right: 12px; }
  .footer-socials.ico-25 [class^="flaticon-"]:before, .footer-socials.ico-25 [class^="flaticon-"]:after { font-size: 1.25rem; }

  .footer-form .newsletter-form .input-group { padding: 0 2px; }
  .footer-form .form-control { height: 50px; font-size: 1rem; }
  .footer-form .btn { height: 50px; padding: 0 15px; }

  .bottom-footer { margin-top: 0; padding-top: 30px; }
  .bottom-footer-list.text-end { text-align: left!important; margin-top: 5px; }

  .bottom-footer-list li { padding-left: 2px; }
  .bottom-footer-list li:after { top: -1px; padding-left: 6px; } 

  /* Inner Page Hero */
  .page-hero-section { padding-top: 45px; padding-bottom: 45px; }
  .page-hero-section h3, .page-hero-section h2 { line-height: 1.35; }
  .page-hero-section p { padding: 0; margin-top: 10px; }

  /* Pagination */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* Terms Page */
  #terms-page.inner-page-hero { margin-top: 70px; padding-top: 70px; }
  .terms-title { margin-bottom: 60px; }
  .terms-title p { margin-top: 15px; }
  .terms-box { margin-bottom: 20px; }
  .terms-box h5 { margin-bottom: 15px; }

  /* Register Page */
  #login-2 .register-form-title, #signup-2 .register-form-title { margin-bottom: 20px; }
  .reset-password-section .register-form-title { margin-bottom: 25px; }

  .register-form-title h4, .register-form-title h3 { font-size: 1.75rem; margin-bottom: 10px; }
  .reset-password-section .register-form-title h4 { font-size: 1.6rem; line-height: 1.3; margin-bottom: 15px; }

  .register-form-title p { font-size: 0.95rem; }
  .reset-password-section .register-form-title p { padding: 0; }

  .login-separator-txt { font-size: 0.95rem; }

  .login-page-logo { margin-bottom: 40px; }
  #reset-password-1 .login-page-logo {margin-bottom: 30px; }
  .login-page-logo img { max-height: 40px; }
  #reset-password-1 .login-page-logo img {max-height: 38px; }

  .form-data { display: block!important; text-align: center; }
  #signup-1 .form-data, #signup-2 .form-data, #signup-3 .form-data { text-align: left; }

  .form-check { display: inline-block; margin: 0 auto 10px; }

  .form-check .form-check-label, p.forgot-password { font-size: 0.9375rem; }
  #signup-1 .form-check .form-check-label, #signup-3 .form-check .form-check-label,
  #signup-1 p.forgot-password, #signup-3 p.forgot-password { font-size: 0.865rem; }
  #signup-2 .form-check .form-check-label, #signup-2 p.forgot-password { font-size: 0.9rem; }

  .register-form p.create-account { font-size: 0.95rem; margin-top: 30px; }

  .sign-in-notice { padding: 0 3%; margin-top: 30px; }
  .sign-in-notice p { font-size: 0.9375rem; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -710%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -710%; }

  #login-2 .login-separator-txt:before, #signup-2 .login-separator-txt:before { left: -650%; }
  #login-2 .login-separator-txt:after, #signup-2 .login-separator-txt:after { right: -650%; }

  #login-3 .login-separator-txt:before, #signup-3 .login-separator-txt:before { left: -710%; }
  #login-3 .login-separator-txt:after, #signup-3 .login-separator-txt:after { right: -710%; }

  #login-2 .register-form form, #signup-2 .register-form form { padding: 35px 10px 40px; }

  .register-form form, #login-2 .register-form form, #signup-2 .register-form form { margin: 0; }
  #reset-password-1 .register-form form { margin: 0; }
  .register-form .form-control, .register-form .btn { height: 48px; font-size: 1rem; }

  .btn-show-pass { top: 16px; right: 15px; }
  .btn-show-pass.ico-20 [class^="flaticon-"]:before, .btn-show-pass.ico-20 [class^="flaticon-"]:after { font-size: 1.05rem; }

  .register-form .btn-facebook, .register-form .btn-google { padding: 0; line-height: 48px; }
  .btn-google img, .btn-facebook img { width: 18px; height: 18px; margin-top: -3px; margin-right: 5px; }

  .reset-password-img { margin: 0 0 50px; }

  /* Scroll To Top */
  #scrollUp { width: 36px; height: 36px; bottom: 20px; right: 10px; }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 320.95px) { 

  #stlChanger { display: none; }

  .wave-shape-bottom { bottom: -3px; }

  .ts-0 { padding-top: 80px; }

  h6, h5, h4, h3 { line-height: 1.4; }

  /* Header H6 */
  h6.h6-xs { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-sm { font-size: 1.05rem; }    /* 16.8px */
  h6.h6-md { font-size: 1.0625rem; }  /* 17px */
  h6.h6-lg { font-size: 1.0625rem; }  /* 17px */
  h6.h6-xl { font-size: 1.0625rem; }  /* 17px */

  .rtl-direction h6.h6-xs { font-size: 1.125rem; }   
  .rtl-direction h6.h6-sm { font-size: 1.125rem; }  
  .rtl-direction h6.h6-md { font-size: 1.125rem; }   
  .rtl-direction h6.h6-lg { font-size: 1.125rem; }  
  .rtl-direction h6.h6-xl { font-size: 1.125rem; }

  /* Header H52 */
  h5.h5-xs { font-size: 1.125rem; }   
  h5.h5-sm { font-size: 1.125rem; }  
  h5.h5-md { font-size: 1.125rem; }     
  h5.h5-lg { font-size: 1.125rem; }   
  h5.h5-xl { font-size: 1.125rem; }   /* 18px */

  .rtl-direction h5.h5-xs { font-size: 1.25rem; }   
  .rtl-direction h5.h5-sm { font-size: 1.25rem; }  
  .rtl-direction h5.h5-md { font-size: 1.25rem; }   
  .rtl-direction h5.h5-lg { font-size: 1.25rem; }  
  .rtl-direction h5.h5-xl { font-size: 1.25rem; }

  /* Header H4 */
  h4.h4-xs { font-size: 1.25rem; }   
  h4.h4-sm { font-size: 1.25rem; }     
  h4.h4-md { font-size: 1.25rem; }  
  h4.h4-lg { font-size: 1.25rem; }   
  h4.h4-xl { font-size: 1.25rem; }    /* 20px */

  /* Header H3 */
  h3.h3-xs { font-size: 1.5rem; } 
  h3.h3-sm { font-size: 1.5rem; }   
  h3.h3-md { font-size: 1.5rem; }  
  h3.h3-lg { font-size: 1.5rem; } 
  h3.h3-xl { font-size: 1.5rem; }     /* 24px */ 

  /* Header H2 */
  h2.h2-xs { font-size: 1.625rem; }  
  h2.h2-sm { font-size: 1.625rem; }  
  h2.h2-md { font-size: 1.625rem; }  
  h2.h2-lg { font-size: 1.625rem; }  
  h2.h2-xl { font-size: 1.625rem; }   /* 26px */

  h2.h2-title-xs { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-sm { font-size: 1.875rem; }  /* 30px */ 
  h2.h2-title-md { font-size: 2rem; }      /* 32px */
  h2.h2-title-lg { font-size: 2rem; }      /* 32px */
  h2.h2-title-xl { font-size: 2.125rem; }  /* 34px */ 

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; }   /* 15px */    
  p { font-size: 0.9375rem; }        /* 15px */  
  p.p-md { font-size: 0.9375rem; }   /* 15px */      
  p.p-lg { font-size: 1rem; }        /* 16px */  
  p.p-xl { font-size: 1rem; }        /* 16px */  

  p.p-title-xs { font-size: 1.05rem; }  /* 16.8px */  
  p.p-title-sm { font-size: 1.05rem; }  /* 16.8px */  
  p.p-title-md { font-size: 1.05rem; }  /* 16.8px */   
  p.p-title-lg { font-size: 1.1rem; }   /* 17.6px */  
  p.p-title-xl { font-size: 1.1rem; }   /* 17.6px */ 

  .rtl-direction p.p-md { font-size: 1.1rem; }  
  .rtl-direction p.p-lg { font-size: 1.1rem; } 
  .rtl-direction p.p-xl { font-size: 1.1rem; } 

  /* Button */
  .btn, .btn.btn-md, .btn.btn-lg { font-size: 0.9375rem; padding: 12px 32px; }
  .btn-transparent, .btn.btn-md.btn-transparent, .btn.btn-lg.btn-transparent { padding: 8px 4px; }

  .btn.ico-15 [class^="flaticon-"]:before, 
  .btn.ico-15 [class^="flaticon-"]:after { font-size: 0.75rem; }
  .btn.ico-20.ico-left span { right: 5px; }

  /* Watch Video Link */
  .watch-video-link { width: 48px; height: 48px; margin-right: 11px; }
  .watch-video-link.ico-35 [class^="flaticon-"]:before, 
  .watch-video-link.ico-35 [class^="flaticon-"]:after { line-height: 46px!important; }  
  .video-txt-lg { margin-bottom: 5px; }
  .video-txt-sm { padding-left: 1px; }

  /* Video Button */
  .video-btn-xl { width: 70px; height: 70px; margin-top: -35px; margin-left: -35px; }
  .video-btn-lg { width: 70px; height: 70px; margin-top: -35px;margin-left: -35px; }
  .video-btn-md { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }
  .video-btn-sm { width: 60px; height: 60px; margin-top: -30px; margin-left: -30px; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 3.75rem; line-height: 70px!important; margin-left: 7px;
  }

  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, .video-btn.video-btn-sm [class^="flaticon-"]:after {
    font-size: 3rem; line-height: 60px!important; margin-left: 6px;
  }

  .video-btn.video-btn-xl:hover:before, 
  .video-btn.video-btn-lg:hover:before { left: -16px; right: -16px; top: -16px; bottom: -16px; }
  .video-btn.video-btn-md:hover:before,
  .video-btn.video-btn-sm:hover:before { left: -12px; right: -12px; top: -12px; bottom: -12px; }

  /* Store Badges */
  .store { margin-right: 10px; }
  .store img { max-height: 42px; }
  .footer .store img { max-height: 42px; }
  span.os-version { font-size: 0.9375rem; line-height: 1.25; font-weight: 300; }

  /* Vector Icons */
  .ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }   
  .ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.1rem; }     
  .ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.375rem; }  
  .ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.65rem; }   
  .ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 1.925rem; }  
  .ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.2rem; }     
  .ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.475rem; }  
  .ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 2.75rem; }   
  .ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.025rem; }  
  .ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.3rem; }     
  .ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 3.575rem; }  
  .ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 3.85rem; }   
  .ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.125rem; }  
  .ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 4.4rem; }   
  .ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 4.675rem; }  
  .ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 4.95rem; }    
  .ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.225rem; }  
  .ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 5.5rem; }   
  .ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 5.775rem; } 
  .ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.05rem; } 
  .ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 6.325rem; } 
  .ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 6.6rem; }   
  .ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 6.875rem; } 

  /* Vector Rounded Icons */
  .ico-rounded-xs { width: 70px; height: 70px; }
  .ico-rounded-sm { width: 75px; height: 75px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 85px; height: 85px; }
  .ico-rounded-xl { width: 95px; height: 95px; }

  .ico-rounded-xs [class*="flaticon-"]:before, .ico-rounded-xs [class*="flaticon-"]:after { line-height: 70px!important; }
  .ico-rounded-sm [class*="flaticon-"]:before, .ico-rounded-sm [class*="flaticon-"]:after { line-height: 75px!important; }
  .ico-rounded-md [class*="flaticon-"]:before, .ico-rounded-md [class*="flaticon-"]:after { line-height: 80px!important; }
  .ico-rounded-lg [class*="flaticon-"]:before, .ico-rounded-lg [class*="flaticon-"]:after { line-height: 85px!important; }
  .ico-rounded-xl [class*="flaticon-"]:before, .ico-rounded-xl [class*="flaticon-"]:after { line-height: 95px!important; }

  /* PNG Icons */
  .ico-125 img { width: 110px; height: 110px; }
  .ico-120 img { width: 105px; height: 105px; }
  .ico-115 img { width: 101px; height: 101px; }
  .ico-110 img { width: 97px; height: 97px; }
  .ico-105 img { width: 92px; height: 92px; }
  .ico-100 img { width: 88px; height: 88px; }
  .ico-95 img { width: 84px; height: 84px; }
  .ico-90 img { width: 79px; height: 79px; }
  .ico-85 img { width: 75px; height: 75px; }
  .ico-80 img { width: 70px; height: 70px; }
  .ico-75 img { width: 66px; height: 66px; }
  .ico-70 img { width: 62px; height: 62px; }
  .ico-65 img { width: 57px; height: 57px; }
  .ico-60 img { width: 53px; height: 53px; }
  .ico-55 img { width: 48px; height: 48px; }
  .ico-50 img { width: 44px; height: 44px; }
  .ico-45 img { width: 40px; height: 40px; }
  .ico-40 img { width: 35px; height: 35px; }
  .ico-35 img { width: 31px; height: 31px; }
  .ico-30 img { width: 26px; height: 26px; }
  .ico-25 img { width: 22px; height: 22px; }
  .ico-20 img { width: 20px; height: 18px; }

  /* Section Id */
  .section-id { font-size: 1rem; margin-bottom: 20px; }
  .section-id.txt-upcase { font-size: 0.825rem; }
  .section-title .section-id, .section-title-left .section-id { margin-bottom: 20px; }

  /* Section Title */
  .title-04 { text-align: center; padding: 0; }

  .section-title h3 { padding: 0; }
  .section-title.title-04 h3 { padding: 0; }
  .title-01 h2 { padding: 0; }
  .title-01 p { padding: 0; margin-top: 14px; }
  .section-title.ico-35 [class*="flaticon-"]:before, .section-title.ico-35 [class*="flaticon-"]:after { font-size: 1.2rem; } 

  /* Text Block Typography */
  .txt-block h3, .txt-block h2, .content-14-txt h4, .content-14-txt h3 { margin-bottom: 16px; }
  h5.sm-title { margin-top: 15px; margin-bottom: 15px; }
  .txt-block .btn, .content-14-txt .btn, .txt-block .stores-badge { margin-top: 15px; }
  .txt-block .btns-group .btn.mr-15 { margin-left: 15px; margin-right: 15px; }

  .advantages li { display: block!important; text-align: center; padding-left: 0;  }  
  .advantages li:after {  display: none; }
  .advantages li p { display: block; float: none; margin-bottom: 0; margin-top: 5px; }

  /* Terms Text */
  .terms-txt a { display: block; }

  /* Tools List */
  .tools-list span { padding-right: 4px; }

  /* Text Block Rating */
  .txt-block-rating { text-align: center; }
  .txt-block-rating [class*="flaticon-"]:before, .txt-block-rating [class*="flaticon-"]:after { font-size: 1rem; }
  .stars-rating span { position: relative; top: 2px; } 
  .stars-rating { font-size: 0.9375rem; }
  .stars-rating p { font-size: 0.9375rem; padding: 0 10%; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .wsmenu > .wsmenu-list { width: 250px; margin-right: -250px; }
  .wsactive .wsmobileheader { margin-right: 250px; }
  .overlapblackbg { width: calc(100% - 250px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 18px 0 0; }

  .wsmobileheader .smllogo { display: block; margin-top: 23px; padding-left: 18px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  /* Hero App Logo */
  .hero-logo-rounded span { font-size: 0.875rem; }

  /* Hero Brands */
  .hero-brands {margin-top: 30px; padding: 0; } 
  .hero-brands p { padding: 0 5%; margin-bottom: 20px; }
  .hero-brands .brand-logo {padding: 0 15px; }

  /* Hero-1 */
  .hero-1-txt { padding: 0 5px; margin-bottom: 30px; }
  .hero-1-txt h2 { font-size: 1.65rem; margin-bottom: 20px; }
  .hero-1-txt p.p-xl { font-size: 1.05rem; padding-right: 0; margin-bottom: 30px; }
  .hero-1-img { padding: 0 5%; }

  /* Hero-2 */
  #hero-2 { margin-bottom: 50px; }
  .hero-2-txt { padding: 0; }
  .hero-2-txt h2 { font-size: 1.65rem; margin-bottom: 15px; }
  .hero-2-txt p.p-xl { font-size: 1.05rem; padding: 0; margin-bottom: 30px; }
  .hero-2-img { padding: 0; }

  /* Hero-3 */
  #hero-3 { margin-bottom: 40px; }
  .hero-3-txt h2 {  font-size: 1.75rem; padding: 0; margin-bottom: 25px; }
  .hero-3-txt p.p-xl { padding: 0; margin-bottom: 30px; }
  .hero-3-img { margin-right: 0; margin-bottom: -40px; }

  /* Hero-4 */
  .hero-4-txt { padding: 0; }
  .hero-4-txt h2 { font-size: 1.65rem; margin-bottom: 20px; }
  #hero-4-form { padding: 40px 15px 30px; text-align: center; margin-left: 0; margin-right: 0; }
  #hero-4-form h4 { font-size: 1.35rem; }
  #hero-4-form p { margin-bottom: 25px; }
  #hero-4-form .form-btn.mt-10 { margin-top: 0; }
  #hero-4-form .form-btn .btn { font-size: 1.05rem;  }

  /* Hero-5 */
  .hero-5-txt { padding: 0 4%; margin-top: 40px; }
  .hero-5-list li { padding: 14px 5px; }
  .hero-5-list li.first-li { padding: 23px 5px 16px; }
  .hero-5-list li.last-li { padding: 16px 5px 20px; }
  .hero-5-list h4.h4-xl { font-size: 1.35rem; }
  .hero-5-list h5.h5-xl { font-size: 1.25rem; }
  .hero-5-links { margin-top: 35px; }

  /* Hero-6 */
  .hero-6-txt { padding: 0; }
  .hero-6-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  #hero-6 .quick-form { margin: 25px 3% 40px; }
  #hero-6 .quick-form .btn { padding: 13px 30px; }

  /* Hero-7 */
  #hero-7 { margin-bottom: 30px; }
  .hero-7-txt { padding: 0; }
  .hero-7-txt h2 { font-size: 1.75rem; margin-bottom: 15px; }
  .hero-7-txt .btns-group { margin-top: 30px; }
  .hero-7-img { margin: 40px 0 -40px; }

  /* Hero-8 */
  .hero-8-txt { padding: 0;  }
  .hero-8-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  .hero-8-img { padding: 0; }
  #hero-8 .quick-form { margin: 25px 2% 50px; }
  #hero-8 .quick-form .btn { padding: 13px 30px; }

  /* Hero-9 */
  #hero-9 { background-position: right center; }
  #hero-9-form { text-align: center; padding: 35px 15px 25px; margin-left: 0; margin-right: 0; }
  #hero-9-form h4.h4-xs { font-size: 1.35rem; }
  #hero-9-form .form-btn.mt-10 { margin-top: 0; }
  #hero-9-form .form-btn .btn { font-size: 1.05rem; }

  /* Hero-10 */
  #hero-10 { margin-bottom: 60px; }
  .hero-10-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  .hero-10-txt p { padding: 0; margin-bottom: 30px; }
  .hero-10-img { padding: 0; margin: 35px 0 -60px; }

  /* Hero-11 */
  .hero-11-txt { text-align: center; padding: 0; margin-bottom: 40px; }
  .hero-11-txt h2 { font-size: 1.6rem; padding: 0 3%; margin-bottom: 20px; }
  .hero-11-txt .os-btn { margin: 0 auto 20px; } 
  .hero-11-img { padding: 0 5%; }

  /* Hero-12 */
  #hero-12:after { height: 25%; top: 80%; }
  .hero-12-txt h2 { font-size: 1.75rem; margin-bottom: 20px; }
  .hero-12-txt p { font-size: 1.05rem; padding-right: 0; margin-bottom: 0; }
  .hero-12-txt .hero-logo-rounded { margin-bottom: 30px; }
  .hero-12-img { margin-top: 30px; }

  /* Hero-13 */
  .hero-13-title { margin-bottom: 15px; }
  .hero-13-title h2 { font-size: 1.65rem; }

  /* Hero-14 */
  .hero-14-txt{ text-align: center;  }
  .hero-14-txt h2 { font-size: 1.75rem; padding: 0 3%; margin-bottom: 15px; }
  .hero-14-txt p.p-xl { padding: 0 5%; }

  /* Hero-15 */
  #hero-15 { overflow: visible; margin-bottom: 40px; }
  .hero-15-txt h2 { font-size: 1.75rem; padding:  0 3%; margin-bottom: 25px; }
  .hero-15-txt p { padding: 0; margin-bottom: 30px; }
  .hero-15-img { padding: 0; margin-top: 30px; margin-bottom: -40px; }

  /* Hero-16 */
  .hero-16-txt { text-align: center; padding: 0; margin-bottom: 40px; }
  .hero-16-txt h2 { font-size: 1.65rem; margin-bottom: 20px; }
  .hero-16-txt p { padding: 0 3%; }
  .hero-16-txt .stores-badge { margin-bottom: 15px; } 
  .hero-16-img { padding: 0 5%; }

  /* Hero-17 */
  .hero-17-title.mb-50 { margin-bottom: 20px; }
  .hero-17-title h2 { font-size: 1.65rem;  }
  .hero-17-txt p.p-xl { padding: 0 5%; }
  .hero-17-txt .os-btn { margin-left: 1%; margin-right: 1%; }
  .hero-17-img { margin-bottom: -30px; }

  /* Hero-18 */
  #hero-18-form { padding: 0 5%; }
  #hero-18-form h2 { font-size: 1.75rem;  }
  #hero-18-form p.p-lg { margin-top: 20px; margin-bottom: 30px; }
  #hero-18-form .form-btn { margin-top: 5px; }
  .hero-18-img { padding: 0 3%; margin-top: 40px; }

  /* Hero-19 */
  #hero-19 { margin-bottom: 30px; }
  .hero-19-txt { text-align: center; margin-top: 0; }
  .hero-19-txt h2 { font-size: 1.75rem;  margin-bottom: 15px; }
  .hero-19-txt p.p-xl { padding: 0 5%; margin-bottom: 20px; }
  .hero-19-img { margin: 30px 0 -40px; }

  /* Hero-20 */
  .hero-20-txt h2 { font-size: 1.7rem; padding: 0 3%; margin-bottom: 15px; }
  .hero-20-txt { padding: 0; }
  .hero-20-txt p { padding: 0 3%; margin-bottom: 25px; }

  /* Hero-21 */
  .hero-21-txt h2 { font-size: 1.7rem; padding: 0 5%; margin-bottom: 20px; }
  .hero-21-txt p.p-xl { padding: 0; }
  #hero-21 .quick-form { margin: 25px 4% 0; }

  /* Hero-22 */
  .hero-22-wrapper { padding: 80px 15px 0; text-align: center; margin: 0 -15px 80px; 
  -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; }
  .hero-22-wrapper .txt-block { padding: 0; margin-bottom: 40px; }
  .hero-22-wrapper .txt-block h3 { font-size: 1.7rem; }
  .hero-22-wrapper .img-block { margin-left: 3%; margin-right: 3%; }

  /* Hero-23 */
  .hero-23-txt { padding: 0 3%; margin-bottom: 40px; }
  .hero-23-txt h2 { font-size: 1.65rem; padding: 0 3%; margin-bottom: 20px; }
  .hero-23-txt p.p-xl { font-size: 1.05rem; padding: 0 3%; margin-bottom: 25px; }
  .hero-23-img { margin: 0 3% -60px; }

  /* Hero-24 */
  .hero-24-txt { padding-top: 80px; padding-bottom: 0; }
  .hero-24-txt h3, .hero-24-txt h2 { padding-bottom: 5px; }
  .hero-24-txt h5 { padding: 0; margin-bottom: 20px; }
  .hero-24-txt h2.tra-header { top: -20px; left: -15%; font-size: 12rem; }
  .hero-24-img img { padding: 0 10% 0 15%; }

  /* Hero Buttons */
  .hero-section .btns-group .btn.mr-15 { margin: 0 15px 15px; }

  /* Hero Request Form */
  .request-form .form-control { height: 50px; font-size: 16px; }

  /* Hero Quick Form */
  .hero-section .quick-form .input-group { background-color: transparent; padding: 0; }
  .hero-section .quick-form .form-control { background-color: #fff; text-align: center; height: 48px; font-size: 15px;
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .hero-section .quick-form .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .hero-section .quick-form .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px; border-bottom-right-radius: 5px; 
  }

  .rtl-direction .hero-section .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 5px; border-bottom-left-radius: 5px; 
  }

  .hero-section .quick-form .input-group-btn { display: block; width: 100%!important; }
  .hero-section .quick-form .btn { display: block; height: 48px; width: 100%!important; font-size: 15px; margin-top: 15px; 
    margin-left: 0; -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; 
  }

  .rtl-direction .input-group .input-group-btn {order: 2; }
  .rtl-direction .input-group .email {order: 1; } 

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* About */
  #about-2 { margin-bottom: 100px; }
  #about-1 .txt-block { padding: 0; }
  .about-2-title p { margin-top: 16px; }
  .about-2-images { margin-top: -10px; margin-bottom: -100px; }

  /* Features */
  .fbox-1 { padding: 0 4%; } 
  .fbox-5 { padding: 0 30px; }
  .fbox-6 { padding: 35px 10%; margin-left: 3%; margin-right: 3%; }
  .fbox-5.bg-white { padding: 35px 20px; margin: 10px 3% 55px; }
  .fbox-7 { text-align: center; padding: 40px 12%; margin-left: 3%; margin-right: 3%; } 
  #features-7 .txt-block p { padding: 0 3%; }

  .rtl-direction .fbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 10px; }
  .rtl-direction .fbox-ico { width: 100%; float: none; text-align: center!important; }
  .fbox-img { margin-left: 9%; margin-right: 9%; padding: 30px 10% 35px; } 

  /* Content */
  .content-5.division { padding-left: 0; padding-right: 0; }
  .content-5 { padding-top: 80px; padding-bottom: 40px; }
  .content-5-wrapper { padding: 0; }
  .content-5.content-section .txt-block.left-column {padding: 0 5%; }
  .content-5.content-section .txt-block.right-column { padding: 0%; }
  .rtl-direction .content-5 ul.simple-list { list-style: disc; }

  .content-8 .section-title.mb-75 { margin-bottom: 30px; }
  .content-9 .section-title, .content-10 .section-title { margin-bottom: 40px; }

  .content-6 ul.simple-list { list-style: none; margin-left: 0; }

  .cbox-2 { text-align: center; width: 100%; min-width: 100%; padding: 20px 15px; }
  .cbox-2-ico { display: block!important; float: none; margin-top: 0; margin-bottom: 10px; } 
  .cbox-2-txt { padding-left: 0; }

  .cbox-3-txt { display: block!important; padding-left: 32px; }
  .rtl-direction .cbox-3-txt { display: block!important; padding-left: 0; padding-right: 34px; }
  .cbox-3-ico { width: 22px; height: 22px;margin-top: 1px; }
  .cbox-3-ico.ico-15 [class*="flaticon-"]:before,
  .cbox-3-ico.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }
  .cbox-3-ico span { line-height: 22px; } 

  .rtl-direction .cbox-txt { width: 100%; text-align: center; padding: 0; margin-top: 10px; }
  .rtl-direction .cbox-ico { width: 100%; float: none; text-align: center!important; }

  .cbox-4.left-column, .cbox-4.right-column { bottom: -10px; right: 5px; width: 72%; padding: 20px 15px; }
  .cbox-4.right-column { left: 5px; right: 0; }
  .cbox-5 { top: -40px; left: -20px; width: 80%; padding: 20px 15px; }
  .cbox-6 { bottom: 0; right: -15px; width: 80%; padding: 20px 15px; }

  .cbox-4 h5, .cbox-5 h5, .cbox-6 h5 { font-size: 1rem; }
  .cbox-4 p, .cbox-5 p, .cbox-6 p { font-size: 0.85rem; }
  .cbox-4 .text-center.mt-20, .cbox-5 .text-center.mt-20, .cbox-6 .text-center.mt-20 { margin-top: 10px; }
  .cbox-4 .divider, .cbox-5 .divider, .cbox-6 .divider { margin-top: 8px; margin-bottom: 8px; }
  .cbox-4 .btn, .cbox-5 .btn,.cbox-6 .btn { font-size: 0.85rem; margin-bottom: 8px; }

  .cb-wrapper { margin: 0 0 70px; }
  .cb-holder { padding: 50px 20px; margin: 0 0 40px; }
  .cb-box-rounded { padding: 20px 15px; }
  .cb-single-box h2.statistic-number { font-size: 2.5rem; }
  .statistic-number sup { font-size: 2.15rem; top: -5px; right: 0; margin-right: 5px; }

  .cb-shape-1 { right: -25px; top: -20px; }
  .cb-shape-2 { left: -20px; bottom: -30px; }
  .cb-shape-1 img, .cb-shape-2 img { padding: 0 18%; }

  .content-10-btn { margin-top: 40px; }

  .content-section .top-row.pb-30,  .content-section .top-row.pb-50 { padding-bottom: 0; }
  .content-4-img { margin-top: 0; }
  .content-7-img { margin-left: 0; }
  .content-8-img { margin-bottom: -10px; }

  /* Projects */
  #projects-2 { padding-bottom: 40px; }
  #projects-2 .masonry-image { margin-bottom: 40px; }
  #projects-1 .project-txt, #projects-2 .project-txt { padding: 0; }
  #projects-2 .more-btn.mt-20 { margin-top: 0; }
  .rtl-direction .projects-section .more-btn { margin-top: 0; }

  .rtl-direction #projects-1 .project-txt, .rtl-direction #projects-2 .project-txt { padding-left: 0;padding-right: 0; }

  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 30px; }
  .project-data span { margin-right: 10px; }

  .project-image.top-img { margin-bottom: 30px; }
  .more-projects { margin-top: 50px; }

  /* Tabs */
  #tabs-1 .tabs-1 {  margin: 0 auto 30px; }
  #tabs-1 .txt-block { margin-bottom: 40px; }
  .tabs-1 li { padding: 9px 30px; }
  #tabs-2 .tabs-1 li {margin: 0 0 15px; padding: 25px 10px; }
  .tabs-1 li span { font-size: 1rem; }

  /* Statistic */
  .statistic-1-wrapper .statistic-block { padding: 0 5%; }
  #sb-2-3 { margin-bottom: 40px; }
  h2.statistic-number, #statistic-3 h3.statistic-number { font-size: 2rem; }
  #statistic-2 h2.statistic-number, #statistic-4 h2.statistic-number { font-size: 2.65rem; }
  #statistic-1 .statistic-block h5 { margin-top: 15px; margin-bottom: 12px; }
  #statistic-2 { padding-bottom: 80px; }
  #statistic-2 .col-lg-3 { margin-bottom: 30px; }
  #statistic-4 .statistic-block { padding: 0 5%; }
  #statistic-2 .txt-block { padding: 0; margin-bottom: 30px; }
  #statistic-2 .txt-block h4, #statistic-2 .txt-block h3 { font-size: 1.3rem; padding: 0 5%; }
  #statistic-2 .txt-block-rating, #statistic-4 .statistic-block p.mt-20 { margin-top: 10px; }

  .rtl-direction  #statistic-2 .col-lg-3 { margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block { padding-right: 0; margin-top: 30px; margin-bottom: 0; }
  .rtl-direction #statistic-2  .col-lg-3 .statistic-block p { margin-top: 10px; }
  .rtl-direction .statistic-block-txt { padding-left: 0; padding-right: 0; }
  .rtl-direction #statistic-4 h6, #statistic-4 h5 { line-height: 1.4; padding: 0 5%; margin-bottom: 0; margin-bottom: 15px;}

  /* Reviews */
  .reviews-4-holder { padding-top: 0; padding-bottom: 0; }
  .reviews-4-holder:after { display: none; }
  .review-1, .review-2, .review-3, .review-4 { -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; }

  .review-1-txt, .review-2-txt { width: 100%; padding-left: 0; }
  #reviews-3 .txt-block { text-align: center; }
  #reviews-3 .txt-block h3 { padding: 0 5%; }

  .review-1 { padding: 25px 30px; margin: 0 0 20px; }  
  .review-2 { padding: 30px 30px; } 
  .review-3 { padding: 35px 40px; }  
  .review-4 { padding: 25px 30px ; margin: 0 0 20px; }

  .review-1-ico, .review-2-ico { width: 10%; margin-top: 0; margin-bottom: 5px; }

  .author-data { margin-top: 20px; }

  .review-3-avatar {width: 100%; display: inline-block!important; float: none; margin-bottom: 20px; margin-top: 0; }
  .review-3-txt { width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .review-3-txt p { padding-right: 0; }
  .review-3-txt a { display: block; margin-top: 10px; }
  .review-2 .review-avatar img, .review-4 .review-avatar img { width: 50px; height: 50px; }

  .rtl-direction .review-1-txt, .rtl-direction .review-2-txt, .rtl-direction .review-3-txt { padding-left: 0; padding-right: 0; }

  /* Pricing */
  .pricing-1-row .col, .pricing-2-row .col { text-align: center; }

  .pricing-1-table { padding: 40px 25px 35px; }
  .pricing-2-table { padding: 30px 30px 35px; }
  .pricing-3-table { padding: 45px 30px; }

  .pricing-1-table.rel, .pricing-3-table.rel { margin-top: 15px; }

  .pricing-plan-title { text-align: left; }
  .pricing-plan-title h6 { right: -5px; top: -6px; }
  .highlight-badge h6, .pricing-plan-title h6 { font-size: 1rem; }

  .pricing-1-table .pricing-plan h5 { margin-bottom: 20px; }
  .pricing-3-table .pricing-plan h6 { margin-bottom: 25px; }

  .pricing-plan span { font-size: 2.5rem; }
  .pricing-3-table .pricing-plan span { font-size: 3.75rem; }
  .pricing-plan sup { font-size: 1.75rem; top: -5px; }
  .pricing-3-table .pricing-plan sup { font-size: 2.25rem; top: -8px; }
  .pricing-3-table .pricing-plan sup.coins { font-size: 2.25rem; top: -16px; }
  .pricing-plan sup.validity span { font-size: 2rem; }
  .pricing-plan sup.validity { font-size: 1.25rem; }

  .pricing-1-table .pricing-plan p { font-size: 1rem; padding-right: 0; }
  .pricing-2-table .pricing-plan p, .pricing-3-table .pricing-plan p { font-size: 1rem; margin-top: 10px; }
  .pricing-2-table .features p, .pricing-3-table .features p { font-size: 1rem; }

  .badge-wrapper { top: -19px; }
  .highlight-badge { padding: 10px 40px; }

  .payment-icons { padding: 0 8%; }
  .payment-methods { text-align: center; margin-top: 0; }
  .payment-methods h6 { margin-bottom: 10px; }

  .pricing-notice { margin-top: 0; }
  .pricing-notice p { padding: 0 3%; }

  .rtl-direction .pricing-plan-title { text-align: right; }
  .rtl-direction .pricing-plan-title h6 {width: auto; right: 55%;}

  /* Team */
  .team-member { margin-left: 5%; margin-right: 5%; }
  .team-member-data h5 { font-size: 1.15rem; }
  .team-member-data p { font-size: 1.05rem; }

  /* Brands */
  .brands-2-wrapper { padding: 0; }
  .brands-2-wrapper .col { padding: 0 8px; }

  .brands-section h4 { padding: 0; margin-bottom: 25px; }
  .brands-section p { padding: 0; margin-bottom: 35px; }
  #brands-1.brands-section p { margin-bottom: 25px; }
  #brands-1 .brand-logo { padding: 0 15px; }
  #brands-2 .brand-logo { padding: 0 10px; }

  /* FAQs */
  .accordion-item .accordion-thumb:after,
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.8rem; top: 23px; right: 0; }

  #faqs-2 .question { margin-bottom: 25px; }

  .accordion-thumb { padding: 20px 0; }
  .accordion-thumb h5 { line-height: 1.4; padding-right: 10%; }
  #faqs-2 .question h5 { line-height: 1.4; margin-bottom: 10px; }

  .more-questions a { display: block; margin-top: 5px; }

  .more-questions { margin-top: 10px; }
  #faqs-1 .more-questions { margin-top: 30px; }

  .rtl-direction .accordion-thumb h5 { line-height: 1.4; padding-left: 5%; padding-right: 0; }
  .rtl-direction .accordion-item .accordion-thumb:after,
  .rtl-direction .accordion-item.is-active .accordion-thumb:after {top: 21px;right: 97%;}

  /* Download */
  span.version-data { font-size: 1.5rem; margin-right: 5px; }
  span.release-date span { font-size: 1.05rem; margin-left: 5px; }

  /* Call To Action */
  #cta-6, #cta-9, #cta-10 { padding-top: 60px; padding-bottom: 60px; }
  #cta-1, #cta-5, #cta-11 { margin: 0 -15px; }

  #cta-3 { margin-top: 110px; }
  #cta-4 { margin-bottom: 110px; }
  .cta-3-wrapper { padding: 40px 10%; margin-top: -120px; }
  .cta-4-wrapper { padding: 40px 10%; margin-bottom: -120px; }

  .cta-1-wrapper { padding: 50px 10%; }
  .cta-5-wrapper { padding: 60px 35px; }
  .cta-6-txt { padding: 0; }
  .cta-10-txt { padding: 0 5%; margin-bottom: 40px; }
  .cta-11-wrapper { padding: 60px 30px; }

  .cta-1-wrapper, .cta-5-wrapper { -webkit-border-radius: 0;-moz-border-radius: 0;-o-border-radius: 0;border-radius: 0;
  -webkit-box-shadow: 0 0;-moz-box-shadow: 0 0;box-shadow: 0 0; }

  .cta-box {padding: 35px 30px; }
  .cta-txt {width: 100%; display: inline-block!important; text-align: center; padding-left: 0; }
  .cta-ico {width: 100%; display: inline-block!important; float: none; margin-top: 0; margin-bottom: 15px; }

  .cta-2-txt h4, .cta-4-txt h4 { font-size: 1.4rem; padding: 0 5%; }
  .cta-6-txt h4, .cta-6-txt h3, .cta-6-txt h2 { margin-bottom: 20px; }
  .cta-9-txt h2 { padding: 0 5%; margin-bottom: 15px; }
  .cta-2-txt p { padding: 0; margin-bottom: 20px; }
  .cta-9-txt p.p-xl { margin-bottom: 30px; }

  .cta-6-txt .btns-group { margin-bottom: 25px; }
  .cta-section .btn.mr-15 { margin: 0 5% 15px; }
  .cta-section .stores-badge { text-align: center; margin-top: 10px; padding: 0 5%; }
  .cta-section .stores-badge .store { float: none; margin-top: 15px; margin-right: 0; }
  .os-btn { width: auto; min-width: auto; padding: 20px; }

  /* Newsletter */
  #newsletter-1 h4.h4-xl, #newsletter-2 h4.h4-xl { font-size: 1.3rem; }
  #newsletter-1 { margin: 0 -15px; }
  .newsletter-wrapper { border: none; padding: 40px 20px 20px;-webkit-border-radius: 0;-moz-border-radius: 0;
  -o-border-radius: 0;border-radius: 0;-webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0; }

  .newsletter-section hr { margin: 20px 0 0; }
  #newsletter-2 .newsletter-txt { padding: 0 10%; }
  #newsletter-1 .newsletter-form { margin-top: 10px; }
  #newsletter-2 .newsletter-form { margin-top: 25px; padding: 0 8%; }
  .newsletter-section .form-control { 
    display: block; width: 100%!important; 
    text-align: center; 
    height: 48px; 
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 0.95rem; 
    margin-right: 0; 
    margin-bottom: 15px; 
    -webkit-border-radius: 5px; -moz-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px;
  }

  #newsletter-2.newsletter-section .form-control { font-size: 1rem; border: 1px solid #ccc; }
  #newsletter-2.bg-lightgrey.newsletter-section .form-control, 
  #newsletter-2.bg-dark.newsletter-section .form-control { font-size: 1rem; border-bottom: 1px solid #ccc; }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), 
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .newsletter-section .form-control:focus { border: 1px solid #998da0; }

  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { 
    display: block; 
    height: 48px; 
    width: 100%!important; margin: 0; 
  }

  .rtl-direction #newsletter-2.newsletter-section .form-control { margin-left: 0; } 

  /* Blog */
  #blog-page .masonry-image { width: 100%; padding: 0; }
  .blog-1-post, .blog-2-post, .featured-post { margin-left: 0; margin-right: 0; }

  .blog-1-post .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  .blog-2-post .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  #blog-page .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }
  #blog-page .blog-post-wide .blog-post-txt { margin-top: 0; }

  /* Blog Listing */
  #blog-page .title-02.mb-85 { margin-bottom: 40px; }
  #blog-page .title-02.mb-85 h3 { padding: 0 6%; }
  h5.posts-category { margin-bottom: 30px; }
  #blog-page .blog-post-wide .blog-post-txt { padding: 30px 30px; }
  .featured-badge { top: 15px; right: 15px; width: 40px; height: 40px; }
  .featured-badge.ico-25 [class*="flaticon-"]:before, 
  .featured-badge.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; line-height: 38px!important; }

  /* Single Post */
  #single-post .post-data { margin-top: 15px; margin-bottom: 25px; }
  #single-post .post-author-avatar img { display: none; }
  .single-post-txt h4, .single-post-txt h3 { margin-bottom: 20px; }
  .single-post-txt h5 { margin-top: 17px; margin-bottom: 20px; }

  #single-post .quote.mt-30.mb-35 { margin-top: 20px; margin-bottom: 25px; }
  .post-inner-img { margin-top: 30px; margin-bottom: 35px; }

  .post-share-links { margin-top: 40px; }
  .post-tags-list span a { font-size: 0.85rem; padding: 3px 6px; margin-right: 2px; }

  .about-post-author { padding: 60px 5px; }
  .author-senoff img { width: 70px; height: 70px; float: none; }
  .author-senoff-txt { padding-left: 0; padding-top: 25px; }
  .author-follow-btn { font-size: 0.95rem; padding: 4px 12px; top: 15px; right: 15px; }

  .post-comments img { width: 50px; height: 50px; }
  .comment-body { margin-left: 15px; }

  .comment-form { margin-top: 40px; }
  .comment-form .form-control { height: 48px; font-size: 1rem; }
  .comment-form textarea { min-height: 150px; }
  .comment-form textarea.form-control { padding: 15px 20px; }

  /* Contacts */
  #contacts-1 { padding-top: 80px; }
  #contacts-1-form { padding: 20px 15px 10px; margin: 0 0 30px; }
  #contacts-2 .contact-form p { margin-bottom: 10px; }
  #contacts-2 .contact-form span { line-height: 1.3; margin-bottom: 15px; }

  .contact-form .form-control, .contact-form .form-select { height: 52px; }
  .contact-form textarea { min-height: 150px; }

  /* Footer */
  .footer { padding-top: 80px; padding-bottom: 30px; }
  #footer-1 .col-sm-6, #footer-2 .col-sm-6, #footer-3 .col-sm-4, #footer-4 .col-sm-6 { width: 50%; float: left; }
  .rtl-direction #footer-3 .col-sm-4.pl-50 { width: 50%; margin-left: 48%; }

  #footer-1 .footer-links { padding-left: 0; }
  img.footer-logo { max-height: 35px; }
  .footer hr { margin-top: 10px; }

  .footer-info p { padding-right: 0; }
  .footer h6, .footer h5 { margin-bottom: 20px; }
  .footer p.p-md { font-size: 1rem; } 
  .footer-socials.ico-25 a { margin-right: 12px; }
  .footer-socials.ico-25 [class^="flaticon-"]:before, .footer-socials.ico-25 [class^="flaticon-"]:after { font-size: 1.25rem; }

  .footer-form .newsletter-form .input-group { padding: 0 2px; }
  .footer-form .form-control { height: 50px; font-size: 1rem; }
  .footer-form .btn { height: 50px; padding: 0 15px; }

  .bottom-footer { margin-top: 0; padding-top: 30px; }
  .bottom-footer-list.text-end { text-align: left!important; margin-top: 5px; }

  .bottom-footer-list li { padding-left: 2px; }
  .bottom-footer-list li:after { top: -1px; padding-left: 6px; } 

  /* Inner Page Hero */
  .page-hero-section { padding-top: 45px; padding-bottom: 45px; }
  .page-hero-section h3, .page-hero-section h2 { line-height: 1.35; }
  .page-hero-section p { padding: 0 5px; margin-top: 10px; }

  /* Pagination */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* Terms Page */
  #terms-page.inner-page-hero { margin-top: 70px; padding-top: 70px; }
  .terms-title { margin-bottom: 60px; }
  .terms-title p { margin-top: 15px; }
  .terms-box { margin-bottom: 20px; }
  .terms-box h5 { margin-bottom: 15px; }

  /* Register Page */
  #login-2 .register-form-title, #signup-2 .register-form-title { margin-bottom: 20px; }
  .reset-password-section .register-form-title { margin-bottom: 25px; }

  .register-form-title h4, .register-form-title h3 { font-size: 1.625rem; margin-bottom: 10px; }
  .reset-password-section .register-form-title h4 { font-size: 1.5rem; line-height: 1.3; margin-bottom: 14px; }
  .reset-password-section .register-form-title p { font-size: 0.925rem; padding: 0; }

  .login-separator-txt { font-size: 0.95rem; }

  .login-page-logo { margin-bottom: 40px; }
  #reset-password-1 .login-page-logo {margin-bottom: 30px; }
  .login-page-logo img { max-height: 40px; }
  #reset-password-1 .login-page-logo img {max-height: 38px; }

  .form-data { display: block!important; text-align: center; }
  #signup-1 .form-data, #signup-2 .form-data, #signup-3 .form-data { text-align: left; }

  .form-check { display: inline-block; margin: 0 auto 10px; }
  .form-check .form-check-label, p.forgot-password { font-size: 0.925rem; }

  .sign-in-notice { padding: 0; margin-top: 30px; }
  .sign-in-notice p { font-size: 0.9rem; }

  #login-1 .login-separator-txt:before, #signup-1 .login-separator-txt:before { left: -610%; }
  #login-1 .login-separator-txt:after, #signup-1 .login-separator-txt:after { right: -610%; }

  #login-2 .login-separator-txt:before, #signup-2 .login-separator-txt:before { left: -550%; }
  #login-2 .login-separator-txt:after, #signup-2 .login-separator-txt:after { right: -550%; }

  #login-3 .login-separator-txt:before, #signup-3 .login-separator-txt:before { left: -610%; }
  #login-3 .login-separator-txt:after, #signup-3 .login-separator-txt:after { right: -610%; }

  #login-2 .register-form form, #signup-2 .register-form form { padding: 35px 10px 40px; }

  .register-form form, #login-2 .register-form form, #signup-2 .register-form form { margin: 0; }
  #reset-password-1 .register-form form { margin: 0; }
  .register-form .form-control { font-size: 1rem; height: 48px; }
  .register-form .btn { height: 48px; font-size: 1rem; }

  .btn-show-pass { top: 16px; right: 15px; }
  .btn-show-pass.ico-20 [class^="flaticon-"]:before, .btn-show-pass.ico-20 [class^="flaticon-"]:after { font-size: 1.05rem; }

  .register-form .btn-facebook, .register-form .btn-google { padding: 0; line-height: 48px; }
  .btn-google img, .btn-facebook img { width: 18px; height: 18px; margin-top: -3px; margin-right: 5px; }

  .reset-password-img { margin: 0 0 50px; }

  /* Scroll To Top */
  #scrollUp { width: 36px; height: 36px; bottom: 20px; right: 10px; }



}


@media (min-width: 992px) and (max-width: 1199.95px){
  .techno-sec .over-techno .box{width: 12%;}
  .os-btn-txt{
    padding-left: 10px;    
  }
}
@media (min-width: 300px) and (max-width: 480px){
  .techno-sec .over-techno .box{width: 32%;}
  .os-btn-ico.text-white.os-new-btn-ico{margin-top: "10px" !important;}
}